<template lang="">
  <div class="updateHost" v-draggable>
    <closeTitle :close="close"></closeTitle>
    <div class="regionList">
      <regionListTable ref="hostReference"></regionListTable>
    </div>
    <div class="newHost">
      <div>新主机：</div>
      <div class="" style="width: 60%"><el-input v-model="host" placeholder="例：192.168.1.1:8080"></el-input></div>
      <div style="margin-left: 10px;"><el-button type="primary" @click="updateHost">更新</el-button></div>
    </div>



  </div>
</template>
<script>
  import axios from "axios";
  import closeTitle from "../closeTitle/index.vue";
  import regionListTable from "./regionListTable.vue";
  export default {
    components: {
      regionListTable,
      closeTitle,
    },
    props: {},
    data() {
      return {
        host: ""
      };
    },
    created() { },
    mounted() { },
    methods: {
      //关闭方法
      close() {
        this.$emit("close");
      },
      updateHost() {

        let multipleSelection = this.$refs.hostReference.multipleSelection;
        let regionModelIds = []
        multipleSelection.forEach((item) => {
          regionModelIds.push(item.regionId)
        })
        console.log(multipleSelection, "当前选中项");
        axios.post("/api/Setting/BatchUpdateModelHost", {
          regionModelIds: regionModelIds,
          modelHostNew: this.host
        }).then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.$message({
              message: "更新成功",
              type: "success",
            });
            this.close();
          } else {
            this.$message({
              message: "更新失败",
              type: "error",
            });
          }
        })
      }

    },
  };
</script>
<style lang="scss" scoped>
  .updateHost {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 100;
    height: 300px;
    width: 500px;
    background-color: #fff;

    .regionList {
      height: 200px;
      overflow: auto;
    }

    .newHost {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;

    }
  }
</style>