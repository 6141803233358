import { GetCameraCfg } from './api.js';
import * as Cesium from 'cesium';
import axios from 'axios';
import { videoConfig as vConfig2 } from './videoConfig.js';
import * as videoStreamApi from './videoStreamApi';
import CesiumVideo3d from './CesiumVideo3d';
var previewVideo = [];
var maps = {};
var videos = {};
var handler = null;
var streamTypeForcedGroupList = [];
export class fusion {
  constructor(fuseShow) {
    console.log('fusion', fuseShow);
    this.debounceReturn = null;
    this.videofuseShow = fuseShow;
    this.addvideo = [];
    this.i = 0;
    this.streamType = {};
    this.preview();
  }
  //开始融合
  async preview() {
    console.log('开始融合')
    this.debounceReturn = this.debounce(this.viewMovement, 3000);
    this.addEvent();
    const result = this.getCameraPosition();
    const res = await GetCameraCfg(result, this.viewMovement);
    console.log(res, "获取数据")
    previewVideo.forEach((el) => {
      el.destroy();
    });
    previewVideo = [];
    this.delAndAdd(res);
  }

  /**
   *获取当前视角下存在的相机列表
   * @param {} camera
   */
  // async GetCameraCfg(camera) {
  // 	try {
  // 		const res = await axios.post('/api/Calib/GetEyeView', camera);
  // 		return res;
  // 	} catch (error) {
  // 		console.error(`请求视角投影参数时出现错误,错误原因:`, error);
  // 		setTimeout(() => {
  // 			this.viewMovement();
  // 		}, 1000 * 5);
  // 	}
  // },

  /**
   * 查找当前投影中应关闭和新增的投影
   * @param {*} res
   */
  delAndAdd(res) {
    console.log(`请求视角投影参数时返回的数据:`, res);
    const removeVideo = [];
    let projectVideo;
    if (res.data.data) {
      projectVideo = res.data.data.cameraViewList
        ? res.data.data.cameraViewList
        : [];
      streamTypeForcedGroupList = res.data.data.streamTypeForcedGroupList;
      if (this.streamType && this.streamType != {}) {
        if (res.data.data.streamTypeValue != this.streamType.value) {
          Object.keys(videos).forEach((el) => {
            console.log(el, '关闭的key');
            this.destroyVideo(el);
          });
          videos = {};
          previewVideo = [];
        }
        this.streamType.name = res.data.data.streamTypeName;
        this.streamType.value = res.data.data.streamTypeValue;
      } else {
        this.streamType.name = res.data.data.streamTypeName;
        this.streamType.value = res.data.data.streamTypeValue;
      }
    } else {
      projectVideo = [];
    }
    console.log(previewVideo, "所有投影")
    previewVideo.forEach((e) => {
      let progect = projectVideo.filter((fill) => {
        return (
          fill.id === e.id &&
          fill.selCol === e.selCol &&
          fill.selRow === e.selRow
        );
      });
      if (!progect || progect.length === 0) {
        e.destroy();
        this.projectionInvisible(e);
        removeVideo.push(e);
      }
    });
    console.debug(`需要删除的对象:`, removeVideo);
    removeVideo.forEach((element) => {
      // let i = previewVideo.indexOf(element);
      // console.log("对象", i, element);
      // this.destroyVideo(element.channelId);
      // previewVideo.splice(i, 1);
      let i = previewVideo.indexOf(element);
      previewVideo.splice(i, 1);
    });
    projectVideo.forEach((e) => {
      let preview = previewVideo.filter((fill) => {
        return (
          e.id === fill.id &&
          e.selCol === fill.selCol &&
          e.selRow === fill.selRow
        );
      });
      let add = this.addvideo.filter((a) => {
        return e.id === a.id && e.selCol === a.selCol && e.selRow === a.selRow;
      });
      if ((!preview || preview.length === 0) && (!add || add.length === 0)) {
        this.addvideo.push(e);
      }
    });
    console.debug(`需要添加的对象:`, this.addvideo);
    this.addvideoshow(res.data.data.streamTypeValue);
  }
  addvideoshow(streamTypeValue) {
    if (this.addvideo.length != 0) {
      this.previewData(this.addvideo[this.i], streamTypeValue);
    }
  }

  /**
   * 添加鼠标事件
   */
  async addEvent() {
    handler = new Cesium.ScreenSpaceEventHandler(window.viewer.canvas);

    window.viewer.camera.moveEnd.addEventListener(this.debounceReturn);
  }

  // 防抖函数
  debounce(func, wait) {
    let timeout;
    return () => {
      // 清空定时器
      console.log('防抖,测试视角变化');
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        console.log(this);
        func(this);
      }, wait);
    };
  }

  /**
   * 视角移动重新计算投影
   */
  async viewMovement(_this = null) {
    // if (this.videofuseShow) {
    let thit;
    if (_this == null) {
      thit = this;
    } else {
      thit = _this;
    }

    console.log('进入if开始执行', thit, _this);
    const result = thit.getCameraPosition();
    const res = await GetCameraCfg(result, thit.viewMovement);
    console.log('获取的视角数据', res);

    if (res) {
      thit.delAndAdd(res);
    }
    // }
  }

  /**
   * 格式化视角相机数据取流
   */
  getCameraPosition() {
    return {
      w: window.viewer.scene.canvas.clientWidth,
      h: window.viewer.scene.canvas.clientHeight,
      hFovDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.frustum.fov),
      vFovDegree: Cesium.Math.toDegrees(
        window.viewer.scene.camera.frustum.fovy
      ),
      point: {
        x: window.viewer.scene.camera.position.x,
        y: window.viewer.scene.camera.position.y,
        z: window.viewer.scene.camera.position.z
      },
      yawDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.heading),
      pitchDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.pitch),
      rollDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.roll)
    };

    // return result
  }

  // 预览处理数据
  async previewData(re, streamTypeValue) {
    console.log(re, "预处理投影数据")
    let videoEl = null;
    let map = await this.gitMap(re.id);
    let res = null;
    if (Object.keys(videos).indexOf(String(re.channelId)) === -1) {
      let streamTypeForcedGroup = streamTypeForcedGroupList.filter((el) => {
        return el.channelId == re.channelId;
      });
      res = await axios.post(
        `/api/itosApi/api/VideoStream/RealPlay/Start/${re.channelId}`,
        {
          linkMode: window.jessConfig.linkMode,
          streamType: streamTypeValue,
          onlyIFrame: 0
        }
      );

      let video = null;
      try {
        video = await this._createVideoEle(
          res.data.handle,
          res.data.stream,
          re.channelId
        );
      } catch (error) {
        console.error(`创建视频标签失败,失败原因:${error}`);
      }

      if (
        videos[re.channelId]
        // && videos[re.channelId] !== {}
      ) {
        video.videoPlayer.destroy();
        video.videoel = null;
        this.videoClearInterval(video.Interval);
        // clearInterval(video.Interval);
        return;
      }
      videos[re.channelId] = {};
      videos[re.channelId].videoel = video.videoel;
      videos[re.channelId].videoPlayer = video.videoPlayer;
      videos[re.channelId].Interval = video.Interval;
      videos[re.channelId].handle = res.data.handle;
      // videos[re.channelId].alpha = 1.0;
      videos[re.channelId].index = 0;
      videos[re.channelId].index += 1;
    } else {
      videos[re.channelId].index += 1;
    }
    videoEl = videos[re.channelId].videoel;
    console.log('视频加载完成', '投影', re.channelId);
    const datas = {};
    datas.alpha = videos[re.channelId].alpha
      ? videos[re.channelId].alpha
      : window.startAlpha; // 透明度
    datas.aspectRatio =
      Math.tan(Cesium.Math.toRadians(re.hfov) / 2) /
      Math.tan(Cesium.Math.toRadians(re.vfov) / 2); // 投影纵横比
    datas.debugFrustum = false; // 是否显示视锥线
    datas.far = re.farPlane; // 远平面
    datas.fov = datas.aspectRatio <= 1 ? re.vfov : re.hfov;
    datas.fovy = re.vfov; // 纵向视场角
    datas.elevation = re.elevation; //相机高度
    datas.id = re.id; // 相机id
    datas.near = re.nearPlane; // 近平面
    datas.position = { x: re.lng, y: re.lat, z: re.height }; // 相机位置
    datas.rotation = { x: re.pitch, y: re.yaw, z: re.roll }; // 俯仰横摆旋转角
    datas.scale = re.scale;
    datas.selRow = re.selRow;
    datas.selCol = re.selCol;
    datas.channelId = re.channelId;
    datas.type = 3; // 投影类型
    datas.mapx = map.mapx;
    datas.mapy = map.mapy;
    datas.mask = map.mask;
    datas.that = this;
    datas.videoEle = videoEl;
    datas.splitPixel = re.pixel;
    datas.streamMediaCustomId = re.streamMediaCustomId;
    let splitSrcWH = {
      Height: re.splitSrcHeight,
      Width: re.splitSrcWidth
    };
    datas.splitSrcWH = splitSrcWH;
    this.addCesiumVideo(datas);
  }

  // 预览投影方法
  async addCesiumVideo(data) {
    try {
      await new CesiumVideo3d(Cesium, window.viewer, data).then((value) => {
        let toBePlay = value;
        let found = previewVideo.find((e) => {
          return e.id == toBePlay.id;
        });
        if (found && found != undefined) {
          toBePlay.destroy();
          return;
        }
        if (this.videofuseShow) {
          previewVideo.push(toBePlay);
          if (this.i == this.addvideo.length - 1) {
            (this.i = 0), (this.addvideo = []);
            // if (this.loading && this.loading !== null) {
            //   // this.loading.close();
            // }
          } else {
            this.i += 1;
            this.addvideoshow();
          }
        } else {
          toBePlay.destroy();
          if (videos[data.channelId]) {
            videos[data.channelId].videoPlayer.destroy();
            this.videoClearInterval(videos[data.channelId].Interval);
            // clearInterval(videos[data.channelId].Interval);
          }
          //还要停止保活
          this.addvideo = [];
        }
      });
    } catch (error) {
      console.error(`新建投影时出错,错误原因:${error}`);
    }
  }

  /** 创建视频Element
   * @param {String} url 视频地址
   **/
  async _createVideoEle(handle, url, channelId) {
    this.videoId = 'visualDomId';
    let section = document.createElement('section');

    const video = await this._flvPlayerOn(url, section, channelId);
    const Interval = setInterval(async () => {
      // let { data } = await axios.post("/api/Media/RealPlayStatus", {
      //   Cameraid: 0,
      //   ChannelId: channelId,
      //   streamHandle: 8766,
      //   streamMediaCustomId: 1,
      // });
      // let { data } = await axios.post(
      //   `/api/VideoStream/${handle}/${channelId}/StreamStatus`
      // );
      // if (data.errorID === 1) {
      //   this.linkBreak(channelId);
      // }
      await videoStreamApi.streamStatus(handle, channelId, (reason) => {
        // clearInterval(this.timer2[winKey]);
        // this.ReconnectionVideo(winKey, reason);
        this.linkBreak(channelId);
      });
    }, 1000 * 10);
    video.Interval = Interval;
    return video;
  }
  _flvPlayerOn(url, section, channelId) {
    console.debug(`创建jess播放器`, channelId);
    let config = {
      ...vConfig2,
      ...window.jessConfig,
      ...window.jessConfigModelOverride
    };
    let videoPlayer = new JessibucaPro({
      container: section,
      decoder: '/jess/decoder-pro-simd.js',
      ...config
    });

    videoPlayer.play(url).then(() => {
      //处理播放器错误事件
      videoPlayer.once('error', (error) => {
        console.debug(`播放器错误,`);
        if (error === JessibucaPro.ERROR.fetchError) {
          this.linkBreak(channelId);
        } else if (error === JessibucaPro.ERROR.websocketError) {
          this.linkBreak(channelId);
        } else {
          console.debug(`未处理的error场景,错误=${error}`);
          this.linkBreak(channelId);
        }
      });

      // //针对画面播放时冻结进行重播
      // videoPlayer.on('stats', (stats) => {
      //   let shouldReplay =
      //     window.connConfig.mseVideoBufferDelayTime &&
      //     stats.mseVideoBufferDelayTime >=
      //     window.connConfig.mseVideoBufferDelayTime;
      //   console.debug(`画面冻结,${shouldReplay},${stats.mseVideoBufferDelayTime}`);
      //   if (shouldReplay) {
      //     this.linkBreak(channelId);
      //   }
      // });
      // //视频播放回调
      // videoPlayer.on('start', (start) => {
      //   videos[channelId].alpha = window.endAlpha;
      //   previewVideo.forEach((element) => {
      //     if (element.channelId == channelId) {
      //       element.alpha = window.endAlpha;
      //     }
      //   });
      // });

      // //当播放器停止播放时尝试重播
      // videoPlayer.once('playFailedAndPaused', (reason) => {
      //   console.debug(`检测到播放器 playFailedAndPaused 状态`);
      //   if (window.connConfig['replayWhenPlayFailedAndPaused']) {
      //     this.linkBreak(channelId);
      //   } else {
      //     console.debug(`检测到播放器 playFailedAndPaused 状态,但未启用重播机制`);
      //   }
      // });
    });
    let videoElement = videoPlayer.$container.querySelector('video');
    return {
      videoel: videoElement,
      videoPlayer: videoPlayer
    };
  }
  //当流出现问题时处理
  async linkBreak(channelId) {
    if (videos[channelId]) {
      await this.destroyVideo(channelId);
      this.viewMovement();
    }
  }
  //当视角移动时处理
  projectionInvisible(el) {
    if (!videos[el.channelId]) {
      return;
    }
    videos[el.channelId].index -= 1;

    if (videos[el.channelId].index === 0) {
      this.videoClearInterval(videos[el.channelId].Interval);
      // clearInterval(videos[el.channelId].Interval);
      videos[el.channelId].videoPlayer.destroy();
      this.videoStop(el.channelId, videos[el.channelId].handle);
      videos[el.channelId].videoel = null;
      delete videos[el.channelId];
      console.log(`${el.channelId}通道关闭,剩余:`);
      console.log(videos);
    }
  }
  destroyVideo(channelId) {
    console.log('销毁', channelId, previewVideo);
    let del = previewVideo.filter((el) => {
      return el.channelId == channelId;
    });
    del.forEach((d) => {
      let int = previewVideo.findIndex((el, index) => {
        return el.id === d.id;
      });
      d.destroy();
      previewVideo.splice(int, 1);
      this.projectionInvisible(d);
      console.debug(`${channelId}中的投影关闭一个,剩余:`);
      console.log(previewVideo);
    });
  }
  // 停止视频预览
  async videoStop(channelId, handle) {
    if (handle) {
      // 过滤undefind
      await axios.post(`/api/itosApi/api/VideoStream/${handle}/${channelId}/RealPlay/Stop`);
    }
  }

  //获取正畸文件转化为纹理
  async getimg(url) {
    return await new Promise((resolve) => {
      let img = new Image();
      img.src = url;
      img.onload = function () {
        // that.type = that.optionType.Image
        let Texture = new Cesium.Texture({
          context: window.viewer.scene.context,
          source: img
        });
        resolve(Texture);
      };
      img.onerror = function () { };
    }).then((testure) => {
      return testure;
    });
  }
  async gitMap(id) {
    let map = {};
    if (maps[id]) {
      map = maps[id];
    } else {
      await this.getimg(
        '/img/mapfile/' + window.AppConfig.mapfileSampleInterval + '/' + id + '.mapx.bmp'
      ).then((value) => {
        map.mapx = value;
      });
      await this.getimg(
        '/img/mapfile/' + window.AppConfig.mapfileSampleInterval + '/' + id + '.mapy.bmp'
      ).then((value) => {
        map.mapy = value;
      });
      await this.getimg('/img/mapfile/' + id + '.mask.bmp').then((value) => {
        map.mask = value;
      });
      maps[id] = map;
    }
    return map;
  }
  destroy() {
    console.log('销毁所有', videos);
    if (handler) {
      handler.destroy();
    }
    window.viewer.camera.moveEnd.removeEventListener(this.debounceReturn);
    Object.keys(videos).forEach(async (id) => {
      console.log('销毁', id);
      await this.destroyVideo(Number(id));
    });
  }
  videoClearInterval(Interval) {
    console.log('销毁定时器')
    clearInterval(Interval)
  }
}
