import * as Cesium from "cesium";
export function useDem(props) {
  function addDem(url) {
    console.log("加载地形数据", url);
    let terr = new Cesium.CesiumTerrainProvider({
      url: url,
      // 请求照明
      // requestVertexNormals: true,
      // 请求水波纹效果
      // requestWaterMask: true,
    });
    window.viewer.terrainProvider = terr;
    return terr;
  }

  function removeDem() {
    console.log("卸载地形数据");
    window.viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider({});
    return;
  }

  return {
    addDem,
    removeDem,
  };
}
