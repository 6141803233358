/**
 *获取视野相机参数
 * @param {*} Cesium
 * @param {*} Viewer
 * @returns
 */
export function getCameraParameter(Cesium, Viewer) {
  let camera = Viewer.camera;
  let heading = camera.heading * (180 / Math.PI);
  let pitch = camera.pitch * (180 / Math.PI);
  let roll = camera.roll * (180 / Math.PI);
  let position = camera.position;
  let cartographic1 = Cesium.Cartographic.fromCartesian(position);
  var lng = Cesium.Math.toDegrees(cartographic1.longitude);
  var lat = Cesium.Math.toDegrees(cartographic1.latitude);
  var height = cartographic1.height; // 模型高度
  return {
    heading: heading,
    pitch: pitch,
    roll: roll,
    cartesian: position,
    coordinate: {
      lng: lng,
      lat: lat,
      height: height,
    },
  };
}
/**
 *
 * @param {*} Cesium
 * @param {*} Viewer
 * @param {*} newCesiumVideo3d
 * @returns
 */
export function getEntitySceneParameter(Cesium, Viewer, newCesiumVideo3d) {
  let camera = newCesiumVideo3d.fixedCamera;
  let heading = camera.heading * (180 / Math.PI);
  let pitch = camera.pitch * (180 / Math.PI);
  let roll = camera.roll * (180 / Math.PI);
  let position = camera.position;
  let cartographic1 = Cesium.Cartographic.fromCartesian(position);
  var lng = Cesium.Math.toDegrees(cartographic1.longitude);
  var lat = Cesium.Math.toDegrees(cartographic1.latitude);
  var height = cartographic1.height; // 模型高度
  console.log(
    `h:${heading},p:${pitch},r:${roll},lng:${lng},lat:${lat},hei:${height}`
  );
  return {
    heading: heading,
    pitch: pitch,
    roll: roll,
    lng: lng,
    lat: lat,
    height: height,
  };
}
