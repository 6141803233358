import { jessConfig } from "./mapJessConfig.js";
import axios from "axios";
//初始化jessibuca返回jess和生成的video标签
function jessCreate(url, fun) {
  var DIV = document.createElement("div");
  var config = jessConfig(DIV);

  function create() {
    console.log("创建");
    var jess = new JessibucaPro(config);
    let video = jess.$container.querySelector("video");
    jess.play(url).then(() => {
      //处理播放器错误事件
      // jess.once("error", (error) => {
      //   log.debug(`播放器错误,`);
      //   if (error === JessibucaPro.ERROR.fetchError) {
      //     // this.linkBreak(channelId);
      //     create();
      //     fun();
      //   } else if (error === JessibucaPro.ERROR.websocketError) {
      //     // this.linkBreak(channelId);
      //     create();
      //     fun();
      //   } else {
      //     // log.debug(`未处理的error场景,错误=${error}`);
      //     // this.linkBreak(channelId);
      //   }
      // });
      // //针对画面播放时冻结进行重播
      // jess.on("stats", (stats) => {
      //   // let shouldReplay =
      //   //   window.connConfig.mseVideoBufferDelayTime &&
      //   //   stats.mseVideoBufferDelayTime >=
      //   //     window.connConfig.mseVideoBufferDelayTime;
      //   // log.debug(`画面冻结,${shouldReplay},${stats.mseVideoBufferDelayTime}`);
      //   // if (shouldReplay) {
      //   //   this.linkBreak(channelId);
      //   // }
      //   create();
      //   fun();
      // });
      //当播放器停止播放时尝试重播
      jess.once("playFailedAndPaused", (reason) => {
        console.log("停止播放");
        // log.debug(`检测到播放器 playFailedAndPaused 状态`);
        // if (window.connConfig["replayWhenPlayFailedAndPaused"]) {
        //   this.linkBreak(channelId);
        // } else {
        //   log.debug(`检测到播放器 playFailedAndPaused 状态,但未启用重播机制`);
        // }
        // jess.destroy().then(() => {
        //   create();
        //   fun();
        // });
        jessDestroy(jess, () => {
          create();
          fun();
        });
      });
    });

    return { video: video, jess: jess };
  }
  return create();
}
function jessDestroy(jess, fun) {
  jess.destroy().then(() => {
    fun();
  });
}
//播放后进行保活
function jessVideoKeepAlive(ids, channelIds, handles, streamMediaCustomIds) {
  let id = ids
  let channelId = channelIds
  let handle = handles
  let streamMediaCustomId = streamMediaCustomIds
  const Interval = setInterval(async () => {
    // let res = await axios.post("/api/itosApi/Media/RealPlayStatus", {
    //   Cameraid: id,
    //   ChannelId: channelId,
    //   streamHandle: handle,
    //   streamMediaCustomId: streamMediaCustomId,
    // });
    console.log("单个保活", ids, channelIds, handles)
    let res = await axios.post(`/api/itosApi/api/VideoStream/${handle}/${channelId}/StreamStatus`)
    console.log(res, "保活返回")
    if (res.status == 200) {
      console.log("保活成功");
      // this.setFireAndForgot(
      //   {
      //     Cameraid: this.id,
      //     ChannelId: this.channelId,
      //     streamHandle: this.handle,
      //   },
      //   res.data.data,
      //   2
      // );
    } else {
      this.$message.error("保活断开");
    }
  }, 1000 * 15);
  return Interval;
}
export { jessCreate, jessVideoKeepAlive };
