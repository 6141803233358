<template>
  <div class="poleList" v-draggable>
    <closeTitle :close="close"></closeTitle>
    <div class="pole">
      <el-table :data="pole" highlight-current-row stripe style="width: 100%" max-height="400" ref="singleTable">
        <el-table-column label="#" type="index" width="50"> </el-table-column>
        <el-table-column prop="poleID" label="杆子" width="100">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit item" title="修改视角" @click.stop="updateCamera(scope.row)"></i>
            <i v-if="!scope.row.projection" class="el-icon-video-camera item" title="杆子投影"
              @click.stop="projection(scope.row)"></i>
            <i v-else class="el-icon-close item" title="关闭投影" @click.stop="destroyProjection(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import closeTitle from "../closeTitle/index.vue";
  import { getCameraParameter } from "../../views/cesiumUtils/cesiumEntitys.js";
  import * as cesium from "cesium"
  import poleInit from "../../views/PolePreview/poleIndex.js"
  export default {
    name: "poleList",
    props: {
      close: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      closeTitle
    },
    data() {
      return {
        pole: null
      };
    },
    created() {
      this.getPoleList()
    },
    beforeDestroy() {

    },
    mounted() {

    },
    methods: {
      async getPoleList() {
        console.log("请求杆子")
        let activeRegion = window.sessionStorage.getItem("activeRegion")
        if (activeRegion) {
          activeRegion = JSON.parse(activeRegion)
          activeRegion = [activeRegion.regionId]
        } else {
          activeRegion = [""]
        }
        let rew = await axios.post("/api/Setting/GetPoleList", {
          regionModelIdList: activeRegion,
          poleIdList: [

          ],
          attachCameraProjectInfoList: true
        })
        console.log(rew, "返回参数")
        this.pole = rew.data.data
      },

      updateCamera(item) {
        console.log("设置最佳视角")
        let camera = getCameraParameter(cesium, window.viewer)
        console.log("设置最佳视角", camera)
        axios.post("/api/Setting/SetPoleBestView", {
          poleID: item.poleID,
          longitude: camera.coordinate.lng,
          latitude: camera.coordinate.lat,
          height: camera.coordinate.height,
          pitch: camera.pitch,
          yaw: camera.heading,
          roll: camera.roll
        }).then((row) => {
          console.log(row, "返回数据")
          if (row.status == 200) {
            this.$message({
              message: '设置当前视角为杆子最佳视角',
              type: 'success'
            });
          } else {
            this.$message.error('设置最佳视角失败');
          }

        })


      },
      projection(row) {
        console.log(row)
        let projection = new poleInit(row)
        this.$set(row, 'projection', projection)

      },
      destroyProjection(row) {
        row.projection.destroy()
        // row.projection = null
        this.$set(row, 'projection', null)

      }



    },
  };
</script>
<style lang="scss" scoped>
  .poleList {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: #fff;
    height: 400px;
    background-color: #fff;

    .item {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
</style>