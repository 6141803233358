import * as cesium from "cesium"
import cameraInit from "./cameraIndex"
import {
  GetPoleList,
  realPlayStart,
  streamStatus,
  realPlayStop
} from "./previewApi.js"
export default class poleInit {
  constructor(pole) {
    this.pole = JSON.parse(JSON.stringify(pole))
    this.init()
  }
  async init() {
    // let res = await GetPoleList(["huoerguosi"], [], true)
    // console.log(res.data.data[2])
    // this.pole = res.data.data[2]
    if (!window.order || window.order == "并行") {
      this.pole.cameraProjectInfoList.forEach((camera) => {
        let obCamera = new cameraInit(camera)
        obCamera.init()
        camera.obCamera = obCamera
      })
    } else if (window.order == "串行") {
      console.log("串行")
      try {
        for (let index = 0; index < this.pole.cameraProjectInfoList.length; index++) {
          const camera = this.pole.cameraProjectInfoList[index];
          let obCamera = new cameraInit(camera)
          camera.obCamera = obCamera
          await obCamera.init()

        }
      } catch (error) {
        console.log(error, "循环")
      }

    }

  }
  destroy() {
    this.pole.cameraProjectInfoList.forEach((camera) => {
      console.log(camera)
      if (camera.obCamera && camera.obCamera != null) {
        camera.obCamera.destroy()
      }
    })
    // this.pole.cameraProjectInfoList = []
  }

}