import * as Cesium from "cesium";
async function getimg(url) {
  return await new Promise((resolve) => {
    let img = new Image();
    img.src = url;
    img.onload = function () {
      // that.type = that.optionType.Image
      let Texture = new Cesium.Texture({
        context: window.viewer.scene.context,
        source: img,
      });
      resolve(Texture);
    };
    img.onerror = function () { };
  }).then((testure) => {
    return testure;
  });
}
export { getimg };
