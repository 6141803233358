import axios from "axios"
async function GetPoleList(regionModelIdList, poleIdList, attachCameraProjectInfoList) {

    const res = await axios.post('/api/Setting/GetPoleList', {
        regionModelIdList: regionModelIdList,
        poleIdList: poleIdList,
        attachCameraProjectInfoList: attachCameraProjectInfoList
    });
    console.log(res, '返回的值');
    return res;

}

async function realPlayStart(channelId, streamTypeValue) {
    const res = await axios.post(
        `/api/itosApi/api/VideoStream/RealPlay/Start/${channelId}`,
        {
            linkMode: window.jessConfig.linkMode,
            streamType: streamTypeValue,
            onlyIFrame: 0
        }
    );
    return res
}

//实时预览心跳保活
async function streamStatus(handle, channelId, errorCallback) {
    let error = undefined;
    let data = null;
    try {
        let result = await axios.post(
            `/api/itosApi/api/VideoStream/${handle}/${channelId}/StreamStatus`
        );
        data = result;
    } catch (ex) {
        error = ex;
    }

    if (!data || data.status != 200) {
        //心跳接口errorId非0时代表出错
        error = '心跳接口响应异常';
        console.debug(error)
    }
    if (error != undefined) {
        errorCallback && errorCallback();
    }
    return data;
}
async function realPlayStop(handle, channelId) {
    await axios.post(`/api/itosApi/api/VideoStream/${handle}/${channelId}/RealPlay/Stop`);
}
export {
    GetPoleList,
    realPlayStart,
    streamStatus,
    realPlayStop
}


