import * as Cesium from "cesium";
export class userCreateViewer {
  constructor(el) {
    this.el = el;
    console.log(el, "el");
    this.viewer = window.viewer = this.createViewer();
  }
  createViewer() {
    const viewerOption = {
      sceneMode: Cesium.SceneMode.SCENE3D, //使用3D模式
      geocoder: false, // 地理位置查询定位控件
      homeButton: false, // 默认相机位置控件
      timeline: false, // 时间滚动条控件
      navigationHelpButton: false, // 默认的相机控制提示控件
      fullscreenButton: false, // 全屏控件
      scene3DOnly: true, // 每个几何实例仅以3D渲染以节省GPU内存
      baseLayerPicker: false, // 底图切换控件
      animation: false, // 控制场景动画的播放速度控件
      vrButton: false, //关闭vr控件
      sceneModePicker: false, //关掉图层选择控件,才能加载其他影像数据
      infoBox: window.infoBox, //选中实体时弹出实体信息框
      skyAtmosphere: false, //天空大气,new Cesium.SkyAtmosphere()
      shadows: false, //阴影是否由光源投射,启用阴影会消耗性能
    };
    if (window.AppConfig.cToken && window.AppConfig.cToken != "") {
      Cesium.Ion.defaultAccessToken = window.AppConfig.cToken;
    } else {
      viewerOption.imageryProvider = false;
    }
    //加载cesium地形
    if (window.cesiumViewerConfig.cesiumTerrainProvider) {
      viewerOption.terrainProvider = new Cesium.CesiumTerrainProvider({
        url: Cesium.IonResource.fromAssetId(1),
        requestVertexNormals: true,
      });
    }
    if (window.cesiumViewerConfig.cesiumImageryProvider) {
      viewerOption.imageryProvider = new Cesium.IonImageryProvider({
        assetId: 4,
      });
    }
    //加载全球低精度影像，当baseTms地址不存在时，加载cesium影像
    if (window.AppConfig.baseTms && window.AppConfig.baseTms != "") {
      viewerOption.imageryProvider = new Cesium.TileMapServiceImageryProvider({
        url: window.AppConfig.baseTms.url,
        fileExtension: window.AppConfig.baseTms.imgType,
        minimumLevel: 1,
        maximumLevel: 18,
      });
    }
    const viewer = new Cesium.Viewer(this.el, viewerOption);

    viewer.scene.light = new Cesium.DirectionalLight({
      direction: new Cesium.Cartesian3(0.354925, -0.890918, -0.283358),
    });
    // viewer.scene.globe.show = false;

    // viewer.imageryLayers.get(0).show = false;//隐藏默认影像
    viewer.extend(Cesium.viewerDragDropMixin);
    viewer.dropError.addEventListener(function (dropHandler, name, error) {
      console.log("cesium崩溃,重新运行cesium", error);
      window.alert(error);
    });
    viewer.scene.renderError.addEventListener(() => {
      // this.$nextTick(() => {
      //   console.log("重新加载")
      //   // viewer = new Cesium.Viewer("cesiumContainer", viewerOption);
      //   this.$router.go(0)
      // })
      // viewer.destroy()
    });
    //使用xyz加载影响
    // const imag = new Cesium.UrlTemplateImageryProvider({
    //   url: "http://192.168.0.190:9400/static/3D/charts/{z}/{x}/{y}/{x}_{y}.png",
    //   // url: "http://mt1.google.cn/vt/lyrs=s&hl=zh-CN&x={x}&y={y}&z={z}&s=Gali",
    //   tilingScheme: new Cesium.WebMercatorTilingScheme(),
    //   maximumLevel: 18,
    // })
    // let i = viewer.imageryLayers.addImageryProvider(imag);
    // viewer.flyTo(i);


    viewer._cesiumWidget._creditContainer.style.display = "none"; // 隐藏版权
    viewer.scene.debugShowFramesPerSecond = true;
    // viewer.scene.logarithmicDepthBuffer = true;//使用对数深度缓冲区
    // 开启地形深度检测
    viewer.scene.globe.depthTestAgainstTerrain = true;
    // 开启抗锯齿
    viewer.scene.fxaa = false;
    viewer.scene.postProcessStages.fxaa.enabled = false;
    // viewer.scene.sun.show = false; //在Cesium1.6(不确定)之后的版本会显示太阳和月亮，不关闭会影响展示
    // viewer.scene.moon.show = false;
    // viewer.scene.skyBox.show = false; //关闭天空盒，否则会显示天空颜色

    // viewer.scene.undergroundMode = true; //重要，开启地下模式，设置基色透明，这样就看不见黑色地球了
    // viewer.scene.underGlobe.show = true;
    // viewer.scene.underGlobe.baseColor = new Cesium.Color(0, 0, 0, 0);
    // viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以
    // viewer.scene.globe.baseColor = new Cesium.Color(0, 0, 0, 0);
    // viewer.scene.backgroundcolor = new Cesium.Color(0, 0, 0, 0);
    // viewer.scene.globe.enableLighting=true
    //调整分辨率解决锯齿问题
    let supportsImageRenderingPixelated =
      viewer.cesiumWidget._supportsImageRenderingPixelated;
    if (supportsImageRenderingPixelated) {
      let vtxfDpr = devicePixelRatio;
      while (vtxfDpr >= 2.0) {
        vtxfDpr /= 2.0;
      }
      viewer.resolutionScale = vtxfDpr;
    }
    // let credit = viewer.cesiumWidget.creditContainer;
    // credit.style.display = "none";
    viewer.camera.percentageChanged = 0.00000001;
    return viewer;
  }
}
