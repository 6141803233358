export const positionData = [
  [42.382316, 92.937955],
  [42.382488, 92.937713],
  [42.38254, 92.937506],
  [42.38254, 92.937506],
  [42.382626, 92.937331],
  [42.382626, 92.937331],
  [42.382626, 92.937331],
  [42.382801, 92.937118],
  [42.382801, 92.937118],
  [42.383008, 92.937058],
  [42.383076, 92.937043],
  [42.383155, 92.937018],
  [42.383155, 92.937018],
  [42.383328, 92.936933],
  [42.383328, 92.936933],
  [42.383478, 92.936848],
  [42.383543, 92.936808],
  [42.383678, 92.936715],
  [42.383678, 92.936715],
  [42.383678, 92.936715],
  [42.38376, 92.936601],
  [42.383776, 92.936528],
  [42.383776, 92.936528],
  [42.383761, 92.936388],
  [42.383698, 92.936273],
  [42.383698, 92.936273],
  [42.383698, 92.936273],
  [42.383563, 92.936223],
  [42.383433, 92.936226],
  [42.383433, 92.936226],
  [42.383303, 92.936263],
  [42.383303, 92.936263],
  [42.383175, 92.93634],
  [42.383111, 92.936365],
  [42.383111, 92.936365],
  [42.38299, 92.936428],
  [42.382873, 92.936496],
  [42.382823, 92.936531],
  [42.382823, 92.936531],
  [42.382703, 92.936605],
  [42.382703, 92.936605],
  [42.382575, 92.936681],
  [42.382506, 92.936708],
  [42.382506, 92.936708],
  [42.382398, 92.936766],
  [42.382301, 92.936805],
  [42.382301, 92.936805],
  [42.382196, 92.936838],
  [42.382141, 92.936861],
  [42.382141, 92.936861],
  [42.382048, 92.936913],
  [42.382048, 92.936913],
  [42.381956, 92.936965],
  [42.381908, 92.936996],
  [42.381908, 92.936996],
  [42.38181, 92.937033],
  [42.381715, 92.937068],
  [42.381715, 92.937068],
  [42.381661, 92.937088],
  [42.381546, 92.937113],
  [42.381546, 92.937113],
  [42.381435, 92.937138],
  [42.381435, 92.937138],
  [42.381385, 92.93715],
  [42.381271, 92.937176],
  [42.381271, 92.937176],
  [42.381185, 92.937186],
  [42.381105, 92.937186],
  [42.381061, 92.9372],
  [42.381061, 92.9372],
  [42.380971, 92.937201],
  [42.380971, 92.937201],
  [42.3809, 92.937193],
  [42.3809, 92.937193],
  [42.38087, 92.937181],
  [42.380811, 92.937165],
  [42.380756, 92.93715],
  [42.380756, 92.93715],
  [42.380693, 92.937151],
  [42.380653, 92.937153],
  [42.380653, 92.937153],
  [42.380586, 92.937165],
  [42.380586, 92.937165],
  [42.380526, 92.937196],
  [42.380505, 92.937215],
  [42.380505, 92.937215],
  [42.38046, 92.93727],
  [42.38046, 92.93727],
  [42.380418, 92.937305],
  [42.380405, 92.937305],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.38039, 92.937325],
  [42.380008, 92.937198],
  [42.380003, 92.9372],
  [42.379993, 92.937201],
  [42.379973, 92.937203],
  [42.379973, 92.937203],
  [42.379958, 92.937198],
  [42.379958, 92.937198],
  [42.379911, 92.937183],
  [42.379911, 92.937183],
  [42.379895, 92.937151],
  [42.37988, 92.937131],
  [42.379861, 92.937093],
  [42.379861, 92.937093],
  [42.379866, 92.93704],
  [42.37986, 92.937011],
  [42.37986, 92.937011],
  [42.379821, 92.936978],
  [42.379821, 92.936978],
  [42.379811, 92.936925],
  [42.379801, 92.936891],
  [42.379806, 92.936871],
  [42.379806, 92.936871],
  [42.379806, 92.936871],
  [42.379821, 92.936826],
  [42.379831, 92.936811],
  [42.379828, 92.936795],
  [42.379828, 92.936795],
  [42.379856, 92.936778],
  [42.379856, 92.936778],
  [42.379856, 92.936778],
  [42.379863, 92.93677],
  [42.379863, 92.936771],
  [42.379863, 92.936771],
  [42.379885, 92.936773],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.37991, 92.93675],
  [42.379821, 92.936818],
  [42.3798, 92.936825],
  [42.3798, 92.936825],
  [42.379786, 92.936835],
  [42.379768, 92.93683],
  [42.379768, 92.93683],
  [42.379745, 92.93683],
  [42.379745, 92.93683],
  [42.379743, 92.93685],
  [42.37974, 92.936855],
  [42.37974, 92.936855],
  [42.379736, 92.93687],
  [42.379753, 92.936885],
  [42.379753, 92.936885],
  [42.379751, 92.936893],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379766, 92.936895],
  [42.379866, 92.936895],
  [42.379871, 92.936901],
  [42.379878, 92.936888],
  [42.379878, 92.936888],
  [42.379893, 92.936875],
  [42.379893, 92.936875],
  [42.379903, 92.936871],
  [42.379911, 92.93687],
  [42.379911, 92.93687],
  [42.379938, 92.936861],
  [42.379945, 92.936866],
  [42.379943, 92.93686],
  [42.379943, 92.93686],
  [42.379961, 92.936888],
  [42.379961, 92.936888],
  [42.379975, 92.936895],
  [42.379985, 92.936901],
  [42.379985, 92.936901],
  [42.380033, 92.936905],
  [42.380056, 92.936921],
  [42.380056, 92.936921],
  [42.380061, 92.93694],
  [42.380076, 92.936965],
  [42.380076, 92.936965],
  [42.380076, 92.936965],
  [42.380106, 92.936996],
  [42.38014, 92.937031],
  [42.38017, 92.93706],
  [42.38017, 92.93706],
  [42.380206, 92.937105],
  [42.380206, 92.937105],
  [42.380271, 92.937145],
  [42.380293, 92.937156],
  [42.380293, 92.937156],
  [42.38036, 92.937156],
  [42.380401, 92.937148],
  [42.380401, 92.937148],
  [42.380425, 92.937153],
  [42.380471, 92.937141],
  [42.380471, 92.937141],
  [42.380515, 92.937161],
  [42.380515, 92.937161],
  [42.380551, 92.937161],
  [42.380566, 92.937156],
  [42.380601, 92.937155],
  [42.380601, 92.937155],
  [42.380643, 92.937156],
  [42.380643, 92.937156],
  [42.380666, 92.93715],
  [42.380718, 92.937153],
  [42.380718, 92.937153],
  [42.380778, 92.937165],
  [42.380848, 92.937161],
  [42.380848, 92.937161],
  [42.380895, 92.937163],
  [42.380971, 92.937176],
  [42.380971, 92.937176],
  [42.381068, 92.937196],
  [42.381108, 92.937213],
  [42.381108, 92.937213],
  [42.381191, 92.937213],
  [42.381191, 92.937213],
  [42.381266, 92.937208],
  [42.381301, 92.937208],
  [42.38138, 92.937203],
  [42.38138, 92.937203],
  [42.381485, 92.937176],
  [42.381485, 92.937176],
  [42.381528, 92.93715],
  [42.381613, 92.937111],
  [42.381613, 92.937111],
  [42.381721, 92.937058],
  [42.381838, 92.936965],
  [42.381838, 92.936965],
  [42.381905, 92.936928],
  [42.382038, 92.936868],
  [42.382038, 92.936868],
  [42.382148, 92.936818],
  [42.382148, 92.936818],
  [42.382205, 92.9368],
  [42.38233, 92.936718],
  [42.38233, 92.936718],
  [42.382451, 92.936683],
  [42.382515, 92.93666],
  [42.382643, 92.936618],
  [42.382643, 92.936618],
  [42.382775, 92.936561],
  [42.382775, 92.936561],
  [42.382913, 92.936495],
  [42.382981, 92.93646],
  [42.382981, 92.93646],
  [42.383095, 92.936403],
  [42.383095, 92.936403],
  [42.383203, 92.93633],
  [42.383255, 92.936296],
  [42.38337, 92.936246],
  [42.38337, 92.936246],
  [42.383476, 92.9362],
  [42.383476, 92.9362],
  [42.383476, 92.9362],
  [42.383623, 92.93619],
  [42.383623, 92.93619],
  [42.383725, 92.936263],
  [42.383765, 92.93631],
  [42.383793, 92.936373],
  [42.383808, 92.936431],
  [42.383808, 92.936431],
  [42.383768, 92.93659],
  [42.383698, 92.936715],
  [42.383698, 92.936715],
  [42.38364, 92.93676],
  [42.383488, 92.936821],
  [42.383488, 92.936821],
  [42.38332, 92.93689],
  [42.383166, 92.936955],
  [42.383166, 92.936955],
  [42.3831, 92.936973],
  [42.382958, 92.937036],
  [42.382958, 92.937036],
  [42.382815, 92.937096],
  [42.382815, 92.937096],
  [42.382696, 92.937183],
  [42.382646, 92.937246],
  [42.382646, 92.937246],
  [42.382536, 92.937423],
  [42.382468, 92.937596],
  [42.382441, 92.937678],
  [42.382441, 92.937678],
  [42.382375, 92.93781],
  [42.382375, 92.93781],
  [42.382266, 92.937878],
  [42.382198, 92.937916],
  [42.382198, 92.937916],
  [42.382061, 92.937968],
  [42.381901, 92.93806],
  [42.381901, 92.93806],
  [42.381821, 92.938113],
  [42.381663, 92.93823],
  [42.381663, 92.93823],
  [42.381511, 92.93835],
  [42.381511, 92.93835],
  [42.381353, 92.938486],
  [42.381273, 92.93858],
  [42.381273, 92.93858],
  [42.381155, 92.938761],
  [42.381015, 92.93892],
  [42.380951, 92.938976],
  [42.380951, 92.938976],
  [42.380835, 92.939098],
  [42.380835, 92.939098],
  [42.380716, 92.939211],
  [42.380716, 92.939211],
  [42.380653, 92.939253],
  [42.38052, 92.939341],
  [42.38039, 92.939426],
  [42.38039, 92.939426],
  [42.38025, 92.939518],
  [42.380181, 92.939565],
  [42.380181, 92.939565],
  [42.38005, 92.939648],
  [42.38005, 92.939648],
  [42.38005, 92.939648],
  [42.379885, 92.93988],
  [42.37982, 92.94007],
  [42.37982, 92.94007],
  [42.37978, 92.94026],
  [42.37978, 92.94026],
  [42.379731, 92.940446],
  [42.37972, 92.940545],
  [42.37972, 92.940545],
  [42.379696, 92.940763],
  [42.379675, 92.941028],
  [42.379668, 92.94116],
  [42.379668, 92.94116],
  [42.379661, 92.941423],
  [42.379661, 92.941423],
  [42.379646, 92.94168],
  [42.379646, 92.94168],
  [42.379623, 92.9418],
  [42.379598, 92.942063],
  [42.37958, 92.942311],
  [42.37958, 92.942311],
  [42.379565, 92.942548],
  [42.379563, 92.942665],
  [42.379563, 92.942665],
  [42.379565, 92.942868],
  [42.379565, 92.942868],
  [42.379518, 92.943083],
  [42.379498, 92.943196],
  [42.379498, 92.943196],
  [42.379483, 92.943433],
  [42.379451, 92.943676],
  [42.379451, 92.943676],
  [42.379448, 92.943801],
  [42.379413, 92.944053],
  [42.379413, 92.944053],
  [42.379385, 92.94431],
  [42.379385, 92.94431],
  [42.379355, 92.944565],
  [42.379336, 92.94469],
  [42.379298, 92.944923],
  [42.379298, 92.944923],
  [42.379275, 92.945156],
  [42.379255, 92.94527],
  [42.379255, 92.94527],
  [42.379216, 92.945511],
  [42.379216, 92.945511],
  [42.379175, 92.945763],
  [42.379156, 92.945875],
  [42.379156, 92.945875],
  [42.379093, 92.946091],
  [42.379093, 92.946091],
  [42.378993, 92.946288],
  [42.378866, 92.946425],
  [42.37879, 92.946483],
  [42.37879, 92.946483],
  [42.378636, 92.946595],
  [42.378636, 92.946595],
  [42.378476, 92.946695],
  [42.378391, 92.946746],
  [42.378391, 92.946746],
  [42.378228, 92.946851],
  [42.378068, 92.946958],
  [42.378068, 92.946958],
  [42.377905, 92.947056],
  [42.377816, 92.947101],
  [42.377816, 92.947101],
  [42.37766, 92.947196],
  [42.37766, 92.947196],
  [42.377515, 92.94729],
  [42.377441, 92.947343],
  [42.377296, 92.94744],
  [42.377296, 92.94744],
  [42.377143, 92.947556],
  [42.377143, 92.947556],
  [42.377065, 92.947615],
  [42.376911, 92.947733],
  [42.376911, 92.947733],
  [42.376753, 92.947855],
  [42.376753, 92.947855],
  [42.37667, 92.947901],
  [42.376513, 92.948006],
  [42.376355, 92.948095],
  [42.376355, 92.948095],
  [42.376185, 92.948198],
  [42.376095, 92.948256],
  [42.376095, 92.948256],
  [42.37592, 92.948355],
  [42.37592, 92.948355],
  [42.375735, 92.948448],
  [42.375646, 92.948488],
  [42.375646, 92.948488],
  [42.375475, 92.948596],
  [42.375295, 92.9487],
  [42.375295, 92.9487],
  [42.37521, 92.948763],
  [42.375031, 92.948863],
  [42.375031, 92.948863],
  [42.374901, 92.948976],
  [42.374901, 92.948976],
  [42.374748, 92.949091],
  [42.374665, 92.949161],
  [42.374488, 92.94928],
  [42.374488, 92.94928],
  [42.374318, 92.949373],
  [42.374318, 92.949373],
  [42.374151, 92.949475],
  [42.374081, 92.949523],
  [42.374081, 92.949523],
  [42.373923, 92.949598],
  [42.373743, 92.949686],
  [42.373743, 92.949686],
  [42.373651, 92.949736],
  [42.37349, 92.949843],
  [42.37349, 92.949843],
  [42.373321, 92.949945],
  [42.373231, 92.949998],
  [42.373231, 92.949998],
  [42.37306, 92.950118],
  [42.37306, 92.950118],
  [42.372895, 92.950235],
  [42.372746, 92.950331],
  [42.372678, 92.950371],
  [42.372678, 92.950371],
  [42.372546, 92.950456],
  [42.372546, 92.950456],
  [42.372443, 92.95054],
  [42.372388, 92.95058],
  [42.372388, 92.95058],
  [42.372268, 92.950638],
  [42.372155, 92.950693],
  [42.372155, 92.950693],
  [42.3721, 92.950715],
  [42.37199, 92.950785],
  [42.37199, 92.950785],
  [42.371883, 92.950863],
  [42.371883, 92.950863],
  [42.371783, 92.950941],
  [42.371735, 92.950973],
  [42.371735, 92.950973],
  [42.37166, 92.951085],
  [42.371583, 92.951205],
  [42.371541, 92.951265],
  [42.371541, 92.951265],
  [42.371476, 92.951391],
  [42.371476, 92.951391],
  [42.371416, 92.951545],
  [42.371416, 92.951545],
  [42.371405, 92.951625],
  [42.371393, 92.951806],
  [42.371393, 92.951806],
  [42.371436, 92.95197],
  [42.371486, 92.952136],
  [42.371513, 92.952225],
  [42.371513, 92.952225],
  [42.37157, 92.952356],
  [42.37157, 92.952356],
  [42.371625, 92.952506],
  [42.371645, 92.952573],
  [42.371645, 92.952573],
  [42.371683, 92.952705],
  [42.371723, 92.952796],
  [42.371723, 92.952796],
  [42.371738, 92.952848],
  [42.371761, 92.952943],
  [42.371761, 92.952943],
  [42.371781, 92.953021],
  [42.371781, 92.953021],
  [42.371828, 92.953068],
  [42.371848, 92.953115],
  [42.371848, 92.953115],
  [42.371851, 92.953203],
  [42.371855, 92.953281],
  [42.371866, 92.953323],
  [42.371866, 92.953323],
  [42.371891, 92.953421],
  [42.371891, 92.953421],
  [42.371926, 92.953508],
  [42.371926, 92.953508],
  [42.371938, 92.953533],
  [42.371955, 92.953616],
  [42.371966, 92.953715],
  [42.371966, 92.953715],
  [42.37198, 92.953758],
  [42.372006, 92.953846],
  [42.372006, 92.953846],
  [42.372033, 92.953908],
  [42.372033, 92.953908],
  [42.372058, 92.954006],
  [42.37207, 92.954063],
  [42.37207, 92.954063],
  [42.372078, 92.95415],
  [42.372075, 92.95425],
  [42.372075, 92.95425],
  [42.372086, 92.954338],
  [42.37209, 92.954378],
  [42.37209, 92.954378],
  [42.372081, 92.95445],
  [42.372081, 92.95445],
  [42.37206, 92.954536],
  [42.37205, 92.954591],
  [42.372025, 92.954718],
  [42.372025, 92.954718],
  [42.371998, 92.95486],
  [42.371998, 92.95486],
  [42.371995, 92.954931],
  [42.371988, 92.955086],
  [42.371988, 92.955086],
  [42.37199, 92.95524],
  [42.37199, 92.95524],
  [42.37201, 92.9554],
  [42.372018, 92.955475],
  [42.372065, 92.95561],
  [42.372065, 92.95561],
  [42.372081, 92.955733],
  [42.372088, 92.955798],
  [42.372088, 92.955798],
  [42.372101, 92.955945],
  [42.372101, 92.955945],
  [42.372111, 92.956085],
  [42.372116, 92.95615],
  [42.372116, 92.95615],
  [42.372126, 92.956266],
  [42.37214, 92.956415],
  [42.37214, 92.956415],
  [42.372131, 92.956535],
  [42.372138, 92.956595],
  [42.372138, 92.956595],
  [42.372166, 92.956731],
  [42.372166, 92.956731],
  [42.372205, 92.956871],
  [42.372218, 92.956941],
  [42.37221, 92.957068],
  [42.37221, 92.957068],
  [42.372203, 92.957175],
  [42.372203, 92.957175],
  [42.37219, 92.957231],
  [42.372171, 92.95735],
  [42.372171, 92.95735],
  [42.372161, 92.957471],
  [42.372161, 92.957581],
  [42.372161, 92.957581],
  [42.372163, 92.957636],
  [42.372168, 92.957758],
  [42.372168, 92.957758],
  [42.37217, 92.957883],
  [42.372176, 92.957948],
  [42.372176, 92.957948],
  [42.372185, 92.958081],
  [42.372185, 92.958081],
  [42.372191, 92.958175],
  [42.372206, 92.95829],
  [42.372216, 92.958348],
  [42.372216, 92.958348],
  [42.372223, 92.95845],
  [42.372223, 92.95845],
  [42.372235, 92.958535],
  [42.372243, 92.958588],
  [42.372243, 92.958588],
  [42.372255, 92.9587],
  [42.372265, 92.95881],
  [42.372265, 92.95881],
  [42.372261, 92.958865],
  [42.372265, 92.95898],
  [42.372265, 92.95898],
  [42.37227, 92.95911],
  [42.37227, 92.95911],
  [42.372268, 92.959173],
  [42.372265, 92.959288],
  [42.372265, 92.959288],
  [42.372246, 92.959435],
  [42.372231, 92.959605],
  [42.372231, 92.959605],
  [42.372226, 92.959686],
  [42.372236, 92.959845],
  [42.372236, 92.959845],
  [42.372261, 92.96001],
  [42.372261, 92.96001],
  [42.372278, 92.960088],
  [42.37232, 92.96023],
  [42.37232, 92.96023],
  [42.372356, 92.960376],
  [42.372385, 92.960528],
  [42.372393, 92.960596],
  [42.372393, 92.960596],
  [42.372421, 92.96071],
  [42.372421, 92.96071],
  [42.372453, 92.960838],
  [42.372476, 92.960905],
  [42.372476, 92.960905],
  [42.372498, 92.961051],
  [42.37253, 92.961191],
  [42.37253, 92.961191],
  [42.372555, 92.961336],
  [42.372563, 92.961395],
  [42.372563, 92.961395],
  [42.372583, 92.961495],
  [42.372583, 92.961495],
  [42.372583, 92.961613],
  [42.372593, 92.961651],
  [42.372593, 92.961651],
  [42.372608, 92.961733],
  [42.372621, 92.961826],
  [42.37263, 92.961866],
  [42.372633, 92.961915],
  [42.372645, 92.961961],
  [42.372645, 92.961961],
  [42.372668, 92.962068],
  [42.372668, 92.962068],
  [42.372693, 92.962183],
  [42.372706, 92.962243],
  [42.372736, 92.962353],
  [42.372736, 92.962353],
  [42.372756, 92.962463],
  [42.372765, 92.962528],
  [42.372765, 92.962528],
  [42.372775, 92.96265],
  [42.372775, 92.96265],
  [42.372795, 92.962766],
  [42.372813, 92.96289],
  [42.372813, 92.96289],
  [42.372818, 92.962956],
  [42.37284, 92.963088],
  [42.37284, 92.963088],
  [42.372858, 92.963223],
  [42.372871, 92.963281],
  [42.372871, 92.963281],
  [42.372891, 92.963398],
  [42.372891, 92.963398],
  [42.372905, 92.963498],
  [42.372916, 92.963553],
  [42.372961, 92.963668],
  [42.372961, 92.963668],
  [42.372993, 92.963796],
  [42.372993, 92.963796],
  [42.37301, 92.963915],
  [42.373018, 92.963985],
  [42.373018, 92.963985],
  [42.373018, 92.963985],
  [42.373018, 92.963985],
  [42.37298, 92.964155],
  [42.37296, 92.96418],
  [42.37293, 92.96418],
  [42.37293, 92.96418],
  [42.372851, 92.964153],
  [42.372795, 92.964076],
  [42.372763, 92.96402],
  [42.372763, 92.96402],
  [42.372691, 92.963905],
  [42.372691, 92.963905],
  [42.37263, 92.96377],
  [42.372571, 92.963628],
  [42.372571, 92.963628],
  [42.372543, 92.963546],
  [42.372495, 92.963375],
  [42.372495, 92.963375],
  [42.372455, 92.963218],
  [42.372436, 92.963143],
  [42.372436, 92.963143],
  [42.372403, 92.962993],
  [42.372403, 92.962993],
  [42.372373, 92.96283],
  [42.372355, 92.962733],
  [42.372313, 92.962538],
  [42.372313, 92.962538],
  [42.37227, 92.962363],
  [42.37227, 92.962363],
  [42.372221, 92.962185],
  [42.3722, 92.962088],
  [42.3722, 92.962088],
  [42.372163, 92.96191],
  [42.372123, 92.961731],
  [42.372123, 92.961731],
  [42.372093, 92.96164],
  [42.372036, 92.96146],
  [42.372036, 92.96146],
  [42.371973, 92.961286],
  [42.37195, 92.961195],
  [42.37195, 92.961195],
  [42.371893, 92.961023],
  [42.371893, 92.961023],
  [42.371841, 92.96086],
  [42.371788, 92.960695],
  [42.371753, 92.960618],
  [42.371753, 92.960618],
  [42.371701, 92.960438],
  [42.371701, 92.960438],
  [42.371641, 92.960281],
  [42.371611, 92.96019],
  [42.371611, 92.96019],
  [42.371555, 92.960031],
  [42.37151, 92.959888],
  [42.37151, 92.959888],
  [42.371481, 92.959813],
  [42.371416, 92.959648],
  [42.371416, 92.959648],
  [42.37134, 92.959456],
  [42.37134, 92.959456],
  [42.371256, 92.95927],
  [42.37121, 92.959193],
  [42.37121, 92.959193],
  [42.371121, 92.959065],
  [42.37103, 92.958938],
  [42.37103, 92.958938],
  [42.370985, 92.958886],
  [42.370885, 92.9588],
  [42.370885, 92.9588],
  [42.370785, 92.958753],
  [42.370785, 92.958753],
  [42.370688, 92.95873],
  [42.370646, 92.958723],
  [42.370646, 92.958723],
  [42.370561, 92.958755],
  [42.370465, 92.958778],
  [42.370415, 92.958795],
  [42.370415, 92.958795],
  [42.37034, 92.958835],
  [42.37034, 92.958835],
  [42.370276, 92.958888],
  [42.370248, 92.95891],
  [42.370248, 92.95891],
  [42.370248, 92.95891],
  [42.370198, 92.959035],
  [42.370193, 92.959086],
  [42.37026, 92.95894],
  [42.370251, 92.95892],
  [42.370241, 92.958901],
  [42.37024, 92.95889],
  [42.37024, 92.95889],
  [42.370241, 92.958885],
  [42.370241, 92.958885],
  [42.370241, 92.958885],
  [42.370241, 92.958885],
  [42.370241, 92.958885],
  [42.370436, 92.959235],
  [42.3705, 92.959326],
  [42.370531, 92.959376],
  [42.370531, 92.959376],
  [42.370591, 92.959446],
  [42.37064, 92.959525],
  [42.37064, 92.959525],
  [42.37066, 92.959561],
  [42.370708, 92.959633],
  [42.370708, 92.959633],
  [42.370741, 92.959713],
  [42.370763, 92.95976],
  [42.370786, 92.959801],
  [42.370811, 92.959846],
  [42.370811, 92.959846],
  [42.370881, 92.959945],
  [42.37093, 92.960036],
  [42.370958, 92.960078],
  [42.370958, 92.960078],
  [42.37103, 92.960175],
  [42.37103, 92.960175],
  [42.37109, 92.960275],
  [42.371118, 92.960326],
  [42.371148, 92.96037],
  [42.37119, 92.960418],
  [42.371275, 92.96052],
  [42.371275, 92.96052],
  [42.37134, 92.960615],
  [42.371366, 92.960666],
  [42.371366, 92.960666],
  [42.371416, 92.960748],
  [42.371416, 92.960748],
  [42.371476, 92.960836],
  [42.37151, 92.960885],
  [42.37151, 92.960885],
  [42.371588, 92.960985],
  [42.371656, 92.961088],
  [42.371656, 92.961088],
  [42.371691, 92.961123],
  [42.37175, 92.961208],
  [42.37175, 92.961208],
  [42.3718, 92.961291],
  [42.3718, 92.961291],
  [42.371845, 92.961375],
  [42.371871, 92.961425],
  [42.371871, 92.961425],
  [42.371933, 92.961528],
  [42.372003, 92.96166],
  [42.372031, 92.961728],
  [42.372031, 92.961728],
  [42.372103, 92.961883],
  [42.372103, 92.961883],
  [42.372175, 92.962051],
  [42.3722, 92.962128],
  [42.3722, 92.962128],
  [42.372245, 92.962278],
  [42.37228, 92.962436],
  [42.37228, 92.962436],
  [42.372315, 92.962596],
  [42.372338, 92.96267],
  [42.372338, 92.96267],
  [42.37239, 92.962855],
  [42.37239, 92.962855],
  [42.372433, 92.963026],
  [42.372451, 92.963108],
  [42.372451, 92.963108],
  [42.372496, 92.963285],
  [42.37254, 92.96346],
  [42.372563, 92.963548],
  [42.372585, 92.963633],
  [42.372616, 92.9637],
  [42.372616, 92.9637],
  [42.37266, 92.963831],
  [42.37266, 92.963831],
  [42.372708, 92.963956],
  [42.372736, 92.964013],
  [42.372801, 92.964115],
  [42.372801, 92.964115],
  [42.372875, 92.964206],
  [42.37292, 92.964236],
  [42.37292, 92.964236],
  [42.37292, 92.964236],
  [42.372986, 92.964268],
  [42.373035, 92.964265],
  [42.373086, 92.96424],
  [42.373128, 92.964188],
  [42.373165, 92.964128],
  [42.37318, 92.964055],
  [42.373181, 92.963908],
  [42.373181, 92.963908],
  [42.373163, 92.96376],
  [42.373155, 92.963693],
  [42.373155, 92.963693],
  [42.373108, 92.963526],
  [42.373108, 92.963526],
  [42.373068, 92.963366],
  [42.373046, 92.963265],
  [42.373001, 92.96308],
  [42.373001, 92.96308],
  [42.372965, 92.962886],
  [42.372965, 92.962886],
  [42.37294, 92.962688],
  [42.372925, 92.962586],
  [42.372925, 92.962586],
  [42.372896, 92.962385],
  [42.372863, 92.962195],
  [42.372863, 92.962195],
  [42.372845, 92.9621],
  [42.372805, 92.961896],
  [42.372805, 92.961896],
  [42.372758, 92.961696],
  [42.372736, 92.9616],
  [42.372736, 92.9616],
  [42.372688, 92.96141],
  [42.372688, 92.96141],
  [42.372643, 92.961208],
  [42.372616, 92.961026],
  [42.372601, 92.960945],
  [42.372601, 92.960945],
  [42.372561, 92.960765],
  [42.372561, 92.960765],
  [42.372523, 92.9606],
  [42.372501, 92.960491],
  [42.372501, 92.960491],
  [42.372475, 92.960311],
  [42.372445, 92.960148],
  [42.372445, 92.960148],
  [42.37241, 92.95998],
  [42.372391, 92.959883],
  [42.372391, 92.959883],
  [42.372368, 92.959683],
  [42.372368, 92.959683],
  [42.372376, 92.959473],
  [42.372386, 92.959371],
  [42.372386, 92.959371],
  [42.372415, 92.959181],
  [42.372428, 92.95898],
  [42.372428, 92.95898],
  [42.372426, 92.958873],
  [42.372403, 92.958658],
  [42.372403, 92.958658],
  [42.372378, 92.958458],
  [42.372378, 92.958458],
  [42.372366, 92.958246],
  [42.372361, 92.958145],
  [42.372361, 92.958145],
  [42.372351, 92.95794],
  [42.372343, 92.957838],
  [42.372336, 92.957625],
  [42.372336, 92.957625],
  [42.372323, 92.9574],
  [42.372323, 92.9574],
  [42.372303, 92.957166],
  [42.372293, 92.957056],
  [42.372293, 92.957056],
  [42.372288, 92.956818],
  [42.372273, 92.956581],
  [42.372273, 92.956581],
  [42.372256, 92.956355],
  [42.372248, 92.956233],
  [42.372248, 92.956233],
  [42.372236, 92.956013],
  [42.372236, 92.956013],
  [42.372215, 92.95582],
  [42.372215, 92.955718],
  [42.372215, 92.955718],
  [42.372201, 92.955516],
  [42.372198, 92.95532],
  [42.372198, 92.955225],
  [42.372198, 92.955225],
  [42.372165, 92.955043],
  [42.372165, 92.955043],
  [42.372116, 92.954885],
  [42.372116, 92.954885],
  [42.372116, 92.954885],
  [42.372171, 92.954685],
  [42.372171, 92.954685],
  [42.372258, 92.954666],
  [42.372341, 92.954733],
  [42.372341, 92.954733],
  [42.372371, 92.954786],
  [42.372428, 92.954926],
  [42.372428, 92.954926],
  [42.372453, 92.955108],
  [42.37246, 92.955208],
  [42.37246, 92.955208],
  [42.372476, 92.955401],
  [42.372478, 92.955546],
  [42.372478, 92.955546],
  [42.372508, 92.955743],
  [42.372521, 92.955836],
  [42.372521, 92.955836],
  [42.372571, 92.95603],
  [42.372571, 92.95603],
  [42.3726, 92.956191],
  [42.372621, 92.956276],
  [42.372621, 92.956276],
  [42.372661, 92.956461],
  [42.372693, 92.956631],
  [42.372693, 92.956631],
  [42.372728, 92.95682],
  [42.372748, 92.95692],
  [42.372748, 92.95692],
  [42.372793, 92.957115],
  [42.372793, 92.957115],
  [42.372841, 92.957311],
  [42.37287, 92.957401],
  [42.372953, 92.957608],
  [42.372953, 92.957608],
  [42.373026, 92.957776],
  [42.373058, 92.957845],
  [42.373058, 92.957845],
  [42.373121, 92.957983],
  [42.373121, 92.957983],
  [42.37319, 92.958113],
  [42.37319, 92.958113],
  [42.373268, 92.958233],
  [42.373315, 92.95831],
  [42.373418, 92.958475],
  [42.373418, 92.958475],
  [42.37353, 92.958625],
  [42.373583, 92.958691],
  [42.373583, 92.958691],
  [42.373673, 92.958758],
  [42.373673, 92.958758],
  [42.37373, 92.95882],
  [42.373763, 92.958858],
  [42.373763, 92.958858],
  [42.373836, 92.958948],
  [42.373883, 92.959045],
  [42.373883, 92.959045],
  [42.373916, 92.959145],
  [42.373945, 92.959195],
  [42.373945, 92.959195],
  [42.373981, 92.95929],
  [42.373981, 92.95929],
  [42.374023, 92.959385],
  [42.374035, 92.959433],
  [42.374073, 92.959528],
  [42.374073, 92.959528],
  [42.374123, 92.959593],
  [42.374151, 92.959626],
  [42.374151, 92.959626],
  [42.374216, 92.959656],
  [42.374216, 92.959656],
  [42.374216, 92.959656],
  [42.374303, 92.95966],
  [42.374318, 92.959645],
  [42.374318, 92.959645],
  [42.374365, 92.959626],
  [42.374365, 92.959626],
  [42.374365, 92.959626],
  [42.37444, 92.959668],
  [42.37449, 92.959691],
  [42.37449, 92.959691],
  [42.37449, 92.959691],
  [42.37452, 92.959771],
  [42.374525, 92.959806],
  [42.374525, 92.959806],
  [42.374576, 92.959946],
  [42.374576, 92.959946],
  [42.374601, 92.959988],
  [42.374603, 92.960045],
  [42.37458, 92.96007],
  [42.37457, 92.960086],
  [42.374526, 92.960098],
  [42.374526, 92.960098],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374505, 92.960086],
  [42.374516, 92.959863],
  [42.374528, 92.959855],
  [42.37452, 92.959841],
  [42.37451, 92.959826],
  [42.37449, 92.959806],
  [42.374481, 92.95979],
  [42.37448, 92.95978],
  [42.37448, 92.95978],
  [42.374465, 92.959775],
  [42.374463, 92.959768],
  [42.374463, 92.959756],
  [42.374465, 92.959751],
  [42.374468, 92.959736],
  [42.374468, 92.959731],
  [42.374455, 92.9597],
  [42.374455, 92.9597],
  [42.374448, 92.95969],
  [42.374445, 92.95968],
  [42.374443, 92.959685],
  [42.374433, 92.959676],
  [42.374425, 92.959668],
  [42.374421, 92.959641],
  [42.374411, 92.959616],
  [42.374406, 92.959583],
  [42.374401, 92.959573],
  [42.374376, 92.959516],
  [42.374378, 92.959503],
  [42.374378, 92.959503],
  [42.374375, 92.959465],
  [42.37438, 92.959448],
  [42.374378, 92.959431],
  [42.374378, 92.959406],
  [42.374378, 92.959386],
  [42.374373, 92.95935],
  [42.37436, 92.95932],
  [42.37436, 92.95932],
  [42.374348, 92.959291],
  [42.37433, 92.959266],
  [42.37433, 92.959266],
  [42.374311, 92.959218],
  [42.374306, 92.95919],
  [42.374296, 92.959176],
  [42.374293, 92.959161],
  [42.374288, 92.959161],
  [42.374288, 92.959143],
  [42.374285, 92.959121],
  [42.374281, 92.959106],
  [42.374275, 92.959078],
  [42.374271, 92.959051],
  [42.37427, 92.959025],
  [42.374263, 92.958996],
  [42.374255, 92.958976],
  [42.37425, 92.958961],
  [42.374245, 92.95894],
  [42.374235, 92.958895],
  [42.374235, 92.958895],
  [42.374245, 92.958838],
  [42.374235, 92.958818],
  [42.374218, 92.958785],
  [42.374205, 92.958755],
  [42.374198, 92.958733],
  [42.37419, 92.958703],
  [42.374183, 92.958685],
  [42.374183, 92.958685],
  [42.374183, 92.958665],
  [42.374173, 92.958598],
  [42.374183, 92.958556],
  [42.374183, 92.958556],
  [42.374205, 92.958526],
  [42.374223, 92.958493],
  [42.374295, 92.958455],
  [42.374316, 92.958436],
  [42.374355, 92.958435],
  [42.374386, 92.958435],
  [42.374428, 92.958433],
  [42.374461, 92.958443],
  [42.374491, 92.95844],
  [42.374525, 92.958423],
  [42.374585, 92.958403],
  [42.374616, 92.958391],
  [42.374616, 92.958391],
  [42.374656, 92.958365],
  [42.374673, 92.958346],
  [42.374691, 92.958333],
  [42.374708, 92.958316],
  [42.37472, 92.958291],
  [42.374743, 92.95827],
  [42.37477, 92.958216],
  [42.37477, 92.958216],
  [42.374805, 92.958146],
  [42.374818, 92.958113],
  [42.374813, 92.958071],
  [42.374813, 92.958071],
  [42.374775, 92.958001],
  [42.374745, 92.957971],
  [42.37472, 92.957945],
  [42.374686, 92.957911],
  [42.374655, 92.957906],
  [42.37461, 92.957918],
  [42.374563, 92.957926],
  [42.374516, 92.95794],
  [42.374478, 92.957953],
  [42.374433, 92.957966],
  [42.374338, 92.957995],
  [42.374296, 92.958013],
  [42.374253, 92.958036],
  [42.37421, 92.958061],
  [42.374165, 92.95809],
  [42.37411, 92.958111],
  [42.374063, 92.958135],
  [42.374011, 92.958163],
  [42.37397, 92.958183],
  [42.3739, 92.958228],
  [42.3739, 92.958228],
  [42.373845, 92.958263],
  [42.37382, 92.958278],
  [42.37379, 92.958298],
  [42.373756, 92.958331],
  [42.373716, 92.958361],
  [42.373671, 92.958385],
  [42.373578, 92.958406],
  [42.373578, 92.958406],
  [42.373528, 92.958406],
  [42.373431, 92.958386],
  [42.373431, 92.958386],
  [42.373346, 92.958311],
  [42.373306, 92.958273],
  [42.373275, 92.958231],
  [42.373243, 92.958178],
  [42.37317, 92.95805],
  [42.37317, 92.95805],
  [42.373105, 92.95791],
  [42.37307, 92.95783],
  [42.37307, 92.95783],
  [42.373011, 92.95767],
  [42.372968, 92.95759],
  [42.372943, 92.957511],
  [42.372918, 92.95743],
  [42.372901, 92.957355],
  [42.372891, 92.957275],
  [42.372863, 92.957135],
  [42.372863, 92.957135],
  [42.372851, 92.957076],
  [42.372825, 92.956941],
  [42.372806, 92.956873],
  [42.372783, 92.95679],
  [42.372765, 92.956708],
  [42.372748, 92.956631],
  [42.372725, 92.956548],
  [42.3727, 92.956478],
  [42.37267, 92.956408],
  [42.372621, 92.956251],
  [42.37259, 92.95616],
  [42.37259, 92.95616],
  [42.372561, 92.955981],
  [42.372546, 92.95589],
  [42.37253, 92.955806],
  [42.372506, 92.955733],
  [42.372491, 92.955658],
  [42.372463, 92.955568],
  [42.372423, 92.955405],
  [42.372423, 92.955405],
  [42.37238, 92.95525],
  [42.372363, 92.955161],
  [42.372341, 92.95508],
  [42.372325, 92.955],
  [42.372311, 92.954923],
  [42.372295, 92.954858],
  [42.372276, 92.954795],
  [42.372261, 92.954735],
  [42.37225, 92.954675],
  [42.372225, 92.954538],
  [42.372213, 92.954466],
  [42.372206, 92.954396],
  [42.372206, 92.95433],
  [42.372213, 92.95425],
  [42.372206, 92.954178],
  [42.372201, 92.95412],
  [42.372196, 92.954053],
  [42.372186, 92.953983],
  [42.372158, 92.953813],
  [42.372158, 92.953813],
  [42.372115, 92.953636],
  [42.372088, 92.95355],
  [42.372061, 92.953451],
  [42.372036, 92.953358],
  [42.37201, 92.953255],
  [42.371981, 92.953151],
  [42.371923, 92.952955],
  [42.371923, 92.952955],
  [42.3719, 92.952861],
  [42.371843, 92.952665],
  [42.371816, 92.95257],
  [42.371798, 92.952483],
  [42.371773, 92.95239],
  [42.371741, 92.952308],
  [42.371711, 92.952226],
  [42.371685, 92.952143],
  [42.371656, 92.952061],
  [42.371628, 92.951965],
  [42.371606, 92.951878],
  [42.371586, 92.95178],
  [42.371583, 92.951571],
  [42.371598, 92.951483],
  [42.371628, 92.951403],
  [42.371665, 92.951318],
  [42.371716, 92.951238],
  [42.371771, 92.951166],
  [42.371833, 92.951093],
  [42.371891, 92.951018],
  [42.371965, 92.950966],
  [42.372105, 92.950868],
  [42.372105, 92.950868],
  [42.372263, 92.950785],
  [42.372345, 92.950743],
  [42.372433, 92.950716],
  [42.372516, 92.95068],
  [42.372596, 92.95064],
  [42.372671, 92.9506],
  [42.372808, 92.950515],
  [42.372808, 92.950515],
  [42.372876, 92.950476],
  [42.373013, 92.950365],
  [42.373071, 92.950316],
  [42.373135, 92.950263],
  [42.3732, 92.950215],
  [42.373261, 92.95017],
  [42.373325, 92.950128],
  [42.373391, 92.950095],
  [42.373458, 92.950065],
  [42.373606, 92.949985],
  [42.373683, 92.949938],
  [42.373683, 92.949938],
  [42.373825, 92.949853],
  [42.373895, 92.94981],
  [42.373968, 92.949766],
  [42.374046, 92.949713],
  [42.374125, 92.949666],
  [42.374203, 92.949621],
  [42.374373, 92.949525],
  [42.374373, 92.949525],
  [42.374456, 92.949473],
  [42.374608, 92.949366],
  [42.374681, 92.949311],
  [42.374758, 92.94927],
  [42.374831, 92.949235],
  [42.374895, 92.949195],
  [42.37496, 92.94916],
  [42.375031, 92.949121],
  [42.375106, 92.949081],
  [42.375246, 92.948991],
  [42.375315, 92.948943],
  [42.375315, 92.948943],
  [42.375443, 92.948876],
  [42.375506, 92.94884],
  [42.375576, 92.948806],
  [42.375645, 92.948776],
  [42.375706, 92.948743],
  [42.375781, 92.948711],
  [42.375848, 92.948676],
  [42.37592, 92.948633],
  [42.37605, 92.948565],
  [42.376115, 92.948533],
  [42.376115, 92.948533],
  [42.376253, 92.948441],
  [42.376323, 92.948393],
  [42.376393, 92.94834],
  [42.37647, 92.948288],
  [42.376543, 92.94823],
  [42.376613, 92.94818],
  [42.376738, 92.948096],
  [42.376738, 92.948096],
  [42.376801, 92.948038],
  [42.376938, 92.947931],
  [42.37701, 92.947876],
  [42.377086, 92.947816],
  [42.377168, 92.947753],
  [42.377253, 92.947691],
  [42.377331, 92.947636],
  [42.3774, 92.947583],
  [42.377478, 92.947536],
  [42.377623, 92.947448],
  [42.377685, 92.947403],
  [42.377685, 92.947403],
  [42.377835, 92.94731],
  [42.377906, 92.947265],
  [42.37798, 92.947216],
  [42.378056, 92.94717],
  [42.378125, 92.94713],
  [42.378193, 92.94709],
  [42.378333, 92.94697],
  [42.378333, 92.94697],
  [42.378486, 92.946868],
  [42.378553, 92.946818],
  [42.37863, 92.946771],
  [42.378705, 92.946726],
  [42.378773, 92.94668],
  [42.37884, 92.94663],
  [42.378915, 92.946586],
  [42.378991, 92.946533],
  [42.37905, 92.946481],
  [42.379136, 92.946318],
  [42.379153, 92.94621],
  [42.379171, 92.946111],
  [42.379198, 92.946005],
  [42.379223, 92.945893],
  [42.379241, 92.945786],
  [42.379261, 92.945675],
  [42.379281, 92.945561],
  [42.379305, 92.94545],
  [42.379341, 92.945243],
  [42.379341, 92.945243],
  [42.379368, 92.945035],
  [42.379385, 92.944941],
  [42.379391, 92.944843],
  [42.3794, 92.944745],
  [42.3794, 92.944646],
  [42.379405, 92.944541],
  [42.37943, 92.94431],
  [42.37943, 92.94431],
  [42.379436, 92.944191],
  [42.379465, 92.943953],
  [42.37948, 92.943836],
  [42.379496, 92.943715],
  [42.379523, 92.9436],
  [42.379543, 92.943483],
  [42.379558, 92.94337],
  [42.379561, 92.943256],
  [42.379565, 92.943131],
  [42.37957, 92.943023],
  [42.379571, 92.942925],
  [42.379578, 92.942825],
  [42.37959, 92.942653],
  [42.379593, 92.942553],
  [42.379611, 92.942476],
  [42.379621, 92.942403],
  [42.379623, 92.942296],
  [42.379636, 92.942176],
  [42.379643, 92.942061],
  [42.379651, 92.941945],
  [42.379665, 92.941845],
  [42.37969, 92.941646],
  [42.37969, 92.941646],
  [42.37971, 92.941446],
  [42.379716, 92.941343],
  [42.379725, 92.941235],
  [42.379726, 92.941128],
  [42.37973, 92.941023],
  [42.379745, 92.94093],
  [42.379763, 92.940723],
  [42.379763, 92.940723],
  [42.379778, 92.940636],
  [42.379815, 92.940465],
  [42.379828, 92.94038],
  [42.379838, 92.940305],
  [42.379861, 92.940225],
  [42.379883, 92.940145],
  [42.379905, 92.940061],
  [42.379926, 92.93999],
  [42.379948, 92.939918],
  [42.37999, 92.939765],
  [42.380023, 92.939698],
  [42.380023, 92.939698],
  [42.38011, 92.939588],
  [42.380163, 92.939555],
  [42.380226, 92.939508],
  [42.380288, 92.939471],
  [42.380356, 92.939431],
  [42.380406, 92.939398],
  [42.380505, 92.939341],
  [42.380505, 92.939341],
  [42.380568, 92.93931],
  [42.38067, 92.939248],
  [42.380718, 92.939233],
  [42.380765, 92.939205],
  [42.380828, 92.939163],
  [42.380883, 92.939116],
  [42.380925, 92.939058],
  [42.380975, 92.939025],
  [42.381036, 92.938986],
  [42.381146, 92.938896],
  [42.381196, 92.938825],
  [42.381196, 92.938825],
  [42.381283, 92.938716],
  [42.381328, 92.938658],
  [42.381375, 92.938595],
  [42.381421, 92.93854],
  [42.381476, 92.938473],
  [42.381523, 92.938415],
  [42.381583, 92.938378],
  [42.381641, 92.938348],
  [42.381756, 92.938273],
  [42.381806, 92.938235],
  [42.381806, 92.938235],
  [42.381908, 92.938168],
  [42.381941, 92.938131],
  [42.38199, 92.93809],
  [42.382041, 92.938056],
  [42.3821, 92.938023],
  [42.382166, 92.937981],
  [42.382315, 92.937891],
  [42.382315, 92.937891],
  [42.382386, 92.93785],
  [42.382486, 92.937755],
  [42.382526, 92.937708],
  [42.382558, 92.937645],
  [42.382586, 92.93759],
  [42.382608, 92.93753],
  [42.382621, 92.93747],
  [42.382636, 92.937411],
  [42.382655, 92.937336],
  [42.382686, 92.937268],
  [42.38274, 92.937206],
  [42.382788, 92.937166],
  [42.382906, 92.937096],
  [42.382966, 92.937045],
  [42.383026, 92.937016],
  [42.383096, 92.936983],
  [42.383166, 92.93695],
  [42.383235, 92.936913],
  [42.383303, 92.936878],
  [42.383376, 92.936845],
  [42.38352, 92.936773],
  [42.383576, 92.936726],
  [42.383576, 92.936726],
  [42.383696, 92.936673],
  [42.38375, 92.936645],
  [42.38375, 92.936645],
  [42.383788, 92.936603],
  [42.383806, 92.936556],
  [42.383816, 92.936435],
  [42.383796, 92.936373],
  [42.383784, 92.936313],
  [42.383784, 92.936313],
  [42.383743, 92.93627],
  [42.383663, 92.936203],
  [42.383618, 92.936193],
  [42.383566, 92.936188],
  [42.383516, 92.936195],
  [42.383456, 92.936206],
  [42.383401, 92.936235],
  [42.38328, 92.936316],
  [42.38328, 92.936316],
  [42.383161, 92.936376],
  [42.383108, 92.93641],
  [42.383056, 92.93643],
  [42.382993, 92.936463],
  [42.382935, 92.936491],
  [42.382876, 92.936518],
  [42.382763, 92.93657],
  [42.382763, 92.93657],
  [42.382703, 92.936598],
  [42.382576, 92.936665],
  [42.382503, 92.936693],
  [42.382446, 92.936725],
  [42.382386, 92.936746],
  [42.382331, 92.936776],
  [42.382275, 92.9368],
  [42.382218, 92.936823],
  [42.382166, 92.936845],
  [42.38211, 92.936858],
  [42.38203, 92.936918],
  [42.38203, 92.936918],
  [42.381941, 92.936958],
  [42.381898, 92.93698],
  [42.381845, 92.937005],
  [42.381793, 92.937038],
  [42.381746, 92.937056],
  [42.381701, 92.937075],
  [42.381603, 92.93713],
  [42.381603, 92.93713],
  [42.381556, 92.937141],
  [42.38146, 92.937153],
  [42.381408, 92.93716],
  [42.381368, 92.937168],
  [42.38133, 92.93718],
  [42.381295, 92.937185],
  [42.381261, 92.937175],
  [42.38123, 92.937168],
  [42.381196, 92.937153],
  [42.381143, 92.937145],
  [42.381106, 92.937148],
  [42.381106, 92.937148],
  [42.381046, 92.937148],
  [42.381015, 92.937146],
  [42.380993, 92.93714],
  [42.380961, 92.937143],
  [42.380928, 92.937146],
  [42.380898, 92.937145],
  [42.380843, 92.937153],
  [42.380843, 92.937153],
  [42.380786, 92.937146],
  [42.380756, 92.937136],
  [42.380756, 92.937136],
  [42.38072, 92.937106],
  [42.380693, 92.937096],
  [42.380675, 92.937078],
  [42.380658, 92.937073],
  [42.380636, 92.937073],
  [42.380618, 92.93707],
  [42.38056, 92.937065],
  [42.38056, 92.937065],
  [42.380533, 92.937063],
  [42.380501, 92.937073],
  [42.380498, 92.937085],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380498, 92.937096],
  [42.380453, 92.93712],
  [42.380413, 92.937163],
  [42.380401, 92.937186],
  [42.38038, 92.937206],
  [42.380365, 92.937218],
  [42.380363, 92.937225],
  [42.380358, 92.937243],
  [42.38035, 92.93724],
  [42.380331, 92.937248],
  [42.380288, 92.937271],
  [42.380271, 92.937273],
  [42.380271, 92.937273],
  [42.380225, 92.937266],
  [42.3802, 92.937266],
  [42.380181, 92.937273],
  [42.380161, 92.93727],
  [42.380151, 92.93727],
  [42.380148, 92.937268],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380148, 92.937261],
  [42.380096, 92.937266],
  [42.380081, 92.937278],
  [42.38005, 92.937251],
  [42.38005, 92.937251],
  [42.380023, 92.937213],
  [42.380023, 92.937213],
  [42.380006, 92.937153],
  [42.380006, 92.93714],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380008, 92.937136],
  [42.380146, 92.937163],
  [42.380158, 92.937123],
  [42.380163, 92.937118],
  [42.380171, 92.937123],
  [42.38018, 92.937126],
  [42.380196, 92.937131],
  [42.380216, 92.937125],
  [42.38024, 92.937113],
  [42.380261, 92.937113],
  [42.38027, 92.93712],
  [42.380288, 92.937133],
  [42.380288, 92.937133],
  [42.380288, 92.937133],
  [42.380336, 92.937151],
  [42.38035, 92.93715],
  [42.38036, 92.937146],
  [42.380378, 92.937153],
  [42.380405, 92.93716],
  [42.380423, 92.937158],
  [42.380468, 92.937153],
  [42.380468, 92.937153],
  [42.380516, 92.937151],
  [42.380535, 92.937153],
  [42.380555, 92.937153],
  [42.3806, 92.937151],
  [42.380615, 92.937161],
  [42.380631, 92.93716],
  [42.380648, 92.937165],
  [42.380661, 92.937176],
  [42.380681, 92.937185],
  [42.380728, 92.937196],
  [42.380761, 92.937188],
  [42.380791, 92.937196],
  [42.38083, 92.937201],
  [42.38086, 92.937201],
  [42.38089, 92.937205],
  [42.380915, 92.937198],
  [42.38094, 92.937176],
  [42.38097, 92.937165],
  [42.381036, 92.937165],
  [42.381036, 92.937165],
  [42.381093, 92.937175],
  [42.381125, 92.93718],
  [42.381151, 92.93719],
  [42.381185, 92.9372],
  [42.381225, 92.937195],
  [42.381263, 92.937198],
  [42.381341, 92.937196],
  [42.381341, 92.937196],
  [42.381383, 92.937191],
  [42.381466, 92.937173],
  [42.381508, 92.937173],
  [42.38155, 92.937168],
  [42.381595, 92.937161],
  [42.381638, 92.937148],
  [42.381693, 92.937133],
  [42.38174, 92.93711],
  [42.381786, 92.937078],
  [42.381876, 92.937023],
  [42.381921, 92.936996],
  [42.381921, 92.936996],
  [42.38202, 92.936946],
  [42.382071, 92.936928],
  [42.382096, 92.936893],
  [42.382126, 92.936865],
  [42.38216, 92.936838],
  [42.382201, 92.936818],
  [42.382306, 92.936783],
  [42.382306, 92.936783],
  [42.38236, 92.936765],
  [42.382471, 92.936718],
  [42.382471, 92.936718],
  [42.382578, 92.936691],
  [42.382626, 92.936676],
  [42.382685, 92.936658],
  [42.382743, 92.936631],
  [42.3828, 92.936601],
  [42.382838, 92.93658],
  [42.382963, 92.936523],
  [42.382963, 92.936523],
  [42.383025, 92.936495],
  [42.383106, 92.936426],
  [42.383166, 92.936395],
  [42.383215, 92.936365],
  [42.383258, 92.936335],
  [42.383305, 92.93631],
  [42.38335, 92.936278],
  [42.3834, 92.936255],
  [42.38346, 92.936241],
  [42.383563, 92.936223],
  [42.383596, 92.936235],
  [42.383596, 92.936235],
  [42.383636, 92.936255],
  [42.383663, 92.936286],
  [42.383725, 92.936376],
  [42.383746, 92.936415],
  [42.383746, 92.936415],
  [42.383746, 92.936415],
  [42.383751, 92.93657],
  [42.383736, 92.936613],
  [42.383736, 92.936613],
  [42.383653, 92.936748],
  [42.383608, 92.936771],
  [42.383556, 92.9368],
  [42.383498, 92.936831],
  [42.383438, 92.936865],
  [42.383383, 92.936898],
  [42.38328, 92.936951],
  [42.38328, 92.936951],
  [42.38317, 92.937015],
  [42.383113, 92.93703],
  [42.383063, 92.937056],
  [42.383008, 92.93708],
  [42.382951, 92.937116],
  [42.382901, 92.93714],
  [42.382801, 92.937191],
  [42.382801, 92.937191],
  [42.382745, 92.93721],
  [42.38267, 92.937278],
  [42.382626, 92.937341],
  [42.382611, 92.937415],
  [42.382591, 92.937495],
  [42.38257, 92.937566],
  [42.382541, 92.93764],
  [42.382518, 92.937705],
  [42.382483, 92.93776],
  [42.382441, 92.93781],
  [42.382338, 92.937915],
  [42.382338, 92.937915],
  [42.38224, 92.937983],
  [42.382188, 92.938013],
  [42.382136, 92.938033],
  [42.382071, 92.93806],
  [42.382008, 92.938078],
  [42.381946, 92.938098],
  [42.381855, 92.938161],
  [42.381855, 92.938161],
  [42.3818, 92.938191],
  [42.381695, 92.938196],
  [42.381655, 92.938225],
  [42.381603, 92.938255],
  [42.381558, 92.938298],
  [42.381513, 92.938333],
  [42.38147, 92.93838],
  [42.381421, 92.938446],
  [42.381366, 92.938516],
  [42.381281, 92.938648],
  [42.381245, 92.938723],
  [42.381245, 92.938723],
  [42.38114, 92.938868],
  [42.381086, 92.938923],
  [42.381025, 92.938978],
  [42.380961, 92.93904],
  [42.380905, 92.939078],
  [42.380856, 92.939118],
  [42.38077, 92.939146],
  [42.38077, 92.939146],
  [42.380726, 92.939171],
  [42.380628, 92.939253],
  [42.380628, 92.939253],
  [42.380523, 92.93935],
  [42.38048, 92.939391],
  [42.380426, 92.93943],
  [42.380371, 92.939463],
  [42.380325, 92.939493],
  [42.380285, 92.939515],
  [42.380195, 92.939561],
  [42.380195, 92.939561],
  [42.38014, 92.939601],
  [42.380028, 92.939691],
  [42.379983, 92.939746],
  [42.379941, 92.939821],
  [42.379911, 92.939906],
  [42.379891, 92.94],
  [42.379878, 92.94008],
  [42.37987, 92.940153],
  [42.37985, 92.940225],
  [42.379798, 92.940383],
  [42.379768, 92.940465],
  [42.379768, 92.940465],
  [42.379733, 92.940611],
  [42.379711, 92.940696],
  [42.379705, 92.940773],
  [42.379693, 92.940861],
  [42.379686, 92.940958],
  [42.379693, 92.94106],
  [42.3797, 92.941263],
  [42.3797, 92.941263],
  [42.379696, 92.941483],
  [42.379691, 92.941588],
  [42.37969, 92.94168],
  [42.379686, 92.94178],
  [42.379681, 92.941888],
  [42.37967, 92.942003],
  [42.37966, 92.942126],
  [42.37966, 92.942245],
  [42.379656, 92.942358],
  [42.379616, 92.942568],
  [42.379605, 92.942668],
  [42.379605, 92.942668],
  [42.379585, 92.942845],
  [42.379583, 92.942936],
  [42.379578, 92.943041],
  [42.379571, 92.943141],
  [42.379568, 92.94325],
  [42.379571, 92.943348],
  [42.37956, 92.943556],
  [42.37956, 92.943556],
  [42.379553, 92.943755],
  [42.379533, 92.943851],
  [42.379523, 92.943966],
  [42.379498, 92.944071],
  [42.379468, 92.944173],
  [42.379443, 92.944285],
  [42.379413, 92.944386],
  [42.37939, 92.9445],
  [42.37937, 92.944611],
  [42.379333, 92.944811],
  [42.37932, 92.944908],
  [42.379303, 92.945003],
  [42.379283, 92.945098],
  [42.379265, 92.945195],
  [42.379248, 92.945285],
  [42.379233, 92.945383],
  [42.379221, 92.945473],
  [42.379213, 92.945565],
  [42.379203, 92.945666],
  [42.379191, 92.94577],
  [42.379161, 92.945958],
  [42.379143, 92.946063],
  [42.379118, 92.94617],
  [42.379076, 92.946263],
  [42.379026, 92.946348],
  [42.378961, 92.946408],
  [42.37889, 92.946451],
  [42.378826, 92.946471],
  [42.378761, 92.946501],
  [42.378611, 92.946601],
  [42.378611, 92.946601],
  [42.37847, 92.946716],
  [42.378388, 92.946768],
  [42.378298, 92.946813],
  [42.37821, 92.946863],
  [42.378125, 92.946915],
  [42.378045, 92.946961],
  [42.377906, 92.947051],
  [42.377906, 92.947051],
  [42.377835, 92.947101],
  [42.377701, 92.947205],
  [42.377641, 92.947243],
  [42.377585, 92.947283],
  [42.377516, 92.947345],
  [42.37745, 92.9474],
  [42.37738, 92.947451],
  [42.377306, 92.947505],
  [42.377231, 92.947548],
  [42.377155, 92.947598],
  [42.377016, 92.94772],
  [42.377016, 92.94772],
  [42.376875, 92.947823],
  [42.376815, 92.947871],
  [42.376751, 92.947915],
  [42.376673, 92.94796],
  [42.3766, 92.94801],
  [42.376518, 92.948053],
  [42.376371, 92.948145],
  [42.376371, 92.948145],
  [42.376296, 92.948193],
  [42.376138, 92.948285],
  [42.376053, 92.948331],
  [42.375968, 92.948375],
  [42.375886, 92.94842],
  [42.375805, 92.948466],
  [42.375723, 92.948513],
  [42.375643, 92.948558],
  [42.375563, 92.948613],
  [42.375406, 92.948713],
  [42.375325, 92.948761],
  [42.375325, 92.948761],
  [42.375171, 92.94885],
  [42.375101, 92.948901],
  [42.375028, 92.948948],
  [42.374946, 92.949],
  [42.374868, 92.949045],
  [42.374785, 92.949105],
  [42.374706, 92.94917],
  [42.374621, 92.949221],
  [42.37446, 92.949333],
  [42.374363, 92.949385],
  [42.374363, 92.949385],
  [42.374163, 92.949481],
  [42.37407, 92.949526],
  [42.373983, 92.94957],
  [42.373895, 92.949616],
  [42.373815, 92.949645],
  [42.373736, 92.949686],
  [42.373588, 92.949758],
  [42.373588, 92.949758],
  [42.373516, 92.949796],
  [42.373361, 92.949906],
  [42.37329, 92.949955],
  [42.373231, 92.949996],
  [42.373163, 92.950045],
  [42.373093, 92.950103],
  [42.373023, 92.95016],
  [42.372948, 92.950218],
  [42.372876, 92.950271],
  [42.372723, 92.950371],
  [42.37265, 92.950411],
  [42.37265, 92.950411],
  [42.372528, 92.95052],
  [42.372466, 92.950568],
  [42.372406, 92.950605],
  [42.372351, 92.950645],
  [42.372283, 92.95067],
  [42.37222, 92.950685],
  [42.372068, 92.95076],
  [42.372068, 92.95076],
  [42.37193, 92.950863],
  [42.371858, 92.950906],
  [42.371781, 92.950975],
  [42.371711, 92.951038],
  [42.371643, 92.951125],
  [42.371585, 92.951213],
  [42.371536, 92.951295],
  [42.371505, 92.951395],
  [42.371478, 92.951506],
  [42.371461, 92.951758],
  [42.371478, 92.951886],
  [42.371478, 92.951886],
  [42.371531, 92.952098],
  [42.371566, 92.952203],
  [42.371605, 92.952305],
  [42.371645, 92.952411],
  [42.371673, 92.952511],
  [42.371701, 92.9526],
  [42.371751, 92.952746],
  [42.371751, 92.952746],
  [42.371785, 92.95287],
  [42.371801, 92.952926],
  [42.371815, 92.952981],
  [42.37183, 92.953036],
  [42.371843, 92.953086],
  [42.371856, 92.95314],
  [42.371881, 92.95324],
  [42.371881, 92.95324],
  [42.371891, 92.953293],
  [42.371915, 92.953385],
  [42.371915, 92.953385],
  [42.371946, 92.953478],
  [42.371961, 92.953525],
  [42.371976, 92.953573],
  [42.371991, 92.953616],
  [42.372006, 92.953663],
  [42.372021, 92.953711],
  [42.372035, 92.953771],
  [42.37204, 92.953821],
  [42.372051, 92.953878],
  [42.372071, 92.953995],
  [42.372073, 92.954056],
  [42.372071, 92.954115],
  [42.372071, 92.954175],
  [42.372078, 92.95422],
  [42.372086, 92.954275],
  [42.372088, 92.954336],
  [42.372086, 92.954395],
  [42.372081, 92.954451],
  [42.372075, 92.954563],
  [42.372075, 92.954563],
  [42.372056, 92.954693],
  [42.372045, 92.954753],
  [42.372035, 92.954811],
  [42.372025, 92.954876],
  [42.372016, 92.954946],
  [42.372018, 92.955025],
  [42.37203, 92.955196],
  [42.37203, 92.955196],
  [42.372048, 92.955285],
  [42.372073, 92.955465],
  [42.372083, 92.955558],
  [42.372095, 92.955643],
  [42.372101, 92.955723],
  [42.372108, 92.955795],
  [42.372116, 92.955865],
  [42.372126, 92.95593],
  [42.372135, 92.955998],
  [42.37214, 92.956075],
  [42.372148, 92.956223],
  [42.372148, 92.956223],
  [42.372153, 92.956365],
  [42.37216, 92.956438],
  [42.372155, 92.956508],
  [42.372161, 92.956576],
  [42.372171, 92.956638],
  [42.372178, 92.956695],
  [42.372175, 92.956828],
  [42.372175, 92.956828],
  [42.372171, 92.956895],
  [42.37218, 92.95701],
  [42.372188, 92.957053],
  [42.3722, 92.957098],
  [42.37221, 92.957143],
  [42.372215, 92.95719],
  [42.372216, 92.957241],
  [42.37222, 92.957293],
  [42.372226, 92.957355],
  [42.372238, 92.957466],
  [42.372238, 92.957518],
  [42.372238, 92.957518],
  [42.372233, 92.95762],
  [42.372233, 92.957676],
  [42.372238, 92.957736],
  [42.372246, 92.957801],
  [42.372251, 92.957866],
  [42.372256, 92.957933],
  [42.372258, 92.957991],
  [42.372263, 92.958046],
  [42.37227, 92.958168],
  [42.372266, 92.958225],
  [42.372266, 92.958225],
  [42.372273, 92.958333],
  [42.372273, 92.958393],
  [42.372271, 92.958451],
  [42.372276, 92.958495],
  [42.372278, 92.95855],
  [42.372285, 92.958605],
  [42.37228, 92.958728],
  [42.37228, 92.958728],
  [42.372281, 92.958795],
  [42.372278, 92.958908],
  [42.372285, 92.958966],
  [42.372286, 92.959015],
  [42.372286, 92.959075],
  [42.372285, 92.959131],
  [42.372276, 92.959186],
  [42.372273, 92.959251],
  [42.372265, 92.95931],
  [42.372255, 92.959443],
  [42.372255, 92.959513],
  [42.372255, 92.959513],
  [42.372288, 92.959655],
  [42.372308, 92.959726],
  [42.372316, 92.959805],
  [42.372323, 92.959875],
  [42.37234, 92.95995],
  [42.372346, 92.960018],
  [42.37237, 92.960128],
  [42.37237, 92.960128],
  [42.372385, 92.960251],
  [42.372398, 92.960308],
  [42.372398, 92.960393],
  [42.372406, 92.960461],
  [42.372423, 92.960541],
  [42.372436, 92.960608],
  [42.372453, 92.960666],
  [42.372468, 92.960715],
  [42.372485, 92.960775],
  [42.372503, 92.960918],
  [42.372515, 92.960985],
  [42.372525, 92.961055],
  [42.372535, 92.96111],
  [42.372551, 92.961145],
  [42.372556, 92.961183],
  [42.37256, 92.96125],
  [42.372566, 92.961308],
  [42.372578, 92.961368],
  [42.372596, 92.961495],
  [42.372596, 92.961495],
  [42.37262, 92.961611],
  [42.37263, 92.961665],
  [42.37263, 92.961665],
  [42.372656, 92.961781],
  [42.372665, 92.961835],
  [42.372683, 92.96188],
  [42.372696, 92.96193],
  [42.372715, 92.961976],
  [42.372731, 92.96203],
  [42.372741, 92.962156],
  [42.372741, 92.962156],
  [42.372756, 92.962266],
  [42.372766, 92.962325],
  [42.372775, 92.962378],
  [42.372786, 92.962433],
  [42.372798, 92.962503],
  [42.372801, 92.96256],
  [42.372796, 92.962603],
  [42.3728, 92.96265],
  [42.372808, 92.9627],
  [42.37283, 92.962813],
  [42.37284, 92.96287],
  [42.372856, 92.962926],
  [42.372861, 92.962986],
  [42.372876, 92.963036],
  [42.372895, 92.963086],
  [42.37292, 92.963133],
  [42.372936, 92.963193],
  [42.372953, 92.963253],
  [42.372985, 92.963378],
  [42.372985, 92.963378],
  [42.373011, 92.963506],
  [42.373016, 92.963585],
  [42.373015, 92.963666],
  [42.373008, 92.963731],
  [42.373008, 92.963793],
  [42.373013, 92.963855],
  [42.37301, 92.963998],
  [42.37301, 92.963998],
  [42.373001, 92.964053],
  [42.372983, 92.964168],
  [42.372955, 92.964215],
  [42.37293, 92.964258],
  [42.372901, 92.964316],
  [42.372881, 92.964348],
  [42.37286, 92.964371],
  [42.372831, 92.964426],
  [42.372808, 92.964461],
  [42.372753, 92.964536],
  [42.37272, 92.964591],
  [42.37272, 92.964591],
  [42.37265, 92.9647],
  [42.372611, 92.964748],
  [42.372576, 92.964791],
  [42.372538, 92.964851],
  [42.372511, 92.964923],
  [42.372481, 92.96499],
  [42.372448, 92.965121],
  [42.372448, 92.965121],
  [42.372425, 92.965176],
  [42.372365, 92.965283],
  [42.372341, 92.965336],
  [42.372318, 92.965388],
  [42.372295, 92.965441],
  [42.372273, 92.965488],
  [42.372246, 92.965533],
  [42.372218, 92.965578],
  [42.3722, 92.965618],
  [42.37216, 92.965705],
  [42.37216, 92.965705],
  [42.372135, 92.965751],
  [42.37208, 92.965831],
  [42.372053, 92.965875],
  [42.372025, 92.96593],
  [42.371996, 92.965983],
  [42.37197, 92.966041],
  [42.371938, 92.966091],
  [42.371908, 92.966146],
  [42.371878, 92.966198],
  [42.371825, 92.966293],
  [42.3718, 92.96634],
  [42.3718, 92.96634],
  [42.371751, 92.966435],
  [42.37173, 92.966481],
  [42.371708, 92.966523],
  [42.371688, 92.96657],
  [42.371668, 92.966613],
  [42.371648, 92.96666],
  [42.371615, 92.966753],
  [42.371615, 92.966753],
  [42.371593, 92.9668],
  [42.371543, 92.96689],
  [42.37152, 92.966931],
  [42.371496, 92.966971],
  [42.371473, 92.967016],
  [42.371448, 92.96706],
  [42.371425, 92.967108],
  [42.371403, 92.967163],
  [42.371383, 92.967205],
  [42.371335, 92.967298],
  [42.371316, 92.967331],
  [42.371316, 92.967331],
  [42.37128, 92.96742],
  [42.371263, 92.967463],
  [42.371248, 92.967503],
  [42.371226, 92.967546],
  [42.371201, 92.967595],
  [42.37118, 92.967643],
  [42.371128, 92.967731],
  [42.371128, 92.967731],
  [42.371081, 92.967828],
  [42.371061, 92.967861],
  [42.371036, 92.967916],
  [42.371008, 92.967968],
  [42.370981, 92.968018],
  [42.370951, 92.968063],
  [42.370926, 92.968101],
  [42.370905, 92.968146],
  [42.370885, 92.968185],
  [42.370843, 92.968245],
  [42.370826, 92.968288],
  [42.370811, 92.968325],
  [42.370786, 92.968365],
  [42.370771, 92.968406],
  [42.370763, 92.968461],
  [42.370755, 92.968513],
  [42.370758, 92.968558],
  [42.370758, 92.968558],
  [42.370801, 92.968655],
  [42.370801, 92.968655],
  [42.370831, 92.968655],
  [42.370886, 92.968648],
  [42.370901, 92.96863],
  [42.370901, 92.96863],
  [42.370936, 92.968595],
  [42.370936, 92.968595],
  [42.370936, 92.968595],
  [42.37091, 92.968618],
  [42.3709, 92.968631],
  [42.37089, 92.96863],
  [42.370868, 92.968643],
  [42.370868, 92.968643],
  [42.370865, 92.968646],
  [42.370843, 92.96866],
  [42.37083, 92.968665],
  [42.370823, 92.968666],
  [42.370818, 92.96868],
  [42.370815, 92.968688],
  [42.370808, 92.968688],
  [42.370813, 92.96869],
  [42.370813, 92.96869],
  [42.370943, 92.968451],
  [42.37096, 92.968416],
  [42.370983, 92.968375],
  [42.371001, 92.968335],
  [42.37102, 92.968285],
  [42.37104, 92.968245],
  [42.371046, 92.968195],
  [42.371065, 92.96814],
  [42.371105, 92.96804],
  [42.371136, 92.967986],
  [42.371136, 92.967986],
  [42.3712, 92.967836],
  [42.371236, 92.967753],
  [42.371276, 92.967678],
  [42.371311, 92.967598],
  [42.371346, 92.967521],
  [42.371383, 92.967445],
  [42.371458, 92.967301],
  [42.371458, 92.967301],
  [42.371535, 92.967173],
  [42.371573, 92.967101],
  [42.371608, 92.96703],
  [42.37165, 92.966945],
  [42.371685, 92.966875],
  [42.371723, 92.966798],
  [42.371796, 92.96663],
  [42.371796, 92.96663],
  [42.371843, 92.966551],
  [42.3719, 92.9664],
  [42.371941, 92.966326],
  [42.371978, 92.96626],
  [42.372018, 92.966176],
  [42.372061, 92.9661],
  [42.372108, 92.966016],
  [42.372146, 92.965931],
  [42.372186, 92.96585],
  [42.372218, 92.965773],
  [42.372288, 92.965616],
  [42.372288, 92.965616],
  [42.372366, 92.965448],
  [42.372401, 92.965366],
  [42.372438, 92.965291],
  [42.372481, 92.965208],
  [42.372526, 92.965116],
  [42.372571, 92.965043],
  [42.372648, 92.964906],
  [42.372648, 92.964906],
  [42.372693, 92.964836],
  [42.372791, 92.964698],
  [42.372838, 92.964641],
  [42.372883, 92.964576],
  [42.37292, 92.964523],
  [42.372951, 92.964468],
  [42.372993, 92.964415],
  [42.373023, 92.964363],
  [42.373048, 92.964303],
  [42.373083, 92.964186],
  [42.373083, 92.964186],
  [42.3731, 92.964121],
  [42.373131, 92.963983],
  [42.373133, 92.963898],
  [42.373131, 92.963811],
  [42.373121, 92.96374],
  [42.373106, 92.963651],
  [42.373091, 92.963563],
  [42.37307, 92.963485],
  [42.37305, 92.963408],
  [42.373025, 92.963335],
  [42.373, 92.963181],
  [42.373, 92.963181],
  [42.372965, 92.963015],
  [42.372943, 92.962933],
  [42.372921, 92.962845],
  [42.372908, 92.962766],
  [42.372888, 92.962676],
  [42.372873, 92.962588],
  [42.372843, 92.962433],
  [42.372843, 92.962433],
  [42.372828, 92.96235],
  [42.372793, 92.962171],
  [42.37278, 92.962086],
  [42.372771, 92.962003],
  [42.372745, 92.96191],
  [42.372721, 92.961821],
  [42.372701, 92.961745],
  [42.372681, 92.96166],
  [42.372666, 92.961578],
  [42.372638, 92.961396],
  [42.372621, 92.961295],
  [42.372621, 92.961295],
  [42.37259, 92.961108],
  [42.372573, 92.961008],
  [42.372553, 92.96091],
  [42.372531, 92.960815],
  [42.37251, 92.960713],
  [42.372481, 92.960608],
  [42.372435, 92.960401],
  [42.372435, 92.960401],
  [42.372396, 92.960201],
  [42.372378, 92.96011],
  [42.372361, 92.96003],
  [42.372343, 92.959938],
  [42.372333, 92.959863],
  [42.37232, 92.95978],
  [42.372306, 92.959683],
  [42.37231, 92.95961],
  [42.372313, 92.959521],
  [42.372338, 92.959338],
  [42.372356, 92.959258],
  [42.372356, 92.959258],
  [42.372393, 92.959108],
  [42.372408, 92.95904],
  [42.37241, 92.958971],
  [42.372415, 92.958901],
  [42.372413, 92.958828],
  [42.372405, 92.958751],
  [42.372393, 92.958615],
  [42.372393, 92.958615],
  [42.372368, 92.95843],
  [42.372366, 92.958335],
  [42.372366, 92.958335],
  [42.372346, 92.958153],
  [42.372336, 92.958063],
  [42.372331, 92.957978],
  [42.372333, 92.957908],
  [42.372338, 92.95783],
  [42.372331, 92.957746],
  [42.372306, 92.95757],
  [42.372306, 92.95757],
  [42.372288, 92.95738],
  [42.372286, 92.957288],
  [42.372283, 92.957198],
  [42.37228, 92.957115],
  [42.372283, 92.957023],
  [42.372278, 92.956928],
  [42.372273, 92.956845],
  [42.372268, 92.95675],
  [42.372246, 92.95664],
  [42.372218, 92.956445],
  [42.372215, 92.956346],
  [42.372206, 92.956256],
  [42.372205, 92.956175],
  [42.3722, 92.956098],
  [42.3722, 92.956011],
  [42.372201, 92.955933],
  [42.372201, 92.955841],
  [42.372198, 92.95576],
  [42.372183, 92.955606],
  [42.372183, 92.955606],
  [42.372165, 92.955433],
  [42.372166, 92.955361],
  [42.37217, 92.955268],
  [42.37217, 92.95518],
  [42.372168, 92.955073],
  [42.372163, 92.954973],
  [42.372165, 92.954776],
  [42.372165, 92.954776],
  [42.372168, 92.954691],
  [42.37217, 92.954525],
  [42.37217, 92.954443],
  [42.372168, 92.954363],
  [42.372168, 92.954291],
  [42.37217, 92.954215],
  [42.372161, 92.954121],
  [42.372153, 92.954028],
  [42.372145, 92.953955],
  [42.372131, 92.953881],
  [42.372105, 92.953718],
  [42.372105, 92.953718],
  [42.372066, 92.953556],
  [42.37204, 92.95347],
  [42.372025, 92.953381],
  [42.372003, 92.953291],
  [42.371981, 92.9532],
  [42.371953, 92.953103],
  [42.371898, 92.952896],
  [42.371898, 92.952896],
  [42.371876, 92.952803],
  [42.37181, 92.95259],
  [42.371766, 92.952481],
  [42.371738, 92.952383],
  [42.371713, 92.952281],
  [42.371678, 92.952193],
  [42.37166, 92.95211],
  [42.371626, 92.952021],
  [42.371603, 92.951925],
  [42.371583, 92.951721],
  [42.371583, 92.951721],
  [42.371596, 92.951613],
  [42.371643, 92.951428],
  [42.37168, 92.951338],
  [42.371733, 92.951251],
  [42.37179, 92.951176],
  [42.371861, 92.951106],
  [42.37194, 92.951043],
  [42.37202, 92.950983],
  [42.3721, 92.950928],
  [42.372251, 92.95082],
  [42.372335, 92.950766],
  [42.372335, 92.950766],
  [42.3725, 92.950673],
  [42.372586, 92.950615],
  [42.372668, 92.950558],
  [42.372753, 92.950505],
  [42.372835, 92.950453],
  [42.372918, 92.950403],
  [42.37308, 92.950295],
  [42.37308, 92.950295],
  [42.373161, 92.950243],
  [42.37332, 92.950143],
  [42.373396, 92.95009],
  [42.373475, 92.950038],
  [42.373555, 92.949986],
  [42.373635, 92.949938],
  [42.373713, 92.949893],
  [42.373795, 92.949843],
  [42.373875, 92.949793],
  [42.374031, 92.949696],
  [42.374113, 92.949638],
  [42.374113, 92.949638],
  [42.374278, 92.949545],
  [42.374363, 92.949496],
  [42.374448, 92.94946],
  [42.374533, 92.949415],
  [42.374618, 92.94937],
  [42.374706, 92.949323],
  [42.374885, 92.949218],
  [42.374885, 92.949218],
  [42.375053, 92.949113],
  [42.375138, 92.94906],
  [42.37522, 92.949011],
  [42.375298, 92.948965],
  [42.375378, 92.948915],
  [42.375456, 92.948866],
  [42.37554, 92.948815],
  [42.375625, 92.948766],
  [42.37571, 92.94873],
  [42.375865, 92.94864],
  [42.375945, 92.94859],
  [42.375945, 92.94859],
  [42.3761, 92.948485],
  [42.37618, 92.948436],
  [42.37626, 92.94838],
  [42.376345, 92.948325],
  [42.376426, 92.948276],
  [42.37651, 92.94823],
  [42.376586, 92.948181],
  [42.376663, 92.948135],
  [42.376808, 92.948041],
  [42.376875, 92.94799],
  [42.376875, 92.94799],
  [42.377021, 92.94788],
  [42.377093, 92.947823],
  [42.377166, 92.947773],
  [42.377246, 92.947711],
  [42.377323, 92.947655],
  [42.377396, 92.947601],
  [42.377545, 92.947511],
  [42.377545, 92.947511],
  [42.377691, 92.947416],
  [42.377765, 92.947371],
  [42.377845, 92.94731],
  [42.377918, 92.947258],
  [42.377996, 92.947198],
  [42.378076, 92.94714],
  [42.378148, 92.947093],
  [42.378218, 92.947041],
  [42.37829, 92.946988],
  [42.378435, 92.94689],
  [42.378513, 92.94684],
  [42.37858, 92.946795],
  [42.378651, 92.946746],
  [42.378718, 92.946706],
  [42.378781, 92.946663],
  [42.378851, 92.946616],
  [42.378923, 92.946568],
  [42.37898, 92.946521],
  [42.379076, 92.9464],
  [42.379076, 92.9464],
  [42.379143, 92.946241],
  [42.37916, 92.946145],
  [42.379176, 92.94605],
  [42.379191, 92.945955],
  [42.379215, 92.94586],
  [42.37923, 92.94575],
  [42.379248, 92.945528],
  [42.379248, 92.945528],
  [42.379268, 92.945426],
  [42.379293, 92.945225],
  [42.379308, 92.945126],
  [42.379308, 92.945126],
  [42.37933, 92.944938],
  [42.37934, 92.944843],
  [42.379358, 92.944746],
  [42.379378, 92.944648],
  [42.379403, 92.944548],
  [42.379423, 92.944451],
  [42.379461, 92.944265],
  [42.379461, 92.944265],
  [42.379485, 92.94406],
  [42.379495, 92.94396],
  [42.379511, 92.943858],
  [42.379526, 92.943761],
  [42.379536, 92.943663],
  [42.379545, 92.943566],
  [42.379556, 92.943376],
  [42.379556, 92.943376],
  [42.379556, 92.94327],
  [42.37955, 92.943071],
  [42.37955, 92.943071],
  [42.379571, 92.942911],
  [42.379585, 92.942823],
  [42.379595, 92.94274],
  [42.379603, 92.942663],
  [42.379611, 92.942588],
  [42.379616, 92.942503],
  [42.379623, 92.94231],
  [42.379623, 92.94231],
  [42.379628, 92.942196],
  [42.379638, 92.941973],
  [42.379643, 92.941863],
  [42.379646, 92.94176],
  [42.379655, 92.941651],
  [42.379658, 92.941543],
  [42.379666, 92.941418],
  [42.379675, 92.9413],
  [42.379681, 92.941183],
  [42.37969, 92.940976],
  [42.379695, 92.940878],
  [42.379695, 92.940878],
  [42.379715, 92.94068],
  [42.37973, 92.940585],
  [42.379746, 92.940493],
  [42.37977, 92.940395],
  [42.379791, 92.94031],
  [42.379818, 92.940216],
  [42.37988, 92.940015],
  [42.37988, 92.940015],
  [42.379906, 92.93992],
  [42.37998, 92.939748],
  [42.380025, 92.939668],
  [42.380025, 92.939668],
  [42.380075, 92.939596],
  [42.380211, 92.939525],
  [42.380276, 92.939495],
  [42.38035, 92.939465],
  [42.38042, 92.939433],
  [42.380481, 92.939401],
  [42.380541, 92.93937],
  [42.380598, 92.939328],
  [42.380651, 92.939293],
  [42.380698, 92.939256],
  [42.380796, 92.939183],
  [42.380843, 92.939146],
  [42.380843, 92.939146],
  [42.380938, 92.939056],
  [42.38099, 92.939008],
  [42.38105, 92.93895],
  [42.381101, 92.938893],
  [42.381153, 92.938831],
  [42.381211, 92.938763],
  [42.381328, 92.938608],
  [42.381328, 92.938608],
  [42.38145, 92.938451],
  [42.381526, 92.938393],
  [42.381601, 92.938348],
  [42.381668, 92.938306],
  [42.381738, 92.938265],
  [42.381803, 92.938225],
  [42.381928, 92.938138],
  [42.381928, 92.938138],
  [42.381988, 92.9381],
  [42.38212, 92.938026],
  [42.382183, 92.937995],
  [42.38225, 92.937953],
  [42.382316, 92.937911],
  [42.382378, 92.937873],
  [42.38243, 92.937823],
  [42.382478, 92.937753],
  [42.38252, 92.937678],
  [42.382556, 92.9376],
  [42.382596, 92.937515],
  [42.382635, 92.937421],
  [42.382715, 92.937261],
  [42.382715, 92.937261],
  [42.382715, 92.937261],
  [42.382903, 92.937138],
  [42.382973, 92.937098],
  [42.383048, 92.937061],
  [42.383121, 92.937023],
  [42.383186, 92.936986],
  [42.383258, 92.936945],
  [42.383395, 92.936861],
  [42.383395, 92.936861],
  [42.383538, 92.936778],
  [42.383603, 92.936725],
  [42.383665, 92.93669],
  [42.383718, 92.936641],
  [42.38377, 92.93658],
  [42.383796, 92.936511],
  [42.383821, 92.936388],
  [42.383821, 92.936388],
  [42.383808, 92.936328],
  [42.383778, 92.936228],
  [42.383751, 92.936193],
  [42.383751, 92.936193],
  [42.383711, 92.936156],
  [42.383671, 92.93613],
  [42.383588, 92.936108],
  [42.38355, 92.936113],
  [42.3835, 92.936118],
  [42.3835, 92.936118],
  [42.38341, 92.936178],
  [42.383376, 92.93621],
  [42.383341, 92.936246],
  [42.383291, 92.936281],
  [42.383186, 92.93634],
  [42.383138, 92.936365],
  [42.383138, 92.936365],
  [42.383036, 92.936421],
  [42.382986, 92.936443],
  [42.38293, 92.936471],
  [42.382865, 92.936511],
  [42.382801, 92.936541],
  [42.382735, 92.936575],
  [42.382603, 92.936628],
  [42.382603, 92.936628],
  [42.382473, 92.936703],
  [42.382423, 92.936738],
  [42.382363, 92.936765],
  [42.38231, 92.936785],
  [42.382258, 92.9368],
  [42.382208, 92.936813],
  [42.38216, 92.936828],
  [42.382115, 92.936848],
  [42.382071, 92.936868],
  [42.381986, 92.936933],
  [42.381935, 92.936953],
  [42.381935, 92.936953],
  [42.381831, 92.936996],
  [42.381778, 92.93703],
  [42.381726, 92.937056],
  [42.381681, 92.93708],
  [42.381633, 92.937101],
  [42.381566, 92.937126],
  [42.381453, 92.937161],
  [42.381453, 92.937161],
  [42.38135, 92.937175],
  [42.3813, 92.937181],
  [42.381265, 92.937186],
  [42.381226, 92.937191],
  [42.381198, 92.937193],
  [42.381163, 92.937188],
  [42.381128, 92.937193],
  [42.381091, 92.9372],
  [42.38105, 92.937205],
  [42.380971, 92.937201],
  [42.380971, 92.937201],
  [42.38091, 92.937175],
  [42.380878, 92.937168],
  [42.38084, 92.937168],
  [42.380803, 92.93717],
  [42.38077, 92.937166],
  [42.38074, 92.937171],
  [42.380708, 92.937183],
  [42.380676, 92.937196],
  [42.380648, 92.937211],
  [42.3806, 92.937223],
  [42.380578, 92.937235],
  [42.380578, 92.937235],
  [42.380528, 92.937283],
  [42.380501, 92.937293],
  [42.380473, 92.937306],
  [42.38046, 92.937318],
  [42.38044, 92.937316],
  [42.380421, 92.937321],
  [42.380373, 92.937326],
  [42.380373, 92.937326],
  [42.380336, 92.937301],
  [42.380323, 92.937288],
  [42.380323, 92.937283],
  [42.380333, 92.937276],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380341, 92.937275],
  [42.380281, 92.937235],
  [42.380276, 92.93724],
  [42.380271, 92.937235],
  [42.380273, 92.937231],
  [42.380265, 92.937238],
  [42.380243, 92.937243],
  [42.38024, 92.937241],
  [42.380233, 92.937236],
  [42.380235, 92.937243],
  [42.380208, 92.937243],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.380188, 92.93725],
  [42.38008, 92.937233],
  [42.380063, 92.937238],
  [42.380056, 92.937228],
  [42.380058, 92.937221],
  [42.380051, 92.937231],
  [42.380051, 92.937231],
  [42.380036, 92.937248],
  [42.380026, 92.93725],
  [42.380023, 92.93725],
  [42.380025, 92.937233],
  [42.380023, 92.937228],
  [42.38002, 92.937216],
  [42.38002, 92.937216],
  [42.380036, 92.937151],
  [42.380046, 92.937138],
  [42.380048, 92.937131],
  [42.380048, 92.937131],
  [42.380048, 92.937131],
  [42.380023, 92.937173],
  [42.380023, 92.937173],
  [42.380026, 92.937168],
  [42.380031, 92.937196],
  [42.380038, 92.937205],
  [42.38004, 92.937231],
  [42.380045, 92.937258],
  [42.380055, 92.93727],
  [42.380056, 92.937281],
  [42.380056, 92.93729],
  [42.380058, 92.937298],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.38005, 92.937311],
  [42.380045, 92.937308],
  [42.380043, 92.93729],
  [42.380048, 92.937271],
  [42.380051, 92.937248],
  [42.380048, 92.937231],
  [42.380043, 92.937213],
  [42.380045, 92.937198],
  [42.380045, 92.937171],
  [42.380056, 92.93712],
  [42.38007, 92.937113],
  [42.380088, 92.937088],
  [42.380103, 92.937073],
  [42.380113, 92.937051],
  [42.380123, 92.937046],
  [42.380123, 92.937046],
  [42.38014, 92.937055],
  [42.380178, 92.937046],
  [42.380203, 92.937053],
  [42.380226, 92.937038],
  [42.380226, 92.937038],
  [42.380246, 92.937025],
  [42.380303, 92.937015],
  [42.380303, 92.937015],
  [42.380335, 92.93703],
  [42.380335, 92.93703],
  [42.380351, 92.937101],
  [42.380353, 92.937111],
  [42.38036, 92.937131],
  [42.380375, 92.937145],
  [42.380396, 92.937155],
  [42.38043, 92.937168],
  [42.380451, 92.93717],
  [42.380451, 92.93717],
  [42.380468, 92.937171],
  [42.380486, 92.937165],
  [42.380521, 92.937168],
  [42.380548, 92.937156],
  [42.380566, 92.937161],
  [42.380588, 92.93716],
  [42.38065, 92.937148],
  [42.38065, 92.937148],
  [42.380701, 92.93714],
  [42.380723, 92.937131],
  [42.380755, 92.937131],
  [42.380773, 92.93713],
  [42.380795, 92.937135],
  [42.380816, 92.937135],
  [42.380846, 92.937135],
  [42.380873, 92.937135],
  [42.380893, 92.93714],
  [42.380931, 92.937148],
  [42.38096, 92.937153],
  [42.38096, 92.937153],
  [42.380993, 92.937155],
  [42.381001, 92.937161],
  [42.381, 92.937168],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38099, 92.93717],
  [42.38096, 92.937171],
  [42.380981, 92.937175],
  [42.381005, 92.937176],
  [42.381028, 92.93718],
  [42.381091, 92.93719],
  [42.381091, 92.93719],
  [42.381115, 92.937198],
  [42.381176, 92.937193],
  [42.381205, 92.937195],
  [42.38125, 92.93719],
  [42.38129, 92.93718],
  [42.381311, 92.937185],
  [42.381346, 92.937175],
  [42.38138, 92.93717],
  [42.381421, 92.93716],
  [42.381501, 92.937125],
  [42.381553, 92.937095],
  [42.381553, 92.937095],
  [42.38167, 92.937061],
  [42.381723, 92.937043],
  [42.38177, 92.937026],
  [42.381816, 92.93701],
  [42.381863, 92.936993],
  [42.381908, 92.936976],
  [42.382001, 92.936946],
  [42.382001, 92.936946],
  [42.382031, 92.936928],
  [42.382118, 92.936883],
  [42.382161, 92.93687],
  [42.382211, 92.936846],
  [42.382261, 92.93682],
  [42.382316, 92.936798],
  [42.382366, 92.936768],
  [42.382406, 92.936738],
  [42.382453, 92.936693],
  [42.382551, 92.936663],
  [42.382608, 92.93664],
  [42.382608, 92.93664],
  [42.382725, 92.93659],
  [42.382778, 92.936568],
  [42.382823, 92.936548],
  [42.382876, 92.936523],
  [42.382931, 92.936496],
  [42.382986, 92.936471],
  [42.383101, 92.936416],
  [42.383101, 92.936416],
  [42.383216, 92.936341],
  [42.383285, 92.936306],
  [42.383343, 92.936271],
  [42.383401, 92.936246],
  [42.383456, 92.93622],
  [42.383513, 92.936211],
  [42.383595, 92.936208],
  [42.383595, 92.936208],
  [42.383631, 92.936208],
  [42.383703, 92.936271],
  [42.383703, 92.936271],
  [42.383786, 92.93635],
  [42.383783, 92.936413],
  [42.383783, 92.936413],
  [42.383783, 92.936413],
  [42.383728, 92.936568],
  [42.3837, 92.936611],
  [42.383675, 92.93665],
  [42.383643, 92.936696],
  [42.383601, 92.936725],
  [42.383545, 92.936745],
  [42.38349, 92.936775],
  [42.383393, 92.93685],
  [42.383393, 92.93685],
  [42.38335, 92.93688],
  [42.38326, 92.936933],
  [42.383208, 92.936971],
  [42.38316, 92.937],
  [42.383111, 92.937026],
  [42.38306, 92.937055],
  [42.383005, 92.937081],
  [42.382953, 92.937106],
  [42.382888, 92.937138],
  [42.382835, 92.937163],
  [42.382726, 92.937251],
  [42.382726, 92.937251],
  [42.382628, 92.937386],
  [42.382586, 92.937456],
  [42.382553, 92.937535],
  [42.382528, 92.937606],
  [42.382491, 92.937678],
  [42.382451, 92.93775],
  [42.38239, 92.937831],
  [42.38239, 92.937831],
  [42.382348, 92.937876],
  [42.38226, 92.937958],
  [42.382205, 92.937998],
  [42.382146, 92.93803],
  [42.382091, 92.938053],
  [42.382031, 92.938086],
  [42.381968, 92.93812],
  [42.381913, 92.938148],
  [42.381868, 92.938166],
  [42.381761, 92.938241],
  [42.3817, 92.938285],
  [42.3817, 92.938285],
  [42.381575, 92.938358],
  [42.381511, 92.9384],
  [42.381446, 92.938446],
  [42.381385, 92.938503],
  [42.38133, 92.938561],
  [42.381286, 92.938623],
  [42.381206, 92.938748],
  [42.381206, 92.938748],
  [42.381126, 92.938865],
  [42.381076, 92.938916],
  [42.381018, 92.938966],
  [42.380956, 92.939013],
  [42.380903, 92.939061],
  [42.380855, 92.9391],
  [42.380803, 92.939141],
  [42.380751, 92.939175],
  [42.380696, 92.939218],
  [42.380586, 92.93929],
  [42.380535, 92.93933],
  [42.380535, 92.93933],
  [42.380416, 92.9394],
  [42.380358, 92.939438],
  [42.3803, 92.939473],
  [42.380243, 92.93951],
  [42.380186, 92.939548],
  [42.380135, 92.939588],
  [42.380043, 92.939681],
  [42.380043, 92.939681],
  [42.379978, 92.939788],
  [42.379953, 92.93985],
  [42.379953, 92.93985],
  [42.379898, 92.939986],
  [42.379883, 92.940056],
  [42.37987, 92.940128],
  [42.37985, 92.940213],
  [42.379825, 92.940291],
  [42.379808, 92.940378],
  [42.37977, 92.940511],
  [42.37977, 92.940511],
  [42.379756, 92.94065],
  [42.379751, 92.940741],
  [42.37974, 92.940833],
  [42.379735, 92.940918],
  [42.379725, 92.941006],
  [42.379711, 92.9411],
  [42.379698, 92.941188],
  [42.379691, 92.941271],
  [42.379686, 92.941361],
  [42.37967, 92.941566],
  [42.379666, 92.941661],
  [42.379666, 92.941661],
  [42.379655, 92.941868],
  [42.379655, 92.941965],
  [42.379653, 92.942075],
  [42.379651, 92.94218],
  [42.379646, 92.942291],
  [42.379636, 92.942395],
  [42.37962, 92.942578],
  [42.37962, 92.942578],
  [42.379608, 92.942743],
  [42.3796, 92.942821],
  [42.379591, 92.942901],
  [42.379585, 92.942983],
  [42.379573, 92.943068],
  [42.379563, 92.943155],
  [42.379546, 92.943305],
  [42.379546, 92.943305],
  [42.37953, 92.943371],
  [42.379485, 92.943495],
  [42.37947, 92.943563],
  [42.379451, 92.943646],
  [42.379428, 92.943713],
  [42.379405, 92.943778],
  [42.379393, 92.94385],
  [42.379375, 92.943916],
  [42.379358, 92.943976],
  [42.379346, 92.94404],
  [42.379343, 92.944166],
  [42.379343, 92.944166],
  [42.379333, 92.944285],
  [42.379326, 92.944356],
  [42.379328, 92.944406],
  [42.379335, 92.944475],
  [42.379335, 92.944475],
  [42.379356, 92.944543],
  [42.379421, 92.944633],
  [42.379421, 92.944633],
  [42.37946, 92.944655],
  [42.37949, 92.944653],
  [42.379551, 92.944623],
  [42.37958, 92.944588],
  [42.379601, 92.944556],
  [42.379621, 92.944515],
  [42.379648, 92.94447],
  [42.379676, 92.944435],
  [42.379696, 92.944381],
  [42.379725, 92.94433],
  [42.379768, 92.944198],
  [42.379791, 92.944143],
  [42.379791, 92.944143],
  [42.379833, 92.944006],
  [42.379845, 92.943935],
  [42.379858, 92.94387],
  [42.37987, 92.9438],
  [42.379898, 92.943741],
  [42.379915, 92.943678],
  [42.379935, 92.943543],
  [42.379935, 92.943543],
  [42.379943, 92.94348],
  [42.379976, 92.943335],
  [42.379993, 92.943273],
  [42.38001, 92.94321],
  [42.38003, 92.943146],
  [42.380048, 92.943086],
  [42.380081, 92.943023],
  [42.380111, 92.942963],
  [42.38014, 92.942911],
  [42.380193, 92.942805],
  [42.380206, 92.942746],
  [42.380206, 92.942746],
  [42.380251, 92.942631],
  [42.38027, 92.942583],
  [42.380295, 92.942528],
  [42.380325, 92.942483],
  [42.38036, 92.942431],
  [42.3804, 92.942401],
  [42.380486, 92.942343],
  [42.380486, 92.942343],
  [42.380598, 92.942285],
  [42.380648, 92.942256],
  [42.380695, 92.94223],
  [42.380738, 92.942208],
  [42.380778, 92.942183],
  [42.380818, 92.942161],
  [42.380863, 92.942138],
  [42.380913, 92.94212],
  [42.380955, 92.942098],
  [42.381043, 92.942058],
  [42.381043, 92.942058],
  [42.381118, 92.942015],
  [42.381166, 92.941983],
  [42.381218, 92.941958],
  [42.381258, 92.941935],
  [42.381301, 92.941911],
  [42.381345, 92.941891],
  [42.381375, 92.941871],
  [42.381413, 92.941841],
  [42.381445, 92.941826],
  [42.381511, 92.941801],
  [42.381543, 92.941785],
  [42.381543, 92.941785],
  [42.38161, 92.941745],
  [42.381641, 92.941733],
  [42.381685, 92.941718],
  [42.381728, 92.941701],
  [42.381766, 92.941675],
  [42.381798, 92.941655],
  [42.381871, 92.941641],
  [42.381871, 92.941641],
  [42.381918, 92.941648],
  [42.38193, 92.941655],
  [42.381953, 92.94167],
  [42.381953, 92.94167],
  [42.381988, 92.941683],
  [42.38201, 92.941695],
  [42.382026, 92.941703],
  [42.382035, 92.941706],
  [42.382043, 92.941715],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.38206, 92.941718],
  [42.382116, 92.941843],
  [42.382126, 92.941848],
  [42.382163, 92.941871],
  [42.382163, 92.941871],
  [42.382205, 92.941886],
  [42.38223, 92.941895],
  [42.382246, 92.9419],
  [42.382263, 92.941901],
  [42.38228, 92.941896],
  [42.382293, 92.941895],
  [42.382323, 92.9419],
  [42.382323, 92.9419],
  [42.38233, 92.941908],
  [42.38234, 92.941915],
  [42.382348, 92.94192],
  [42.382358, 92.941908],
  [42.382368, 92.941905],
  [42.38238, 92.941903],
  [42.38239, 92.941898],
  [42.382398, 92.941901],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382403, 92.941898],
  [42.382371, 92.941901],
  [42.382371, 92.941901],
  [42.382366, 92.941906],
  [42.382328, 92.941911],
  [42.382328, 92.941911],
  [42.382328, 92.941911],
  [42.382321, 92.9419],
  [42.382281, 92.941878],
  [42.382278, 92.941863],
  [42.382276, 92.941851],
  [42.382276, 92.941851],
  [42.382273, 92.941836],
  [42.382278, 92.941795],
  [42.382278, 92.941795],
  [42.382278, 92.941795],
  [42.382278, 92.941795],
  [42.382278, 92.941795],
  [42.38225, 92.941835],
  [42.382233, 92.941836],
  [42.382186, 92.941873],
  [42.382171, 92.941878],
  [42.382151, 92.94188],
  [42.38214, 92.941876],
  [42.382123, 92.941878],
  [42.382123, 92.941878],
  [42.382123, 92.941878],
  [42.382123, 92.941878],
  [42.382123, 92.941878],
  [42.382131, 92.941866],
  [42.382131, 92.941866],
  [42.38216, 92.941858],
  [42.382165, 92.941851],
  [42.382168, 92.94185],
  [42.382181, 92.941836],
  [42.382193, 92.941836],
  [42.382201, 92.941833],
  [42.382215, 92.94184],
  [42.38223, 92.941855],
  [42.382246, 92.94186],
  [42.382273, 92.941885],
  [42.382281, 92.941881],
  [42.382281, 92.941881],
  [42.382296, 92.941883],
  [42.382313, 92.94188],
  [42.382313, 92.941891],
  [42.382323, 92.941891],
  [42.38234, 92.941895],
  [42.382355, 92.9419],
  [42.382361, 92.941903],
  [42.382361, 92.941903],
  [42.382351, 92.941916],
  [42.382343, 92.94192],
  [42.382343, 92.941926],
  [42.382346, 92.941925],
  [42.382351, 92.941933],
  [42.382351, 92.941935],
  [42.38235, 92.94193],
  [42.382346, 92.94193],
  [42.382353, 92.941938],
  [42.382356, 92.941931],
  [42.382371, 92.94193],
  [42.382381, 92.941928],
  [42.382381, 92.941928],
  [42.382381, 92.941928],
  [42.382381, 92.941928],
  [42.382381, 92.941928],
  [42.382381, 92.941928],
  [42.38234, 92.941911],
  [42.38232, 92.941891],
  [42.382305, 92.94188],
  [42.382295, 92.941878],
  [42.382295, 92.941878],
  [42.382295, 92.941878],
  [42.382295, 92.941878],
  [42.382203, 92.941811],
  [42.382183, 92.941798],
  [42.38217, 92.941788],
  [42.382156, 92.941763],
  [42.382131, 92.941728],
  [42.382103, 92.94169],
  [42.382018, 92.941635],
  [42.382018, 92.941635],
  [42.381981, 92.941626],
  [42.381901, 92.941598],
  [42.381871, 92.941581],
  [42.381836, 92.941576],
  [42.38179, 92.941581],
  [42.381748, 92.941603],
  [42.381708, 92.941613],
  [42.38166, 92.94163],
  [42.381623, 92.941656],
  [42.38159, 92.941703],
  [42.381533, 92.941785],
  [42.381533, 92.941785],
  [42.38145, 92.941851],
  [42.381406, 92.94187],
  [42.381358, 92.941893],
  [42.381305, 92.941918],
  [42.381241, 92.941953],
  [42.381185, 92.941985],
  [42.381101, 92.942028],
  [42.381101, 92.942028],
  [42.38106, 92.942045],
  [42.380983, 92.942075],
  [42.380983, 92.942075],
  [42.380891, 92.942115],
  [42.380843, 92.942138],
  [42.380793, 92.942156],
  [42.380743, 92.94218],
  [42.380688, 92.9422],
  [42.380631, 92.942221],
  [42.380538, 92.942278],
  [42.380538, 92.942278],
  [42.380486, 92.942311],
  [42.3804, 92.942378],
  [42.380365, 92.942421],
  [42.38034, 92.942448],
  [42.380311, 92.9425],
  [42.380283, 92.942555],
  [42.380263, 92.942598],
  [42.380246, 92.942651],
  [42.380223, 92.942711],
  [42.380161, 92.9428],
  [42.380131, 92.942848],
  [42.380131, 92.942848],
  [42.380085, 92.94294],
  [42.380068, 92.942971],
  [42.380055, 92.943018],
  [42.380035, 92.943078],
  [42.380015, 92.94314],
  [42.379991, 92.943205],
  [42.379933, 92.943358],
  [42.379933, 92.943358],
  [42.379913, 92.943426],
  [42.379893, 92.943595],
  [42.379875, 92.943676],
  [42.379853, 92.943748],
  [42.379835, 92.943826],
  [42.379813, 92.9439],
  [42.379793, 92.943971],
  [42.37977, 92.944041],
  [42.379746, 92.944108],
  [42.3797, 92.944243],
  [42.379676, 92.944301],
  [42.379676, 92.944301],
  [42.379651, 92.944431],
  [42.379643, 92.944503],
  [42.379623, 92.944556],
  [42.379606, 92.944603],
  [42.37958, 92.944641],
  [42.37958, 92.944641],
  [42.379515, 92.944681],
  [42.379476, 92.944683],
  [42.379435, 92.94465],
  [42.3794, 92.944615],
  [42.379378, 92.944591],
  [42.37935, 92.944538],
  [42.379323, 92.944476],
  [42.379363, 92.944361],
  [42.379375, 92.944306],
  [42.379391, 92.944231],
  [42.37941, 92.944155],
  [42.379436, 92.944091],
  [42.379461, 92.944015],
  [42.3795, 92.94384],
  [42.3795, 92.94384],
  [42.379518, 92.94375],
  [42.37954, 92.943536],
  [42.379548, 92.94344],
  [42.379555, 92.943328],
  [42.37956, 92.94325],
  [42.379571, 92.943165],
  [42.379583, 92.943095],
  [42.379593, 92.94301],
  [42.3796, 92.942926],
  [42.379611, 92.942845],
  [42.379628, 92.94268],
  [42.379628, 92.94268],
  [42.37964, 92.942481],
  [42.379636, 92.94237],
  [42.379633, 92.942243],
  [42.37964, 92.942116],
  [42.379645, 92.941996],
  [42.379645, 92.941876],
  [42.379658, 92.94162],
  [42.379658, 92.94162],
  [42.379661, 92.9415],
  [42.379678, 92.941261],
  [42.379693, 92.94114],
  [42.379691, 92.941021],
  [42.379688, 92.940903],
  [42.3797, 92.940791],
  [42.379698, 92.94069],
  [42.379715, 92.94058],
  [42.379738, 92.940476],
  [42.379803, 92.94027],
  [42.379835, 92.940171],
  [42.379835, 92.940171],
  [42.3799, 92.939983],
  [42.379936, 92.939891],
  [42.379975, 92.939795],
  [42.380013, 92.9397],
  [42.380068, 92.93962],
  [42.380136, 92.939568],
  [42.380276, 92.939465],
  [42.380276, 92.939465],
  [42.38034, 92.939425],
  [42.38047, 92.939365],
  [42.380541, 92.939336],
  [42.380603, 92.939301],
  [42.380665, 92.93927],
  [42.38073, 92.93923],
  [42.38078, 92.939196],
  [42.38083, 92.939163],
  [42.380885, 92.939123],
  [42.380986, 92.939038],
  [42.381036, 92.938985],
  [42.381036, 92.938985],
  [42.381151, 92.938863],
  [42.38121, 92.938781],
  [42.381273, 92.93871],
  [42.381331, 92.938628],
  [42.381395, 92.938543],
  [42.381456, 92.938468],
  [42.381525, 92.9384],
  [42.381596, 92.938341],
  [42.381758, 92.938251],
  [42.381831, 92.93821],
  [42.381831, 92.93821],
  [42.381968, 92.938108],
  [42.382036, 92.938083],
  [42.382103, 92.938055],
  [42.382175, 92.938023],
  [42.382243, 92.937986],
  [42.382318, 92.937941],
  [42.38245, 92.937821],
  [42.38245, 92.937821],
  [42.38253, 92.937673],
  [42.38257, 92.937593],
  [42.382601, 92.937508],
  [42.382631, 92.937421],
  [42.382665, 92.937333],
  [42.382706, 92.937251],
  [42.382706, 92.937251],
  [42.382896, 92.937111],
  [42.38297, 92.937081],
  [42.383041, 92.937053],
  [42.383103, 92.937031],
  [42.383178, 92.936995],
  [42.38325, 92.93696],
  [42.3834, 92.936883],
  [42.3834, 92.936883],
  [42.383553, 92.93679],
  [42.38363, 92.936746],
  [42.383706, 92.936698],
  [42.383755, 92.936631],
  [42.383755, 92.936631],
  [42.383791, 92.936563],
  [42.383806, 92.936483],
  [42.383816, 92.936398],
  [42.3838, 92.936326],
  [42.383771, 92.936268],
  [42.383705, 92.936186],
  [42.383658, 92.93617],
  [42.383593, 92.936186],
  [42.38353, 92.93621],
  [42.38346, 92.936225],
  [42.383391, 92.936241],
  [42.383263, 92.936321],
  [42.383263, 92.936321],
  [42.383193, 92.93636],
  [42.383046, 92.936435],
  [42.382976, 92.936453],
  [42.38291, 92.936491],
  [42.382845, 92.936533],
  [42.382773, 92.936571],
  [42.382705, 92.936606],
  [42.382628, 92.936641],
  [42.382558, 92.936668],
  [42.38243, 92.936715],
  [42.382356, 92.936745],
  [42.382356, 92.936745],
  [42.382228, 92.936813],
  [42.382175, 92.93684],
  [42.382126, 92.936875],
  [42.382075, 92.936905],
  [42.382015, 92.936933],
  [42.381953, 92.936963],
  [42.381841, 92.937023],
  [42.381841, 92.937023],
  [42.381783, 92.93705],
  [42.381641, 92.937098],
  [42.381585, 92.937126],
  [42.381523, 92.937151],
  [42.381453, 92.937171],
  [42.381386, 92.93719],
  [42.381325, 92.937201],
  [42.38126, 92.937205],
  [42.3812, 92.937208],
  [42.381106, 92.937208],
  [42.381106, 92.937208],
  [42.381053, 92.9372],
  [42.380966, 92.937186],
  [42.380913, 92.937165],
  [42.380865, 92.937143],
  [42.380818, 92.937126],
  [42.38078, 92.937126],
  [42.38074, 92.937133],
  [42.380705, 92.93714],
  [42.38066, 92.93716],
  [42.380575, 92.9372],
  [42.380545, 92.937216],
  [42.380545, 92.937216],
  [42.380483, 92.937251],
  [42.38045, 92.937273],
  [42.380408, 92.937296],
  [42.38038, 92.937305],
  [42.380346, 92.937303],
  [42.380315, 92.937295],
  [42.380268, 92.937286],
  [42.380268, 92.937286],
  [42.380246, 92.937288],
  [42.380213, 92.937286],
  [42.380205, 92.937278],
  [42.380193, 92.937273],
  [42.38019, 92.93727],
  [42.380191, 92.93727],
  [42.380191, 92.93727],
  [42.380191, 92.93727],
  [42.380191, 92.93727],
  [42.380191, 92.93727],
  [42.380003, 92.937246],
  [42.380003, 92.937246],
  [42.379981, 92.93723],
  [42.379981, 92.93723],
  [42.379971, 92.93721],
  [42.379958, 92.937145],
  [42.379966, 92.937118],
  [42.379978, 92.93711],
  [42.379986, 92.937103],
  [42.379986, 92.937103],
  [42.379995, 92.937313],
  [42.380005, 92.93733],
  [42.380005, 92.93733],
  [42.380021, 92.937356],
  [42.380026, 92.937361],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380025, 92.937383],
  [42.380038, 92.937303],
  [42.38005, 92.93729],
  [42.380046, 92.937271],
  [42.380045, 92.937258],
  [42.380045, 92.937251],
  [42.380045, 92.937243],
  [42.380061, 92.937201],
  [42.380061, 92.937201],
  [42.380081, 92.937168],
  [42.380088, 92.937153],
  [42.38011, 92.937143],
  [42.380126, 92.937138],
  [42.38014, 92.937128],
  [42.38016, 92.937121],
  [42.38019, 92.937121],
  [42.380196, 92.93712],
  [42.380196, 92.93712],
  [42.380196, 92.93712],
  [42.380226, 92.9371],
  [42.380235, 92.937091],
  [42.380261, 92.93709],
  [42.380281, 92.937081],
  [42.380311, 92.937083],
  [42.38036, 92.937095],
  [42.380376, 92.93711],
  [42.380376, 92.93711],
  [42.380396, 92.937131],
  [42.380406, 92.937138],
  [42.380416, 92.937151],
  [42.380423, 92.93716],
  [42.380443, 92.937155],
  [42.380465, 92.93716],
  [42.380495, 92.937168],
  [42.380495, 92.937168],
  [42.380533, 92.937156],
  [42.380553, 92.937145],
  [42.380575, 92.937153],
  [42.380606, 92.937161],
  [42.380636, 92.937168],
  [42.380658, 92.93717],
  [42.38069, 92.937173],
  [42.380703, 92.93718],
  [42.380703, 92.93718],
  [42.380703, 92.93718],
  [42.380703, 92.93718],
  [42.380703, 92.93718],
  [42.380703, 92.93718],
  [42.380676, 92.937146],
  [42.380696, 92.937148],
  [42.380731, 92.937141],
  [42.380755, 92.937133],
  [42.380755, 92.937133],
  [42.380806, 92.937128],
  [42.380821, 92.937125],
  [42.380843, 92.937131],
  [42.380865, 92.937141],
  [42.38089, 92.937155],
  [42.380921, 92.93717],
  [42.380948, 92.937181],
  [42.380975, 92.937193],
  [42.381006, 92.937193],
  [42.381081, 92.937206],
  [42.381081, 92.937206],
  [42.381191, 92.93721],
  [42.381231, 92.937213],
  [42.381271, 92.93721],
  [42.3813, 92.937201],
  [42.381335, 92.937196],
  [42.381375, 92.937186],
  [42.381456, 92.937145],
  [42.381456, 92.937145],
  [42.3815, 92.937135],
  [42.381603, 92.937085],
  [42.381675, 92.937066],
  [42.38174, 92.937041],
  [42.381806, 92.93701],
  [42.381866, 92.936995],
  [42.38193, 92.936985],
  [42.381975, 92.936965],
  [42.38202, 92.936938],
  [42.382115, 92.93691],
  [42.382153, 92.936895],
  [42.382153, 92.936895],
  [42.382228, 92.936825],
  [42.382275, 92.936783],
  [42.382326, 92.936758],
  [42.38238, 92.936741],
  [42.382431, 92.936716],
  [42.38249, 92.936706],
  [42.382588, 92.936673],
  [42.382588, 92.936673],
  [42.382693, 92.936643],
  [42.382738, 92.936638],
  [42.382781, 92.936615],
  [42.382835, 92.936583],
  [42.382886, 92.936556],
  [42.382928, 92.936515],
  [42.38298, 92.93649],
  [42.383028, 92.93646],
  [42.383078, 92.93643],
  [42.383193, 92.936363],
  [42.383248, 92.936338],
  [42.383248, 92.936338],
  [42.383365, 92.936285],
  [42.38343, 92.936253],
  [42.383495, 92.936243],
  [42.383553, 92.936236],
  [42.383598, 92.936228],
  [42.383646, 92.93623],
  [42.38373, 92.936268],
  [42.38373, 92.936268],
  [42.38376, 92.936365],
  [42.383783, 92.936433],
  [42.383786, 92.93648],
  [42.383786, 92.93648],
  [42.383781, 92.936551],
  [42.383726, 92.936635],
  [42.383686, 92.936665],
  [42.383645, 92.936698],
  [42.38359, 92.936733],
  [42.383538, 92.936765],
  [42.38348, 92.936793],
  [42.383346, 92.936856],
  [42.383346, 92.936856],
  [42.383281, 92.936891],
  [42.383175, 92.93696],
  [42.383121, 92.936998],
  [42.383071, 92.937036],
  [42.383005, 92.937065],
  [42.382941, 92.937093],
  [42.382873, 92.937123],
  [42.382811, 92.93716],
  [42.382751, 92.9372],
  [42.382653, 92.9373],
  [42.38262, 92.93736],
  [42.38262, 92.93736],
  [42.382573, 92.937481],
  [42.382556, 92.93755],
  [42.382538, 92.937616],
  [42.382501, 92.93767],
  [42.382475, 92.93772],
  [42.38244, 92.937778],
  [42.382351, 92.937863],
  [42.382351, 92.937863],
  [42.3823, 92.937903],
  [42.382168, 92.937963],
  [42.38209, 92.938001],
  [42.382013, 92.938031],
  [42.381941, 92.938061],
  [42.381878, 92.938103],
  [42.381816, 92.938136],
  [42.381775, 92.93818],
  [42.381726, 92.938211],
  [42.381631, 92.93829],
  [42.381583, 92.938341],
  [42.381583, 92.938341],
  [42.38147, 92.938428],
  [42.381418, 92.938455],
  [42.381365, 92.938498],
  [42.381316, 92.938566],
  [42.381271, 92.938631],
  [42.381225, 92.938701],
  [42.381138, 92.938828],
  [42.381138, 92.938828],
  [42.381046, 92.938951],
  [42.380998, 92.939018],
  [42.380948, 92.939063],
  [42.380886, 92.939101],
  [42.380826, 92.939133],
  [42.380765, 92.939173],
  [42.380706, 92.939205],
  [42.380651, 92.939235],
  [42.380598, 92.939255],
  [42.380498, 92.939315],
  [42.380451, 92.939343],
  [42.380451, 92.939343],
  [42.380351, 92.939408],
  [42.380311, 92.939451],
  [42.380263, 92.939491],
  [42.38021, 92.939526],
  [42.380155, 92.939578],
  [42.3801, 92.939623],
  [42.38005, 92.939671],
  [42.380013, 92.939725],
  [42.379943, 92.939851],
  [42.379916, 92.939933],
  [42.379916, 92.939933],
  [42.379855, 92.940075],
  [42.37983, 92.940146],
  [42.379813, 92.94022],
  [42.379795, 92.940298],
  [42.379765, 92.940378],
  [42.379741, 92.940446],
  [42.379698, 92.940598],
  [42.379698, 92.940598],
  [42.379675, 92.940766],
  [42.37966, 92.940845],
  [42.379643, 92.940921],
  [42.379631, 92.941005],
  [42.379628, 92.941068],
  [42.379621, 92.941163],
  [42.379621, 92.941248],
  [42.379631, 92.94134],
  [42.379633, 92.941438],
  [42.379645, 92.941606],
  [42.379643, 92.941693],
  [42.379635, 92.9418],
  [42.37962, 92.941908],
  [42.379613, 92.94202],
  [42.379611, 92.942121],
  [42.379606, 92.942225],
  [42.379601, 92.942331],
  [42.379596, 92.942441],
  [42.379585, 92.942638],
  [42.379585, 92.942638],
  [42.379583, 92.942805],
  [42.37958, 92.942896],
  [42.37957, 92.942985],
  [42.379561, 92.943076],
  [42.379548, 92.943178],
  [42.379536, 92.943278],
  [42.379523, 92.943478],
  [42.379523, 92.943478],
  [42.37951, 92.943568],
  [42.379486, 92.943783],
  [42.379465, 92.943896],
  [42.379446, 92.944011],
  [42.379438, 92.944116],
  [42.37942, 92.944221],
  [42.379406, 92.94434],
  [42.379388, 92.94445],
  [42.379365, 92.944558],
  [42.37935, 92.94467],
  [42.379295, 92.944865],
  [42.379295, 92.944865],
  [42.379235, 92.945055],
  [42.379211, 92.945155],
  [42.379191, 92.945248],
  [42.379163, 92.945353],
  [42.379145, 92.945451],
  [42.379141, 92.945546],
  [42.379138, 92.945743],
  [42.379138, 92.945743],
  [42.37913, 92.94584],
  [42.379098, 92.946021],
  [42.379098, 92.946021],
  [42.379036, 92.946208],
  [42.37898, 92.946291],
  [42.378931, 92.946343],
  [42.378878, 92.946393],
  [42.378815, 92.946426],
  [42.378755, 92.946458],
  [42.37863, 92.94652],
  [42.37863, 92.94652],
  [42.378553, 92.946571],
  [42.378395, 92.946681],
  [42.378318, 92.946735],
  [42.378238, 92.946783],
  [42.378155, 92.946838],
  [42.378068, 92.946893],
  [42.377993, 92.946956],
  [42.37792, 92.94702],
  [42.377846, 92.947085],
  [42.377766, 92.947141],
  [42.377611, 92.947251],
  [42.377611, 92.947251],
  [42.377455, 92.947361],
  [42.377378, 92.947416],
  [42.377296, 92.94746],
  [42.377216, 92.94751],
  [42.377146, 92.947568],
  [42.377075, 92.947625],
  [42.376913, 92.947731],
  [42.376913, 92.947731],
  [42.376831, 92.947785],
  [42.376685, 92.947891],
  [42.376616, 92.947936],
  [42.376538, 92.947968],
  [42.376458, 92.948015],
  [42.376376, 92.948058],
  [42.376295, 92.948106],
  [42.376216, 92.948155],
  [42.376133, 92.948195],
  [42.375971, 92.948296],
  [42.375891, 92.948346],
  [42.375891, 92.948346],
  [42.375728, 92.948446],
  [42.375653, 92.948493],
  [42.375585, 92.948536],
  [42.375513, 92.948591],
  [42.375435, 92.948643],
  [42.375343, 92.948683],
  [42.375195, 92.948805],
  [42.375195, 92.948805],
  [42.37504, 92.948926],
  [42.374948, 92.948986],
  [42.374871, 92.949043],
  [42.374783, 92.949091],
  [42.374696, 92.949145],
  [42.374606, 92.949195],
  [42.374516, 92.94925],
  [42.374426, 92.949306],
  [42.374341, 92.94936],
  [42.374176, 92.949453],
  [42.374176, 92.949453],
  [42.37409, 92.949506],
  [42.373916, 92.949598],
  [42.373835, 92.94965],
  [42.37375, 92.949701],
  [42.373665, 92.949753],
  [42.37358, 92.949808],
  [42.373495, 92.949851],
  [42.373408, 92.949898],
  [42.373323, 92.949948],
  [42.373153, 92.950046],
  [42.373078, 92.950106],
  [42.373078, 92.950106],
  [42.372916, 92.950201],
  [42.372838, 92.950255],
  [42.372761, 92.950303],
  [42.37269, 92.950356],
  [42.372616, 92.9504],
  [42.372551, 92.950451],
  [42.372426, 92.950555],
  [42.372426, 92.950555],
  [42.372295, 92.950618],
  [42.372236, 92.950645],
  [42.372175, 92.950688],
  [42.37211, 92.950738],
  [42.37204, 92.950786],
  [42.371976, 92.95084],
  [42.371911, 92.950898],
  [42.371845, 92.950955],
  [42.371776, 92.951016],
  [42.371641, 92.95118],
  [42.371581, 92.951266],
  [42.371528, 92.951361],
  [42.371493, 92.951471],
  [42.371458, 92.95159],
  [42.371438, 92.951721],
  [42.371441, 92.951835],
  [42.371458, 92.95195],
  [42.371493, 92.952045],
  [42.371568, 92.952231],
  [42.371568, 92.952231],
  [42.371633, 92.952411],
  [42.371661, 92.952508],
  [42.371693, 92.952598],
  [42.371725, 92.952681],
  [42.371755, 92.95276],
  [42.37178, 92.952825],
  [42.371823, 92.952953],
  [42.371823, 92.952953],
  [42.37184, 92.953006],
  [42.371876, 92.953111],
  [42.371895, 92.953156],
  [42.3719, 92.953201],
  [42.371908, 92.953246],
  [42.371915, 92.953298],
  [42.371921, 92.953353],
  [42.371931, 92.953405],
  [42.371938, 92.953456],
  [42.371946, 92.95351],
  [42.37196, 92.953566],
  [42.371978, 92.953625],
  [42.372005, 92.95373],
  [42.372015, 92.953786],
  [42.372026, 92.953845],
  [42.372036, 92.953901],
  [42.372046, 92.953955],
  [42.372055, 92.954018],
  [42.372056, 92.954066],
  [42.372065, 92.954121],
  [42.372075, 92.954181],
  [42.372085, 92.9543],
  [42.372085, 92.9543],
  [42.372086, 92.954423],
  [42.372088, 92.954478],
  [42.372088, 92.95454],
  [42.372083, 92.954606],
  [42.37208, 92.95467],
  [42.372081, 92.95473],
  [42.37208, 92.954861],
  [42.37208, 92.954861],
  [42.372075, 92.954928],
  [42.372068, 92.955068],
  [42.372075, 92.955168],
  [42.372075, 92.95526],
  [42.372085, 92.955353],
  [42.372091, 92.955445],
  [42.372095, 92.955548],
  [42.372096, 92.955645],
  [42.372103, 92.95573],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372115, 92.955885],
  [42.372151, 92.956581],
  [42.372151, 92.956581],
  [42.372151, 92.956581],
  [42.372148, 92.956771],
  [42.372151, 92.956845],
  [42.372151, 92.956845],
  [42.372156, 92.956965],
  [42.372163, 92.957021],
  [42.372173, 92.957088],
  [42.372178, 92.957153],
  [42.37218, 92.957195],
  [42.37219, 92.957321],
  [42.372195, 92.957378],
  [42.372195, 92.957378],
  [42.372201, 92.957488],
  [42.372201, 92.957543],
  [42.372206, 92.957598],
  [42.372206, 92.957658],
  [42.372216, 92.957726],
  [42.372225, 92.957785],
  [42.372225, 92.957905],
  [42.372225, 92.957905],
  [42.37223, 92.95801],
  [42.372233, 92.958061],
  [42.372235, 92.958113],
  [42.372233, 92.958166],
  [42.37224, 92.958223],
  [42.372245, 92.958273],
  [42.372248, 92.95832],
  [42.372248, 92.95837],
  [42.372253, 92.958428],
  [42.372258, 92.958543],
  [42.372258, 92.958543],
  [42.37226, 92.958605],
  [42.37226, 92.958605],
  [42.37226, 92.958605],
  [42.372278, 92.958863],
  [42.372276, 92.958928],
  [42.372276, 92.958995],
  [42.372276, 92.95905],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372278, 92.959106],
  [42.372348, 92.960126],
  [42.372358, 92.960201],
  [42.372371, 92.960261],
  [42.372381, 92.960316],
  [42.372386, 92.96038],
  [42.372398, 92.960448],
  [42.37241, 92.960515],
  [42.372413, 92.960581],
  [42.372416, 92.960663],
  [42.372435, 92.96079],
  [42.37244, 92.960846],
  [42.372451, 92.960925],
  [42.372463, 92.96098],
  [42.37248, 92.961051],
  [42.372493, 92.961123],
  [42.37251, 92.961191],
  [42.37253, 92.961258],
  [42.372548, 92.961318],
  [42.372575, 92.96145],
  [42.372575, 92.96145],
  [42.372596, 92.961553],
  [42.37261, 92.961605],
  [42.37262, 92.961665],
  [42.372636, 92.961728],
  [42.372653, 92.961791],
  [42.372675, 92.961846],
  [42.372706, 92.961953],
  [42.372706, 92.961953],
  [42.372721, 92.962008],
  [42.372741, 92.962125],
  [42.372745, 92.962183],
  [42.372753, 92.96224],
  [42.37276, 92.9623],
  [42.372766, 92.962353],
  [42.372776, 92.962398],
  [42.372785, 92.962453],
  [42.372798, 92.96251],
  [42.372808, 92.962565],
  [42.372813, 92.962623],
  [42.372825, 92.962673],
  [42.37285, 92.962791],
  [42.372863, 92.96286],
  [42.372873, 92.962928],
  [42.372883, 92.962993],
  [42.37289, 92.963051],
  [42.372896, 92.96311],
  [42.372911, 92.96318],
  [42.372918, 92.963246],
  [42.372925, 92.963305],
  [42.372946, 92.963421],
  [42.372946, 92.963421],
  [42.372976, 92.963538],
  [42.372993, 92.963603],
  [42.373008, 92.963675],
  [42.373021, 92.963746],
  [42.37303, 92.963821],
  [42.373036, 92.963883],
  [42.373033, 92.964],
  [42.373033, 92.964],
  [42.373016, 92.96406],
  [42.372961, 92.964166],
  [42.372931, 92.964208],
  [42.372896, 92.96426],
  [42.372873, 92.964298],
  [42.372841, 92.964355],
  [42.372811, 92.964408],
  [42.372788, 92.964455],
  [42.372755, 92.964506],
  [42.372691, 92.96462],
  [42.372666, 92.964678],
  [42.372666, 92.964678],
  [42.372608, 92.964776],
  [42.372583, 92.964833],
  [42.37255, 92.964896],
  [42.37252, 92.964958],
  [42.372501, 92.965006],
  [42.372478, 92.965068],
  [42.372423, 92.96519],
  [42.372423, 92.96519],
  [42.372386, 92.965248],
  [42.372325, 92.965328],
  [42.37229, 92.96538],
  [42.372265, 92.965423],
  [42.372241, 92.965461],
  [42.37222, 92.965516],
  [42.3722, 92.965573],
  [42.372173, 92.965621],
  [42.372151, 92.96566],
  [42.372101, 92.965768],
  [42.372081, 92.965813],
  [42.372081, 92.965813],
  [42.372036, 92.965903],
  [42.37201, 92.965955],
  [42.371985, 92.966005],
  [42.371958, 92.966051],
  [42.371928, 92.966101],
  [42.371898, 92.966156],
  [42.371838, 92.966248],
  [42.371838, 92.966248],
  [42.371791, 92.966346],
  [42.37177, 92.966391],
  [42.37177, 92.966391],
  [42.37173, 92.966468],
  [42.371706, 92.966511],
  [42.371685, 92.966561],
  [42.37166, 92.96661],
  [42.371635, 92.966661],
  [42.371613, 92.966725],
  [42.371566, 92.96683],
  [42.371566, 92.96683],
  [42.37152, 92.966931],
  [42.37149, 92.966978],
  [42.371461, 92.967023],
  [42.371435, 92.967076],
  [42.371411, 92.967121],
  [42.371395, 92.967178],
  [42.37137, 92.967216],
  [42.371338, 92.967271],
  [42.371301, 92.967325],
  [42.37125, 92.967418],
  [42.371226, 92.96746],
  [42.371226, 92.96746],
  [42.371185, 92.967526],
  [42.371165, 92.967573],
  [42.371138, 92.967613],
  [42.371121, 92.967648],
  [42.371108, 92.967696],
  [42.37109, 92.967746],
  [42.371048, 92.967841],
  [42.371048, 92.967841],
  [42.371011, 92.967921],
  [42.370995, 92.967956],
  [42.370971, 92.967996],
  [42.370951, 92.968033],
  [42.370936, 92.968055],
  [42.370916, 92.968098],
  [42.370893, 92.968123],
  [42.370863, 92.968155],
  [42.370833, 92.968186],
  [42.370778, 92.968231],
  [42.370748, 92.968248],
  [42.370748, 92.968248],
  [42.370723, 92.968261],
  [42.370705, 92.968293],
  [42.370705, 92.968293],
  [42.370685, 92.96843],
  [42.370685, 92.96843],
  [42.370693, 92.968466],
  [42.370693, 92.968466],
  [42.37069, 92.968321],
  [42.370693, 92.968301],
  [42.370693, 92.968301],
  [42.370691, 92.968273],
  [42.370693, 92.968256],
  [42.370693, 92.968246],
  [42.37069, 92.968243],
  [42.370681, 92.968241],
  [42.370675, 92.968236],
  [42.370675, 92.968226],
  [42.370675, 92.968226],
  [42.370666, 92.968223],
  [42.370663, 92.968221],
  [42.37066, 92.96822],
  [42.370656, 92.968213],
  [42.370655, 92.968206],
  [42.370655, 92.968206],
  [42.370655, 92.968206],
  [42.370655, 92.968206],
  [42.370655, 92.968206],
  [42.370811, 92.9683],
  [42.370845, 92.968316],
  [42.370876, 92.968321],
  [42.370943, 92.968318],
  [42.370966, 92.968306],
  [42.370966, 92.968306],
  [42.37101, 92.968271],
  [42.371033, 92.968235],
  [42.37105, 92.96818],
  [42.371078, 92.968131],
  [42.37111, 92.968068],
  [42.371136, 92.968005],
  [42.371198, 92.96789],
  [42.371198, 92.96789],
  [42.371256, 92.967763],
  [42.371293, 92.9677],
  [42.371333, 92.967628],
  [42.37137, 92.967553],
  [42.371396, 92.96749],
  [42.371428, 92.967423],
  [42.37146, 92.96736],
  [42.371501, 92.967315],
  [42.371535, 92.96725],
  [42.3716, 92.967111],
  [42.371638, 92.967036],
  [42.371638, 92.967036],
  [42.37172, 92.966896],
  [42.371753, 92.966833],
  [42.37178, 92.966763],
  [42.37181, 92.966683],
  [42.37184, 92.966605],
  [42.371876, 92.966531],
  [42.371951, 92.966356],
  [42.371951, 92.966356],
  [42.372041, 92.966216],
  [42.372041, 92.966216],
  [42.372121, 92.966046],
  [42.372151, 92.965966],
  [42.372188, 92.965896],
  [42.372225, 92.965816],
  [42.372263, 92.965743],
  [42.372301, 92.965668],
  [42.372333, 92.96559],
  [42.372401, 92.965408],
  [42.372438, 92.965325],
  [42.372486, 92.96525],
  [42.372535, 92.965168],
  [42.372585, 92.965103],
  [42.37263, 92.965041],
  [42.372685, 92.964988],
  [42.372731, 92.96494],
  [42.372778, 92.964881],
  [42.372815, 92.964836],
  [42.372856, 92.964796],
  [42.372938, 92.964685],
  [42.372938, 92.964685],
  [42.372938, 92.964685],
  [42.372938, 92.964685],
  [42.372938, 92.964685],
  [42.372938, 92.964685],
  [42.373095, 92.964375],
  [42.373116, 92.964301],
  [42.373126, 92.964246],
  [42.37313, 92.964115],
  [42.37313, 92.964115],
  [42.373136, 92.963956],
  [42.373131, 92.963883],
  [42.373123, 92.963808],
  [42.373115, 92.963731],
  [42.373105, 92.96367],
  [42.373088, 92.963601],
  [42.373053, 92.963451],
  [42.373053, 92.963451],
  [42.37304, 92.96338],
  [42.373023, 92.96323],
  [42.373001, 92.963136],
  [42.372985, 92.963048],
  [42.372971, 92.962983],
  [42.372955, 92.962893],
  [42.372935, 92.962805],
  [42.372916, 92.962711],
  [42.372896, 92.962611],
  [42.372883, 92.96252],
  [42.372853, 92.962351],
  [42.372853, 92.962351],
  [42.37282, 92.96217],
  [42.372798, 92.962075],
  [42.372781, 92.961983],
  [42.37276, 92.961893],
  [42.37274, 92.961808],
  [42.372718, 92.96172],
  [42.372678, 92.961538],
  [42.372678, 92.961538],
  [42.372665, 92.961461],
  [42.372623, 92.96127],
  [42.372605, 92.961171],
  [42.372593, 92.961083],
  [42.372583, 92.960988],
  [42.37257, 92.960895],
  [42.372555, 92.960801],
  [42.372538, 92.960701],
  [42.37252, 92.960606],
  [42.372496, 92.960415],
  [42.372483, 92.96033],
  [42.372483, 92.96033],
  [42.372458, 92.960176],
  [42.372438, 92.960091],
  [42.372408, 92.959991],
  [42.37239, 92.959906],
  [42.372375, 92.959826],
  [42.372366, 92.95973],
  [42.372366, 92.959638],
  [42.372376, 92.959548],
  [42.372393, 92.959455],
  [42.372418, 92.959265],
  [42.372418, 92.959265],
  [42.37243, 92.959096],
  [42.372426, 92.959023],
  [42.372416, 92.958938],
  [42.37241, 92.958863],
  [42.372396, 92.958776],
  [42.372388, 92.958698],
  [42.37238, 92.958526],
  [42.37238, 92.958526],
  [42.372375, 92.958445],
  [42.372371, 92.958271],
  [42.372368, 92.958188],
  [42.372368, 92.9581],
  [42.372356, 92.958003],
  [42.37235, 92.957901],
  [42.372346, 92.957813],
  [42.372341, 92.957718],
  [42.372333, 92.95762],
  [42.372313, 92.957408],
  [42.372306, 92.957306],
  [42.372306, 92.957306],
  [42.372291, 92.957125],
  [42.372286, 92.957055],
  [42.372276, 92.956973],
  [42.372268, 92.956891],
  [42.37226, 92.956815],
  [42.372255, 92.956715],
  [42.37224, 92.956521],
  [42.37224, 92.956521],
  [42.372226, 92.956381],
  [42.372216, 92.95629],
  [42.372208, 92.956198],
  [42.372198, 92.956105],
  [42.372196, 92.956015],
  [42.372193, 92.955936],
  [42.372191, 92.955851],
  [42.372186, 92.955753],
  [42.372176, 92.955645],
  [42.372175, 92.955413],
  [42.372176, 92.955311],
  [42.372176, 92.955311],
  [42.372185, 92.955126],
  [42.372178, 92.955028],
  [42.372173, 92.954946],
  [42.372186, 92.954861],
  [42.372195, 92.954785],
  [42.372206, 92.95471],
  [42.37221, 92.95455],
  [42.37221, 92.95455],
  [42.372198, 92.95439],
  [42.372188, 92.95432],
  [42.372176, 92.95422],
  [42.37218, 92.954138],
  [42.372178, 92.95404],
  [42.372163, 92.95396],
  [42.372135, 92.953876],
  [42.37212, 92.953788],
  [42.372093, 92.953686],
  [42.372028, 92.953493],
  [42.372028, 92.953493],
  [42.37197, 92.95332],
  [42.371955, 92.953233],
  [42.371933, 92.953138],
  [42.37191, 92.953058],
  [42.371891, 92.952978],
  [42.371868, 92.952896],
  [42.371843, 92.95281],
  [42.371821, 92.952735],
  [42.3718, 92.952661],
  [42.371745, 92.952496],
  [42.371716, 92.952398],
  [42.371685, 92.952293],
  [42.371656, 92.952203],
  [42.371635, 92.952116],
  [42.371618, 92.952013],
  [42.371608, 92.951906],
  [42.371605, 92.951801],
  [42.37161, 92.951698],
  [42.371641, 92.951486],
  [42.371641, 92.951486],
  [42.371743, 92.951316],
  [42.371798, 92.95124],
  [42.371856, 92.95115],
  [42.371925, 92.951071],
  [42.371991, 92.951001],
  [42.372061, 92.95094],
  [42.3722, 92.950833],
  [42.3722, 92.950833],
  [42.37227, 92.950776],
  [42.372425, 92.950676],
  [42.372513, 92.950623],
  [42.372601, 92.950566],
  [42.372685, 92.950518],
  [42.372775, 92.950473],
  [42.372866, 92.950423],
  [42.372955, 92.950373],
  [42.373043, 92.950323],
  [42.373131, 92.950273],
  [42.3733, 92.950175],
  [42.3733, 92.950175],
  [42.373466, 92.950078],
  [42.373556, 92.950028],
  [42.373643, 92.949978],
  [42.373728, 92.94992],
  [42.373813, 92.94987],
  [42.373903, 92.949811],
  [42.374078, 92.949681],
  [42.374078, 92.949681],
  [42.374168, 92.949615],
  [42.374338, 92.949511],
  [42.374421, 92.949456],
  [42.374508, 92.949405],
  [42.374596, 92.949355],
  [42.374675, 92.949315],
  [42.374748, 92.949265],
  [42.374826, 92.949218],
  [42.3749, 92.94918],
  [42.375048, 92.949108],
  [42.37512, 92.949065],
  [42.37512, 92.949065],
  [42.375276, 92.948961],
  [42.375355, 92.948908],
  [42.375425, 92.948856],
  [42.3755, 92.948805],
  [42.375578, 92.948761],
  [42.375658, 92.94871],
  [42.375743, 92.948658],
  [42.375845, 92.948601],
  [42.37603, 92.948503],
  [42.376128, 92.948446],
  [42.376128, 92.948446],
  [42.376316, 92.948355],
  [42.376401, 92.948313],
  [42.376493, 92.948271],
  [42.376583, 92.948223],
  [42.376668, 92.948168],
  [42.376746, 92.948113],
  [42.376896, 92.948003],
  [42.376896, 92.948003],
  [42.376961, 92.947945],
  [42.37711, 92.94783],
  [42.377188, 92.947773],
  [42.377266, 92.947716],
  [42.377343, 92.947663],
  [42.377421, 92.947615],
  [42.377498, 92.947563],
  [42.377573, 92.947505],
  [42.377651, 92.947456],
  [42.377811, 92.947331],
  [42.377891, 92.947271],
  [42.377891, 92.947271],
  [42.378055, 92.947146],
  [42.378138, 92.94709],
  [42.378225, 92.947028],
  [42.378306, 92.946966],
  [42.378396, 92.946911],
  [42.378478, 92.94686],
  [42.378645, 92.946781],
  [42.378645, 92.946781],
  [42.3788, 92.946686],
  [42.378878, 92.946638],
  [42.378943, 92.94658],
  [42.379, 92.946518],
  [42.379053, 92.946448],
  [42.379096, 92.946378],
  [42.379135, 92.946303],
  [42.379165, 92.946215],
  [42.379193, 92.946113],
  [42.37925, 92.945896],
  [42.379265, 92.945796],
  [42.379265, 92.945796],
  [42.379291, 92.945573],
  [42.379301, 92.945451],
  [42.379316, 92.945336],
  [42.379326, 92.945225],
  [42.37934, 92.94513],
  [42.379343, 92.945031],
  [42.379355, 92.94483],
  [42.379355, 92.94483],
  [42.379371, 92.94465],
  [42.379385, 92.944553],
  [42.379385, 92.944553],
  [42.379418, 92.944326],
  [42.379433, 92.944211],
  [42.379453, 92.944103],
  [42.379476, 92.943976],
  [42.379488, 92.943856],
  [42.379508, 92.943748],
  [42.379548, 92.943518],
  [42.379548, 92.943518],
  [42.37958, 92.943303],
  [42.379583, 92.943198],
  [42.379581, 92.943103],
  [42.379578, 92.943008],
  [42.379578, 92.94291],
  [42.379578, 92.942821],
  [42.379578, 92.942736],
  [42.379583, 92.942641],
  [42.379583, 92.942553],
  [42.379583, 92.942355],
  [42.379586, 92.94225],
  [42.379586, 92.94214],
  [42.37959, 92.942026],
  [42.379596, 92.941915],
  [42.37961, 92.94179],
  [42.379623, 92.94167],
  [42.37963, 92.941558],
  [42.379643, 92.94144],
  [42.379668, 92.941211],
  [42.379668, 92.941211],
  [42.379695, 92.940981],
  [42.379705, 92.940871],
  [42.379715, 92.94077],
  [42.379723, 92.940673],
  [42.379726, 92.94057],
  [42.379736, 92.940488],
  [42.379773, 92.940316],
  [42.379773, 92.940316],
  [42.379793, 92.940231],
  [42.379835, 92.94005],
  [42.379861, 92.93996],
  [42.379891, 92.939881],
  [42.379926, 92.939806],
  [42.379971, 92.939735],
  [42.380028, 92.939678],
  [42.380098, 92.939626],
  [42.380175, 92.93958],
  [42.380248, 92.939548],
  [42.380396, 92.939466],
  [42.380396, 92.939466],
  [42.380525, 92.939385],
  [42.380581, 92.939346],
  [42.380633, 92.939305],
  [42.380685, 92.939265],
  [42.38073, 92.93923],
  [42.380773, 92.939183],
  [42.380821, 92.939141],
  [42.380863, 92.939098],
  [42.380905, 92.939063],
  [42.381001, 92.93897],
  [42.381001, 92.93897],
  [42.381108, 92.938851],
  [42.381163, 92.938791],
  [42.38122, 92.938723],
  [42.381273, 92.93864],
  [42.38132, 92.938563],
  [42.381388, 92.938498],
  [42.381515, 92.93838],
  [42.381515, 92.93838],
  [42.381588, 92.938331],
  [42.381748, 92.93826],
  [42.381823, 92.938221],
  [42.381895, 92.93818],
  [42.38197, 92.938145],
  [42.382038, 92.938105],
  [42.382105, 92.938068],
  [42.382165, 92.938035],
  [42.382225, 92.938],
  [42.382343, 92.937928],
  [42.3824, 92.937873],
  [42.3824, 92.937873],
  [42.382486, 92.937761],
  [42.382523, 92.937671],
  [42.382558, 92.93758],
  [42.382593, 92.937488],
  [42.382623, 92.937405],
  [42.382651, 92.93732],
  [42.382698, 92.937236],
  [42.382698, 92.937236],
  [42.382821, 92.93714],
  [42.382966, 92.937091],
  [42.383036, 92.937066],
  [42.383105, 92.93703],
  [42.383171, 92.936993],
  [42.383243, 92.936956],
  [42.38332, 92.936915],
  [42.383396, 92.936873],
  [42.383468, 92.936838],
  [42.38361, 92.936765],
  [42.383681, 92.936726],
  [42.383681, 92.936726],
  [42.38379, 92.936658],
  [42.383825, 92.936618],
  [42.38385, 92.936563],
  [42.38385, 92.936563],
  [42.383866, 92.936516],
  [42.383835, 92.93642],
  [42.383835, 92.93642],
  [42.383813, 92.936351],
  [42.383736, 92.936303],
  [42.383693, 92.936268],
  [42.383636, 92.936243],
  [42.38357, 92.936231],
  [42.383511, 92.936221],
  [42.383386, 92.936241],
  [42.383326, 92.936276],
  [42.383326, 92.936276],
  [42.383233, 92.936333],
  [42.383188, 92.936361],
  [42.383138, 92.936388],
  [42.383086, 92.936405],
  [42.383018, 92.93644],
  [42.382953, 92.936466],
  [42.382831, 92.936533],
  [42.382831, 92.936533],
  [42.382723, 92.936585],
  [42.382666, 92.936606],
  [42.382593, 92.936625],
  [42.38254, 92.936663],
  [42.382478, 92.936693],
  [42.382416, 92.936716],
  [42.382358, 92.936736],
  [42.382308, 92.936758],
  [42.382265, 92.936775],
  [42.382183, 92.936826],
  [42.38214, 92.936851],
  [42.382088, 92.936871],
  [42.382043, 92.936891],
  [42.381996, 92.93691],
  [42.381953, 92.936933],
  [42.381905, 92.936953],
  [42.381858, 92.936976],
  [42.38181, 92.936996],
  [42.381711, 92.937051],
  [42.381711, 92.937051],
  [42.381603, 92.937106],
  [42.381553, 92.93713],
  [42.381501, 92.937145],
  [42.381456, 92.937168],
  [42.381406, 92.93719],
  [42.381365, 92.937195],
  [42.381306, 92.937183],
  [42.381306, 92.937183],
  [42.381275, 92.937185],
  [42.38121, 92.937156],
  [42.38121, 92.937156],
  [42.38116, 92.937136],
  [42.381145, 92.937111],
  [42.381126, 92.937101],
  [42.38109, 92.937091],
  [42.381053, 92.9371],
  [42.381005, 92.937105],
  [42.380961, 92.937116],
  [42.38092, 92.93712],
  [42.38087, 92.937133],
  [42.380783, 92.937146],
  [42.38075, 92.937151],
  [42.38071, 92.937156],
  [42.380675, 92.937166],
  [42.380638, 92.937173],
  [42.380601, 92.937196],
  [42.380563, 92.93721],
  [42.380536, 92.937226],
  [42.380508, 92.93724],
  [42.380445, 92.937268],
  [42.380445, 92.937268],
  [42.380398, 92.937276],
  [42.38038, 92.937273],
  [42.380355, 92.937268],
  [42.380336, 92.937271],
  [42.380315, 92.937286],
  [42.38029, 92.937293],
  [42.380261, 92.937278],
  [42.380261, 92.937278],
  [42.380256, 92.937285],
  [42.380235, 92.93729],
  [42.38021, 92.937283],
  [42.380181, 92.937275],
  [42.380168, 92.93727],
  [42.380156, 92.937268],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380155, 92.937266],
  [42.380105, 92.93728],
  [42.38009, 92.937271],
  [42.38006, 92.937291],
  [42.380041, 92.937286],
  [42.380025, 92.937288],
  [42.380008, 92.937283],
  [42.379986, 92.937276],
  [42.379986, 92.937276],
  [42.379965, 92.937211],
  [42.379965, 92.937176],
  [42.379973, 92.937151],
  [42.379971, 92.937133],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.379971, 92.93713],
  [42.380076, 92.93718],
  [42.380088, 92.937156],
  [42.380098, 92.937143],
  [42.380116, 92.937126],
  [42.380116, 92.937126],
  [42.380148, 92.937091],
  [42.380156, 92.937081],
  [42.380173, 92.937073],
  [42.380186, 92.93707],
  [42.380195, 92.937066],
  [42.380201, 92.937068],
  [42.380226, 92.937078],
  [42.380226, 92.937078],
  [42.380226, 92.937078],
  [42.380288, 92.937106],
  [42.380298, 92.937118],
  [42.38032, 92.937123],
  [42.380343, 92.937135],
  [42.380365, 92.937141],
  [42.380386, 92.937146],
  [42.380425, 92.937155],
  [42.380425, 92.937155],
  [42.38045, 92.93715],
  [42.38047, 92.93715],
  [42.380485, 92.937148],
  [42.380501, 92.937141],
  [42.38052, 92.93714],
  [42.380545, 92.937135],
  [42.380576, 92.937118],
  [42.380576, 92.937118],
  [42.38059, 92.937108],
  [42.380616, 92.937111],
  [42.38062, 92.937116],
  [42.380615, 92.937123],
  [42.380625, 92.937113],
  [42.380646, 92.937108],
  [42.380663, 92.937105],
  [42.380675, 92.937095],
  [42.38069, 92.937088],
  [42.38073, 92.937066],
  [42.380738, 92.937076],
  [42.380738, 92.937076],
  [42.380765, 92.937108],
  [42.380781, 92.937125],
  [42.380801, 92.937131],
  [42.380845, 92.937135],
  [42.380873, 92.937138],
  [42.380903, 92.937148],
  [42.380958, 92.937168],
  [42.380958, 92.937168],
  [42.380988, 92.937171],
  [42.381053, 92.937193],
  [42.381053, 92.937193],
  [42.381111, 92.937228],
  [42.381148, 92.937226],
  [42.38119, 92.937238],
  [42.38123, 92.937241],
  [42.381275, 92.93723],
  [42.38132, 92.937213],
  [42.381393, 92.937185],
  [42.381393, 92.937185],
  [42.381426, 92.937176],
  [42.381486, 92.93715],
  [42.381505, 92.937131],
  [42.381526, 92.937113],
  [42.381561, 92.937091],
  [42.381606, 92.93707],
  [42.381655, 92.937048],
  [42.381706, 92.937028],
  [42.381758, 92.93701],
  [42.381866, 92.936958],
  [42.381916, 92.93695],
  [42.381916, 92.93695],
  [42.382013, 92.936915],
  [42.38206, 92.936893],
  [42.382105, 92.936878],
  [42.38214, 92.936861],
  [42.382178, 92.936828],
  [42.382216, 92.93681],
  [42.382306, 92.936773],
  [42.382306, 92.936773],
  [42.382363, 92.93675],
  [42.382466, 92.936696],
  [42.382508, 92.936678],
  [42.38256, 92.936656],
  [42.382615, 92.936633],
  [42.382666, 92.936605],
  [42.382723, 92.936586],
  [42.382778, 92.936566],
  [42.382823, 92.936543],
  [42.382925, 92.936503],
  [42.382973, 92.936485],
  [42.382973, 92.936485],
  [42.383081, 92.936438],
  [42.383138, 92.936405],
  [42.383183, 92.936393],
  [42.383233, 92.93637],
  [42.383285, 92.936348],
  [42.383348, 92.936328],
  [42.38346, 92.936271],
  [42.38346, 92.936271],
  [42.383565, 92.936245],
  [42.383618, 92.936245],
  [42.383618, 92.936245],
  [42.383618, 92.936245],
  [42.383753, 92.936323],
  [42.383753, 92.936323],
  [42.383765, 92.936363],
  [42.383768, 92.936416],
  [42.383741, 92.93652],
  [42.383741, 92.93652],
  [42.383741, 92.93652],
  [42.383678, 92.936626],
  [42.38365, 92.936655],
  [42.383605, 92.936685],
  [42.38357, 92.936711],
  [42.383526, 92.93673],
  [42.383426, 92.93676],
  [42.38338, 92.936775],
  [42.38338, 92.936775],
  [42.38329, 92.936806],
  [42.38325, 92.936823],
  [42.383208, 92.936848],
  [42.383165, 92.936861],
  [42.38311, 92.93689],
  [42.383063, 92.936906],
  [42.382966, 92.936963],
  [42.382966, 92.936963],
  [42.382863, 92.937023],
  [42.38282, 92.937048],
  [42.382766, 92.937088],
  [42.38271, 92.937121],
  [42.382663, 92.937158],
  [42.382626, 92.937201],
  [42.382581, 92.93727],
  [42.382548, 92.937356],
  [42.382526, 92.937431],
  [42.38249, 92.93758],
  [42.382458, 92.937658],
  [42.382458, 92.937658],
  [42.382401, 92.93778],
  [42.382363, 92.937811],
  [42.382328, 92.937835],
  [42.382291, 92.937866],
  [42.382255, 92.937883],
  [42.382216, 92.937903],
  [42.382123, 92.93796],
  [42.382123, 92.93796],
  [42.382013, 92.938013],
  [42.381958, 92.938036],
  [42.38191, 92.938061],
  [42.381856, 92.938093],
  [42.381798, 92.93813],
  [42.381745, 92.938166],
  [42.381651, 92.938231],
  [42.381651, 92.938231],
  [42.381606, 92.938258],
  [42.381511, 92.938305],
  [42.381456, 92.938335],
  [42.381403, 92.938358],
  [42.38135, 92.938401],
  [42.381315, 92.93845],
  [42.381275, 92.938501],
  [42.38124, 92.93856],
  [42.381198, 92.938616],
  [42.381156, 92.938665],
  [42.381115, 92.938718],
  [42.381081, 92.93878],
  [42.381, 92.938856],
  [42.380966, 92.938908],
  [42.38093, 92.938948],
  [42.380885, 92.938978],
  [42.38085, 92.93902],
  [42.380813, 92.939051],
  [42.380766, 92.93909],
  [42.380725, 92.939121],
  [42.380681, 92.939151],
  [42.380603, 92.939208],
  [42.380603, 92.939208],
  [42.380515, 92.93926],
  [42.380481, 92.939306],
  [42.380446, 92.93935],
  [42.380401, 92.939386],
  [42.380356, 92.93943],
  [42.38031, 92.939463],
  [42.380205, 92.93955],
  [42.380205, 92.93955],
  [42.380153, 92.939588],
  [42.38005, 92.939638],
  [42.380011, 92.939665],
  [42.37998, 92.939715],
  [42.37995, 92.939756],
  [42.379921, 92.93982],
  [42.3799, 92.939891],
  [42.379878, 92.939958],
  [42.379855, 92.940031],
  [42.379833, 92.940098],
  [42.379793, 92.940235],
  [42.379793, 92.940235],
  [42.379743, 92.940365],
  [42.379728, 92.940425],
  [42.379718, 92.940491],
  [42.37971, 92.940565],
  [42.379703, 92.940638],
  [42.3797, 92.940715],
  [42.379711, 92.940871],
  [42.379711, 92.940871],
  [42.379715, 92.940965],
  [42.379705, 92.941138],
  [42.379698, 92.941236],
  [42.37969, 92.94133],
  [42.379693, 92.941428],
  [42.379686, 92.941528],
  [42.379678, 92.94163],
  [42.379673, 92.94174],
  [42.379666, 92.94185],
  [42.379656, 92.942046],
  [42.379648, 92.942146],
  [42.379648, 92.942146],
  [42.37962, 92.94235],
  [42.379606, 92.942453],
  [42.379596, 92.94255],
  [42.379583, 92.942638],
  [42.379578, 92.942731],
  [42.379566, 92.942828],
  [42.379563, 92.942928],
  [42.379561, 92.943031],
  [42.379551, 92.943216],
  [42.379543, 92.943308],
  [42.379543, 92.943308],
  [42.37953, 92.943505],
  [42.37952, 92.943605],
  [42.379511, 92.943708],
  [42.3795, 92.943815],
  [42.379481, 92.943926],
  [42.379468, 92.944036],
  [42.37943, 92.944271],
  [42.37943, 92.944271],
  [42.379408, 92.944391],
  [42.379371, 92.944623],
  [42.379351, 92.944715],
  [42.379331, 92.944821],
  [42.379305, 92.944923],
  [42.379281, 92.945028],
  [42.379266, 92.94513],
  [42.379256, 92.945233],
  [42.37925, 92.945346],
  [42.379225, 92.945551],
  [42.379208, 92.94566],
  [42.379208, 92.94566],
  [42.379165, 92.945838],
  [42.379136, 92.945936],
  [42.379106, 92.946036],
  [42.379066, 92.946135],
  [42.379025, 92.946236],
  [42.378968, 92.946323],
  [42.378845, 92.946421],
  [42.378845, 92.946421],
  [42.378713, 92.946486],
  [42.37865, 92.94652],
  [42.378578, 92.946576],
  [42.378503, 92.946623],
  [42.37843, 92.94667],
  [42.378358, 92.946711],
  [42.378285, 92.946755],
  [42.378216, 92.946801],
  [42.378151, 92.946853],
  [42.378016, 92.946963],
  [42.377946, 92.947028],
  [42.377866, 92.947093],
  [42.377781, 92.947156],
  [42.3777, 92.947221],
  [42.377625, 92.947276],
  [42.377545, 92.94733],
  [42.377468, 92.947385],
  [42.377385, 92.947435],
  [42.377225, 92.947543],
  [42.377225, 92.947543],
  [42.37706, 92.947666],
  [42.376983, 92.947725],
  [42.37691, 92.947776],
  [42.376833, 92.94784],
  [42.376753, 92.9479],
  [42.376673, 92.94795],
  [42.376588, 92.947988],
  [42.376503, 92.94803],
  [42.376423, 92.948078],
  [42.376251, 92.948173],
  [42.376251, 92.948173],
  [42.37608, 92.948283],
  [42.376001, 92.948323],
  [42.375921, 92.948366],
  [42.375836, 92.948405],
  [42.375753, 92.948458],
  [42.375666, 92.948503],
  [42.37558, 92.948566],
  [42.3755, 92.94863],
  [42.375426, 92.948676],
  [42.375291, 92.948753],
  [42.375216, 92.94879],
  [42.375146, 92.948823],
  [42.37507, 92.948875],
  [42.374985, 92.948915],
  [42.374913, 92.948966],
  [42.374828, 92.94902],
  [42.37476, 92.949061],
  [42.374686, 92.949126],
  [42.37452, 92.949225],
  [42.37452, 92.949225],
  [42.374333, 92.949353],
  [42.374246, 92.949411],
  [42.37416, 92.949465],
  [42.374078, 92.949503],
  [42.373995, 92.949541],
  [42.373916, 92.949581],
  [42.373748, 92.949673],
  [42.373748, 92.949673],
  [42.37367, 92.949718],
  [42.373515, 92.949801],
  [42.373438, 92.949841],
  [42.373365, 92.949881],
  [42.373288, 92.949923],
  [42.373215, 92.949966],
  [42.373145, 92.95001],
  [42.373073, 92.950061],
  [42.37299, 92.950113],
  [42.372825, 92.950211],
  [42.372743, 92.950266],
  [42.372743, 92.950266],
  [42.3726, 92.950371],
  [42.37253, 92.95041],
  [42.372471, 92.950466],
  [42.372405, 92.950501],
  [42.372345, 92.95053],
  [42.37229, 92.950556],
  [42.372161, 92.950621],
  [42.372161, 92.950621],
  [42.3721, 92.950668],
  [42.371968, 92.950798],
  [42.371901, 92.95087],
  [42.37184, 92.950906],
  [42.371786, 92.950968],
  [42.371735, 92.95104],
  [42.37168, 92.951088],
  [42.371633, 92.951155],
  [42.371583, 92.951243],
  [42.371515, 92.95144],
  [42.371515, 92.95144],
  [42.371483, 92.95155],
  [42.37146, 92.95177],
  [42.371475, 92.951868],
  [42.371508, 92.951968],
  [42.371521, 92.952061],
  [42.371545, 92.952158],
  [42.371568, 92.952245],
  [42.37159, 92.952323],
  [42.371616, 92.952403],
  [42.371698, 92.952558],
  [42.371736, 92.952628],
  [42.371736, 92.952628],
  [42.371791, 92.952745],
  [42.3718, 92.952795],
  [42.371816, 92.952836],
  [42.37183, 92.952886],
  [42.37184, 92.952921],
  [42.371848, 92.952965],
  [42.371865, 92.953046],
  [42.371865, 92.953046],
  [42.371876, 92.953085],
  [42.371883, 92.953183],
  [42.371891, 92.953233],
  [42.371906, 92.953281],
  [42.371916, 92.953338],
  [42.371923, 92.953391],
  [42.371936, 92.953435],
  [42.371946, 92.953483],
  [42.371958, 92.953535],
  [42.371981, 92.953646],
  [42.371996, 92.953705],
  [42.371996, 92.953705],
  [42.372033, 92.953801],
  [42.372053, 92.953861],
  [42.372065, 92.953916],
  [42.372076, 92.95396],
  [42.37209, 92.954005],
  [42.372096, 92.954038],
  [42.3721, 92.95414],
  [42.3721, 92.95414],
  [42.372106, 92.954243],
  [42.372098, 92.954285],
  [42.37209, 92.954328],
  [42.372083, 92.95438],
  [42.372085, 92.95442],
  [42.372085, 92.954466],
  [42.372076, 92.954508],
  [42.372066, 92.95456],
  [42.372063, 92.954605],
  [42.372055, 92.954726],
  [42.372053, 92.95479],
  [42.372051, 92.954865],
  [42.372045, 92.954921],
  [42.372036, 92.955003],
  [42.372036, 92.955081],
  [42.372038, 92.955151],
  [42.37205, 92.955223],
  [42.372065, 92.9553],
  [42.372071, 92.955378],
  [42.372083, 92.955463],
  [42.372096, 92.955628],
  [42.372105, 92.955708],
  [42.372105, 92.955708],
  [42.372115, 92.955858],
  [42.372115, 92.955935],
  [42.372115, 92.956005],
  [42.37212, 92.956078],
  [42.372125, 92.956151],
  [42.37213, 92.95622],
  [42.37214, 92.956371],
  [42.37214, 92.956371],
  [42.372141, 92.956526],
  [42.37214, 92.956606],
  [42.372143, 92.956685],
  [42.372148, 92.956746],
  [42.37215, 92.956803],
  [42.37216, 92.956875],
  [42.372166, 92.95694],
  [42.372176, 92.95699],
  [42.372176, 92.957041],
  [42.372183, 92.957158],
  [42.372186, 92.957211],
  [42.372191, 92.957261],
  [42.372198, 92.957318],
  [42.372205, 92.957361],
  [42.372205, 92.957423],
  [42.372216, 92.957481],
  [42.37222, 92.957545],
  [42.37222, 92.957601],
  [42.372228, 92.957731],
  [42.372228, 92.957731],
  [42.372251, 92.957836],
  [42.372253, 92.957888],
  [42.372255, 92.957941],
  [42.372253, 92.958003],
  [42.372258, 92.958053],
  [42.372261, 92.958111],
  [42.372258, 92.958236],
  [42.372258, 92.958236],
  [42.372256, 92.958298],
  [42.372268, 92.958405],
  [42.372275, 92.958473],
  [42.372278, 92.958536],
  [42.372285, 92.958603],
  [42.372281, 92.958678],
  [42.372283, 92.958751],
  [42.372293, 92.95881],
  [42.372296, 92.958871],
  [42.372293, 92.959],
  [42.372298, 92.959053],
  [42.372298, 92.959053],
  [42.372298, 92.959163],
  [42.37229, 92.959216],
  [42.372285, 92.959266],
  [42.372275, 92.959336],
  [42.372266, 92.959408],
  [42.372253, 92.95949],
  [42.37224, 92.95965],
  [42.37224, 92.95965],
  [42.372243, 92.959738],
  [42.37227, 92.95987],
  [42.37227, 92.95987],
  [42.372298, 92.960021],
  [42.37231, 92.960088],
  [42.372325, 92.960153],
  [42.372335, 92.960223],
  [42.372341, 92.960296],
  [42.372353, 92.960368],
  [42.372388, 92.96052],
  [42.372388, 92.96052],
  [42.372405, 92.960596],
  [42.372423, 92.96075],
  [42.37244, 92.960808],
  [42.372456, 92.96087],
  [42.37247, 92.960931],
  [42.37248, 92.960995],
  [42.372495, 92.961068],
  [42.372506, 92.961131],
  [42.372526, 92.961183],
  [42.372568, 92.961305],
  [42.37258, 92.961368],
  [42.37258, 92.961368],
  [42.37261, 92.961471],
  [42.372621, 92.961518],
  [42.37263, 92.96157],
  [42.372638, 92.961633],
  [42.372651, 92.961693],
  [42.372666, 92.961765],
  [42.37268, 92.961905],
  [42.37268, 92.961905],
  [42.372688, 92.962035],
  [42.37269, 92.962096],
  [42.372703, 92.962158],
  [42.372718, 92.962205],
  [42.372728, 92.962266],
  [42.372735, 92.962315],
  [42.372741, 92.962373],
  [42.372746, 92.962431],
  [42.37276, 92.962488],
  [42.372783, 92.962585],
  [42.372808, 92.962645],
  [42.372808, 92.962645],
  [42.372838, 92.962773],
  [42.372853, 92.962831],
  [42.372863, 92.962896],
  [42.372878, 92.962951],
  [42.372891, 92.963018],
  [42.372906, 92.963085],
  [42.372928, 92.963218],
  [42.372928, 92.963218],
  [42.372951, 92.963346],
  [42.372961, 92.963408],
  [42.372971, 92.963471],
  [42.372983, 92.963535],
  [42.372996, 92.963593],
  [42.373003, 92.963655],
  [42.373013, 92.96372],
  [42.373015, 92.963783],
  [42.373025, 92.963855],
  [42.37302, 92.963975],
  [42.373006, 92.964041],
  [42.372985, 92.964096],
  [42.37296, 92.964148],
  [42.37294, 92.964201],
  [42.37292, 92.964251],
  [42.372903, 92.964303],
  [42.372876, 92.964343],
  [42.372856, 92.964378],
  [42.372836, 92.964413],
  [42.372805, 92.96446],
  [42.372758, 92.964528],
  [42.372735, 92.96457],
  [42.372735, 92.96457],
  [42.372673, 92.964661],
  [42.372635, 92.964713],
  [42.372598, 92.964761],
  [42.372563, 92.96483],
  [42.372533, 92.964895],
  [42.372506, 92.964958],
  [42.37244, 92.965091],
  [42.37244, 92.965091],
  [42.372378, 92.965191],
  [42.37235, 92.965241],
  [42.372325, 92.9653],
  [42.3723, 92.965353],
  [42.372281, 92.965405],
  [42.372255, 92.965458],
  [42.372228, 92.965508],
  [42.372203, 92.965556],
  [42.372183, 92.965605],
  [42.372135, 92.965703],
  [42.372105, 92.96576],
  [42.372078, 92.965806],
  [42.372056, 92.965846],
  [42.372035, 92.965888],
  [42.372013, 92.965935],
  [42.371985, 92.965981],
  [42.371961, 92.96603],
  [42.371936, 92.966078],
  [42.371888, 92.966173],
  [42.371888, 92.966173],
  [42.371843, 92.966263],
  [42.371821, 92.96632],
  [42.371791, 92.966381],
  [42.371758, 92.966463],
  [42.371726, 92.966516],
  [42.371703, 92.96656],
  [42.371655, 92.966663],
  [42.371655, 92.966663],
  [42.371626, 92.966721],
  [42.371576, 92.966835],
  [42.371545, 92.966893],
  [42.37152, 92.966948],
  [42.371498, 92.966996],
  [42.371478, 92.967046],
  [42.371461, 92.96709],
  [42.371441, 92.967141],
  [42.371418, 92.967188],
  [42.37138, 92.967276],
  [42.37136, 92.967321],
  [42.37136, 92.967321],
  [42.37131, 92.967425],
  [42.371285, 92.967453],
  [42.371256, 92.967486],
  [42.371236, 92.967526],
  [42.371213, 92.96756],
  [42.371188, 92.967595],
  [42.37117, 92.967625],
  [42.371146, 92.967656],
  [42.371125, 92.967695],
  [42.371071, 92.967783],
  [42.371071, 92.967783],
  [42.371015, 92.967878],
  [42.370995, 92.967923],
  [42.370981, 92.967963],
  [42.370968, 92.968005],
  [42.370945, 92.968051],
  [42.370935, 92.968105],
  [42.37093, 92.968141],
  [42.37093, 92.968141],
  [42.370948, 92.968233],
  [42.37098, 92.968281],
  [42.370998, 92.968295],
  [42.37101, 92.968311],
  [42.37104, 92.968326],
  [42.371055, 92.96834],
  [42.371078, 92.96835],
  [42.371096, 92.968365],
  [42.371113, 92.968388],
  [42.371113, 92.968388],
  [42.371143, 92.968488],
  [42.371143, 92.968488],
  [42.37114, 92.96853],
  [42.371115, 92.96857],
  [42.371105, 92.968581],
  [42.371088, 92.968601],
  [42.371085, 92.968601],
  [42.371085, 92.968601],
  [42.371085, 92.968601],
  [42.371103, 92.968556],
  [42.371103, 92.968556],
  [42.371113, 92.968546],
  [42.371106, 92.968541],
  [42.371115, 92.968525],
  [42.371113, 92.9685],
  [42.371115, 92.96849],
  [42.37112, 92.96848],
  [42.371133, 92.968473],
  [42.371133, 92.968473],
  [42.371151, 92.968466],
  [42.371156, 92.968468],
  [42.371165, 92.968468],
  [42.371166, 92.968458],
  [42.371181, 92.968443],
  [42.371191, 92.96844],
  [42.37121, 92.96844],
  [42.37121, 92.96844],
  [42.371215, 92.968433],
  [42.371228, 92.968433],
  [42.371236, 92.968433],
  [42.371243, 92.968423],
  [42.371256, 92.968418],
  [42.371261, 92.968421],
  [42.371271, 92.96841],
  [42.371271, 92.96841],
  [42.371271, 92.96841],
  [42.371271, 92.96841],
  [42.371263, 92.968415],
  [42.371245, 92.968411],
  [42.371245, 92.968411],
  [42.371243, 92.9684],
  [42.371223, 92.968375],
  [42.37121, 92.968365],
  [42.37121, 92.968365],
  [42.371195, 92.96837],
  [42.371138, 92.968348],
  [42.371115, 92.968343],
  [42.371086, 92.968326],
  [42.37107, 92.968301],
  [42.37107, 92.968301],
  [42.371061, 92.968261],
  [42.371065, 92.968173],
  [42.371075, 92.968116],
  [42.371098, 92.968046],
  [42.37112, 92.967998],
  [42.371138, 92.967946],
  [42.371188, 92.967835],
  [42.371218, 92.967778],
  [42.371218, 92.967778],
  [42.371276, 92.967648],
  [42.371306, 92.967593],
  [42.371336, 92.967533],
  [42.371368, 92.96747],
  [42.371405, 92.967401],
  [42.371445, 92.96733],
  [42.37152, 92.9672],
  [42.37152, 92.9672],
  [42.371556, 92.967128],
  [42.371621, 92.966975],
  [42.371646, 92.9669],
  [42.371681, 92.96681],
  [42.371718, 92.966731],
  [42.371755, 92.966653],
  [42.371788, 92.966576],
  [42.371818, 92.9665],
  [42.371855, 92.966425],
  [42.371928, 92.966255],
  [42.371961, 92.966173],
  [42.371961, 92.966173],
  [42.372053, 92.966018],
  [42.372091, 92.965938],
  [42.372136, 92.96586],
  [42.372178, 92.965783],
  [42.372221, 92.965695],
  [42.372266, 92.96562],
  [42.372348, 92.965473],
  [42.372348, 92.965473],
  [42.372445, 92.965311],
  [42.372491, 92.965231],
  [42.372543, 92.96515],
  [42.372588, 92.965063],
  [42.372626, 92.964985],
  [42.37268, 92.964915],
  [42.372726, 92.964853],
  [42.372773, 92.964793],
  [42.372815, 92.96473],
  [42.37289, 92.964611],
  [42.37289, 92.964611],
  [42.372918, 92.964558],
  [42.372991, 92.964473],
  [42.373035, 92.964433],
  [42.37307, 92.964378],
  [42.373096, 92.964321],
  [42.373118, 92.964251],
  [42.373125, 92.96418],
  [42.373136, 92.964101],
  [42.373145, 92.964025],
  [42.373146, 92.963866],
  [42.373145, 92.963795],
  [42.373145, 92.963795],
  [42.373108, 92.963625],
  [42.373085, 92.963538],
  [42.373071, 92.96345],
  [42.373058, 92.963351],
  [42.37303, 92.963268],
  [42.372998, 92.963171],
  [42.372953, 92.962981],
  [42.372953, 92.962981],
  [42.372913, 92.962778],
  [42.372893, 92.962678],
  [42.372875, 92.962576],
  [42.372851, 92.962471],
  [42.372835, 92.962373],
  [42.372808, 92.962271],
  [42.372793, 92.962163],
  [42.372776, 92.962055],
  [42.372753, 92.961956],
  [42.372706, 92.961741],
  [42.372688, 92.961616],
  [42.37267, 92.961513],
  [42.37265, 92.961415],
  [42.372636, 92.961286],
  [42.372611, 92.961163],
  [42.372586, 92.961043],
  [42.37257, 92.960926],
  [42.372556, 92.960811],
  [42.372516, 92.960593],
  [42.372516, 92.960593],
  [42.372478, 92.960375],
  [42.372455, 92.960276],
  [42.372435, 92.960165],
  [42.372415, 92.960068],
  [42.372398, 92.959975],
  [42.37238, 92.959885],
  [42.372351, 92.959688],
  [42.372351, 92.959688],
  [42.372333, 92.959585],
  [42.372358, 92.95939],
  [42.372371, 92.959305],
  [42.372388, 92.959221],
  [42.3724, 92.959148],
  [42.372401, 92.959068],
  [42.372416, 92.959001],
  [42.37241, 92.958916],
  [42.372408, 92.958843],
  [42.372406, 92.958771],
  [42.3724, 92.958618],
  [42.3724, 92.958618],
  [42.372401, 92.958475],
  [42.372393, 92.958401],
  [42.372383, 92.958313],
  [42.372375, 92.958228],
  [42.37237, 92.958143],
  [42.372365, 92.958038],
  [42.372348, 92.957931],
  [42.372338, 92.957821],
  [42.372328, 92.957715],
  [42.372313, 92.957508],
  [42.372313, 92.957508],
  [42.372278, 92.957291],
  [42.372276, 92.957191],
  [42.372276, 92.957098],
  [42.37227, 92.956998],
  [42.372268, 92.956918],
  [42.372265, 92.956821],
  [42.372248, 92.956636],
  [42.372248, 92.956636],
  [42.372238, 92.956541],
  [42.372235, 92.956361],
  [42.372231, 92.956276],
  [42.37223, 92.956198],
  [42.372228, 92.956125],
  [42.372223, 92.956053],
  [42.372216, 92.955975],
  [42.372208, 92.955883],
  [42.372205, 92.955786],
  [42.372201, 92.955691],
  [42.372186, 92.955475],
  [42.372186, 92.955475],
  [42.372173, 92.955258],
  [42.372175, 92.955153],
  [42.372171, 92.955055],
  [42.37216, 92.954963],
  [42.372155, 92.954885],
  [42.372146, 92.954793],
  [42.372155, 92.954635],
  [42.372155, 92.954635],
  [42.37216, 92.954548],
  [42.372155, 92.954378],
  [42.37216, 92.954298],
  [42.372156, 92.954223],
  [42.372161, 92.95415],
  [42.37217, 92.954078],
  [42.372153, 92.953993],
  [42.372135, 92.9539],
  [42.372116, 92.953806],
  [42.37207, 92.95362],
  [42.372048, 92.95351],
  [42.372048, 92.95351],
  [42.372005, 92.95332],
  [42.37198, 92.953213],
  [42.371953, 92.953118],
  [42.371926, 92.95302],
  [42.371896, 92.952933],
  [42.371875, 92.952841],
  [42.371801, 92.952663],
  [42.371801, 92.952663],
  [42.371748, 92.952465],
  [42.371718, 92.952363],
  [42.371688, 92.952256],
  [42.37166, 92.952148],
  [42.37163, 92.952045],
  [42.371605, 92.951938],
  [42.371585, 92.951826],
  [42.371593, 92.951713],
  [42.371606, 92.951598],
  [42.371673, 92.951395],
  [42.371705, 92.951291],
  [42.371705, 92.951291],
  [42.371823, 92.951133],
  [42.371888, 92.951068],
  [42.37197, 92.951006],
  [42.372058, 92.950945],
  [42.37214, 92.950891],
  [42.372223, 92.950841],
  [42.372308, 92.950786],
  [42.372403, 92.95074],
  [42.372576, 92.950618],
  [42.372658, 92.950563],
  [42.372658, 92.950563],
  [42.372811, 92.95046],
  [42.372896, 92.9504],
  [42.372986, 92.950338],
  [42.373073, 92.950278],
  [42.37316, 92.950223],
  [42.37324, 92.950171],
  [42.373398, 92.950071],
  [42.373398, 92.950071],
  [42.373563, 92.949968],
  [42.373646, 92.949915],
  [42.373725, 92.94986],
  [42.373806, 92.94981],
  [42.37389, 92.949758],
  [42.373973, 92.949708],
  [42.374056, 92.949658],
  [42.374143, 92.949603],
  [42.374228, 92.949548],
  [42.374401, 92.949445],
  [42.374491, 92.949395],
  [42.37458, 92.94935],
  [42.37466, 92.949311],
  [42.37474, 92.94927],
  [42.374826, 92.949223],
  [42.374913, 92.949176],
  [42.375001, 92.949121],
  [42.375088, 92.949068],
  [42.375256, 92.948958],
  [42.375256, 92.948958],
  [42.375421, 92.94886],
  [42.375511, 92.948818],
  [42.375593, 92.948768],
  [42.375666, 92.948718],
  [42.37575, 92.948668],
  [42.375828, 92.94861],
  [42.375986, 92.948496],
  [42.375986, 92.948496],
  [42.376071, 92.948436],
  [42.376251, 92.948335],
  [42.37634, 92.94829],
  [42.376426, 92.948241],
  [42.376506, 92.94819],
  [42.376586, 92.948146],
  [42.376666, 92.948085],
  [42.376745, 92.948033],
  [42.37682, 92.947981],
  [42.376903, 92.947925],
  [42.37699, 92.947868],
  [42.377068, 92.947813],
  [42.377228, 92.947706],
  [42.377311, 92.94765],
  [42.37739, 92.947591],
  [42.377465, 92.947538],
  [42.377543, 92.94749],
  [42.37762, 92.947448],
  [42.377696, 92.947401],
  [42.377771, 92.947348],
  [42.377848, 92.947295],
  [42.37802, 92.94719],
  [42.37802, 92.94719],
  [42.378201, 92.947086],
  [42.378288, 92.947025],
  [42.378368, 92.946965],
  [42.378443, 92.946903],
  [42.37852, 92.946846],
  [42.378605, 92.946796],
  [42.378761, 92.946698],
  [42.378761, 92.946698],
  [42.378835, 92.94665],
  [42.37897, 92.94655],
  [42.379028, 92.946475],
  [42.37908, 92.946393],
  [42.379135, 92.946296],
  [42.37918, 92.946195],
  [42.379223, 92.946078],
  [42.37925, 92.945968],
  [42.37927, 92.945848],
  [42.379281, 92.94562],
  [42.37928, 92.945493],
  [42.37928, 92.945493],
  [42.379288, 92.945263],
  [42.3793, 92.94515],
  [42.379308, 92.945033],
  [42.379321, 92.944918],
  [42.379336, 92.944801],
  [42.379348, 92.944695],
  [42.379381, 92.944483],
  [42.379381, 92.944483],
  [42.379396, 92.944346],
  [42.379438, 92.944123],
  [42.379448, 92.944018],
  [42.379461, 92.943896],
  [42.379466, 92.943788],
  [42.379485, 92.943675],
  [42.379496, 92.943548],
  [42.379506, 92.943435],
  [42.379521, 92.943326],
  [42.379551, 92.94311],
  [42.379565, 92.943013],
  [42.379565, 92.943013],
  [42.37958, 92.942828],
  [42.379593, 92.942728],
  [42.379603, 92.942638],
  [42.379616, 92.942546],
  [42.379621, 92.94245],
  [42.379625, 92.942348],
  [42.379626, 92.942143],
  [42.379626, 92.942143],
  [42.379648, 92.94193],
  [42.379656, 92.941826],
  [42.379656, 92.941826],
  [42.379658, 92.941603],
  [42.37966, 92.941498],
  [42.379666, 92.941393],
  [42.379675, 92.941283],
  [42.379681, 92.941166],
  [42.379688, 92.941061],
  [42.379701, 92.940865],
  [42.379701, 92.940865],
  [42.379705, 92.94077],
  [42.379718, 92.940603],
  [42.379733, 92.940526],
  [42.379755, 92.940463],
  [42.379776, 92.940381],
  [42.379803, 92.940296],
  [42.379825, 92.940193],
  [42.379856, 92.940095],
  [42.379886, 92.940005],
  [42.379946, 92.939803],
  [42.379988, 92.939708],
  [42.379988, 92.939708],
  [42.38004, 92.939628],
  [42.380106, 92.939581],
  [42.380276, 92.939523],
  [42.38035, 92.939495],
  [42.380416, 92.939466],
  [42.380481, 92.939438],
  [42.380543, 92.9394],
  [42.380611, 92.93936],
  [42.38073, 92.939286],
  [42.38073, 92.939286],
  [42.38078, 92.939253],
  [42.380875, 92.939183],
  [42.380906, 92.939148],
  [42.38094, 92.939116],
  [42.380975, 92.939068],
  [42.381025, 92.939011],
  [42.381071, 92.938955],
  [42.381116, 92.938881],
  [42.381163, 92.93881],
  [42.381246, 92.938656],
  [42.381298, 92.938586],
  [42.381298, 92.938586],
  [42.38141, 92.938463],
  [42.381468, 92.938421],
  [42.381533, 92.938378],
  [42.381605, 92.938338],
  [42.381675, 92.938308],
  [42.381738, 92.938276],
  [42.381856, 92.938208],
  [42.381856, 92.938208],
  [42.381916, 92.938165],
  [42.382031, 92.938091],
  [42.382098, 92.938061],
  [42.382165, 92.938018],
  [42.382225, 92.93798],
  [42.3823, 92.937951],
  [42.38236, 92.937916],
  [42.382406, 92.937886],
  [42.382453, 92.937858],
  [42.382516, 92.937741],
  [42.382516, 92.937741],
  [42.382543, 92.93767],
  [42.382596, 92.937528],
  [42.382618, 92.937445],
  [42.382641, 92.937368],
  [42.382678, 92.937293],
  [42.382678, 92.937293],
  [42.382723, 92.937225],
  [42.382843, 92.93711],
  [42.382905, 92.937081],
  [42.383031, 92.937013],
  [42.383031, 92.937013],
  [42.3831, 92.936978],
  [42.383241, 92.936913],
  [42.383318, 92.936871],
  [42.383398, 92.936833],
  [42.38347, 92.936798],
  [42.383545, 92.936755],
  [42.383613, 92.936735],
  [42.383678, 92.936701],
  [42.383678, 92.936701],
  [42.383768, 92.936583],
  [42.383816, 92.936468],
  [42.383816, 92.936468],
  [42.383816, 92.936468],
  [42.383796, 92.936346],
  [42.383761, 92.936295],
  [42.383671, 92.936208],
  [42.383621, 92.936191],
  [42.383571, 92.936178],
  [42.38351, 92.936186],
  [42.383451, 92.936191],
  [42.383403, 92.9362],
  [42.383321, 92.936238],
  [42.383321, 92.936238],
  [42.383233, 92.93628],
  [42.383188, 92.936296],
  [42.383143, 92.93631],
  [42.383106, 92.936335],
  [42.383063, 92.936353],
  [42.383016, 92.936378],
  [42.382916, 92.936433],
  [42.382916, 92.936433],
  [42.382865, 92.936455],
  [42.382761, 92.936476],
  [42.382706, 92.9365],
  [42.382648, 92.936525],
  [42.382588, 92.936546],
  [42.38254, 92.936568],
  [42.382493, 92.936593],
  [42.382438, 92.936616],
  [42.382395, 92.936641],
  [42.382306, 92.936698],
  [42.38226, 92.936728],
  [42.38226, 92.936728],
  [42.382165, 92.936798],
  [42.382113, 92.936846],
  [42.382051, 92.936883],
  [42.382005, 92.936906],
  [42.381955, 92.93692],
  [42.381901, 92.936948],
  [42.38185, 92.936991],
  [42.381796, 92.93701],
  [42.381748, 92.937033],
  [42.381656, 92.937076],
  [42.381656, 92.937076],
  [42.381541, 92.93713],
  [42.381486, 92.937155],
  [42.381435, 92.937163],
  [42.381383, 92.937166],
  [42.381343, 92.937178],
  [42.381303, 92.937188],
  [42.381245, 92.9372],
  [42.381245, 92.9372],
  [42.381206, 92.93722],
  [42.381145, 92.93722],
  [42.381108, 92.937218],
  [42.381071, 92.937211],
  [42.38104, 92.937208],
  [42.381006, 92.937203],
  [42.380976, 92.937196],
  [42.380951, 92.9372],
  [42.380921, 92.937186],
  [42.380876, 92.937138],
  [42.380851, 92.937125],
  [42.380851, 92.937125],
  [42.380801, 92.937103],
  [42.380771, 92.937103],
  [42.380751, 92.937085],
  [42.380716, 92.937081],
  [42.38069, 92.937085],
  [42.380663, 92.937073],
  [42.380603, 92.93706],
  [42.380603, 92.93706],
  [42.380553, 92.937076],
  [42.380535, 92.937073],
  [42.380523, 92.93707],
  [42.380523, 92.937063],
  [42.38052, 92.937058],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.380523, 92.937056],
  [42.38047, 92.937058],
  [42.380445, 92.937045],
  [42.380435, 92.937031],
  [42.380418, 92.937018],
  [42.38041, 92.937018],
  [42.380395, 92.937006],
  [42.380383, 92.936998],
  [42.380368, 92.936988],
  [42.380368, 92.936988],
  [42.380335, 92.936983],
  [42.38033, 92.936975],
  [42.38033, 92.936975],
  [42.380321, 92.936966],
  [42.380318, 92.936965],
  [42.380318, 92.936965],
  [42.380318, 92.936965],
  [42.380318, 92.936965],
  [42.380318, 92.936965],
  [42.380248, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380246, 92.937085],
  [42.380271, 92.936915],
  [42.380273, 92.936931],
  [42.380273, 92.936931],
  [42.380278, 92.936941],
  [42.380275, 92.936938],
  [42.380271, 92.936946],
  [42.380273, 92.936951],
  [42.380276, 92.936963],
  [42.380278, 92.93698],
  [42.380281, 92.937018],
  [42.380281, 92.937018],
  [42.380311, 92.937058],
  [42.380315, 92.937078],
  [42.380315, 92.937078],
  [42.380315, 92.937078],
  [42.380365, 92.937146],
  [42.380395, 92.937161],
  [42.38043, 92.937183],
  [42.380445, 92.937196],
  [42.380463, 92.937191],
  [42.380493, 92.937196],
  [42.380501, 92.937206],
  [42.380516, 92.937211],
  [42.380515, 92.937203],
  [42.380523, 92.9372],
  [42.380525, 92.937203],
  [42.380531, 92.937203],
  [42.38054, 92.937195],
  [42.380548, 92.937186],
  [42.380573, 92.937178],
  [42.380573, 92.937178],
  [42.380581, 92.93718],
  [42.380581, 92.93718],
  [42.3806, 92.937166],
  [42.380656, 92.93717],
  [42.380691, 92.937171],
  [42.38071, 92.937166],
  [42.38073, 92.937175],
  [42.380748, 92.937176],
  [42.38076, 92.937175],
  [42.380783, 92.937168],
  [42.380783, 92.937168],
  [42.380803, 92.937171],
  [42.380815, 92.937183],
  [42.380826, 92.937186],
  [42.380836, 92.937181],
  [42.380846, 92.937181],
  [42.380853, 92.93717],
  [42.380868, 92.937175],
  [42.380868, 92.937175],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.380868, 92.93718],
  [42.381041, 92.93718],
  [42.38107, 92.937181],
  [42.381096, 92.937188],
  [42.381123, 92.937188],
  [42.381156, 92.937193],
  [42.381208, 92.937191],
  [42.381208, 92.937191],
  [42.381245, 92.937185],
  [42.381308, 92.937166],
  [42.381328, 92.937155],
  [42.381355, 92.937146],
  [42.381391, 92.937131],
  [42.38143, 92.937133],
  [42.38148, 92.937126],
  [42.38152, 92.937116],
  [42.381566, 92.937101],
  [42.381623, 92.937083],
  [42.381673, 92.937068],
  [42.381728, 92.937038],
  [42.381826, 92.936988],
  [42.38187, 92.93697],
  [42.381925, 92.936963],
  [42.381981, 92.936948],
  [42.382021, 92.936933],
  [42.382063, 92.936925],
  [42.382108, 92.936895],
  [42.38216, 92.93686],
  [42.382201, 92.936838],
  [42.382308, 92.936755],
  [42.382308, 92.936755],
  [42.382378, 92.936675],
  [42.382411, 92.936638],
  [42.382456, 92.936605],
  [42.382503, 92.936588],
  [42.382555, 92.93656],
  [42.382603, 92.936535],
  [42.382708, 92.936485],
  [42.382708, 92.936485],
  [42.382758, 92.936475],
  [42.38285, 92.936431],
  [42.382898, 92.936415],
  [42.382946, 92.936388],
  [42.383, 92.93637],
  [42.383051, 92.936336],
  [42.383103, 92.936326],
  [42.383153, 92.936305],
  [42.383195, 92.936298],
  [42.383291, 92.936265],
  [42.383346, 92.936248],
  [42.383346, 92.936248],
  [42.383456, 92.936216],
  [42.383506, 92.9362],
  [42.38356, 92.93619],
  [42.38356, 92.93619],
  [42.38361, 92.936193],
  [42.383645, 92.936216],
  [42.383688, 92.936245],
  [42.38375, 92.936321],
  [42.38375, 92.936321],
  [42.38377, 92.936371],
  [42.383765, 92.93648],
  [42.383758, 92.936535],
  [42.38375, 92.93659],
  [42.383723, 92.93663],
  [42.383685, 92.93667],
  [42.383633, 92.936713],
  [42.383548, 92.936773],
  [42.383548, 92.936773],
  [42.383433, 92.936816],
  [42.383363, 92.936833],
  [42.383286, 92.936855],
  [42.383223, 92.936881],
  [42.383151, 92.936923],
  [42.383086, 92.936958],
  [42.383023, 92.936986],
  [42.382963, 92.937018],
  [42.382901, 92.937041],
  [42.38278, 92.93711],
  [42.382728, 92.93715],
  [42.382688, 92.937183],
  [42.382645, 92.937228],
  [42.382611, 92.937295],
  [42.38259, 92.937366],
  [42.382573, 92.937445],
  [42.382555, 92.937511],
  [42.382541, 92.937588],
  [42.382495, 92.937691],
  [42.382495, 92.937691],
  [42.382421, 92.937793],
  [42.38238, 92.937836],
  [42.38238, 92.937836],
  [42.382298, 92.937896],
  [42.38224, 92.937915],
  [42.382181, 92.93796],
  [42.38204, 92.938011],
  [42.38204, 92.938011],
  [42.38198, 92.938063],
  [42.381856, 92.938123],
  [42.381805, 92.938171],
  [42.381745, 92.938223],
  [42.381686, 92.938275],
  [42.38163, 92.938308],
  [42.381571, 92.938351],
  [42.38151, 92.938388],
  [42.381453, 92.938438],
  [42.381343, 92.93853],
  [42.381343, 92.93853],
  [42.38129, 92.938583],
  [42.381208, 92.938673],
  [42.381175, 92.938715],
  [42.381136, 92.93876],
  [42.3811, 92.938813],
  [42.381058, 92.93886],
  [42.381015, 92.938893],
  [42.380966, 92.938926],
  [42.380923, 92.938956],
  [42.38088, 92.939],
  [42.38079, 92.939091],
  [42.38079, 92.939091],
  [42.380696, 92.939175],
  [42.380641, 92.93921],
  [42.380578, 92.939243],
  [42.380508, 92.939281],
  [42.380438, 92.939313],
  [42.38038, 92.939353],
  [42.380255, 92.939418],
  [42.380255, 92.939418],
  [42.380188, 92.939443],
  [42.380073, 92.939528],
  [42.380038, 92.939556],
  [42.380006, 92.939608],
  [42.379971, 92.939671],
  [42.37995, 92.939761],
  [42.379933, 92.939853],
  [42.379908, 92.939955],
  [42.379886, 92.940061],
  [42.379841, 92.940261],
  [42.379808, 92.940368],
  [42.379808, 92.940368],
  [42.379768, 92.940525],
  [42.379745, 92.940601],
  [42.379731, 92.940668],
  [42.379726, 92.940746],
  [42.379716, 92.940823],
  [42.379705, 92.9409],
  [42.379691, 92.941078],
  [42.379691, 92.941078],
  [42.37968, 92.94128],
  [42.379668, 92.941381],
  [42.379663, 92.941486],
  [42.379653, 92.941588],
  [42.379646, 92.94169],
  [42.379641, 92.941801],
  [42.379628, 92.941916],
  [42.379623, 92.942041],
  [42.379618, 92.942146],
  [42.379596, 92.942368],
  [42.37959, 92.942478],
  [42.37959, 92.942478],
  [42.37958, 92.94266],
  [42.379576, 92.942753],
  [42.379571, 92.942833],
  [42.379566, 92.942908],
  [42.379556, 92.942986],
  [42.37955, 92.943076],
  [42.379536, 92.943278],
  [42.379536, 92.943278],
  [42.37952, 92.943488],
  [42.379518, 92.94359],
  [42.379518, 92.94359],
  [42.379483, 92.943811],
  [42.379465, 92.943923],
  [42.379456, 92.944031],
  [42.379446, 92.94414],
  [42.379435, 92.94424],
  [42.379415, 92.944333],
  [42.379391, 92.944535],
  [42.379391, 92.944535],
  [42.379361, 92.944735],
  [42.379341, 92.944823],
  [42.379325, 92.944906],
  [42.379318, 92.944991],
  [42.3793, 92.945066],
  [42.379295, 92.945156],
  [42.379285, 92.945248],
  [42.379268, 92.945338],
  [42.379255, 92.945438],
  [42.37925, 92.945645],
  [42.379246, 92.945733],
  [42.379246, 92.945733],
  [42.379203, 92.94591],
  [42.379168, 92.94598],
  [42.379133, 92.946055],
  [42.3791, 92.946123],
  [42.379058, 92.946196],
  [42.37901, 92.946266],
  [42.378888, 92.946363],
  [42.378888, 92.946363],
  [42.37878, 92.94644],
  [42.378723, 92.946468],
  [42.378665, 92.9465],
  [42.3786, 92.946521],
  [42.378538, 92.946541],
  [42.378478, 92.946576],
  [42.37836, 92.946656],
  [42.37836, 92.946656],
  [42.3783, 92.946701],
  [42.378185, 92.946778],
  [42.378126, 92.946825],
  [42.378068, 92.946876],
  [42.378018, 92.946918],
  [42.377966, 92.946961],
  [42.377905, 92.946998],
  [42.377856, 92.94703],
  [42.377805, 92.947063],
  [42.377758, 92.947103],
  [42.377661, 92.947188],
  [42.377661, 92.947188],
  [42.377555, 92.94726],
  [42.377495, 92.947298],
  [42.37745, 92.947331],
  [42.377403, 92.947366],
  [42.377351, 92.947401],
  [42.377308, 92.94743],
  [42.37721, 92.947496],
  [42.37721, 92.947496],
  [42.37717, 92.947531],
  [42.377065, 92.947611],
  [42.377011, 92.947655],
  [42.376956, 92.947698],
  [42.376913, 92.947736],
  [42.376861, 92.947786],
  [42.37681, 92.94783],
  [42.376755, 92.94786],
  [42.3767, 92.947896],
  [42.37658, 92.947963],
  [42.376521, 92.947993],
  [42.376521, 92.947993],
  [42.376401, 92.948071],
  [42.37633, 92.94811],
  [42.376263, 92.948148],
  [42.3762, 92.948181],
  [42.37614, 92.94822],
  [42.376081, 92.94826],
  [42.376016, 92.948291],
  [42.37595, 92.948328],
  [42.375885, 92.948363],
  [42.375761, 92.948431],
  [42.375761, 92.948431],
  [42.375626, 92.948515],
  [42.375551, 92.948568],
  [42.37547, 92.948615],
  [42.375385, 92.948655],
  [42.375301, 92.948693],
  [42.375225, 92.948745],
  [42.375076, 92.948865],
  [42.375076, 92.948865],
  [42.375001, 92.948906],
  [42.374858, 92.949023],
  [42.374773, 92.94908],
  [42.37469, 92.949133],
  [42.374605, 92.949188],
  [42.374523, 92.949236],
  [42.374448, 92.949285],
  [42.37436, 92.949335],
  [42.374276, 92.94937],
  [42.374108, 92.94948],
  [42.37403, 92.949526],
  [42.37403, 92.949526],
  [42.373888, 92.949606],
  [42.37383, 92.949636],
  [42.373753, 92.949678],
  [42.373683, 92.949708],
  [42.373621, 92.949738],
  [42.373561, 92.94977],
  [42.373416, 92.949875],
  [42.373416, 92.949875],
  [42.373281, 92.94997],
  [42.373218, 92.950015],
  [42.373141, 92.95006],
  [42.373065, 92.950095],
  [42.372988, 92.950135],
  [42.372916, 92.950173],
  [42.372845, 92.950215],
  [42.372775, 92.950256],
  [42.372708, 92.950291],
  [42.372575, 92.950375],
  [42.372506, 92.950436],
  [42.372506, 92.950436],
  [42.372376, 92.95053],
  [42.372303, 92.950565],
  [42.372233, 92.950608],
  [42.372165, 92.950651],
  [42.372098, 92.950698],
  [42.37204, 92.950736],
  [42.371933, 92.95083],
  [42.371933, 92.95083],
  [42.371828, 92.950921],
  [42.37177, 92.950985],
  [42.371723, 92.951046],
  [42.371668, 92.95112],
  [42.37161, 92.951195],
  [42.371555, 92.951268],
  [42.371505, 92.951373],
  [42.371468, 92.951488],
  [42.371438, 92.951601],
  [42.371458, 92.95183],
  [42.371458, 92.95183],
  [42.371523, 92.952045],
  [42.371561, 92.952131],
  [42.371598, 92.952225],
  [42.371618, 92.952305],
  [42.37165, 92.95239],
  [42.371676, 92.952485],
  [42.371701, 92.952566],
  [42.37173, 92.952648],
  [42.371753, 92.952713],
  [42.371793, 92.952828],
  [42.37181, 92.952883],
  [42.37181, 92.952883],
  [42.371846, 92.952996],
  [42.37186, 92.95306],
  [42.371873, 92.95312],
  [42.37188, 92.953178],
  [42.371891, 92.953233],
  [42.371906, 92.953293],
  [42.371933, 92.953413],
  [42.371933, 92.953413],
  [42.371951, 92.953511],
  [42.371965, 92.953555],
  [42.371976, 92.953596],
  [42.371986, 92.95364],
  [42.371995, 92.953681],
  [42.372006, 92.953726],
  [42.372028, 92.953818],
  [42.372028, 92.953818],
  [42.372038, 92.953858],
  [42.37206, 92.953961],
  [42.372068, 92.954011],
  [42.372075, 92.954061],
  [42.372076, 92.954108],
  [42.372068, 92.954163],
  [42.372066, 92.954213],
  [42.372065, 92.954261],
  [42.372071, 92.954311],
  [42.372078, 92.954361],
  [42.372085, 92.954463],
  [42.372085, 92.954463],
  [42.372081, 92.954561],
  [42.372081, 92.954621],
  [42.372081, 92.954686],
  [42.372078, 92.954753],
  [42.37207, 92.954818],
  [42.372066, 92.954885],
  [42.372063, 92.955001],
  [42.372063, 92.955001],
  [42.372066, 92.955058],
  [42.372063, 92.955221],
  [42.37207, 92.9553],
  [42.372078, 92.955375],
  [42.372086, 92.95545],
  [42.372091, 92.955531],
  [42.372091, 92.955605],
  [42.372091, 92.955681],
  [42.372091, 92.955681],
  [42.372113, 92.955916],
  [42.372113, 92.955916],
  [42.372118, 92.955986],
  [42.37213, 92.956131],
  [42.372135, 92.956205],
  [42.372143, 92.956275],
  [42.372148, 92.95635],
  [42.372153, 92.956423],
  [42.37216, 92.956498],
  [42.372165, 92.956571],
  [42.372166, 92.956636],
  [42.372171, 92.956706],
  [42.372185, 92.95684],
  [42.372185, 92.95684],
  [42.372198, 92.956963],
  [42.372203, 92.957025],
  [42.37221, 92.957085],
  [42.372215, 92.957145],
  [42.372218, 92.957206],
  [42.372225, 92.957265],
  [42.372231, 92.95739],
  [42.372231, 92.95739],
  [42.372235, 92.957456],
  [42.372246, 92.957583],
  [42.372248, 92.95765],
  [42.372248, 92.957718],
  [42.372251, 92.95778],
  [42.372256, 92.957836],
  [42.372261, 92.957893],
  [42.372268, 92.957945],
  [42.372275, 92.957991],
  [42.372281, 92.958108],
  [42.372286, 92.958171],
  [42.372286, 92.958171],
  [42.372286, 92.958285],
  [42.372283, 92.958341],
  [42.372285, 92.9584],
  [42.372291, 92.958465],
  [42.372296, 92.95853],
  [42.372303, 92.958596],
  [42.372313, 92.958741],
  [42.372313, 92.958741],
  [42.372306, 92.958866],
  [42.372303, 92.958918],
  [42.372298, 92.958981],
  [42.372305, 92.95904],
  [42.372296, 92.9591],
  [42.372286, 92.959166],
  [42.372278, 92.959236],
  [42.37227, 92.959308],
  [42.372256, 92.959373],
  [42.372261, 92.959513],
  [42.372266, 92.959586],
  [42.372266, 92.959586],
  [42.372275, 92.959733],
  [42.37228, 92.95979],
  [42.372286, 92.959855],
  [42.372288, 92.959916],
  [42.372293, 92.959975],
  [42.372305, 92.960045],
  [42.372321, 92.96012],
  [42.372335, 92.960183],
  [42.37236, 92.960321],
  [42.372376, 92.960388],
  [42.372376, 92.960388],
  [42.372406, 92.96053],
  [42.372421, 92.960598],
  [42.372436, 92.960661],
  [42.372451, 92.960733],
  [42.372471, 92.960805],
  [42.372491, 92.960875],
  [42.372498, 92.961006],
  [42.372498, 92.961006],
  [42.372525, 92.961151],
  [42.372546, 92.961218],
  [42.372571, 92.961288],
  [42.372585, 92.96136],
  [42.372595, 92.961428],
  [42.372601, 92.9615],
  [42.372615, 92.961568],
  [42.372631, 92.961626],
  [42.372646, 92.961688],
  [42.372686, 92.961818],
  [42.372693, 92.961873],
  [42.372698, 92.961933],
  [42.372706, 92.961988],
  [42.372716, 92.962053],
  [42.372733, 92.962113],
  [42.372748, 92.962166],
  [42.372756, 92.962221],
  [42.372773, 92.962271],
  [42.372788, 92.962388],
  [42.372788, 92.962388],
  [42.372806, 92.962501],
  [42.372815, 92.962558],
  [42.372828, 92.962616],
  [42.37284, 92.962675],
  [42.372853, 92.962735],
  [42.372873, 92.962788],
  [42.3729, 92.962891],
  [42.3729, 92.962891],
  [42.37291, 92.962941],
  [42.372931, 92.963053],
  [42.372936, 92.963098],
  [42.372945, 92.963146],
  [42.372951, 92.963195],
  [42.372961, 92.963245],
  [42.37297, 92.963298],
  [42.372973, 92.963358],
  [42.372976, 92.963403],
  [42.372991, 92.963448],
  [42.373006, 92.963555],
  [42.373006, 92.963555],
  [42.373031, 92.963646],
  [42.373041, 92.963703],
  [42.37305, 92.963753],
  [42.373058, 92.963793],
  [42.373063, 92.963841],
  [42.373068, 92.9639],
  [42.373053, 92.964013],
  [42.373053, 92.964013],
  [42.37305, 92.964061],
  [42.373016, 92.964171],
  [42.373016, 92.964171],
  [42.372978, 92.964245],
  [42.372951, 92.964265],
  [42.372925, 92.964283],
  [42.372898, 92.964315],
  [42.372878, 92.964356],
  [42.372853, 92.964395],
  [42.372803, 92.964473],
  [42.372803, 92.964473],
  [42.372773, 92.96452],
  [42.372726, 92.964603],
  [42.372698, 92.964645],
  [42.372676, 92.964683],
  [42.372648, 92.964723],
  [42.37262, 92.964761],
  [42.372596, 92.9648],
  [42.37258, 92.964848],
  [42.372553, 92.964893],
  [42.372503, 92.964995],
  [42.372473, 92.965053],
  [42.372473, 92.965053],
  [42.37241, 92.965161],
  [42.372376, 92.96522],
  [42.37235, 92.965278],
  [42.372323, 92.965325],
  [42.372298, 92.965383],
  [42.372266, 92.965445],
  [42.372223, 92.965536],
  [42.372223, 92.965536],
  [42.372201, 92.965578],
  [42.372145, 92.965661],
  [42.372125, 92.965711],
  [42.372103, 92.965761],
  [42.372076, 92.96581],
  [42.37205, 92.965865],
  [42.372025, 92.965916],
  [42.371998, 92.965965],
  [42.371976, 92.96601],
  [42.371928, 92.966106],
  [42.371908, 92.966156],
  [42.371908, 92.966156],
  [42.371856, 92.966246],
  [42.371828, 92.966295],
  [42.371798, 92.966346],
  [42.371771, 92.966393],
  [42.371751, 92.966445],
  [42.371728, 92.966491],
  [42.371686, 92.966576],
  [42.371686, 92.966576],
  [42.371635, 92.966673],
  [42.371608, 92.966718],
  [42.371585, 92.966768],
  [42.371561, 92.966813],
  [42.371538, 92.966855],
  [42.371513, 92.966905],
  [42.371483, 92.966948],
  [42.371451, 92.966995],
  [42.371415, 92.967046],
  [42.371345, 92.967135],
  [42.37132, 92.967178],
  [42.37132, 92.967178],
  [42.371281, 92.967281],
  [42.371253, 92.967328],
  [42.371231, 92.967376],
  [42.371226, 92.96742],
  [42.371205, 92.967471],
  [42.37118, 92.96752],
  [42.371156, 92.967561],
  [42.371131, 92.967598],
  [42.371085, 92.96768],
  [42.371058, 92.967716],
  [42.371058, 92.967716],
  [42.371018, 92.967801],
  [42.371, 92.967843],
  [42.370985, 92.967878],
  [42.37096, 92.967923],
  [42.370931, 92.967965],
  [42.370911, 92.968018],
  [42.370878, 92.968098],
  [42.370878, 92.968098],
  [42.370878, 92.968195],
  [42.370875, 92.968238],
  [42.37087, 92.968288],
  [42.370866, 92.96832],
  [42.370853, 92.968356],
  [42.37085, 92.96839],
  [42.370856, 92.968443],
  [42.370868, 92.96851],
  [42.370876, 92.968568],
  [42.370908, 92.968676],
  [42.370936, 92.968711],
  [42.370936, 92.968711],
  [42.37096, 92.968765],
  [42.370995, 92.968776],
  [42.371056, 92.968731],
  [42.371056, 92.968731],
  [42.371056, 92.968731],
  [42.37112, 92.968678],
  [42.371133, 92.968628],
  [42.371133, 92.968531],
  [42.371126, 92.968501],
  [42.371118, 92.968495],
  [42.371118, 92.968495],
  [42.371118, 92.968495],
  [42.371103, 92.968526],
  [42.371088, 92.968543],
  [42.371088, 92.968543],
  [42.3711, 92.968583],
  [42.371101, 92.968586],
  [42.371106, 92.96859],
  [42.371121, 92.96861],
  [42.371125, 92.968626],
  [42.371133, 92.968655],
  [42.371135, 92.968673],
  [42.371141, 92.96869],
  [42.371153, 92.968701],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.37117, 92.96871],
  [42.371121, 92.96863],
  [42.371115, 92.96861],
  [42.371108, 92.968593],
  [42.371105, 92.968585],
  [42.371105, 92.968583],
  [42.371093, 92.968568],
  [42.37106, 92.968531],
  [42.37106, 92.968531],
  [42.371048, 92.968503],
  [42.371026, 92.968433],
  [42.371028, 92.968403],
  [42.371033, 92.968355],
  [42.371043, 92.968298],
  [42.371063, 92.968241],
  [42.371085, 92.968188],
  [42.371108, 92.96813],
  [42.371126, 92.968053],
  [42.371146, 92.967981],
  [42.371216, 92.967838],
  [42.371216, 92.967838],
  [42.371281, 92.96767],
  [42.371318, 92.967576],
  [42.371358, 92.967485],
  [42.371396, 92.967406],
  [42.37144, 92.967336],
  [42.371476, 92.967251],
  [42.371581, 92.967073],
  [42.371581, 92.967073],
  [42.371631, 92.966981],
  [42.371728, 92.966793],
  [42.371758, 92.9667],
  [42.371808, 92.966608],
  [42.371848, 92.966515],
  [42.37189, 92.966433],
  [42.37193, 92.96635],
  [42.37197, 92.966271],
  [42.372015, 92.966193],
  [42.37206, 92.966103],
  [42.372125, 92.965938],
  [42.372125, 92.965938],
  [42.372203, 92.965765],
  [42.372245, 92.965671],
  [42.372298, 92.965578],
  [42.372341, 92.965481],
  [42.372385, 92.965385],
  [42.372431, 92.965286],
  [42.372516, 92.965086],
  [42.372516, 92.965086],
  [42.372608, 92.964905],
  [42.372653, 92.964828],
  [42.37271, 92.964768],
  [42.372761, 92.964713],
  [42.37281, 92.96467],
  [42.37286, 92.964618],
  [42.372893, 92.964585],
  [42.372935, 92.964546],
  [42.372968, 92.964505],
  [42.373028, 92.964398],
  [42.373058, 92.964346],
  [42.373058, 92.964346],
  [42.373106, 92.964216],
  [42.373118, 92.964136],
  [42.373133, 92.96406],
  [42.373133, 92.963973],
  [42.373121, 92.963891],
  [42.373103, 92.963815],
  [42.373083, 92.963611],
  [42.373083, 92.963611],
  [42.373053, 92.963415],
  [42.373035, 92.963326],
  [42.373035, 92.963326],
  [42.373003, 92.963125],
  [42.372985, 92.963036],
  [42.372953, 92.962926],
  [42.372933, 92.96282],
  [42.37292, 92.962711],
  [42.372901, 92.9626],
  [42.372861, 92.962386],
  [42.372861, 92.962386],
  [42.372833, 92.962171],
  [42.372813, 92.962061],
  [42.372793, 92.961953],
  [42.372768, 92.961838],
  [42.372743, 92.961725],
  [42.37272, 92.961623],
  [42.372693, 92.961513],
  [42.37267, 92.961411],
  [42.37265, 92.9613],
  [42.37262, 92.961091],
  [42.372606, 92.960996],
  [42.372606, 92.960996],
  [42.372565, 92.960761],
  [42.372536, 92.960628],
  [42.372511, 92.96051],
  [42.372486, 92.960395],
  [42.372463, 92.96028],
  [42.372436, 92.96016],
  [42.372383, 92.959926],
  [42.372383, 92.959926],
  [42.372343, 92.959711],
  [42.37233, 92.959586],
  [42.372336, 92.959468],
  [42.372363, 92.959368],
  [42.372391, 92.95928],
  [42.372411, 92.959186],
  [42.372426, 92.9591],
  [42.372438, 92.959015],
  [42.37244, 92.95893],
  [42.372441, 92.95875],
  [42.37243, 92.958663],
  [42.372426, 92.958571],
  [42.372413, 92.958471],
  [42.3724, 92.95836],
  [42.372393, 92.958271],
  [42.37239, 92.958163],
  [42.372383, 92.958055],
  [42.372381, 92.957943],
  [42.372375, 92.957706],
  [42.372375, 92.957706],
  [42.372353, 92.957455],
  [42.372336, 92.95734],
  [42.372323, 92.95723],
  [42.372316, 92.957123],
  [42.3723, 92.95703],
  [42.372296, 92.956933],
  [42.372288, 92.956726],
  [42.372288, 92.956726],
  [42.372271, 92.956628],
  [42.372268, 92.95641],
  [42.372263, 92.956306],
  [42.372255, 92.956205],
  [42.372246, 92.95611],
  [42.37224, 92.956008],
  [42.372235, 92.955901],
  [42.372228, 92.955798],
  [42.372211, 92.955693],
  [42.372195, 92.95549],
  [42.372173, 92.955373],
  [42.372173, 92.955373],
  [42.372153, 92.955141],
  [42.372141, 92.95504],
  [42.372131, 92.954948],
  [42.37213, 92.954846],
  [42.372135, 92.954761],
  [42.37215, 92.954655],
  [42.372168, 92.954568],
  [42.372191, 92.954488],
  [42.372208, 92.954398],
  [42.372218, 92.954221],
  [42.372218, 92.954221],
  [42.37221, 92.954053],
  [42.372198, 92.953958],
  [42.372198, 92.953958],
  [42.372165, 92.95376],
  [42.372143, 92.95367],
  [42.37211, 92.953565],
  [42.372046, 92.95335],
  [42.372046, 92.95335],
  [42.37202, 92.953253],
  [42.371973, 92.953036],
  [42.371946, 92.952931],
  [42.371925, 92.952851],
  [42.371903, 92.95277],
  [42.371875, 92.952675],
  [42.371848, 92.952588],
  [42.371811, 92.9525],
  [42.371776, 92.952403],
  [42.371696, 92.952191],
  [42.371651, 92.952086],
  [42.371651, 92.952086],
  [42.37158, 92.951853],
  [42.371563, 92.95174],
  [42.371573, 92.951616],
  [42.371601, 92.951493],
  [42.371635, 92.951386],
  [42.371676, 92.951283],
  [42.371801, 92.951106],
  [42.371801, 92.951106],
  [42.371878, 92.951028],
  [42.372045, 92.950908],
  [42.372118, 92.95085],
  [42.372206, 92.95079],
  [42.372295, 92.950736],
  [42.37239, 92.950683],
  [42.372481, 92.95064],
  [42.372565, 92.950591],
  [42.37265, 92.950541],
  [42.372816, 92.950441],
  [42.372903, 92.950395],
  [42.372903, 92.950395],
  [42.373076, 92.950296],
  [42.373163, 92.950243],
  [42.37325, 92.950188],
  [42.37333, 92.950133],
  [42.373415, 92.95008],
  [42.373511, 92.950026],
  [42.373693, 92.949911],
  [42.373693, 92.949911],
  [42.37387, 92.949798],
  [42.373968, 92.949756],
  [42.373968, 92.949756],
  [42.374145, 92.949671],
  [42.374233, 92.949625],
  [42.374316, 92.949573],
  [42.374403, 92.94952],
  [42.374478, 92.949458],
  [42.374566, 92.949405],
  [42.374731, 92.949303],
  [42.374731, 92.949303],
  [42.374888, 92.949203],
  [42.374968, 92.94915],
  [42.375056, 92.9491],
  [42.375143, 92.949045],
  [42.375226, 92.948988],
  [42.375226, 92.948988],
  [42.375393, 92.948875],
  [42.375488, 92.948821],
  [42.375583, 92.948771],
  [42.375755, 92.948675],
  [42.375836, 92.948616],
  [42.375836, 92.948616],
  [42.376023, 92.948516],
  [42.376103, 92.948458],
  [42.376185, 92.948403],
  [42.376278, 92.948355],
  [42.376368, 92.948301],
  [42.376451, 92.948255],
  [42.376605, 92.948148],
  [42.376605, 92.948148],
  [42.376756, 92.948038],
  [42.37684, 92.947981],
  [42.376916, 92.947928],
  [42.376998, 92.947878],
  [42.37708, 92.94783],
  [42.37717, 92.947778],
  [42.377263, 92.947713],
  [42.377348, 92.947658],
  [42.37743, 92.947596],
  [42.3776, 92.947495],
  [42.377683, 92.947453],
  [42.377766, 92.947401],
  [42.377851, 92.947348],
  [42.37794, 92.947288],
  [42.378025, 92.947223],
  [42.37811, 92.947156],
  [42.378198, 92.947088],
  [42.378275, 92.947028],
  [42.378416, 92.94692],
  [42.378416, 92.94692],
  [42.378578, 92.946815],
  [42.378651, 92.946756],
  [42.378733, 92.94671],
  [42.378805, 92.946655],
  [42.378878, 92.946603],
  [42.37895, 92.94656],
  [42.379085, 92.946435],
  [42.379085, 92.946435],
  [42.37913, 92.946346],
  [42.379193, 92.946121],
  [42.379193, 92.946121],
  [42.379231, 92.945881],
  [42.379251, 92.945763],
  [42.379276, 92.945648],
  [42.379291, 92.945535],
  [42.379306, 92.945425],
  [42.379321, 92.945313],
  [42.379348, 92.945081],
  [42.379348, 92.945081],
  [42.379356, 92.94497],
  [42.379386, 92.944748],
  [42.379403, 92.94464],
  [42.379415, 92.944523],
  [42.379435, 92.944403],
  [42.37946, 92.94429],
  [42.379471, 92.944166],
  [42.379488, 92.944045],
  [42.379503, 92.943915],
  [42.379503, 92.943778],
  [42.37951, 92.943521],
  [42.37951, 92.943521],
  [42.379526, 92.94328],
  [42.37954, 92.943176],
  [42.379561, 92.943076],
  [42.379585, 92.942986],
  [42.379591, 92.942878],
  [42.379598, 92.942763],
  [42.37962, 92.942556],
  [42.37962, 92.942556],
  [42.379621, 92.942465],
  [42.379638, 92.942273],
  [42.379656, 92.942166],
  [42.379666, 92.942055],
  [42.379675, 92.941946],
  [42.379683, 92.941835],
  [42.379693, 92.941715],
  [42.379698, 92.941596],
  [42.379696, 92.941461],
  [42.379698, 92.941215],
  [42.379698, 92.941091],
  [42.379698, 92.941091],
  [42.379706, 92.940868],
  [42.37971, 92.940763],
  [42.379716, 92.940653],
  [42.379728, 92.940548],
  [42.379746, 92.940458],
  [42.379771, 92.940353],
  [42.379826, 92.940168],
  [42.379826, 92.940168],
  [42.37989, 92.939961],
  [42.379923, 92.939883],
  [42.379953, 92.939798],
  [42.379988, 92.93971],
  [42.380038, 92.939655],
  [42.380098, 92.939601],
  [42.380156, 92.93955],
  [42.380238, 92.939516],
  [42.38032, 92.939488],
  [42.38045, 92.9394],
  [42.380513, 92.93936],
  [42.380513, 92.93936],
  [42.380645, 92.939271],
  [42.380705, 92.93923],
  [42.380756, 92.939185],
  [42.380825, 92.939143],
  [42.380878, 92.939111],
  [42.380925, 92.939071],
  [42.38097, 92.939033],
  [42.381028, 92.938981],
  [42.381141, 92.938865],
  [42.381206, 92.938818],
  [42.381206, 92.938818],
  [42.38131, 92.938693],
  [42.38136, 92.938631],
  [42.381418, 92.93857],
  [42.381475, 92.938501],
  [42.381535, 92.938438],
  [42.381583, 92.938373],
  [42.381721, 92.93827],
  [42.381721, 92.93827],
  [42.381878, 92.93819],
  [42.381945, 92.938145],
  [42.382006, 92.938095],
  [42.382073, 92.93806],
  [42.382143, 92.938025],
  [42.382208, 92.93799],
  [42.382271, 92.937953],
  [42.382338, 92.937903],
  [42.3824, 92.937845],
  [42.3825, 92.937721],
  [42.382538, 92.937655],
  [42.382538, 92.937655],
  [42.382598, 92.937506],
  [42.382626, 92.93743],
  [42.382651, 92.937343],
  [42.382676, 92.93725],
  [42.382676, 92.93725],
  [42.382766, 92.93711],
  [42.382898, 92.937051],
  [42.38297, 92.937011],
  [42.383031, 92.936983],
  [42.383098, 92.93695],
  [42.383171, 92.936913],
  [42.38325, 92.936878],
  [42.383325, 92.936838],
  [42.383408, 92.936793],
  [42.383486, 92.936751],
  [42.383621, 92.936693],
  [42.383621, 92.936693],
  [42.383751, 92.936628],
  [42.383788, 92.93659],
  [42.383788, 92.93659],
  [42.383815, 92.936548],
  [42.383835, 92.936485],
  [42.383823, 92.936356],
  [42.383823, 92.936356],
  [42.383766, 92.936251],
  [42.383723, 92.93622],
  [42.383671, 92.936178],
  [42.38361, 92.936165],
  [42.383548, 92.936163],
  [42.383496, 92.936161],
  [42.383386, 92.936186],
  [42.383331, 92.936215],
  [42.383331, 92.936215],
  [42.383233, 92.936268],
  [42.383185, 92.936296],
  [42.383133, 92.936336],
  [42.383073, 92.936365],
  [42.383015, 92.936396],
  [42.383015, 92.936396],
  [42.382831, 92.936491],
  [42.382831, 92.936491],
  [42.382778, 92.93652],
  [42.382635, 92.936571],
  [42.38257, 92.936603],
  [42.382511, 92.936628],
  [42.382448, 92.936656],
  [42.38239, 92.936686],
  [42.382331, 92.936716],
  [42.382275, 92.936741],
  [42.38222, 92.93676],
  [42.382135, 92.936828],
  [42.382135, 92.936828],
  [42.382086, 92.936858],
  [42.382001, 92.93692],
  [42.381953, 92.936953],
  [42.381905, 92.93697],
  [42.381851, 92.936995],
  [42.381808, 92.937016],
  [42.381763, 92.937045],
  [42.381715, 92.937065],
  [42.381656, 92.937081],
  [42.38154, 92.937113],
  [42.38148, 92.937135],
  [42.38148, 92.937135],
  [42.381371, 92.93717],
  [42.381325, 92.937183],
  [42.381278, 92.9372],
  [42.381251, 92.937195],
  [42.381215, 92.937208],
  [42.381175, 92.9372],
  [42.381091, 92.937196],
  [42.381091, 92.937196],
  [42.381053, 92.937186],
  [42.380966, 92.937173],
  [42.38092, 92.937165],
  [42.380876, 92.93715],
  [42.38083, 92.93715],
  [42.380786, 92.937136],
  [42.380756, 92.937113],
  [42.380725, 92.937103],
  [42.38069, 92.937075],
  [42.380628, 92.93705],
  [42.380606, 92.937045],
  [42.380606, 92.937045],
  [42.380561, 92.93704],
  [42.38054, 92.937045],
  [42.380515, 92.937045],
  [42.380491, 92.937041],
  [42.380465, 92.93703],
  [42.380448, 92.937018],
  [42.3804, 92.93701],
  [42.3804, 92.93701],
  [42.380371, 92.936995],
  [42.380356, 92.936986],
  [42.380348, 92.936986],
  [42.380333, 92.936988],
  [42.380321, 92.936985],
  [42.380306, 92.936981],
  [42.380291, 92.936966],
  [42.380288, 92.936956],
  [42.380281, 92.936943],
  [42.38024, 92.93693],
  [42.380225, 92.936925],
  [42.380218, 92.936918],
  [42.380198, 92.936903],
  [42.380178, 92.936891],
  [42.38016, 92.936885],
  [42.380131, 92.936868],
  [42.380101, 92.936858],
  [42.380078, 92.936851],
  [42.380036, 92.936835],
  [42.380036, 92.936835],
  [42.380003, 92.936848],
  [42.380003, 92.936848],
  [42.37997, 92.936868],
  [42.37995, 92.936873],
  [42.379933, 92.936903],
  [42.379933, 92.936903],
  [42.379921, 92.93693],
  [42.379915, 92.936953],
  [42.37991, 92.936985],
  [42.379918, 92.937021],
  [42.379965, 92.93691],
  [42.379965, 92.93691],
  [42.379975, 92.936876],
  [42.379978, 92.936865],
  [42.379998, 92.936851],
  [42.380008, 92.936836],
  [42.380011, 92.936838],
  [42.380016, 92.936835],
  [42.380013, 92.936825],
  [42.380013, 92.936825],
  [42.38001, 92.936813],
  [42.380006, 92.936796],
  [42.379998, 92.936786],
  [42.379996, 92.936778],
  [42.379993, 92.936776],
  [42.37999, 92.936778],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379991, 92.936773],
  [42.379993, 92.93681],
  [42.379995, 92.936845],
  [42.380003, 92.936853],
  [42.380013, 92.936863],
  [42.380013, 92.936863],
  [42.380031, 92.936858],
  [42.380068, 92.93688],
  [42.38008, 92.936895],
  [42.380101, 92.936921],
  [42.380111, 92.936926],
  [42.380126, 92.936938],
  [42.380141, 92.936958],
  [42.380153, 92.936965],
  [42.380166, 92.936971],
  [42.380166, 92.936971],
  [42.380198, 92.936996],
  [42.380205, 92.937008],
  [42.380216, 92.937026],
  [42.380231, 92.937038],
  [42.380245, 92.937055],
  [42.380258, 92.937065],
  [42.380295, 92.93709],
  [42.380295, 92.93709],
  [42.380311, 92.937111],
  [42.380346, 92.937138],
  [42.380375, 92.937153],
  [42.380403, 92.937161],
  [42.380418, 92.937181],
  [42.38044, 92.93719],
  [42.380468, 92.937193],
  [42.380496, 92.937185],
  [42.380516, 92.937178],
  [42.380543, 92.937185],
  [42.380558, 92.93719],
  [42.380558, 92.93719],
  [42.380535, 92.937193],
  [42.380535, 92.937193],
  [42.380535, 92.937193],
  [42.380535, 92.937193],
  [42.380535, 92.937193],
  [42.380673, 92.937143],
  [42.3807, 92.937135],
  [42.380761, 92.93715],
  [42.380761, 92.93715],
  [42.380836, 92.937188],
  [42.38088, 92.937205],
  [42.380923, 92.937213],
  [42.380961, 92.937216],
  [42.380995, 92.937218],
  [42.381025, 92.93722],
  [42.381106, 92.937211],
  [42.381106, 92.937211],
  [42.381153, 92.937211],
  [42.381235, 92.937211],
  [42.381275, 92.9372],
  [42.381316, 92.937176],
  [42.381346, 92.937168],
  [42.381375, 92.937153],
  [42.3814, 92.937141],
  [42.381431, 92.93712],
  [42.381473, 92.937106],
  [42.381566, 92.937056],
  [42.381616, 92.937036],
  [42.381616, 92.937036],
  [42.381711, 92.937001],
  [42.38176, 92.936988],
  [42.381805, 92.936976],
  [42.38186, 92.936963],
  [42.381916, 92.936945],
  [42.381966, 92.936935],
  [42.38201, 92.936916],
  [42.38206, 92.936901],
  [42.382108, 92.936881],
  [42.382191, 92.936828],
  [42.382191, 92.936828],
  [42.382286, 92.936768],
  [42.38234, 92.936745],
  [42.3824, 92.936733],
  [42.382463, 92.936735],
  [42.382508, 92.936726],
  [42.382558, 92.936723],
  [42.38266, 92.936701],
  [42.38266, 92.936701],
  [42.382721, 92.93668],
  [42.382835, 92.936621],
  [42.382891, 92.936583],
  [42.382946, 92.936543],
  [42.383003, 92.9365],
  [42.383055, 92.936453],
  [42.383115, 92.936415],
  [42.383173, 92.936385],
  [42.383236, 92.936358],
  [42.38334, 92.936301],
  [42.383395, 92.93628],
  [42.383395, 92.93628],
  [42.383511, 92.936251],
  [42.383561, 92.936233],
  [42.383618, 92.936225],
  [42.383666, 92.936248],
  [42.383718, 92.93627],
  [42.383763, 92.936288],
  [42.38382, 92.936346],
  [42.38382, 92.936346],
  [42.38382, 92.936346],
  [42.383848, 92.936453],
  [42.383843, 92.936518],
  [42.383823, 92.936568],
  [42.383796, 92.936618],
  [42.383718, 92.936675],
  [42.383668, 92.936711],
  [42.383618, 92.93674],
  [42.383565, 92.93677],
  [42.383503, 92.9368],
  [42.383448, 92.936836],
  [42.383391, 92.93687],
  [42.38333, 92.936913],
  [42.383265, 92.93695],
  [42.383131, 92.937011],
  [42.383068, 92.93703],
  [42.38301, 92.937055],
  [42.382951, 92.937078],
  [42.382893, 92.937106],
  [42.382836, 92.937131],
  [42.382791, 92.937161],
  [42.382748, 92.937198],
  [42.382711, 92.93725],
  [42.382625, 92.937345],
  [42.382625, 92.937345],
  [42.382573, 92.937476],
  [42.382545, 92.937546],
  [42.382525, 92.937621],
  [42.382496, 92.937673],
  [42.382468, 92.937726],
  [42.382426, 92.937766],
  [42.38234, 92.937848],
  [42.38234, 92.937848],
  [42.382288, 92.937881],
  [42.38218, 92.93796],
  [42.382116, 92.937996],
  [42.382043, 92.938036],
  [42.381983, 92.938068],
  [42.381935, 92.938105],
  [42.381883, 92.938143],
  [42.381828, 92.93819],
  [42.381768, 92.938233],
  [42.381711, 92.93828],
  [42.381588, 92.938361],
  [42.381588, 92.938361],
  [42.381468, 92.938441],
  [42.381413, 92.938485],
  [42.381358, 92.938526],
  [42.381306, 92.938586],
  [42.381251, 92.938651],
  [42.381201, 92.938713],
  [42.381158, 92.938783],
  [42.381111, 92.938861],
  [42.381061, 92.938936],
  [42.380948, 92.939041],
  [42.380948, 92.939041],
  [42.380838, 92.939126],
  [42.38078, 92.939166],
  [42.380728, 92.939203],
  [42.380678, 92.939238],
  [42.380625, 92.939273],
  [42.380583, 92.939298],
  [42.380483, 92.939381],
  [42.380483, 92.939381],
  [42.380428, 92.939421],
  [42.38033, 92.939493],
  [42.380263, 92.939523],
  [42.380213, 92.939551],
  [42.38016, 92.939586],
  [42.380113, 92.939631],
  [42.380073, 92.939671],
  [42.380028, 92.939716],
  [42.379991, 92.939773],
  [42.379945, 92.939921],
  [42.37993, 92.93999],
  [42.37993, 92.93999],
  [42.379886, 92.940146],
  [42.379865, 92.940225],
  [42.379838, 92.940298],
  [42.379813, 92.940365],
  [42.379786, 92.940438],
  [42.379765, 92.940513],
  [42.379728, 92.940671],
  [42.379728, 92.940671],
  [42.379713, 92.940753],
  [42.379673, 92.940921],
  [42.379665, 92.941005],
  [42.379646, 92.941088],
  [42.379641, 92.941168],
  [42.379635, 92.941245],
  [42.379646, 92.941335],
  [42.379658, 92.941421],
  [42.37967, 92.941513],
  [42.379673, 92.941713],
  [42.379668, 92.941818],
  [42.379668, 92.941818],
  [42.37966, 92.942033],
  [42.37966, 92.94215],
  [42.379656, 92.942271],
  [42.379651, 92.94239],
  [42.379645, 92.942501],
  [42.379638, 92.942605],
  [42.379628, 92.942781],
  [42.379628, 92.942781],
  [42.379613, 92.942958],
  [42.379598, 92.943056],
  [42.379576, 92.943166],
  [42.379553, 92.943288],
  [42.379533, 92.943411],
  [42.37952, 92.943533],
  [42.379501, 92.943656],
  [42.37949, 92.943786],
  [42.379475, 92.943906],
  [42.379453, 92.944148],
  [42.379453, 92.944148],
  [42.379436, 92.944275],
  [42.37939, 92.944523],
  [42.37937, 92.944645],
  [42.379348, 92.944766],
  [42.379331, 92.944875],
  [42.379316, 92.944966],
  [42.379311, 92.94507],
  [42.379315, 92.94517],
  [42.379315, 92.945265],
  [42.37931, 92.945458],
  [42.379296, 92.945565],
  [42.379296, 92.945565],
  [42.379256, 92.945751],
  [42.379233, 92.945845],
  [42.379201, 92.945928],
  [42.379163, 92.946006],
  [42.379123, 92.94608],
  [42.379086, 92.946158],
  [42.37899, 92.94629],
  [42.37899, 92.94629],
  [42.378858, 92.946378],
  [42.378786, 92.946421],
  [42.378718, 92.94646],
  [42.378651, 92.946501],
  [42.37858, 92.946543],
  [42.378503, 92.946588],
  [42.378426, 92.946635],
  [42.378355, 92.946683],
  [42.378278, 92.946735],
  [42.378138, 92.94684],
  [42.378071, 92.9469],
  [42.378, 92.946958],
  [42.377928, 92.947013],
  [42.377865, 92.94707],
  [42.377798, 92.947121],
  [42.37773, 92.947173],
  [42.37766, 92.947225],
  [42.377588, 92.947275],
  [42.377441, 92.947378],
  [42.377441, 92.947378],
  [42.377303, 92.947476],
  [42.377238, 92.947536],
  [42.37716, 92.947591],
  [42.37709, 92.947641],
  [42.377016, 92.947695],
  [42.376945, 92.947746],
  [42.376803, 92.947856],
  [42.376803, 92.947856],
  [42.376733, 92.947901],
  [42.376593, 92.947995],
  [42.376518, 92.948038],
  [42.376451, 92.948081],
  [42.376381, 92.94813],
  [42.376303, 92.94817],
  [42.376225, 92.94821],
  [42.376143, 92.948255],
  [42.376056, 92.948305],
  [42.375973, 92.94835],
  [42.37589, 92.948391],
  [42.375806, 92.948443],
  [42.37565, 92.948536],
  [42.375561, 92.948576],
  [42.375491, 92.948625],
  [42.37541, 92.948678],
  [42.375336, 92.948723],
  [42.37526, 92.948766],
  [42.375181, 92.948825],
  [42.3751, 92.948871],
  [42.375015, 92.948923],
  [42.374851, 92.94903],
  [42.374851, 92.94903],
  [42.374686, 92.949138],
  [42.374608, 92.94919],
  [42.37453, 92.94923],
  [42.374438, 92.949275],
  [42.374338, 92.949316],
  [42.374248, 92.949366],
  [42.374085, 92.949478],
  [42.374085, 92.949478],
  [42.374, 92.949528],
  [42.373841, 92.949631],
  [42.373761, 92.949683],
  [42.373685, 92.949726],
  [42.373608, 92.949776],
  [42.373533, 92.949815],
  [42.373533, 92.949815],
  [42.37338, 92.949905],
  [42.3733, 92.949966],
  [42.373153, 92.950068],
  [42.373075, 92.95012],
  [42.373075, 92.95012],
  [42.372926, 92.950215],
  [42.372858, 92.950265],
  [42.372786, 92.950308],
  [42.372716, 92.95035],
  [42.37265, 92.950393],
  [42.372573, 92.950426],
  [42.372453, 92.95051],
  [42.372453, 92.95051],
  [42.372396, 92.950548],
  [42.372276, 92.950611],
  [42.372206, 92.950645],
  [42.37214, 92.950683],
  [42.372075, 92.950723],
  [42.37201, 92.950768],
  [42.371945, 92.950816],
  [42.371883, 92.95087],
  [42.371818, 92.950926],
  [42.371698, 92.951068],
  [42.371645, 92.951143],
  [42.371645, 92.951143],
  [42.37154, 92.951323],
  [42.371503, 92.951438],
  [42.37148, 92.951553],
  [42.371471, 92.951676],
  [42.371483, 92.951803],
  [42.37151, 92.951931],
  [42.37158, 92.95214],
  [42.37158, 92.95214],
  [42.371645, 92.952315],
  [42.371675, 92.952398],
  [42.371703, 92.952475],
  [42.371726, 92.95255],
  [42.371751, 92.952625],
  [42.371773, 92.952701],
  [42.371788, 92.952768],
  [42.371803, 92.952826],
  [42.371815, 92.952888],
  [42.371856, 92.953035],
  [42.371856, 92.953035],
  [42.37188, 92.953091],
  [42.371925, 92.953211],
  [42.37194, 92.953268],
  [42.371953, 92.95332],
  [42.371961, 92.95337],
  [42.371971, 92.953416],
  [42.371975, 92.953478],
  [42.371983, 92.953536],
  [42.372, 92.95359],
  [42.372026, 92.953703],
  [42.372041, 92.953763],
  [42.372041, 92.953763],
  [42.372063, 92.953878],
  [42.372075, 92.95393],
  [42.372085, 92.95399],
  [42.372093, 92.954046],
  [42.372108, 92.95411],
  [42.372115, 92.954155],
  [42.372118, 92.954268],
  [42.372118, 92.954268],
  [42.372113, 92.954388],
  [42.372108, 92.954446],
  [42.372101, 92.954506],
  [42.372091, 92.954575],
  [42.372095, 92.954651],
  [42.372096, 92.954708],
  [42.372088, 92.95477],
  [42.372083, 92.954831],
  [42.372076, 92.954891],
  [42.372068, 92.954985],
  [42.372073, 92.955053],
  [42.37207, 92.95513],
  [42.372086, 92.955215],
  [42.3721, 92.955298],
  [42.372106, 92.95539],
  [42.37211, 92.95548],
  [42.372121, 92.955575],
  [42.372133, 92.955661],
  [42.372148, 92.955821],
  [42.372148, 92.955821],
  [42.372161, 92.955968],
  [42.372175, 92.956045],
  [42.372181, 92.956123],
  [42.372186, 92.956205],
  [42.372185, 92.956271],
  [42.372186, 92.956338],
  [42.37219, 92.956475],
  [42.37219, 92.956475],
  [42.37219, 92.956543],
  [42.372198, 92.956668],
  [42.372205, 92.956736],
  [42.37221, 92.956805],
  [42.372218, 92.956873],
  [42.372226, 92.956946],
  [42.37223, 92.957013],
  [42.372233, 92.95707],
  [42.372235, 92.95713],
  [42.372236, 92.957188],
  [42.372238, 92.957258],
  [42.372238, 92.957328],
  [42.372243, 92.957466],
  [42.372248, 92.957528],
  [42.372253, 92.957586],
  [42.37226, 92.957658],
  [42.372261, 92.957716],
  [42.37227, 92.957776],
  [42.372278, 92.957825],
  [42.372285, 92.957878],
  [42.372293, 92.957941],
  [42.372303, 92.958055],
  [42.372303, 92.958055],
  [42.372311, 92.958153],
  [42.372311, 92.958206],
  [42.372313, 92.95826],
  [42.372316, 92.958303],
  [42.372318, 92.958345],
  [42.372318, 92.958396],
  [42.372313, 92.95851],
  [42.372313, 92.95851],
  [42.372313, 92.958575],
  [42.372311, 92.958706],
  [42.372313, 92.958775],
  [42.372316, 92.95884],
  [42.372316, 92.9589],
  [42.37231, 92.958961],
  [42.372298, 92.959021],
  [42.372288, 92.959081],
  [42.372278, 92.959143],
  [42.372258, 92.959278],
  [42.372251, 92.959341],
  [42.372251, 92.959341],
  [42.372258, 92.959486],
  [42.372263, 92.959563],
  [42.37227, 92.959631],
  [42.372271, 92.959703],
  [42.372283, 92.959783],
  [42.372293, 92.959855],
  [42.372335, 92.960003],
  [42.372335, 92.960003],
  [42.372348, 92.960066],
  [42.372378, 92.960208],
  [42.372385, 92.960278],
  [42.372395, 92.960348],
  [42.372411, 92.960413],
  [42.37242, 92.960478],
  [42.372433, 92.96055],
  [42.372445, 92.96061],
  [42.372463, 92.960676],
  [42.37249, 92.960806],
  [42.372506, 92.960876],
  [42.372506, 92.960876],
  [42.372526, 92.960991],
  [42.372538, 92.96106],
  [42.372545, 92.96113],
  [42.372556, 92.9612],
  [42.372573, 92.96128],
  [42.372591, 92.961356],
  [42.37261, 92.96143],
  [42.372613, 92.961486],
  [42.372635, 92.96161],
  [42.372643, 92.961673],
  [42.372643, 92.961673],
  [42.372678, 92.961801],
  [42.372688, 92.961861],
  [42.3727, 92.961923],
  [42.37271, 92.961983],
  [42.372725, 92.962041],
  [42.372736, 92.962098],
  [42.372775, 92.96222],
  [42.372775, 92.96222],
  [42.372796, 92.962335],
  [42.372806, 92.962395],
  [42.372806, 92.962451],
  [42.372818, 92.962513],
  [42.37282, 92.962576],
  [42.372826, 92.962635],
  [42.372836, 92.962693],
  [42.372848, 92.96276],
  [42.372863, 92.962818],
  [42.372888, 92.962926],
  [42.372905, 92.962978],
  [42.372905, 92.962978],
  [42.372926, 92.963105],
  [42.372945, 92.96317],
  [42.37296, 92.963235],
  [42.37297, 92.963291],
  [42.372975, 92.963348],
  [42.372986, 92.963411],
  [42.37301, 92.963538],
  [42.37301, 92.963538],
  [42.373015, 92.963666],
  [42.373021, 92.963725],
  [42.373025, 92.963795],
  [42.373031, 92.963855],
  [42.373025, 92.963915],
  [42.373018, 92.96398],
  [42.373006, 92.964028],
  [42.372988, 92.964076],
  [42.372961, 92.964118],
  [42.372923, 92.9642],
  [42.37291, 92.964235],
  [42.372885, 92.964265],
  [42.372855, 92.964288],
  [42.372828, 92.96432],
  [42.3728, 92.964378],
  [42.372786, 92.964426],
  [42.372773, 92.964475],
  [42.372748, 92.964521],
  [42.372676, 92.96464],
  [42.372676, 92.96464],
  [42.372618, 92.964768],
  [42.372586, 92.964836],
  [42.372561, 92.964893],
  [42.372536, 92.96495],
  [42.372511, 92.965005],
  [42.372485, 92.965061],
  [42.372433, 92.965176],
  [42.372433, 92.965176],
  [42.372411, 92.965231],
  [42.37236, 92.965348],
  [42.37236, 92.965348],
  [42.372313, 92.965408],
  [42.372295, 92.965433],
  [42.372268, 92.965471],
  [42.372243, 92.965515],
  [42.372218, 92.965558],
  [42.3722, 92.965605],
  [42.372146, 92.96572],
  [42.372146, 92.96572],
  [42.37212, 92.965788],
  [42.37206, 92.965905],
  [42.372033, 92.965955],
  [42.372001, 92.966006],
  [42.371971, 92.966056],
  [42.371945, 92.966105],
  [42.37192, 92.966155],
  [42.371885, 92.966216],
  [42.371853, 92.966275],
  [42.37182, 92.966328],
  [42.371758, 92.96644],
  [42.371758, 92.96644],
  [42.371706, 92.966546],
  [42.371685, 92.96661],
  [42.371653, 92.96666],
  [42.371628, 92.966716],
  [42.371613, 92.966771],
  [42.371593, 92.96682],
  [42.371536, 92.966926],
  [42.371536, 92.966926],
  [42.371511, 92.96698],
  [42.371465, 92.96707],
  [42.371441, 92.967105],
  [42.37142, 92.967146],
  [42.371398, 92.967185],
  [42.37137, 92.967235],
  [42.371348, 92.967275],
  [42.37133, 92.967316],
  [42.371308, 92.967363],
  [42.371258, 92.967466],
  [42.371235, 92.967525],
  [42.371235, 92.967525],
  [42.371185, 92.967638],
  [42.371158, 92.967698],
  [42.371126, 92.967755],
  [42.3711, 92.967805],
  [42.371068, 92.967856],
  [42.37104, 92.9679],
  [42.370988, 92.967995],
  [42.370988, 92.967995],
  [42.370968, 92.968033],
  [42.370945, 92.968071],
  [42.370933, 92.968128],
  [42.370943, 92.968196],
  [42.370955, 92.96825],
  [42.371025, 92.968315],
  [42.371065, 92.968345],
  [42.371101, 92.968365],
  [42.371143, 92.968366],
  [42.371171, 92.968381],
  [42.371171, 92.968381],
  [42.371205, 92.96841],
  [42.37123, 92.968453],
  [42.37123, 92.968453],
  [42.371236, 92.968541],
  [42.37122, 92.968573],
  [42.371183, 92.968596],
  [42.371183, 92.968596],
  [42.37116, 92.9686],
  [42.371091, 92.96862],
  [42.371131, 92.968568],
  [42.371146, 92.968575],
  [42.37115, 92.968581],
  [42.371158, 92.968586],
  [42.371163, 92.968601],
  [42.371163, 92.968601],
  [42.371178, 92.968605],
  [42.37118, 92.968621],
  [42.37118, 92.968621],
  [42.371161, 92.968586],
  [42.371126, 92.968545],
  [42.371115, 92.968531],
  [42.371105, 92.968523],
  [42.371098, 92.96851],
  [42.371081, 92.968485],
  [42.37108, 92.968466],
  [42.37108, 92.968466],
  [42.371076, 92.968431],
  [42.371078, 92.96841],
  [42.371075, 92.968356],
  [42.371075, 92.968356],
  [42.37105, 92.96827],
  [42.371036, 92.968203],
  [42.37104, 92.96813],
  [42.371055, 92.968071],
  [42.37107, 92.968001],
  [42.3711, 92.96793],
  [42.371161, 92.9678],
  [42.371161, 92.9678],
  [42.371201, 92.967738],
  [42.371281, 92.9676],
  [42.371321, 92.967533],
  [42.371361, 92.967466],
  [42.3714, 92.967408],
  [42.37144, 92.967336],
  [42.371486, 92.967255],
  [42.37154, 92.967188],
  [42.371585, 92.967088],
  [42.37168, 92.966908],
  [42.37168, 92.966908],
  [42.371721, 92.966813],
  [42.371821, 92.966631],
  [42.371868, 92.96655],
  [42.371911, 92.966468],
  [42.371963, 92.966383],
  [42.371991, 92.9663],
  [42.372033, 92.966228],
  [42.372063, 92.966145],
  [42.372108, 92.966071],
  [42.372176, 92.9659],
  [42.37222, 92.965821],
  [42.37222, 92.965821],
  [42.372301, 92.965661],
  [42.372336, 92.96558],
  [42.372373, 92.965488],
  [42.372413, 92.965393],
  [42.37246, 92.965308],
  [42.37251, 92.965216],
  [42.372605, 92.965035],
  [42.372605, 92.965035],
  [42.372645, 92.964963],
  [42.372723, 92.96483],
  [42.372761, 92.964756],
  [42.372801, 92.964686],
  [42.372846, 92.964631],
  [42.372885, 92.964573],
  [42.37293, 92.964516],
  [42.372971, 92.964468],
  [42.373, 92.964428],
  [42.37306, 92.964333],
  [42.373096, 92.964278],
  [42.373096, 92.964278],
  [42.373138, 92.964148],
  [42.373156, 92.964066],
  [42.373163, 92.963996],
  [42.373173, 92.963921],
  [42.37316, 92.963841],
  [42.373145, 92.963755],
  [42.373105, 92.963583],
  [42.373105, 92.963583],
  [42.373066, 92.963401],
  [42.37305, 92.96331],
  [42.37303, 92.963208],
  [42.373013, 92.963098],
  [42.373, 92.962991],
  [42.372981, 92.962881],
  [42.372963, 92.962766],
  [42.372945, 92.962646],
  [42.372933, 92.96254],
  [42.372893, 92.962305],
  [42.372876, 92.96219],
  [42.372876, 92.96219],
  [42.372835, 92.961965],
  [42.37281, 92.961848],
  [42.372785, 92.961731],
  [42.372758, 92.961618],
  [42.372726, 92.961513],
  [42.3727, 92.961396],
  [42.372678, 92.961285],
  [42.37266, 92.961176],
  [42.372606, 92.960946],
  [42.372578, 92.960833],
  [42.372578, 92.960833],
  [42.372536, 92.960606],
  [42.372511, 92.960495],
  [42.372486, 92.960393],
  [42.372461, 92.960278],
  [42.372445, 92.960163],
  [42.37243, 92.960045],
  [42.372395, 92.95982],
  [42.372395, 92.95982],
  [42.37238, 92.959595],
  [42.372381, 92.959491],
  [42.372395, 92.959396],
  [42.37241, 92.959316],
  [42.372428, 92.959243],
  [42.372446, 92.959165],
  [42.372463, 92.959096],
  [42.372466, 92.959031],
  [42.372466, 92.958948],
  [42.372471, 92.958783],
  [42.372463, 92.958706],
  [42.372456, 92.958605],
  [42.372443, 92.958506],
  [42.372428, 92.95841],
  [42.372418, 92.958305],
  [42.372408, 92.958203],
  [42.3724, 92.958096],
  [42.372391, 92.957986],
  [42.372376, 92.957761],
  [42.372376, 92.957761],
  [42.372353, 92.95754],
  [42.372343, 92.957431],
  [42.372328, 92.95733],
  [42.372325, 92.957231],
  [42.372318, 92.957136],
  [42.372313, 92.957045],
  [42.3723, 92.956863],
  [42.3723, 92.956863],
  [42.372288, 92.95676],
  [42.372285, 92.956568],
  [42.372283, 92.956471],
  [42.37228, 92.956381],
  [42.37228, 92.9563],
  [42.372276, 92.956208],
  [42.37227, 92.956118],
  [42.372263, 92.95602],
  [42.372251, 92.955918],
  [42.372245, 92.955816],
  [42.372223, 92.95561],
  [42.372223, 92.95561],
  [42.372195, 92.955398],
  [42.372186, 92.955298],
  [42.372176, 92.955185],
  [42.372168, 92.955081],
  [42.37216, 92.954983],
  [42.372146, 92.954893],
  [42.372171, 92.95473],
  [42.372171, 92.95473],
  [42.372183, 92.954658],
  [42.372196, 92.954508],
  [42.372201, 92.954435],
  [42.372203, 92.954348],
  [42.372203, 92.95426],
  [42.372203, 92.954173],
  [42.3722, 92.954086],
  [42.372196, 92.954001],
  [42.372183, 92.953915],
  [42.372155, 92.953705],
  [42.372155, 92.953705],
  [42.37213, 92.953608],
  [42.372078, 92.953386],
  [42.372055, 92.953265],
  [42.372026, 92.953143],
  [42.371995, 92.953026],
  [42.371958, 92.952923],
  [42.37192, 92.952831],
  [42.371885, 92.952738],
  [42.371851, 92.95266],
  [42.371775, 92.952471],
  [42.371743, 92.952365],
  [42.371743, 92.952365],
  [42.37167, 92.952138],
  [42.371635, 92.952035],
  [42.371606, 92.951943],
  [42.371581, 92.951835],
  [42.372953, 92.9504],
  [42.37304, 92.950348],
  [42.373128, 92.950291],
  [42.373211, 92.950236],
  [42.373368, 92.950133],
  [42.373368, 92.950133],
  [42.373538, 92.95002],
  [42.373618, 92.949965],
  [42.37369, 92.949908],
  [42.373765, 92.94985],
  [42.373838, 92.949786],
  [42.373916, 92.949725],
  [42.373995, 92.94966],
  [42.374083, 92.94961],
  [42.374176, 92.949556],
  [42.37434, 92.949456],
  [42.374431, 92.949413],
  [42.374518, 92.94937],
  [42.374605, 92.949326],
  [42.374696, 92.949285],
  [42.374783, 92.949238],
  [42.374868, 92.949193],
  [42.374951, 92.94914],
  [42.375043, 92.94909],
  [42.375133, 92.949046],
  [42.375216, 92.948993],
  [42.375385, 92.948883],
  [42.37547, 92.948825],
  [42.37547, 92.948825],
  [42.375553, 92.948763],
  [42.375723, 92.948655],
  [42.375805, 92.948601],
  [42.375885, 92.948536],
  [42.375981, 92.948485],
  [42.376075, 92.948433],
  [42.376253, 92.948323],
  [42.376253, 92.948323],
  [42.376425, 92.948221],
  [42.376505, 92.948166],
  [42.376603, 92.948121],
  [42.376693, 92.948075],
  [42.376781, 92.948028],
  [42.376858, 92.947975],
  [42.37694, 92.947921],
  [42.377018, 92.947863],
  [42.377103, 92.947803],
  [42.377265, 92.947683],
  [42.37735, 92.947626],
  [42.377431, 92.94757],
  [42.377515, 92.947518],
  [42.377595, 92.947466],
  [42.37767, 92.947415],
  [42.377753, 92.947366],
  [42.377841, 92.94732],
  [42.377926, 92.947266],
  [42.37809, 92.947155],
  [42.37809, 92.947155],
  [42.378253, 92.947045],
  [42.378335, 92.946991],
  [42.3784, 92.946933],
  [42.378478, 92.946888],
  [42.378541, 92.946833],
  [42.378606, 92.946788],
  [42.378745, 92.946676],
  [42.378745, 92.946676],
  [42.378813, 92.946625],
  [42.378958, 92.94652],
  [42.37902, 92.946458],
  [42.379076, 92.946388],
  [42.379131, 92.946285],
  [42.379175, 92.946171],
  [42.379211, 92.946053],
  [42.379243, 92.94593],
  [42.379275, 92.945816],
  [42.379305, 92.94558],
  [42.379316, 92.94546],
  [42.379316, 92.94546],
  [42.379343, 92.945241],
  [42.379355, 92.945133],
  [42.379366, 92.945028],
  [42.379378, 92.944923],
  [42.379391, 92.94482],
  [42.379403, 92.944716],
  [42.379423, 92.944503],
  [42.379423, 92.944503],
  [42.379436, 92.944403],
  [42.379468, 92.944178],
  [42.379468, 92.944178],
  [42.379486, 92.943945],
  [42.379511, 92.943828],
  [42.379538, 92.943726],
  [42.379553, 92.943611],
  [42.379565, 92.943495],
  [42.37958, 92.943386],
  [42.379605, 92.943171],
  [42.379605, 92.943171],
  [42.379615, 92.943063],
  [42.379633, 92.942866],
  [42.379638, 92.942771],
  [42.379648, 92.942678],
  [42.379651, 92.942581],
  [42.379658, 92.942478],
  [42.379653, 92.94235],
  [42.379656, 92.942233],
  [42.379665, 92.94211],
  [42.379666, 92.941858],
  [42.379671, 92.941731],
  [42.379671, 92.941731],
  [42.379695, 92.941495],
  [42.3797, 92.941381],
  [42.379705, 92.941258],
  [42.379706, 92.941135],
  [42.37971, 92.941018],
  [42.379716, 92.940916],
  [42.379726, 92.940701],
  [42.379726, 92.940701],
  [42.379738, 92.940601],
  [42.379773, 92.94043],
  [42.379806, 92.94034],
  [42.379836, 92.940246],
  [42.37986, 92.940151],
  [42.379883, 92.940068],
  [42.379903, 92.939978],
  [42.379935, 92.939891],
  [42.379973, 92.939808],
  [42.38006, 92.939666],
  [42.380121, 92.939618],
  [42.380121, 92.939618],
  [42.380258, 92.93954],
  [42.380333, 92.939506],
  [42.3804, 92.939463],
  [42.380461, 92.939421],
  [42.380533, 92.939376],
  [42.380588, 92.939323],
  [42.380708, 92.939215],
  [42.380708, 92.939215],
  [42.380806, 92.93912],
  [42.38085, 92.939071],
  [42.380898, 92.939028],
  [42.380955, 92.93898],
  [42.381023, 92.938936],
  [42.381088, 92.93887],
  [42.381151, 92.938803],
  [42.381213, 92.93873],
  [42.381268, 92.938658],
  [42.381386, 92.938495],
  [42.381451, 92.938433],
  [42.38153, 92.938376],
  [42.381615, 92.938335],
  [42.381693, 92.938298],
  [42.381765, 92.938258],
  [42.381831, 92.938215],
  [42.381913, 92.938176],
  [42.381985, 92.938133],
  [42.382058, 92.938093],
  [42.382125, 92.93806],
  [42.382248, 92.938016],
  [42.38231, 92.937975],
  [42.38231, 92.937975],
  [42.382418, 92.937873],
  [42.382471, 92.937811],
  [42.382501, 92.937756],
  [42.382535, 92.937685],
  [42.38256, 92.937596],
  [42.382583, 92.937505],
  [42.382651, 92.937318],
  [42.382651, 92.937318],
  [42.382686, 92.937226],
  [42.382715, 92.937143],
  [42.382775, 92.937093],
  [42.382913, 92.937051],
  [42.382993, 92.937035],
  [42.383056, 92.93702],
  [42.383133, 92.936998],
  [42.383196, 92.936965],
  [42.383275, 92.936928],
  [42.383363, 92.936891],
  [42.383448, 92.93685],
  [42.38359, 92.936753],
  [42.383651, 92.936715],
  [42.383651, 92.936715],
  [42.38376, 92.936631],
  [42.38376, 92.936631],
  [42.383808, 92.936578],
  [42.38386, 92.936456],
  [42.383866, 92.936395],
  [42.383866, 92.936395],
  [42.383855, 92.93633],
  [42.38384, 92.936285],
  [42.383801, 92.93625],
  [42.383708, 92.936185],
  [42.383661, 92.936166],
  [42.383596, 92.936166],
  [42.383525, 92.93617],
  [42.383461, 92.936181],
  [42.38341, 92.936208],
  [42.383363, 92.93624],
  [42.383311, 92.936278],
  [42.383256, 92.936308],
  [42.383136, 92.936363],
  [42.383078, 92.936388],
  [42.383078, 92.936388],
  [42.382958, 92.936443],
  [42.382891, 92.936475],
  [42.38283, 92.936511],
  [42.38276, 92.936531],
  [42.382695, 92.936555],
  [42.382635, 92.936575],
  [42.382516, 92.936643],
  [42.382516, 92.936643],
  [42.382411, 92.936725],
  [42.38236, 92.936746],
  [42.38231, 92.936761],
  [42.382271, 92.936776],
  [42.382236, 92.936786],
  [42.38221, 92.936791],
  [42.382185, 92.936786],
  [42.382166, 92.93679],
  [42.382146, 92.936796],
  [42.382098, 92.93682],
  [42.382071, 92.936828],
  [42.382046, 92.936843],
  [42.38202, 92.936858],
  [42.381985, 92.936881],
  [42.381946, 92.93691],
  [42.381905, 92.936925],
  [42.381865, 92.936948],
  [42.381823, 92.936966],
  [42.38172, 92.93699],
  [42.38172, 92.93699],
  [42.381628, 92.937023],
  [42.381571, 92.93704],
  [42.381516, 92.937048],
  [42.381456, 92.937085],
  [42.381408, 92.937111],
  [42.38136, 92.937128],
  [42.381268, 92.937138],
  [42.381268, 92.937138],
  [42.381221, 92.937136],
  [42.381125, 92.937138],
  [42.381081, 92.937145],
  [42.381035, 92.93714],
  [42.380991, 92.93713],
  [42.380946, 92.937128],
  [42.380921, 92.937116],
  [42.38088, 92.937108],
  [42.380833, 92.937106],
  [42.380763, 92.93709],
  [42.38073, 92.93708],
  [42.38073, 92.93708],
  [42.380665, 92.937086],
  [42.380635, 92.937095],
  [42.380598, 92.937103],
  [42.380563, 92.9371],
  [42.380528, 92.937105],
  [42.380493, 92.937098],
  [42.380468, 92.937096],
  [42.380441, 92.93709],
  [42.380428, 92.937066],
  [42.380388, 92.937026],
  [42.380388, 92.937026],
  [42.380335, 92.936998],
  [42.380305, 92.936985],
  [42.380296, 92.936981],
  [42.380283, 92.93697],
  [42.380256, 92.936953],
  [42.38024, 92.936938],
  [42.380215, 92.936903],
  [42.380215, 92.936903],
  [42.38021, 92.936886],
  [42.380168, 92.93688],
  [42.380146, 92.936861],
  [42.380123, 92.936835],
  [42.3801, 92.936826],
  [42.380075, 92.936833],
  [42.380065, 92.936833],
  [42.380063, 92.936823],
  [42.380071, 92.936821],
  [42.380071, 92.936821],
  [42.380071, 92.936821],
  [42.380071, 92.936821],
  [42.380071, 92.936821],
  [42.379933, 92.936783],
  [42.379911, 92.936808],
  [42.37989, 92.93684],
  [42.37989, 92.93684],
  [42.379886, 92.936868],
  [42.379891, 92.936915],
  [42.379891, 92.936915],
  [42.379891, 92.936915],
  [42.379891, 92.936915],
  [42.379891, 92.936915],
  [42.379926, 92.936893],
  [42.379941, 92.936901],
  [42.379941, 92.936901],
  [42.37993, 92.936905],
  [42.37992, 92.9369],
  [42.379926, 92.93689],
  [42.379925, 92.936876],
  [42.379926, 92.936853],
  [42.379928, 92.936836],
  [42.379921, 92.936791],
  [42.379921, 92.936791],
  [42.379916, 92.936773],
  [42.379906, 92.936731],
  [42.379906, 92.936715],
  [42.379901, 92.936701],
  [42.379891, 92.936701],
  [42.379885, 92.936701],
  [42.379883, 92.936691],
  [42.37989, 92.936681],
  [42.379885, 92.936676],
  [42.379896, 92.936673],
  [42.379901, 92.936666],
  [42.379901, 92.936666],
  [42.379916, 92.93664],
  [42.379923, 92.936643],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379923, 92.936653],
  [42.379885, 92.936745],
  [42.379895, 92.936763],
  [42.379921, 92.936773],
  [42.37994, 92.936773],
  [42.379951, 92.936771],
  [42.379956, 92.936775],
  [42.379965, 92.936798],
  [42.379975, 92.936823],
  [42.379986, 92.936833],
  [42.380008, 92.936838],
  [42.38005, 92.93686],
  [42.38005, 92.93686],
  [42.380071, 92.936863],
  [42.380088, 92.936871],
  [42.380088, 92.936871],
  [42.380088, 92.936871],
  [42.38013, 92.936896],
  [42.380143, 92.936896],
  [42.380153, 92.936908],
  [42.380153, 92.936908],
  [42.380163, 92.936911],
  [42.380176, 92.93694],
  [42.380186, 92.936958],
  [42.3802, 92.936975],
  [42.380213, 92.936983],
  [42.38022, 92.936995],
  [42.380231, 92.936995],
  [42.380238, 92.937],
  [42.38025, 92.937001],
  [42.380268, 92.937016],
  [42.380298, 92.93705],
  [42.380298, 92.93705],
  [42.380333, 92.93708],
  [42.380353, 92.937086],
  [42.380371, 92.9371],
  [42.38039, 92.937105],
  [42.380415, 92.937106],
  [42.38044, 92.937118],
  [42.380485, 92.937128],
  [42.380485, 92.937128],
  [42.38051, 92.937136],
  [42.380556, 92.937143],
  [42.38058, 92.93714],
  [42.380601, 92.937141],
  [42.380625, 92.937145],
  [42.380648, 92.937148],
  [42.380671, 92.937153],
  [42.380693, 92.937163],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380706, 92.937175],
  [42.380803, 92.937138],
  [42.380836, 92.937143],
  [42.380906, 92.937145],
  [42.380928, 92.937155],
  [42.380953, 92.937166],
  [42.38098, 92.937168],
  [42.38101, 92.937166],
  [42.381036, 92.937168],
  [42.38107, 92.937165],
  [42.38111, 92.937161],
  [42.381141, 92.93716],
  [42.381215, 92.937168],
  [42.381215, 92.937168],
  [42.381273, 92.937171],
  [42.38131, 92.937161],
  [42.381335, 92.937151],
  [42.38136, 92.93715],
  [42.381395, 92.937138],
  [42.381435, 92.937128],
  [42.38152, 92.937095],
  [42.38152, 92.937095],
  [42.381573, 92.937088],
  [42.381665, 92.93706],
  [42.381665, 92.93706],
  [42.38176, 92.937013],
  [42.381805, 92.936986],
  [42.381858, 92.93696],
  [42.381905, 92.936943],
  [42.381951, 92.936908],
  [42.382001, 92.936883],
  [42.382098, 92.936845],
  [42.382098, 92.936845],
  [42.382135, 92.936821],
  [42.382223, 92.936773],
  [42.38227, 92.93675],
  [42.38231, 92.936728],
  [42.382366, 92.936708],
  [42.38242, 92.936683],
  [42.382456, 92.936668],
  [42.382488, 92.93664],
  [42.382533, 92.936616],
  [42.382595, 92.9366],
  [42.3827, 92.936546],
  [42.3827, 92.936546],
  [42.38282, 92.936498],
  [42.382868, 92.936466],
  [42.382916, 92.936451],
  [42.382971, 92.936426],
  [42.383018, 92.936396],
  [42.38307, 92.936363],
  [42.38318, 92.9363],
  [42.38318, 92.9363],
  [42.383226, 92.936261],
  [42.383325, 92.936206],
  [42.383376, 92.936183],
  [42.38343, 92.93616],
  [42.383483, 92.936148],
  [42.383536, 92.93613],
  [42.383583, 92.936133],
  [42.383633, 92.936143],
  [42.383685, 92.936163],
  [42.383685, 92.936163],
  [42.383796, 92.936335],
  [42.383828, 92.936395],
  [42.383828, 92.936395],
  [42.38384, 92.936453],
  [42.383836, 92.936561],
  [42.383836, 92.936561],
  [42.383803, 92.936598],
  [42.383716, 92.936663],
  [42.383663, 92.936688],
  [42.38361, 92.93671],
  [42.383543, 92.93673],
  [42.383481, 92.936748],
  [42.383421, 92.936785],
  [42.383311, 92.936836],
  [42.383311, 92.936836],
  [42.383186, 92.936893],
  [42.383118, 92.936918],
  [42.38305, 92.936948],
  [42.38299, 92.936978],
  [42.382921, 92.93701],
  [42.382855, 92.937045],
  [42.382795, 92.937073],
  [42.382743, 92.937106],
  [42.382695, 92.937148],
  [42.382626, 92.937253],
  [42.382606, 92.937326],
  [42.382606, 92.937326],
  [42.382566, 92.937483],
  [42.382545, 92.937561],
  [42.382521, 92.937641],
  [42.382491, 92.937706],
  [42.382461, 92.937768],
  [42.382411, 92.937806],
  [42.382303, 92.937905],
  [42.382303, 92.937905],
  [42.382198, 92.937988],
  [42.382131, 92.938035],
  [42.382131, 92.938035],
  [42.382011, 92.938093],
  [42.381946, 92.938135],
  [42.381888, 92.938163],
  [42.381836, 92.938193],
  [42.38178, 92.938225],
  [42.381723, 92.938261],
  [42.381603, 92.938346],
  [42.381603, 92.938346],
  [42.38148, 92.938401],
  [42.381418, 92.938448],
  [42.381363, 92.938495],
  [42.381313, 92.938565],
  [42.38127, 92.938636],
  [42.38122, 92.93871],
  [42.381178, 92.938768],
  [42.381133, 92.938835],
  [42.381078, 92.938893],
  [42.38095, 92.938978],
  [42.38089, 92.939013],
  [42.380835, 92.939041],
  [42.380778, 92.939071],
  [42.380728, 92.939106],
  [42.380673, 92.939141],
  [42.38061, 92.939168],
  [42.380548, 92.939195],
  [42.38049, 92.939233],
  [42.380393, 92.939276],
  [42.380393, 92.939276],
  [42.380295, 92.939331],
  [42.380245, 92.939361],
  [42.380201, 92.939388],
  [42.38016, 92.939428],
  [42.380133, 92.939476],
  [42.380103, 92.939518],
  [42.380048, 92.939636],
  [42.380048, 92.939636],
  [42.380026, 92.939691],
  [42.379981, 92.939835],
  [42.37996, 92.93991],
  [42.37993, 92.939986],
  [42.379898, 92.94007],
  [42.379878, 92.94015],
  [42.379853, 92.940251],
  [42.379826, 92.940341],
  [42.379788, 92.940423],
  [42.379738, 92.940581],
  [42.37972, 92.940638],
  [42.37972, 92.940638],
  [42.379711, 92.94079],
  [42.37971, 92.940873],
  [42.379715, 92.94096],
  [42.37971, 92.941048],
  [42.379705, 92.941145],
  [42.379696, 92.941248],
  [42.37967, 92.941431],
  [42.37967, 92.941431],
  [42.379661, 92.941528],
  [42.379648, 92.941731],
  [42.37965, 92.941828],
  [42.379651, 92.94194],
  [42.379648, 92.942056],
  [42.379641, 92.942163],
  [42.379636, 92.942275],
  [42.379631, 92.94239],
  [42.379626, 92.9425],
  [42.379608, 92.942695],
  [42.379608, 92.942695],
  [42.379593, 92.942795],
  [42.379575, 92.942993],
  [42.379566, 92.943096],
  [42.379551, 92.943213],
  [42.379538, 92.943338],
  [42.379526, 92.943463],
  [42.379515, 92.94359],
  [42.379506, 92.943706],
  [42.379485, 92.943833],
  [42.379436, 92.944118],
  [42.379413, 92.944266],
  [42.379413, 92.944266],
  [42.379358, 92.94453],
  [42.379335, 92.944658],
  [42.379308, 92.944793],
  [42.37928, 92.944926],
  [42.379258, 92.945063],
  [42.379238, 92.9452],
  [42.379203, 92.945471],
  [42.379203, 92.945471],
  [42.37919, 92.945601],
  [42.379158, 92.945855],
  [42.379131, 92.945985],
  [42.379101, 92.946088],
  [42.379065, 92.946186],
  [42.378986, 92.94626],
  [42.378921, 92.946325],
  [42.378835, 92.946398],
  [42.378765, 92.946455],
  [42.378623, 92.946545],
  [42.378546, 92.946593],
  [42.378546, 92.946593],
  [42.378381, 92.946693],
  [42.378303, 92.946746],
  [42.378218, 92.946796],
  [42.378136, 92.946848],
  [42.378046, 92.946906],
  [42.377956, 92.94696],
  [42.377793, 92.947078],
  [42.377793, 92.947078],
  [42.37763, 92.947181],
  [42.377551, 92.947235],
  [42.377476, 92.947295],
  [42.377405, 92.947358],
  [42.377331, 92.947416],
  [42.377261, 92.94748],
  [42.377183, 92.947543],
  [42.377103, 92.947605],
  [42.377021, 92.947673],
  [42.37685, 92.947793],
  [42.376768, 92.947851],
  [42.376688, 92.947916],
  [42.376595, 92.947975],
  [42.376505, 92.948026],
  [42.37642, 92.948081],
  [42.376325, 92.94813],
  [42.376228, 92.948165],
  [42.376136, 92.9482],
  [42.37597, 92.948288],
  [42.37597, 92.948288],
  [42.375805, 92.948386],
  [42.375713, 92.948435],
  [42.375713, 92.948435],
  [42.375525, 92.94855],
  [42.37544, 92.948606],
  [42.375356, 92.948663],
  [42.375266, 92.948713],
  [42.375178, 92.948771],
  [42.375098, 92.948835],
  [42.374943, 92.948956],
  [42.374943, 92.948956],
  [42.374783, 92.949096],
  [42.374688, 92.949151],
  [42.3746, 92.949203],
  [42.374508, 92.949258],
  [42.374415, 92.949311],
  [42.374318, 92.949361],
  [42.374225, 92.94942],
  [42.374123, 92.949466],
  [42.374031, 92.949513],
  [42.373851, 92.949608],
  [42.373768, 92.949656],
  [42.37368, 92.949701],
  [42.373586, 92.949746],
  [42.373493, 92.949796],
  [42.373406, 92.949845],
  [42.373318, 92.949895],
  [42.373235, 92.94995],
  [42.373151, 92.94999],
  [42.372988, 92.950086],
  [42.372988, 92.950086],
  [42.37282, 92.950183],
  [42.372743, 92.950235],
  [42.372673, 92.950288],
  [42.372606, 92.950345],
  [42.37253, 92.950385],
  [42.37246, 92.950431],
  [42.37233, 92.95053],
  [42.37233, 92.95053],
  [42.372248, 92.95056],
  [42.372111, 92.950663],
  [42.372046, 92.950721],
  [42.371978, 92.95078],
  [42.371905, 92.950838],
  [42.371833, 92.950896],
  [42.371758, 92.950965],
  [42.371693, 92.951036],
  [42.37163, 92.951113],
  [42.371535, 92.951313],
  [42.371495, 92.951428],
  [42.371495, 92.951428],
  [42.371458, 92.95168],
  [42.371458, 92.95168],
  [42.371468, 92.95181],
  [42.371541, 92.952036],
  [42.371573, 92.952148],
  [42.371608, 92.952253],
  [42.371635, 92.952345],
  [42.37166, 92.952425],
  [42.371688, 92.952518],
  [42.371743, 92.952706],
  [42.371743, 92.952706],
  [42.371806, 92.952878],
  [42.371833, 92.952961],
  [42.371856, 92.95302],
  [42.37187, 92.953081],
  [42.371885, 92.953141],
  [42.3719, 92.953205],
  [42.371938, 92.953325],
  [42.371938, 92.953325],
  [42.371948, 92.953371],
  [42.371968, 92.95348],
  [42.37198, 92.95354],
  [42.37199, 92.9536],
  [42.372003, 92.953655],
  [42.372015, 92.95371],
  [42.372026, 92.953761],
  [42.372045, 92.95381],
  [42.372056, 92.953871],
  [42.372065, 92.953928],
  [42.37209, 92.954031],
  [42.37209, 92.954031],
  [42.372103, 92.95415],
  [42.372101, 92.954195],
  [42.372101, 92.95425],
  [42.372105, 92.954305],
  [42.372103, 92.95436],
  [42.372105, 92.954413],
  [42.372106, 92.954471],
  [42.372096, 92.954526],
  [42.372091, 92.954591],
  [42.372086, 92.95472],
  [42.372086, 92.95472],
  [42.372073, 92.954838],
  [42.372066, 92.954898],
  [42.37206, 92.954981],
  [42.372061, 92.955073],
  [42.372068, 92.95516],
  [42.372083, 92.95524],
  [42.372118, 92.955418],
  [42.372118, 92.955418],
  [42.372128, 92.9555],
  [42.372146, 92.955666],
  [42.372151, 92.955755],
  [42.372158, 92.955846],
  [42.37216, 92.955926],
  [42.37216, 92.956001],
  [42.372161, 92.956078],
  [42.37216, 92.956155],
  [42.372165, 92.95623],
  [42.372181, 92.956386],
  [42.372183, 92.956465],
  [42.372183, 92.956465],
  [42.372178, 92.95661],
  [42.372175, 92.956675],
  [42.372178, 92.956746],
  [42.372175, 92.956818],
  [42.372175, 92.956881],
  [42.37218, 92.956948],
  [42.372191, 92.957071],
  [42.372191, 92.957071],
  [42.372215, 92.957173],
  [42.372221, 92.957233],
  [42.372225, 92.957296],
  [42.37223, 92.957358],
  [42.372233, 92.957408],
  [42.372235, 92.957471],
  [42.372238, 92.957546],
  [42.372243, 92.957606],
  [42.372245, 92.957675],
  [42.372245, 92.9578],
  [42.372245, 92.957861],
  [42.372245, 92.957861],
  [42.37225, 92.957971],
  [42.372253, 92.958021],
  [42.372255, 92.958075],
  [42.372253, 92.958123],
  [42.372255, 92.958171],
  [42.372256, 92.958218],
  [42.372273, 92.958328],
  [42.372273, 92.958328],
  [42.37229, 92.95844],
  [42.37229, 92.958493],
  [42.37229, 92.958493],
  [42.3723, 92.958615],
  [42.372305, 92.958685],
  [42.372301, 92.958746],
  [42.372295, 92.95882],
  [42.37229, 92.958873],
  [42.372285, 92.958926],
  [42.37228, 92.959061],
  [42.37228, 92.959061],
  [42.37229, 92.959195],
  [42.372286, 92.959265],
  [42.372295, 92.95934],
  [42.372296, 92.95941],
  [42.372286, 92.959495],
  [42.372285, 92.959571],
  [42.372281, 92.959641],
  [42.372285, 92.959705],
  [42.372281, 92.959791],
  [42.372315, 92.95994],
  [42.372325, 92.960016],
  [42.372333, 92.960086],
  [42.372343, 92.960151],
  [42.372351, 92.960215],
  [42.372366, 92.960271],
  [42.372391, 92.960333],
  [42.372408, 92.960401],
  [42.372423, 92.960471],
  [42.372441, 92.960598],
  [42.372441, 92.960598],
  [42.37246, 92.960733],
  [42.372461, 92.9608],
  [42.372466, 92.96086],
  [42.37248, 92.960921],
  [42.372493, 92.960993],
  [42.372505, 92.96106],
  [42.37254, 92.961196],
  [42.37254, 92.961196],
  [42.372555, 92.961268],
  [42.372585, 92.961395],
  [42.372591, 92.96146],
  [42.372593, 92.961525],
  [42.372596, 92.961583],
  [42.3726, 92.961641],
  [42.372601, 92.961698],
  [42.372608, 92.961753],
  [42.372613, 92.96181],
  [42.372623, 92.961865],
  [42.372636, 92.961975],
  [42.372636, 92.961975],
  [42.372658, 92.962093],
  [42.37267, 92.96215],
  [42.37268, 92.962206],
  [42.372693, 92.962261],
  [42.372701, 92.962306],
  [42.372721, 92.962361],
  [42.372756, 92.962461],
  [42.372756, 92.962461],
  [42.372771, 92.962518],
  [42.372811, 92.962646],
  [42.372828, 92.9627],
  [42.372851, 92.962766],
  [42.372875, 92.962843],
  [42.372891, 92.96291],
  [42.372905, 92.962975],
  [42.372916, 92.963035],
  [42.372926, 92.963095],
  [42.372958, 92.963225],
  [42.372958, 92.963225],
  [42.372968, 92.963286],
  [42.372985, 92.9634],
  [42.372993, 92.963453],
  [42.373001, 92.963508],
  [42.373011, 92.963571],
  [42.373025, 92.963635],
  [42.373038, 92.963701],
  [42.37305, 92.963768],
  [42.373056, 92.96383],
  [42.373071, 92.963968],
  [42.373053, 92.964031],
  [42.373053, 92.964031],
  [42.373018, 92.964153],
  [42.373003, 92.964205],
  [42.372986, 92.964253],
  [42.37296, 92.964291],
  [42.372925, 92.96433],
  [42.372895, 92.964373],
  [42.372831, 92.964458],
  [42.372831, 92.964458],
  [42.372811, 92.964493],
  [42.37275, 92.964565],
  [42.372723, 92.96461],
  [42.37269, 92.96466],
  [42.37266, 92.964715],
  [42.372628, 92.964761],
  [42.372593, 92.96481],
  [42.372566, 92.964865],
  [42.372541, 92.964911],
  [42.372485, 92.965003],
  [42.372453, 92.965051],
  [42.372453, 92.965051],
  [42.372388, 92.965181],
  [42.372358, 92.965241],
  [42.372323, 92.965306],
  [42.372285, 92.965363],
  [42.372256, 92.965411],
  [42.372218, 92.965475],
  [42.372151, 92.965585],
  [42.372151, 92.965585],
  [42.372103, 92.965673],
  [42.372075, 92.96572],
  [42.37205, 92.96576],
  [42.372025, 92.965806],
  [42.372003, 92.965851],
  [42.371976, 92.965905],
  [42.371948, 92.965955],
  [42.371926, 92.966003],
  [42.371905, 92.96605],
  [42.371871, 92.96615],
  [42.371853, 92.966198],
  [42.371853, 92.966198],
  [42.37181, 92.966298],
  [42.371786, 92.966355],
  [42.37177, 92.966405],
  [42.37176, 92.966453],
  [42.371738, 92.966508],
  [42.371713, 92.966555],
  [42.371668, 92.966645],
  [42.371668, 92.966645],
  [42.371618, 92.966731],
  [42.371591, 92.966785],
  [42.371591, 92.966785],
  [42.371541, 92.966881],
  [42.371511, 92.966935],
  [42.37148, 92.966981],
  [42.37144, 92.967048],
  [42.371401, 92.967108],
  [42.371371, 92.967166],
  [42.371321, 92.967256],
  [42.371321, 92.967256],
  [42.371276, 92.96733],
  [42.371255, 92.96738],
  [42.37124, 92.967423],
  [42.371216, 92.967478],
  [42.371198, 92.967516],
  [42.371171, 92.967561],
  [42.37115, 92.967613],
  [42.371131, 92.967663],
  [42.371111, 92.967708],
  [42.371071, 92.9678],
  [42.371056, 92.967845],
  [42.371041, 92.967883],
  [42.371018, 92.967935],
  [42.370998, 92.96798],
  [42.370965, 92.968011],
  [42.370945, 92.96804],
  [42.370915, 92.968076],
  [42.370895, 92.968115],
  [42.370845, 92.968178],
  [42.370845, 92.968178],
  [42.370801, 92.968201],
  [42.370776, 92.96822],
  [42.370751, 92.96825],
  [42.370731, 92.968283],
  [42.370716, 92.968315],
  [42.370716, 92.968315],
  [42.370713, 92.968366],
  [42.370693, 92.968411],
  [42.3707, 92.968446],
  [42.370708, 92.968513],
  [42.370708, 92.968513],
  [42.370775, 92.968595],
  [42.370775, 92.968595],
  [42.3708, 92.9686],
  [42.370816, 92.968595],
  [42.370828, 92.96859],
  [42.370828, 92.96859],
  [42.370828, 92.96859],
  [42.370828, 92.96859],
  [42.370828, 92.96859],
  [42.370838, 92.968421],
  [42.370898, 92.968381],
  [42.370898, 92.968381],
  [42.370925, 92.968356],
  [42.37098, 92.968308],
  [42.371005, 92.968278],
  [42.37103, 92.968245],
  [42.371055, 92.968213],
  [42.37108, 92.968153],
  [42.371106, 92.968106],
  [42.371135, 92.968065],
  [42.371158, 92.968011],
  [42.371206, 92.967881],
  [42.371238, 92.9678],
  [42.371238, 92.9678],
  [42.371293, 92.967641],
  [42.371323, 92.967573],
  [42.37136, 92.967503],
  [42.37139, 92.967433],
  [42.371423, 92.967353],
  [42.371456, 92.967271],
  [42.371548, 92.967106],
  [42.371548, 92.967106],
  [42.371641, 92.966935],
  [42.371681, 92.966853],
  [42.371721, 92.966773],
  [42.371758, 92.966693],
  [42.371798, 92.966616],
  [42.371838, 92.966541],
  [42.371881, 92.966468],
  [42.371923, 92.966393],
  [42.371968, 92.96631],
  [42.372053, 92.966146],
  [42.372098, 92.96606],
  [42.372146, 92.965971],
  [42.372191, 92.965886],
  [42.372231, 92.965803],
  [42.372273, 92.965718],
  [42.372311, 92.965631],
  [42.372346, 92.965548],
  [42.372391, 92.96546],
  [42.372475, 92.965285],
  [42.372475, 92.965285],
  [42.372576, 92.965116],
  [42.372615, 92.965036],
  [42.372658, 92.964958],
  [42.372676, 92.964895],
  [42.372711, 92.964828],
  [42.372755, 92.964753],
  [42.372841, 92.964623],
  [42.372841, 92.964623],
  [42.372881, 92.964571],
  [42.372961, 92.96447],
  [42.372961, 92.96447],
  [42.373038, 92.964368],
  [42.373073, 92.964311],
  [42.373095, 92.964248],
  [42.373116, 92.964183],
  [42.373131, 92.964113],
  [42.373143, 92.964041],
  [42.373151, 92.963976],
  [42.373155, 92.963911],
  [42.373158, 92.96383],
  [42.373133, 92.963665],
  [42.37311, 92.96358],
  [42.373083, 92.96349],
  [42.37307, 92.9634],
  [42.373056, 92.963305],
  [42.373031, 92.963215],
  [42.373006, 92.963115],
  [42.37298, 92.963018],
  [42.372956, 92.962926],
  [42.372918, 92.962733],
  [42.372918, 92.962733],
  [42.372886, 92.96252],
  [42.372868, 92.962423],
  [42.372846, 92.962328],
  [42.37283, 92.96222],
  [42.372811, 92.962118],
  [42.372795, 92.962003],
  [42.372758, 92.961793],
  [42.372758, 92.961793],
  [42.372735, 92.961685],
  [42.372696, 92.961478],
  [42.372683, 92.961365],
  [42.372665, 92.96124],
  [42.372646, 92.961141],
  [42.372623, 92.961023],
  [42.3726, 92.960915],
  [42.37258, 92.960818],
  [42.372555, 92.960711],
  [42.372498, 92.960505],
  [42.372478, 92.960405],
  [42.372478, 92.960405],
  [42.372435, 92.960205],
  [42.37241, 92.960108],
  [42.372391, 92.960001],
  [42.372366, 92.959901],
  [42.372348, 92.959795],
  [42.372343, 92.959686],
  [42.372353, 92.959505],
  [42.372353, 92.959505],
  [42.372368, 92.959423],
  [42.372406, 92.95926],
  [42.372423, 92.959186],
  [42.37244, 92.959116],
  [42.372446, 92.959036],
  [42.372438, 92.958951],
  [42.372433, 92.958861],
  [42.37243, 92.958771],
  [42.372431, 92.95867],
  [42.372431, 92.958476],
  [42.37242, 92.95837],
  [42.37242, 92.95837],
  [42.372398, 92.958173],
  [42.372393, 92.958076],
  [42.372381, 92.957968],
  [42.37237, 92.957856],
  [42.372363, 92.95775],
  [42.372355, 92.957628],
  [42.372341, 92.957496],
  [42.372325, 92.957376],
  [42.372285, 92.957138],
  [42.372273, 92.957035],
  [42.372273, 92.957035],
  [42.372273, 92.956853],
  [42.372271, 92.956771],
  [42.372276, 92.956678],
  [42.372276, 92.956583],
  [42.37228, 92.95649],
  [42.37229, 92.956401],
  [42.372283, 92.956216],
  [42.372283, 92.956216],
  [42.372278, 92.956048],
  [42.372271, 92.955963],
  [42.372268, 92.955865],
  [42.372251, 92.955745],
  [42.372241, 92.95563],
  [42.372221, 92.955516],
  [42.372205, 92.95541],
  [42.372196, 92.9553],
  [42.37219, 92.955178],
  [42.372158, 92.954978],
  [42.372156, 92.954911],
  [42.372156, 92.954911],
  [42.37216, 92.954736],
  [42.372171, 92.954655],
  [42.372188, 92.95458],
  [42.372203, 92.954495],
  [42.37221, 92.954413],
  [42.37222, 92.954346],
  [42.372226, 92.954205],
  [42.372226, 92.954205],
  [42.372198, 92.954025],
  [42.372161, 92.953941],
  [42.372135, 92.953853],
  [42.372113, 92.95375],
  [42.372098, 92.953643],
  [42.372071, 92.953538],
  [42.372055, 92.953425],
  [42.372038, 92.953311],
  [42.372015, 92.953195],
  [42.371946, 92.952963],
  [42.371918, 92.952866],
  [42.371885, 92.952778],
  [42.371853, 92.952683],
  [42.371826, 92.95258],
  [42.371791, 92.952475],
  [42.37176, 92.952366],
  [42.37173, 92.952253],
  [42.371688, 92.952128],
  [42.371601, 92.95191],
  [42.371601, 92.95191],
  [42.371561, 92.95167],
  [42.371576, 92.951541],
  [42.371611, 92.951436],
  [42.371668, 92.951326],
  [42.371728, 92.951228],
  [42.371793, 92.951145],
  [42.371858, 92.951061],
  [42.371931, 92.950993],
  [42.372023, 92.950935],
  [42.372195, 92.950838],
  [42.372195, 92.950838],
  [42.372375, 92.950751],
  [42.372463, 92.950701],
  [42.37255, 92.950653],
  [42.372638, 92.9506],
  [42.372725, 92.950546],
  [42.372808, 92.950495],
  [42.372971, 92.950386],
  [42.372971, 92.950386],
  [42.37306, 92.95033],
  [42.373228, 92.950221],
  [42.373316, 92.950161],
  [42.373403, 92.950111],
  [42.3735, 92.950048],
  [42.373588, 92.949993],
  [42.373671, 92.949941],
  [42.373758, 92.94989],
  [42.373838, 92.949841],
  [42.373926, 92.949783],
  [42.374095, 92.949676],
  [42.374095, 92.949676],
  [42.374273, 92.94956],
  [42.374361, 92.949505],
  [42.374453, 92.949453],
  [42.374541, 92.949401],
  [42.374628, 92.949351],
  [42.374715, 92.949301],
  [42.374893, 92.949185],
  [42.374893, 92.949185],
  [42.374981, 92.949133],
  [42.375155, 92.94901],
  [42.375236, 92.948953],
  [42.375318, 92.948896],
  [42.3754, 92.948845],
  [42.375488, 92.948795],
  [42.375583, 92.94874],
  [42.375671, 92.948691],
  [42.375763, 92.948641],
  [42.37594, 92.94853],
  [42.376025, 92.94847],
  [42.376025, 92.94847],
  [42.376196, 92.948366],
  [42.376286, 92.94831],
  [42.376376, 92.948258],
  [42.376466, 92.948208],
  [42.376555, 92.948161],
  [42.376645, 92.948115],
  [42.376815, 92.948005],
  [42.376815, 92.948005],
  [42.3769, 92.947951],
  [42.377068, 92.947838],
  [42.377151, 92.94778],
  [42.377243, 92.947718],
  [42.377321, 92.947655],
  [42.377395, 92.947595],
  [42.377473, 92.947538],
  [42.377555, 92.947481],
  [42.377638, 92.947428],
  [42.377803, 92.947315],
  [42.377803, 92.947315],
  [42.377881, 92.947258],
  [42.378053, 92.947138],
  [42.378148, 92.947078],
  [42.378235, 92.94702],
  [42.378325, 92.94696],
  [42.3784, 92.946903],
  [42.37847, 92.946858],
  [42.378548, 92.946816],
  [42.37863, 92.946768],
  [42.378785, 92.946676],
  [42.378858, 92.946626],
  [42.378858, 92.946626],
  [42.378991, 92.946518],
  [42.379046, 92.94644],
  [42.379111, 92.94635],
  [42.379156, 92.946246],
  [42.379198, 92.946138],
  [42.379223, 92.94602],
  [42.379255, 92.945786],
  [42.379255, 92.945786],
  [42.379303, 92.945556],
  [42.379318, 92.945445],
  [42.379338, 92.945318],
  [42.379345, 92.945213],
  [42.379343, 92.945091],
  [42.379355, 92.944976],
  [42.379361, 92.944876],
  [42.37938, 92.944771],
  [42.379396, 92.94467],
  [42.379435, 92.944451],
  [42.37945, 92.944338],
  [42.37945, 92.944338],
  [42.379476, 92.944116],
  [42.379491, 92.944005],
  [42.37951, 92.943878],
  [42.37953, 92.943753],
  [42.379545, 92.943626],
  [42.379555, 92.943505],
  [42.379583, 92.943278],
  [42.379583, 92.943278],
  [42.379603, 92.943068],
  [42.379606, 92.942975],
  [42.379618, 92.94287],
  [42.379635, 92.942775],
  [42.37965, 92.94268],
  [42.379658, 92.942583],
  [42.379656, 92.94248],
  [42.37965, 92.942378],
  [42.379655, 92.942271],
  [42.379663, 92.942048],
  [42.379668, 92.94193],
  [42.379673, 92.941811],
  [42.37968, 92.941698],
  [42.379685, 92.941603],
  [42.379696, 92.941485],
  [42.379706, 92.941363],
  [42.379708, 92.94124],
  [42.379705, 92.941125],
  [42.379716, 92.940901],
  [42.379716, 92.940901],
  [42.379733, 92.940683],
  [42.379736, 92.940575],
  [42.37975, 92.940481],
  [42.379773, 92.940383],
  [42.379805, 92.940293],
  [42.379841, 92.940198],
  [42.37987, 92.940113],
  [42.379898, 92.940011],
  [42.379925, 92.939913],
  [42.379973, 92.939731],
  [42.379973, 92.939731],
  [42.380085, 92.939591],
  [42.380151, 92.93954],
  [42.380225, 92.939493],
  [42.380293, 92.939458],
  [42.380363, 92.939428],
  [42.380438, 92.93939],
  [42.380508, 92.939351],
  [42.380508, 92.939351],
  [42.380628, 92.939275],
  [42.38074, 92.93919],
  [42.380786, 92.939148],
  [42.380833, 92.939108],
  [42.380885, 92.93908],
  [42.380935, 92.939041],
  [42.380988, 92.939001],
  [42.381056, 92.938956],
  [42.381118, 92.938895],
  [42.381168, 92.93884],
  [42.38126, 92.93872],
  [42.38126, 92.93872],
  [42.381368, 92.938575],
  [42.38142, 92.9385],
  [42.381475, 92.938428],
  [42.381541, 92.938371],
  [42.381616, 92.938325],
  [42.38169, 92.938286],
  [42.381826, 92.938203],
  [42.381826, 92.938203],
  [42.38189, 92.938165],
  [42.382028, 92.938073],
  [42.382093, 92.938033],
  [42.382163, 92.937996],
  [42.382238, 92.937961],
  [42.38231, 92.937918],
  [42.38231, 92.937918],
  [42.38244, 92.937821],
  [42.382475, 92.937746],
  [42.382556, 92.937585],
  [42.382586, 92.937495],
  [42.382586, 92.937495],
  [42.382655, 92.937323],
  [42.382686, 92.937256],
  [42.382726, 92.937181],
  [42.382726, 92.937181],
  [42.382785, 92.937133],
  [42.382908, 92.937056],
  [42.382976, 92.937021],
  [42.383051, 92.93699],
  [42.38312, 92.936955],
  [42.383195, 92.936916],
  [42.383268, 92.936876],
  [42.383415, 92.936793],
  [42.383415, 92.936793],
  [42.383558, 92.93671],
  [42.38363, 92.936676],
  [42.383691, 92.93664],
  [42.383751, 92.936596],
  [42.383801, 92.93654],
  [42.383801, 92.93654],
  [42.383848, 92.93648],
  [42.383853, 92.936416],
  [42.383853, 92.936416],
  [42.383813, 92.936236],
  [42.383784, 92.936196],
  [42.383723, 92.936168],
  [42.383723, 92.936168],
  [42.38362, 92.936153],
  [42.38348, 92.936163],
  [42.383423, 92.93618],
  [42.383366, 92.936205],
  [42.383316, 92.936243],
  [42.383261, 92.936281],
  [42.383201, 92.936306],
  [42.38314, 92.936335],
  [42.383078, 92.936358],
  [42.383016, 92.93638],
  [42.382885, 92.936455],
  [42.382885, 92.936455],
  [42.382761, 92.936521],
  [42.3827, 92.936555],
  [42.38265, 92.936575],
  [42.382586, 92.936598],
  [42.38252, 92.93662],
  [42.382456, 92.936653],
  [42.382343, 92.936703],
  [42.382343, 92.936703],
  [42.382283, 92.936735],
  [42.382196, 92.936808],
  [42.382151, 92.936836],
  [42.382096, 92.93686],
  [42.382038, 92.93688],
  [42.381986, 92.936901],
  [42.381935, 92.936916],
  [42.381888, 92.936931],
  [42.381838, 92.936955],
  [42.381783, 92.936971],
  [42.381685, 92.93701],
  [42.381685, 92.93701],
  [42.381578, 92.93707],
  [42.381521, 92.937096],
  [42.381461, 92.937123],
  [42.381405, 92.93715],
  [42.381355, 92.937166],
  [42.381303, 92.937191],
  [42.381228, 92.93722],
  [42.381228, 92.93722],
  [42.38118, 92.93722],
  [42.381081, 92.937205],
  [42.381041, 92.937201],
  [42.381003, 92.937196],
  [42.380951, 92.937196],
  [42.380951, 92.937196],
  [42.380871, 92.93719],
  [42.380843, 92.937181],
  [42.380803, 92.937183],
  [42.380725, 92.937175],
  [42.380691, 92.937163],
  [42.380691, 92.937163],
  [42.380623, 92.937146],
  [42.380593, 92.93713],
  [42.380558, 92.937121],
  [42.380536, 92.937111],
  [42.380536, 92.937111],
  [42.380483, 92.937083],
  [42.380438, 92.937026],
  [42.380438, 92.937026],
  [42.380395, 92.936995],
  [42.380368, 92.936985],
  [42.380368, 92.936985],
  [42.380318, 92.936963],
  [42.3803, 92.936951],
  [42.38027, 92.93695],
  [42.380248, 92.936938],
  [42.38023, 92.936918],
  [42.380211, 92.936915],
  [42.380176, 92.936893],
  [42.380176, 92.936893],
  [42.380165, 92.936881],
  [42.38012, 92.936868],
  [42.380093, 92.936856],
  [42.380076, 92.93684],
  [42.38006, 92.936833],
  [42.380048, 92.936821],
  [42.380031, 92.936808],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.380025, 92.936803],
  [42.379925, 92.936763],
  [42.379901, 92.936768],
  [42.379901, 92.936768],
  [42.379856, 92.936786],
  [42.37984, 92.936811],
  [42.379835, 92.936845],
  [42.37982, 92.936871],
  [42.37982, 92.93689],
  [42.379813, 92.936898],
  [42.379813, 92.936898],
  [42.380003, 92.936693],
  [42.380003, 92.936685],
  [42.380001, 92.936683],
  [42.38, 92.93668],
  [42.379993, 92.93667],
  [42.379993, 92.93667],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.379993, 92.936673],
  [42.37993, 92.936745],
  [42.37994, 92.936776],
  [42.37994, 92.936776],
  [42.37994, 92.936795],
  [42.37996, 92.93684],
  [42.379968, 92.93686],
  [42.37998, 92.936875],
  [42.379993, 92.936901],
  [42.379993, 92.936901],
  [42.380028, 92.936936],
  [42.380035, 92.936945],
  [42.380046, 92.936963],
  [42.380091, 92.936958],
  [42.380091, 92.936958],
  [42.380095, 92.936948],
  [42.380121, 92.936951],
  [42.380133, 92.936953],
  [42.38015, 92.936958],
  [42.380168, 92.936955],
  [42.38018, 92.936955],
  [42.38018, 92.936955],
  [42.3802, 92.936965],
  [42.380228, 92.937006],
  [42.38024, 92.937025],
  [42.380258, 92.93704],
  [42.380268, 92.937051],
  [42.38027, 92.937056],
  [42.38027, 92.937056],
  [42.38027, 92.937056],
  [42.38027, 92.937056],
  [42.38027, 92.937056],
  [42.38027, 92.937056],
  [42.380395, 92.937138],
  [42.380418, 92.937133],
  [42.380443, 92.937138],
  [42.38047, 92.937141],
  [42.380501, 92.93714],
  [42.380531, 92.937143],
  [42.380566, 92.937143],
  [42.380566, 92.937143],
  [42.38063, 92.937156],
  [42.380658, 92.937166],
  [42.380725, 92.93719],
  [42.380768, 92.937201],
  [42.38081, 92.937215],
  [42.380843, 92.937213],
  [42.380881, 92.937221],
  [42.380916, 92.937223],
  [42.38095, 92.937216],
  [42.380983, 92.937226],
  [42.381016, 92.937231],
  [42.381056, 92.93722],
  [42.381115, 92.937225],
  [42.381146, 92.93721],
  [42.381176, 92.937195],
  [42.381216, 92.937191],
  [42.38125, 92.937195],
  [42.381283, 92.937188],
  [42.381308, 92.937171],
  [42.381341, 92.937163],
  [42.381375, 92.937138],
  [42.381455, 92.937121],
  [42.381455, 92.937121],
  [42.381555, 92.937091],
  [42.381606, 92.937075],
  [42.381661, 92.937051],
  [42.381708, 92.937023],
  [42.381756, 92.936996],
  [42.381811, 92.93696],
  [42.381921, 92.936903],
  [42.381921, 92.936903],
  [42.381981, 92.936865],
  [42.382091, 92.936815],
  [42.382146, 92.936788],
  [42.38219, 92.93676],
  [42.382236, 92.936728],
  [42.382286, 92.936701],
  [42.382343, 92.936675],
  [42.3824, 92.936638],
  [42.382446, 92.936626],
  [42.382506, 92.936608],
  [42.38258, 92.936568],
  [42.38258, 92.936568],
  [42.382668, 92.936553],
  [42.382721, 92.936543],
  [42.38277, 92.936525],
  [42.382825, 92.936503],
  [42.382891, 92.936496],
  [42.382956, 92.936478],
  [42.383075, 92.93641],
  [42.383075, 92.93641],
  [42.38313, 92.936371],
  [42.383236, 92.936313],
  [42.383288, 92.936295],
  [42.38334, 92.936275],
  [42.383391, 92.936246],
  [42.383451, 92.936235],
  [42.383505, 92.936225],
  [42.383556, 92.936205],
  [42.383613, 92.936185],
  [42.383663, 92.936185],
  [42.38372, 92.936205],
  [42.383775, 92.936243],
  [42.383853, 92.936328],
  [42.383878, 92.936381],
  [42.383878, 92.936381],
  [42.383878, 92.936448],
  [42.383871, 92.93651],
  [42.383871, 92.93651],
  [42.383773, 92.93665],
  [42.38373, 92.936688],
  [42.383611, 92.936718],
  [42.383611, 92.936718],
  [42.383553, 92.936753],
  [42.383421, 92.936825],
  [42.383351, 92.936855],
  [42.383286, 92.93689],
  [42.383236, 92.936911],
  [42.383168, 92.936933],
  [42.383105, 92.936953],
  [42.383046, 92.93699],
  [42.382983, 92.937028],
  [42.382915, 92.937071],
  [42.382778, 92.93714],
  [42.382778, 92.93714],
  [42.382695, 92.937231],
  [42.382663, 92.937286],
  [42.38263, 92.937346],
  [42.382593, 92.937423],
  [42.382576, 92.937485],
  [42.38255, 92.937561],
  [42.382506, 92.937703],
  [42.382506, 92.937703],
  [42.38248, 92.937771],
  [42.382396, 92.937875],
  [42.382341, 92.937913],
  [42.38229, 92.937953],
  [42.382241, 92.937991],
  [42.38219, 92.938028],
  [42.38214, 92.938043],
  [42.382085, 92.938055],
  [42.38203, 92.938065],
  [42.381896, 92.938131],
  [42.381833, 92.938168],
  [42.381833, 92.938168],
  [42.381698, 92.938256],
  [42.381636, 92.93829],
  [42.381573, 92.938321],
  [42.381508, 92.938361],
  [42.381441, 92.938416],
  [42.381385, 92.938458],
  [42.381335, 92.938541],
  [42.381281, 92.938615],
  [42.381191, 92.938755],
  [42.381148, 92.938816],
  [42.381148, 92.938816],
  [42.38106, 92.938953],
  [42.381001, 92.939003],
  [42.38095, 92.939051],
  [42.380901, 92.939105],
  [42.380855, 92.939151],
  [42.3808, 92.9392],
  [42.380688, 92.939281],
  [42.380688, 92.939281],
  [42.38063, 92.93932],
  [42.380505, 92.939401],
  [42.38045, 92.939438],
  [42.380396, 92.939461],
  [42.380341, 92.939496],
  [42.380281, 92.939525],
  [42.380223, 92.939555],
  [42.380163, 92.93959],
  [42.38011, 92.939611],
  [42.380011, 92.939666],
  [42.379965, 92.939716],
  [42.379965, 92.939716],
  [42.379891, 92.939845],
  [42.379871, 92.939903],
  [42.37984, 92.939975],
  [42.379808, 92.940045],
  [42.379785, 92.94012],
  [42.379761, 92.940193],
  [42.379733, 92.940325],
  [42.379733, 92.940325],
  [42.379688, 92.940471],
  [42.379665, 92.940545],
  [42.379675, 92.940616],
  [42.37968, 92.9407],
  [42.37968, 92.940778],
  [42.379686, 92.940871],
  [42.379691, 92.94098],
  [42.379696, 92.941093],
  [42.379703, 92.941215],
  [42.379701, 92.941455],
  [42.3797, 92.94158],
  [42.3797, 92.94158],
  [42.379686, 92.94184],
  [42.37967, 92.941961],
  [42.379653, 92.942095],
  [42.379638, 92.942231],
  [42.379625, 92.942361],
  [42.379615, 92.942476],
  [42.379593, 92.942708],
  [42.379593, 92.942708],
  [42.379571, 92.9429],
  [42.379573, 92.943008],
  [42.379565, 92.943126],
  [42.37955, 92.943255],
  [42.379543, 92.94339],
  [42.37953, 92.943528],
  [42.379521, 92.94365],
  [42.379501, 92.94377],
  [42.379478, 92.943906],
  [42.379443, 92.944173],
  [42.379443, 92.944173],
  [42.379406, 92.944388],
  [42.379373, 92.944501],
  [42.379341, 92.944623],
  [42.379315, 92.944748],
  [42.37928, 92.944868],
  [42.379256, 92.94499],
  [42.37923, 92.945115],
  [42.379206, 92.94523],
  [42.379186, 92.945341],
  [42.379166, 92.945558],
  [42.379168, 92.945656],
  [42.379166, 92.945763],
  [42.379153, 92.94587],
  [42.379125, 92.945976],
  [42.37911, 92.94608],
  [42.379081, 92.946183],
  [42.379026, 92.946278],
  [42.378963, 92.94634],
  [42.378826, 92.946451],
  [42.378826, 92.946451],
  [42.378671, 92.946546],
  [42.378596, 92.946593],
  [42.378521, 92.94664],
  [42.37844, 92.946693],
  [42.378356, 92.946746],
  [42.37827, 92.946801],
  [42.378085, 92.946895],
  [42.378085, 92.946895],
  [42.377996, 92.946946],
  [42.377831, 92.947063],
  [42.377753, 92.947123],
  [42.37767, 92.947185],
  [42.377583, 92.947241],
  [42.377506, 92.9473],
  [42.377426, 92.947363],
  [42.377355, 92.94743],
  [42.377283, 92.947495],
  [42.377213, 92.947558],
  [42.377051, 92.947693],
  [42.377051, 92.947693],
  [42.376881, 92.94782],
  [42.376801, 92.947871],
  [42.376715, 92.947918],
  [42.376626, 92.947975],
  [42.376541, 92.948023],
  [42.376463, 92.948066],
  [42.376288, 92.948155],
  [42.376288, 92.948155],
  [42.376191, 92.948205],
  [42.376015, 92.948303],
  [42.376015, 92.948303],
  [42.375851, 92.948395],
  [42.37577, 92.948438],
  [42.37577, 92.948438],
  [42.375603, 92.948533],
  [42.375515, 92.948583],
  [42.375426, 92.948638],
  [42.375251, 92.948746],
  [42.375251, 92.948746],
  [42.375171, 92.948805],
  [42.375018, 92.948908],
  [42.37494, 92.948963],
  [42.374848, 92.949028],
  [42.374763, 92.949086],
  [42.374665, 92.949138],
  [42.374571, 92.949191],
  [42.374483, 92.949238],
  [42.3744, 92.949286],
  [42.374216, 92.949388],
  [42.374133, 92.949441],
  [42.374133, 92.949441],
  [42.373956, 92.94955],
  [42.373856, 92.949605],
  [42.373776, 92.949661],
  [42.373695, 92.949716],
  [42.373608, 92.949771],
  [42.373526, 92.94982],
  [42.373358, 92.949895],
  [42.373358, 92.949895],
  [42.373281, 92.94994],
  [42.373121, 92.950033],
  [42.373045, 92.950086],
  [42.372971, 92.950141],
  [42.372893, 92.95019],
  [42.372816, 92.95023],
  [42.37274, 92.95027],
  [42.372668, 92.950315],
  [42.372598, 92.950368],
  [42.37246, 92.950498],
  [42.372391, 92.95055],
  [42.372391, 92.95055],
  [42.372251, 92.950643],
  [42.372173, 92.950685],
  [42.372105, 92.950746],
  [42.372043, 92.95079],
  [42.371981, 92.950845],
  [42.371908, 92.950908],
  [42.371775, 92.951041],
  [42.371775, 92.951041],
  [42.37164, 92.951175],
  [42.371583, 92.951273],
  [42.371531, 92.951388],
  [42.371491, 92.95151],
  [42.371466, 92.951631],
  [42.371443, 92.951745],
  [42.371473, 92.95186],
  [42.371513, 92.951983],
  [42.371556, 92.9521],
  [42.371621, 92.952316],
  [42.371655, 92.952418],
  [42.371686, 92.952501],
  [42.371718, 92.95257],
  [42.371745, 92.952646],
  [42.371766, 92.952738],
  [42.371786, 92.952823],
  [42.371805, 92.9529],
  [42.371823, 92.952955],
  [42.37184, 92.953033],
  [42.371853, 92.953101],
  [42.371886, 92.953223],
  [42.371901, 92.953275],
  [42.371901, 92.953275],
  [42.371928, 92.953368],
  [42.371938, 92.953411],
  [42.371951, 92.953463],
  [42.371958, 92.953501],
  [42.371971, 92.953546],
  [42.371976, 92.953591],
  [42.371998, 92.953701],
  [42.371998, 92.953701],
  [42.372021, 92.95381],
  [42.37203, 92.953853],
  [42.372038, 92.953901],
  [42.372045, 92.953955],
  [42.372053, 92.954003],
  [42.372055, 92.95406],
  [42.372066, 92.954126],
  [42.372073, 92.954181],
  [42.372083, 92.954228],
  [42.372113, 92.954295],
  [42.372111, 92.95434],
  [42.372118, 92.954381],
  [42.372125, 92.954433],
  [42.372126, 92.954493],
  [42.37213, 92.954565],
  [42.37213, 92.954635],
  [42.372133, 92.954711],
  [42.372135, 92.954791],
  [42.372131, 92.95495],
  [42.372131, 92.95495],
  [42.372105, 92.955111],
  [42.372106, 92.955195],
  [42.372106, 92.955288],
  [42.3721, 92.955385],
  [42.3721, 92.955471],
  [42.372105, 92.955563],
  [42.372111, 92.955651],
  [42.372111, 92.955651],
  [42.372118, 92.955801],
  [42.372125, 92.955956],
  [42.372131, 92.956008],
  [42.372141, 92.95608],
  [42.372151, 92.956156],
  [42.372163, 92.95623],
  [42.372166, 92.9563],
  [42.372176, 92.956373],
  [42.372181, 92.956448],
  [42.372198, 92.956578],
  [42.372203, 92.956635],
  [42.372203, 92.956635],
  [42.37221, 92.956755],
  [42.372213, 92.956823],
  [42.37221, 92.956911],
  [42.372206, 92.95698],
  [42.3722, 92.957061],
  [42.372198, 92.957138],
  [42.372201, 92.957191],
  [42.372205, 92.957238],
  [42.372211, 92.957291],
  [42.37222, 92.957401],
  [42.37222, 92.957401],
  [42.372233, 92.957516],
  [42.372236, 92.95757],
  [42.372236, 92.957611],
  [42.372243, 92.957666],
  [42.37224, 92.957723],
  [42.372246, 92.957788],
  [42.37226, 92.9579],
  [42.37226, 92.9579],
  [42.37226, 92.9579],
  [42.372268, 92.958051],
  [42.372261, 92.958108],
  [42.37226, 92.958161],
  [42.37226, 92.958211],
  [42.372263, 92.958276],
  [42.37227, 92.958343],
  [42.37228, 92.958405],
  [42.372288, 92.958461],
  [42.372298, 92.958583],
  [42.372295, 92.958636],
  [42.372295, 92.958636],
  [42.372306, 92.958778],
  [42.372308, 92.958835],
  [42.372321, 92.9589],
  [42.372323, 92.958945],
  [42.372315, 92.958985],
  [42.372315, 92.959041],
  [42.372296, 92.959176],
  [42.372296, 92.959176],
  [42.37229, 92.959245],
  [42.372271, 92.959361],
  [42.372261, 92.959431],
  [42.372256, 92.959501],
  [42.37224, 92.959571],
  [42.372233, 92.959645],
  [42.372236, 92.959706],
  [42.372246, 92.95977],
  [42.372261, 92.959841],
  [42.372291, 92.959976],
  [42.372308, 92.96004],
  [42.372308, 92.96004],
  [42.372341, 92.960178],
  [42.372353, 92.96025],
  [42.372373, 92.960328],
  [42.372396, 92.960391],
  [42.372396, 92.960391],
  [42.372428, 92.96052],
  [42.372453, 92.96065],
  [42.372453, 92.96065],
  [42.37247, 92.960786],
  [42.372478, 92.960848],
  [42.372491, 92.9609],
  [42.372505, 92.960963],
  [42.372516, 92.961041],
  [42.372523, 92.961118],
  [42.372536, 92.96118],
  [42.372551, 92.961228],
  [42.372571, 92.961278],
  [42.372596, 92.961385],
  [42.372596, 92.961385],
  [42.372625, 92.961503],
  [42.372643, 92.961568],
  [42.372663, 92.961628],
  [42.372681, 92.961693],
  [42.372691, 92.961745],
  [42.3727, 92.961805],
  [42.372701, 92.961865],
  [42.3727, 92.961926],
  [42.372705, 92.961991],
  [42.372726, 92.962105],
  [42.37273, 92.962158],
  [42.37273, 92.962158],
  [42.37274, 92.962268],
  [42.372751, 92.962321],
  [42.372755, 92.96239],
  [42.37277, 92.962431],
  [42.372783, 92.962476],
  [42.372793, 92.962526],
  [42.372816, 92.962638],
  [42.372816, 92.962638],
  [42.372835, 92.96278],
  [42.372843, 92.962843],
  [42.372851, 92.962895],
  [42.372863, 92.962938],
  [42.37288, 92.962996],
  [42.372905, 92.96306],
  [42.372916, 92.96312],
  [42.372928, 92.963178],
  [42.37294, 92.963233],
  [42.372965, 92.963351],
  [42.37298, 92.963411],
  [42.372995, 92.963483],
  [42.373011, 92.963545],
  [42.37302, 92.96362],
  [42.373031, 92.963693],
  [42.37305, 92.963758],
  [42.373056, 92.963828],
  [42.373065, 92.963898],
  [42.37307, 92.96404],
  [42.37307, 92.96404],
  [42.37302, 92.964153],
  [42.372998, 92.964201],
  [42.372998, 92.964201],
  [42.372955, 92.964278],
  [42.372926, 92.964313],
  [42.372888, 92.964356],
  [42.372826, 92.96443],
  [42.372826, 92.96443],
  [42.372788, 92.964468],
  [42.37272, 92.964551],
  [42.372686, 92.964605],
  [42.37266, 92.964665],
  [42.372626, 92.964716],
  [42.372591, 92.964776],
  [42.372566, 92.964843],
  [42.372548, 92.964903],
  [42.372528, 92.96496],
  [42.372475, 92.965066],
  [42.37245, 92.96512],
  [42.37245, 92.96512],
  [42.372388, 92.965233],
  [42.372361, 92.965291],
  [42.372335, 92.965346],
  [42.372295, 92.965403],
  [42.372265, 92.96545],
  [42.372235, 92.965505],
  [42.372215, 92.965556],
  [42.372188, 92.9656],
  [42.372166, 92.965648],
  [42.372125, 92.965751],
  [42.372125, 92.965751],
  [42.372071, 92.965858],
  [42.37205, 92.965906],
  [42.372021, 92.965965],
  [42.372, 92.966021],
  [42.371973, 92.966076],
  [42.371951, 92.966128],
  [42.371905, 92.966245],
  [42.371905, 92.966245],
  [42.371885, 92.966291],
  [42.371843, 92.966375],
  [42.371823, 92.966423],
  [42.371805, 92.96647],
  [42.371783, 92.96654],
  [42.371753, 92.966596],
  [42.371726, 92.966646],
  [42.371696, 92.966691],
  [42.371675, 92.966736],
  [42.37164, 92.966833],
  [42.37162, 92.966878],
  [42.37162, 92.966878],
  [42.37157, 92.966973],
  [42.371541, 92.967016],
  [42.37151, 92.967063],
  [42.371478, 92.967108],
  [42.371456, 92.967151],
  [42.371425, 92.967196],
  [42.371375, 92.96727],
  [42.371375, 92.96727],
  [42.371343, 92.967366],
  [42.371321, 92.967416],
  [42.371301, 92.967473],
  [42.371278, 92.967523],
  [42.371256, 92.967563],
  [42.371235, 92.9676],
  [42.371208, 92.967643],
  [42.371191, 92.967685],
  [42.371171, 92.96773],
  [42.371141, 92.967806],
  [42.371103, 92.967846],
  [42.371103, 92.967846],
  [42.371081, 92.967938],
  [42.371055, 92.967978],
  [42.371036, 92.968035],
  [42.37101, 92.968073],
  [42.370975, 92.968098],
  [42.370941, 92.968131],
  [42.37088, 92.968198],
  [42.37088, 92.968198],
  [42.370846, 92.968218],
  [42.370815, 92.968231],
  [42.370786, 92.968221],
  [42.370745, 92.96823],
  [42.370715, 92.968243],
  [42.370715, 92.968243],
  [42.370691, 92.968266],
  [42.370676, 92.968303],
  [42.37066, 92.96832],
  [42.370651, 92.968356],
  [42.370663, 92.968406],
  [42.370671, 92.968425],
  [42.370671, 92.968425],
  [42.370695, 92.968425],
  [42.370711, 92.968443],
  [42.370743, 92.968441],
  [42.370763, 92.968443],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.37077, 92.968438],
  [42.370766, 92.968445],
  [42.370766, 92.968445],
  [42.370766, 92.968431],
  [42.370758, 92.968421],
  [42.370751, 92.96842],
  [42.370748, 92.968418],
  [42.370741, 92.968416],
  [42.37072, 92.968406],
  [42.370701, 92.968406],
  [42.370701, 92.968406],
  [42.370688, 92.968416],
  [42.370656, 92.96842],
  [42.370643, 92.96842],
  [42.370633, 92.968421],
  [42.370628, 92.96843],
  [42.370621, 92.968435],
  [42.370621, 92.968435],
  [42.370621, 92.968435],
  [42.370621, 92.968435],
  [42.370621, 92.968435],
  [42.370701, 92.968408],
  [42.370715, 92.968385],
  [42.37074, 92.968351],
  [42.370771, 92.968326],
  [42.370798, 92.9683],
  [42.370826, 92.968276],
  [42.370868, 92.968266],
  [42.370933, 92.968226],
  [42.370965, 92.968203],
  [42.370965, 92.968203],
  [42.371016, 92.968156],
  [42.371043, 92.968113],
  [42.371055, 92.968073],
  [42.371076, 92.96803],
  [42.371095, 92.967978],
  [42.37114, 92.967935],
  [42.371215, 92.967863],
  [42.371215, 92.967863],
  [42.37125, 92.967811],
  [42.371313, 92.967685],
  [42.371348, 92.967616],
  [42.37138, 92.967541],
  [42.371416, 92.967466],
  [42.371458, 92.967385],
  [42.371498, 92.967301],
  [42.371546, 92.967216],
  [42.3716, 92.96713],
  [42.37167, 92.966963],
  [42.37167, 92.966963],
  [42.371701, 92.96689],
  [42.371765, 92.966751],
  [42.371795, 92.96668],
  [42.37183, 92.9666],
  [42.371868, 92.966521],
  [42.371911, 92.966441],
  [42.371953, 92.966371],
  [42.371996, 92.966291],
  [42.372035, 92.966211],
  [42.372126, 92.966046],
  [42.37217, 92.965965],
  [42.37217, 92.965965],
  [42.372253, 92.96579],
  [42.372286, 92.965713],
  [42.372318, 92.965648],
  [42.372351, 92.965576],
  [42.372386, 92.965498],
  [42.372421, 92.965415],
  [42.372508, 92.965265],
  [42.372508, 92.965265],
  [42.37259, 92.965113],
  [42.372631, 92.965045],
  [42.372668, 92.964976],
  [42.372715, 92.964903],
  [42.372753, 92.964831],
  [42.372793, 92.964768],
  [42.372831, 92.964698],
  [42.37288, 92.964648],
  [42.372926, 92.9646],
  [42.373003, 92.964481],
  [42.373036, 92.964403],
  [42.373036, 92.964403],
  [42.373105, 92.96427],
  [42.373138, 92.964213],
  [42.373166, 92.96414],
  [42.373193, 92.964071],
  [42.373203, 92.963998],
  [42.373211, 92.963938],
  [42.373208, 92.963798],
  [42.373208, 92.963798],
  [42.373201, 92.96362],
  [42.37318, 92.963531],
  [42.373163, 92.963436],
  [42.37315, 92.963361],
  [42.373131, 92.963283],
  [42.37311, 92.963198],
  [42.373081, 92.96312],
  [42.373053, 92.963031],
  [42.373033, 92.962933],
  [42.372986, 92.962741],
  [42.372961, 92.962643],
  [42.372941, 92.96255],
  [42.372923, 92.96246],
  [42.372905, 92.962365],
  [42.372886, 92.962268],
  [42.37287, 92.962171],
  [42.372846, 92.96207],
  [42.372818, 92.961965],
  [42.372791, 92.961855],
  [42.372771, 92.961753],
  [42.372736, 92.96154],
  [42.372715, 92.961431],
  [42.372715, 92.961431],
  [42.372666, 92.961215],
  [42.372636, 92.961101],
  [42.372608, 92.960983],
  [42.372585, 92.960863],
  [42.372556, 92.960755],
  [42.372531, 92.960643],
  [42.37248, 92.960421],
  [42.37248, 92.960421],
  [42.372433, 92.960201],
  [42.372408, 92.960091],
  [42.372388, 92.95999],
  [42.372365, 92.959888],
  [42.37235, 92.959783],
  [42.372336, 92.959693],
  [42.372348, 92.959528],
  [42.372348, 92.959528],
  [42.37237, 92.959438],
  [42.372431, 92.959288],
  [42.37245, 92.959198],
  [42.37246, 92.95912],
  [42.372466, 92.95905],
  [42.372468, 92.958958],
  [42.372463, 92.958866],
  [42.37246, 92.95877],
  [42.372451, 92.958676],
  [42.372443, 92.958583],
  [42.372423, 92.958396],
  [42.372423, 92.958396],
  [42.372405, 92.958188],
  [42.37239, 92.958083],
  [42.372383, 92.957975],
  [42.372373, 92.957856],
  [42.372365, 92.957741],
  [42.372353, 92.957615],
  [42.372321, 92.957363],
  [42.372321, 92.957363],
  [42.372311, 92.95725],
  [42.372283, 92.957035],
  [42.372281, 92.956935],
  [42.372281, 92.956835],
  [42.372276, 92.956741],
  [42.372276, 92.956646],
  [42.372275, 92.95655],
  [42.372281, 92.956456],
  [42.372288, 92.956366],
  [42.372276, 92.956195],
  [42.372285, 92.956123],
  [42.372285, 92.956123],
  [42.372285, 92.955961],
  [42.372276, 92.955866],
  [42.372275, 92.955771],
  [42.372266, 92.955668],
  [42.372251, 92.95556],
  [42.372235, 92.955461],
  [42.372201, 92.955226],
  [42.372201, 92.955226],
  [42.372188, 92.955121],
  [42.372176, 92.95495],
  [42.372176, 92.95495],
  [42.372188, 92.954791],
  [42.372193, 92.954705],
  [42.372201, 92.954621],
  [42.372205, 92.954541],
  [42.372203, 92.954455],
  [42.37221, 92.954373],
  [42.372225, 92.954226],
  [42.372225, 92.954226],
  [42.372215, 92.954148],
  [42.372203, 92.953965],
  [42.37218, 92.953871],
  [42.372158, 92.953768],
  [42.372138, 92.953665],
  [42.372118, 92.95355],
  [42.372093, 92.95343],
  [42.37207, 92.953321],
  [42.372048, 92.953216],
  [42.372, 92.953018],
  [42.371978, 92.952931],
  [42.371978, 92.952931],
  [42.371931, 92.952755],
  [42.371905, 92.952671],
  [42.37187, 92.952558],
  [42.371826, 92.952456],
  [42.371791, 92.952346],
  [42.371758, 92.952246],
  [42.371695, 92.952031],
  [42.371695, 92.952031],
  [42.371621, 92.951823],
  [42.371603, 92.951713],
  [42.371606, 92.95161],
  [42.37161, 92.951516],
  [42.371641, 92.951398],
  [42.371675, 92.951308],
  [42.371715, 92.951221],
  [42.371778, 92.951145],
  [42.37185, 92.951068],
  [42.372016, 92.95093],
  [42.372101, 92.950855],
  [42.372101, 92.950855],
  [42.37227, 92.950728],
  [42.372356, 92.950676],
  [42.372443, 92.950618],
  [42.372536, 92.950566],
  [42.372626, 92.95052],
  [42.372718, 92.950478],
  [42.37288, 92.950365],
  [42.37288, 92.950365],
  [42.373061, 92.950281],
  [42.373158, 92.950231],
  [42.373243, 92.950178],
  [42.373335, 92.950118],
  [42.373416, 92.950056],
  [42.3735, 92.950003],
  [42.373585, 92.949943],
  [42.373673, 92.949896],
  [42.373765, 92.949843],
  [42.373936, 92.949726],
  [42.373936, 92.949726],
  [42.374118, 92.949625],
  [42.374203, 92.949571],
  [42.374293, 92.949515],
  [42.374368, 92.949456],
  [42.374445, 92.949396],
  [42.374531, 92.949346],
  [42.374615, 92.94929],
  [42.374705, 92.949236],
  [42.374798, 92.949188],
  [42.374981, 92.949096],
  [42.37507, 92.949048],
  [42.375153, 92.948996],
  [42.375236, 92.94894],
  [42.375318, 92.94889],
  [42.375405, 92.94884],
  [42.375495, 92.948805],
  [42.375578, 92.948775],
  [42.375661, 92.948733],
  [42.375856, 92.948635],
  [42.375856, 92.948635],
  [42.376031, 92.948513],
  [42.376108, 92.948446],
  [42.376186, 92.948388],
  [42.376263, 92.948326],
  [42.376348, 92.948275],
  [42.37644, 92.94823],
  [42.3766, 92.948113],
  [42.3766, 92.948113],
  [42.376686, 92.948068],
  [42.376853, 92.947966],
  [42.376938, 92.947915],
  [42.377023, 92.947858],
  [42.377105, 92.947796],
  [42.377185, 92.947735],
  [42.377273, 92.947681],
  [42.377355, 92.947618],
  [42.377435, 92.947565],
  [42.377511, 92.94751],
  [42.377683, 92.947398],
  [42.377683, 92.947398],
  [42.37786, 92.947303],
  [42.377945, 92.947246],
  [42.378033, 92.947188],
  [42.378118, 92.947136],
  [42.378205, 92.947091],
  [42.378288, 92.94704],
  [42.378463, 92.946943],
  [42.378463, 92.946943],
  [42.378548, 92.946893],
  [42.378703, 92.946786],
  [42.378778, 92.946731],
  [42.378845, 92.946676],
  [42.378911, 92.946616],
  [42.378976, 92.946558],
  [42.379035, 92.946483],
  [42.379098, 92.946401],
  [42.379153, 92.946323],
  [42.379238, 92.94613],
  [42.379266, 92.946013],
  [42.379266, 92.946013],
  [42.379311, 92.945796],
  [42.37932, 92.945683],
  [42.379335, 92.945566],
  [42.379348, 92.945446],
  [42.379365, 92.945341],
  [42.379373, 92.945228],
  [42.379366, 92.945125],
  [42.37937, 92.945021],
  [42.379361, 92.944808],
  [42.379366, 92.944701],
  [42.379366, 92.944701],
  [42.3794, 92.944465],
  [42.379418, 92.944351],
  [42.379426, 92.944233],
  [42.379445, 92.944118],
  [42.379463, 92.944003],
  [42.379486, 92.943881],
  [42.379533, 92.943635],
  [42.379533, 92.943635],
  [42.379551, 92.943518],
  [42.379566, 92.943288],
  [42.379586, 92.943168],
  [42.379603, 92.943066],
  [42.379618, 92.942955],
  [42.37963, 92.942853],
  [42.379645, 92.942756],
  [42.379658, 92.94265],
  [42.379661, 92.942553],
  [42.379668, 92.942331],
  [42.37967, 92.942215],
  [42.37967, 92.942215],
  [42.379668, 92.941991],
  [42.379668, 92.941883],
  [42.379671, 92.941775],
  [42.379678, 92.941653],
  [42.379685, 92.941536],
  [42.379695, 92.941425],
  [42.379715, 92.941201],
  [42.379715, 92.941201],
  [42.379728, 92.94099],
  [42.379736, 92.940875],
  [42.379738, 92.940768],
  [42.379745, 92.94066],
  [42.379756, 92.940575],
  [42.379768, 92.94049],
  [42.379786, 92.940401],
  [42.379828, 92.940306],
  [42.379866, 92.940205],
  [42.37992, 92.94003],
  [42.37995, 92.939925],
  [42.37995, 92.939925],
  [42.37999, 92.939753],
  [42.380021, 92.93968],
  [42.38008, 92.93963],
  [42.380143, 92.93957],
  [42.380211, 92.939528],
  [42.380291, 92.939486],
  [42.38044, 92.939411],
  [42.38044, 92.939411],
  [42.380576, 92.939323],
  [42.380656, 92.939278],
  [42.380656, 92.939278],
  [42.380775, 92.939195],
  [42.380838, 92.939161],
  [42.380898, 92.939135],
  [42.380953, 92.939096],
  [42.380998, 92.939055],
  [42.38104, 92.939015],
  [42.381155, 92.938898],
  [42.381155, 92.938898],
  [42.381261, 92.938756],
  [42.381318, 92.93868],
  [42.381381, 92.938601],
  [42.381431, 92.938518],
  [42.381498, 92.938448],
  [42.381568, 92.938395],
  [42.381635, 92.938346],
  [42.381706, 92.938303],
  [42.381776, 92.938263],
  [42.381916, 92.93818],
  [42.381985, 92.938128],
  [42.382056, 92.938076],
  [42.382128, 92.938041],
  [42.382206, 92.938008],
  [42.382283, 92.93797],
  [42.38236, 92.937925],
  [42.382433, 92.937886],
  [42.382493, 92.937833],
  [42.38258, 92.937685],
  [42.38258, 92.937685],
  [42.382645, 92.937508],
  [42.382661, 92.937408],
  [42.38269, 92.937301],
  [42.38269, 92.937301],
  [42.382735, 92.937203],
  [42.382863, 92.93708],
  [42.38294, 92.937033],
  [42.383011, 92.936993],
  [42.38309, 92.936956],
  [42.383161, 92.936916],
  [42.383236, 92.936881],
  [42.383388, 92.936806],
  [42.383388, 92.936806],
  [42.383468, 92.936766],
  [42.383616, 92.936681],
  [42.383681, 92.936643],
  [42.383763, 92.936596],
  [42.383816, 92.936558],
  [42.383816, 92.936558],
  [42.383861, 92.936488],
  [42.383891, 92.936431],
  [42.383905, 92.936365],
  [42.383896, 92.936315],
  [42.383871, 92.936256],
  [42.383808, 92.936216],
  [42.383745, 92.936228],
  [42.38367, 92.936223],
  [42.38361, 92.936226],
  [42.383553, 92.936225],
  [42.383491, 92.936241],
  [42.383366, 92.936271],
  [42.383366, 92.936271],
  [42.38325, 92.936365],
  [42.383196, 92.936408],
  [42.383131, 92.936446],
  [42.383068, 92.936473],
  [42.382996, 92.936488],
  [42.382925, 92.936503],
  [42.382788, 92.936551],
  [42.382788, 92.936551],
  [42.382721, 92.93658],
  [42.382603, 92.936626],
  [42.382536, 92.936651],
  [42.382473, 92.936675],
  [42.382418, 92.9367],
  [42.382355, 92.936728],
  [42.382298, 92.93676],
  [42.382251, 92.936783],
  [42.382198, 92.936813],
  [42.382106, 92.93686],
  [42.382065, 92.936883],
  [42.382065, 92.936883],
  [42.381958, 92.936923],
  [42.381903, 92.936951],
  [42.381853, 92.936983],
  [42.381811, 92.937013],
  [42.381761, 92.937033],
  [42.381705, 92.937045],
  [42.381653, 92.937055],
  [42.381601, 92.937063],
  [42.38155, 92.937085],
  [42.381466, 92.937118],
  [42.381466, 92.937118],
  [42.381391, 92.93715],
  [42.381356, 92.937163],
  [42.381318, 92.93717],
  [42.381276, 92.937173],
  [42.381241, 92.937168],
  [42.381211, 92.937175],
  [42.381121, 92.937191],
  [42.381121, 92.937191],
  [42.381078, 92.937203],
  [42.380995, 92.937208],
  [42.380941, 92.937191],
  [42.380903, 92.937185],
  [42.380866, 92.937186],
  [42.380835, 92.93717],
  [42.380801, 92.937161],
  [42.380768, 92.93715],
  [42.380745, 92.937143],
  [42.380668, 92.937141],
  [42.380641, 92.937136],
  [42.380641, 92.937136],
  [42.38057, 92.937128],
  [42.380546, 92.93711],
  [42.380523, 92.937103],
  [42.380501, 92.937088],
  [42.380491, 92.937078],
  [42.380481, 92.937046],
  [42.380445, 92.93703],
  [42.380445, 92.93703],
  [42.380406, 92.937008],
  [42.380396, 92.936995],
  [42.380375, 92.936985],
  [42.380355, 92.936988],
  [42.380335, 92.93698],
  [42.380315, 92.936968],
  [42.380305, 92.93696],
  [42.380288, 92.936953]
]
