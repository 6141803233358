export default function draggable(el) {
  let x = 0;
  let y = 0;
  //当用户按下鼠标时触发，记录鼠标位置
  const handleMouseDown = (event) => {
    //clientX和clientY是鼠标指针在浏览器窗口中的横坐标和纵坐标的属性。
    //offsetLeft则是被拖动元素的左侧和顶部与其定位父元素的距离
    //以下代码是计算鼠标相对于被拖动元素 的初始偏移量，拖动时计算这个量
    x = event.clientX - el.offsetLeft;
    y = event.clientY - el.offsetTop;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    zindexRemove();
  };
  //计算新的偏移量
  const handleMouseMove = (event) => {
    el.style.left = `${event.clientX - x}px`;
    el.style.top = `${event.clientY - y}px`;
  };
  //鼠标抬起移除监听
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };
  //初始化监听鼠标点击
  el.querySelector(".closeTitle").addEventListener(
    "mousedown",
    handleMouseDown
  );
  function zindexRemove() {
    // el.style.zIndex = 100;
    let elements = document.getElementsByClassName("closeTitle");
    for (let index = 0; index < elements.length; index++) {

      elements[index].parentNode.style.zIndex = 100;
    }

    el.style.zIndex = 999;
  }
  //卸载监听事件
  // onUnmounted(() => {
  //   el.querySelector(".button").removeEventListener(
  //     "mousedown",
  //     handleMouseDown
  //   );
  //   document.removeEventListener("mousemove", handleMouseMove);
  //   document.removeEventListener("mouseup", handleMouseUp);
  // });
}
