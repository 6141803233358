import axios from 'axios';
async function GetCameraCfg(camera, viewMovement) {
  try {
    const res = await axios.post('/api/Calib/GetEyeView', camera);
    return res;
  } catch (error) {
    console.error(`请求视角投影参数时出现错误,错误原因:`, error);
    setTimeout(() => {
      viewMovement();
    }, 1000 * 5);
  }
}
async function GetPoleList(viewMovement = () => { }) {
  try {
    const res = await axios.post('/api/Setting/GetPoleList');
    console.log(res, '返回的值');
    return res;
  } catch (error) {
    console.error(`请求视角投影参数时出现错误,错误原因:`, error);
    setTimeout(() => {
      viewMovement();
    }, 1000 * 5);
    return;
  }
}
export { GetCameraCfg, GetPoleList };
