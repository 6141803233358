export const videoConfig = {
  hasAudio: false,

  // 解码
  useMSE: true,
  useWCS: true,
  useSIMD: true,
  autoWasm:false,

  // 配置低延迟（300ms）解决方案建议【直播流】

  // 配置低延迟（300ms）解决方案建议【直播流】
  videoName: new Date().getTime(),

  // 【录像集成】
  videoBuffer: 0.2, // 缓存时长
  isStream: true, //true 直播 false 录播

  // 【录像集成】


  //当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 等同于 setScaleMode(1)
  //当为false的时候：视频画面完全填充canvas区域,画面会被拉伸。等同于 setScaleMode(0)
  isResize: false,

  text: "",

  loadingText: "加载中",

  debug: false,
  debugLevel: "debug",


  // 显示网速
  showBandwidth: false,
  // // 显示性能
  //showPerformance: window.showPerformance,

  // 播放过程中，超时重连时，是否使用最后一帧来显示
  loadingTimeout: 5,
  // heartTimeout: 5,
  heartTimeoutReplayUseLastFrameShow: true,
  //无限重启
  loadingTimeoutReplay: -1,
  // 当为true的时候：ws协议不检验是否以.flv为依据，进行协议解析。
  isFlv: true,
  isNotMute: false,
  // 播放过程中，超时重连时，是否使用最后一帧来显示。
  heartTimeoutReplayUseLastFrameShow: true,
  showScreenButton:false,
 // videoBuffer:window.videoBuffer ,
  //双击全屏  // 修改 在文件中覆盖  不要改这里
   supportDblclickFullscreen: false,
 // videoBufferDelay: window.videoBufferDelay,
  demuxUseWorker:true,
  debug: false,

  }
