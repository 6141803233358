<template lang="">

  <div class="closeTitle">
    <span class="title">{{title}}</span>
    <span class="close" @click="close()">❌</span>
  </div>


</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: "",
      },
      close: {
        type: Function,
        default: () => { },
      },
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .closeTitle {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #333;
    color: #fff;
    cursor: move;
    user-select: none;

    .title {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 30px;
    }

    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      right: 10px;
      text-shadow: 2px 2px 2px #022122;
      cursor: pointer;
      animation: fontColor 1s;
      top: 50%;
      transform: translateY(-50%);

      &:active {
        animation: fade-in 1s ease;
      }
    }
  }

  @keyframes fade-in {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
</style>
