<template lang="">
  <div class="updateProxy" v-draggable>
    <closeTitle :close="close"></closeTitle>
    <div class="regionList">
      <regionListTable ref="proxyReference"></regionListTable>
    </div>
    <div class="newProxy">
      <div>新协议：</div>
      <!-- <div class="" style="width: 60%"><el-input v-model="proxy" placeholder="请输入内容"></el-input></div> -->
      <el-select v-model="proxy" placeholder="请选择">
        <el-option v-for="item in options" :key="item.proxy" :label="item.proxy" :value="item.proxy">
        </el-option>
      </el-select>
      <div style="margin-left: 10px;"><el-button type="primary" @click="updateProxy">更新</el-button></div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import closeTitle from "../closeTitle/index.vue";
  import regionListTable from "./regionListTable.vue";
  export default {
    components: {
      regionListTable,
      closeTitle,
    },
    props: {},
    data() {
      return {
        options: [{
          proxy: "http"
        }, {
          proxy: "https"
        }],
        proxy: ""
      };
    },
    created() { },
    mounted() { },
    methods: {
      //关闭方法
      close() {
        this.$emit("close");
      },
      updateProxy() {

        let multipleSelection = this.$refs.proxyReference.multipleSelection;
        let regionModelIds = []
        multipleSelection.forEach((item) => {
          regionModelIds.push(item.regionId)
        })
        console.log(multipleSelection, "当前选中项");
        axios.post("/api/Setting/BatchUpdateModelProxy",
          {
            regionModelIds: regionModelIds,
            modelProtocolNew: this.proxy
          }
        ).then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.$message({
              message: "更新成功",
              type: "success",
            });
            this.close();
          } else {
            this.$message({
              message: "更新失败",
              type: "error",
            });
          }
        })
      }
    },
  };
</script>
<style lang="scss" scoped>
  .updateProxy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    height: 300px;
    width: 500px;
    background-color: #fff;

    .regionList {
      height: 200px;
      overflow: auto;
    }

    .newProxy {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;

    }
  }
</style>