import axios from 'axios';


//实时预览心跳保活
export async function streamStatus(handle, channelId, errorCallback) {
  let error = undefined;
  let data = null;
  try {
    let result = await axios.post(
      `/api/itosApi/api/VideoStream/${handle}/${channelId}/StreamStatus`
    );
    data = result;
    console.log(`心跳保活完成,（channelId=${channelId},handle=${handle}）`);
  } catch (ex) {
    error = ex;
  }

  if (!data || data.status != 200) {
    //心跳接口errorId非0时代表出错
    error = '心跳接口响应异常';
  }
  if (error != undefined) {
    console.error(error)
    errorCallback && errorCallback(`请求心跳接口出错，详情：${error}`, error);
    console.error(
      `心跳保活异常,（channelId=${channelId},handle=${handle},error=${error}）`,
      error
    );
  }
  return data;
}

//开始实时预览
export async function start(
  channelId,
  streamType = undefined,
  successCallback,
  errorCallback
) {
  let options = {
    linkMode: window.jessConfig.linkMode,
    streamType
  };

  let api = `/api/itosApi/api/VideoStream/RealPlay/Start/${channelId}`;


  axios
    .post(api, {
      linkMode: window.jessConfig.linkMode,
      streamType: 0,
      onlyIFrame: 0
    })
    .then((resp) => {
      successCallback && successCallback(resp);
    })
    .catch((ex) => {
      errorCallback && errorCallback(ex);
    });
  // axios
  //   .post(api, options)
  //   .then((resp) => {
  //     successCallback && successCallback(resp);
  //   })
  //   .catch((ex) => {
  //     errorCallback && errorCallback(ex);
  //   });
}
