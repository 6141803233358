import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import vuebus from "vue-bus";
import draggable from "./utils/dtaggable.js";
import axios from "axios"
import { login } from './login/index.js'


axios.interceptors.request.use((config) => {
  if(window.login.enable){
    config.headers.Authorization =
    'Bearer' + ' ' + window.sessionStorage.getItem('token');
  }

  // if (config.method === 'get') {
  // 	//如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
  // 	config.paramsSerializer = function(params) {
  // 		return qs.stringify(params, {arrayFormat: 'repeat'});
  // 	};
  // }

  return config;
});

axios.defaults.withCredentials = true;
// axios.defaults.timeout = 1000 * 60;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:

         if(window.login.enable){
  login()
}

      }
    }
    return Promise.reject(error.response.data);
  }
);
if(window.login.enable){
  login()
}


Vue.config.productionTip = false;
Vue.use(ElementUI);
// 全局获取缓存数据
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'activeRegion') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('activeRegion', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
};

// 注册一个全局自定义指令 `v-draggable`
Vue.directive("draggable", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: draggable,
});

Vue.use(vuebus);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
