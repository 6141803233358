/* eslint-disable no-undef */
<template>
  <div style="height: 100%; width: 100%">
    <!-- 功能分页 -->
    <z-title @btnClick="btnClick" v-if="titleShow"></z-title>

    <!-- 中心十字标 -->
    <div class="centre">+</div>

    <!-- 视野锁定，坐标查询 -->
    <inputPosition class="inputposition" @clockEntity="clockEntity" v-show="inputPositionShow"></inputPosition>

    <!-- 区域列表 -->
    <z-region-list v-show="regionListShow" @close="regionListShow = false" @convert="convert"
      :regionListShow="regionListShow"></z-region-list>
    <!-- 区域调整 -->
    <convert v-if="convertShow" :close=convertClose :convertRow=convertRow :convertItem=convertItem></convert>

    <!-- 区域列表配置 -->
    <el-dialog :visible.sync="regionListConfigShow" width="40%" :before-close="() => (regionListConfigShow = false)"
      class="regionListConfig">
      <z-region-list-config v-if="regionListConfigShow"></z-region-list-config>
    </el-dialog>

    <!-- 当前相机位置信息 -->
    <viewerCamera v-if="visualAngleShoe"></viewerCamera>

    <!--相机投影范围-->
    <!-- <div class="button" v-show="boundaryshow">
      <span>相机投影范围</span>
      <el-form ref="form" label-width="80px">
        <el-row class="row" v-for="(value, index) in indexs" :key="index">
          <el-col :span="8">
            <el-form-item label="x">
              <div class="inputdiv">
                <el-input v-model.number="value.x"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="y">
              <div class="inputdiv">
                <el-input v-model.number="value.y"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="z">
              <div class="inputdiv">
                <el-input v-model.number="value.z"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row class="row">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button @click="cancel">取消</el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <el-button type="primary" @click="promise">保存</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div> -->

    <!--坐标转换-->
    <coordinateTransformation v-show="coordinateTransformationShow" class="coordinate"></coordinateTransformation>

    <!--投影相机及参数页面-->
    <home @emitindex="emitindex" @updateCameraConfig="updateCameraConfig" @xyChange="xyChange"
      @updateCameraPosition="updateCameraPosition" @updateRotation="updateRotation" @updatenear="updatenear"
      @updatefar="updatefar" @updatefov="updatefov" @updatefovY="updatefovY" @updateAlpha="updateAlpha"
      @changeshow="changeshow" @createVideo="createVideo" @delVideo="delVideo" @getpoint2="getpoint2"
      @gotovideo="gotovideo" @save="save" ref="cameralist" :cameralistShow="cameralistShow"
      :cameralisClose="cameralisClose" :id="id"></home>

    <!-- 区域主机区域协议 -->
    <z-update-host v-if="updateHostShoe" @close="updateHostShoe = false"></z-update-host>
    <z-update-proxy v-if="updateProxyShoe" @close="updateProxyShoe = false"></z-update-proxy>
    <!-- <boundaryConfig v-if="boundaryshow"></boundaryConfig> -->

    <!-- cesium容器 -->
    <div id="cesiumContainer" ref="cesiumContainer"></div>

    <!-- 地址栏携带参数切换定位模型和视频融合 -->
    <div class="elSwitch">
      <el-switch v-if="!titleShow" v-model="value1" active-text="视频融合" inactive-text="定位模型" @change="change">
      </el-switch>
    </div>
    <poleList v-if="poleShow" :close="poleClose"></poleList>
  </div>
</template>

<script>
  // import * as Cesium from "cesium/Cesium";
  // // eslint-disable-next-line no-unused-vars
  // import * as widget from "cesium/Widgets/widgets.css";
  import "cesium/Build/Cesium/Widgets/widgets.css";
  import * as Cesium from "cesium";
  import axios from "axios";

  import {
    ifromImplant,
    getTitleShow,
    addFusion,
    fusionVideoCameraDestroy,
  } from "./ifromImplant/index.js";
  import CesiumVideo3d from "./CesiumVideo3d";
  import { userCreateViewer } from "./utils/cesiumCreate.js";
  import { inputposition1, inputposition2, clrcle } from "./cesiumUtils/entitys";
  import { addCamera } from "./cesiumUtils/entitys.js";

  import cameralist from "../components/cameraList/index.vue";
  import boundaryConfig from "../components/boundaryConfig/index.vue";
  import inputPosition from "../components/inputPosition/index.vue";
  import coordinateTransformation from "../components/MC-coordinateTransformation/index.vue";
  import bxmap from "./cesiumUtils/add3Dpaths";
  import Clipboard from "clipboard";
  import Bubble from "../components/popupWindow/index.js";
  import viewerCamera from "../components/viewerCamera/index.vue";
  import title from "../components/title/index.vue";
  import regionList from "../components/regionList/index.vue";
  import regionListConfig from "../components/regionListConfig/index.vue";
  import home from "../components/home/index.vue";
  import updateHost from "../components/updateHostAndProxy/updateHost.vue";
  import updateProxy from "../components/updateHostAndProxy/updateProxy.vue";
  import convert from "../components/regionList/convert/index.vue"

  import { fusion } from './overLookFusion/fusion.js';

  import poleList from "../components/poleConfig/index.vue"


  import poleInit from "./PolePreview/poleIndex.js"

  var pickInfoEntity = null;
  var myEntityCollection = null;
  var newcesiumvideo3d2 = [];
  var newCesiumVideo3d = null;
  var vueCamera = {};
  var previewVideo = [];
  var addcameras = [];
  var handler = null;
  // var window.viewer = null;
  var maps = {};
  var OnEventReceivedEntityCollection = null;
  var clrcleEntityCollection = null;
  var fusionObj = null

  export default {
    name: "CesiumScene",
    components: {
      cameralist,
      inputPosition,
      boundaryConfig,
      coordinateTransformation,
      viewerCamera,
      "z-title": title,
      "z-region-list": regionList,
      "z-region-list-config": regionListConfig,
      home,
      "z-update-host": updateHost,
      "z-update-proxy": updateProxy,
      convert,
      poleList
    },

    data() {
      return {
        cameraItem: {},
        // options: {},
        dialogVisible: false,
        cameraMap: [],
        abc: 0,
        /**
         * @param {Cesium.Cartesian3} options.viewPosition 观测点位置。
         * @param {Cesium.Cartesian3} options.viewPositionEnd 最远观测点位置（如果设置了观测距离，这个属性可以不设置）。
         * @param {Number} options.viewDistance 观测距离（单位`米`，默认值100）。
         * @param {Number} options.viewHeading 航向角（单位`度`，默认值0）。
         * @param {Number} options.viewPitch 俯仰角（单位`度`，默认值0）。
         * @param {Number} options.horizontalViewAngle 可视域水平夹角（单位`度`，默认值90）。
         * @param {Number} options.verticalViewAngle 可视域垂直夹角（单位`度`，默认值60）。
         * @param {Cesium.Color} options.visibleAreaColor 可视区域颜色（默认值`绿色`）。
         * @param {Cesium.Color} options.invisibleAreaColor 不可视区域颜色（默认值`红色`）。
         * @param {Boolean} options.enabled 阴影贴图是否可用。
         * @param {Boolean} options.softShadows 是否启用柔和阴影。
         * @param {number} options.size 每个阴影贴图的大小。
         */
        option: {
          position: {
            x: 0,
            y: 0,
            z: 990,
          },
          // 远平面
          far: 100,
          // 横摆角俯仰角
          rotation: {
            x: -89,
            y: 0,
            z: 0,
          },
          elevation: 0.0,
          url: require("../assets/abnormalImg.png"),
          // 透明度
          alpha: 0.7,
          // 近平面
          near: 0.5,
          // 视场角
          fov: 100,
          // 是否显示视锥线
          debugFrustum: false,
          type: 3,
          aspectRatio: null,
          fovy: 60,
        },
        cArr: [],
        index: 0,
        configShow: false,
        buttonShow: false,
        boundaryshow: false,
        id: null,
        rew: null,
        indexs: [],
        debounceReturn: null,
        cardId: [],
        abcd: 0.01,
        texture: null,
        fileList: [
          {
            name: "food.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
        ],
        longlat: "0,0,0",
        Cartesian3: "0,0,0",
        streamType: {},

        //调整模型位置姿态
        convertRow: null,
        convertItem: null,

        //弹窗展示字段
        coordinateTransformationShow: false,
        inputPositionShow: false,
        cameralistShow: false,
        regionListShow: false,
        regionListConfigShow: false,
        titleShow: false,
        value1: true,
        elSwitchShow: true,
        updateHostShoe: false,
        updateProxyShoe: false,
        visualAngleShoe: false,
        convertShow: false,
        poleShow: false,

        poleClose: () => {
          this.poleShow = false
        },

        convertClose: () => {
          this.convertShow = false
        },
        cameralisClose: () => {
          this.cameralistShow = false;
        },
        fusionNumbet: 0
      };
    },
    created() {
      // this.$bus.on("cancel", () => {
      //   this.configShow = false;
      //   if (newCesiumVideo3d) {
      //     this.buttonShow = true;
      //   }
      //   // this.item={}
      //   this.boundaryshow = false;
      // });
      this.$bus.on("OnEventReceived", (message) => {
        let a = {};
        (a.lng = message.videoSiteBLH.x),
          (a.lat = message.videoSiteBLH.y),
          (a.height = message.videoSiteBLH.z);
        inputposition1(
          Cesium,
          window.viewer,
          a,
          OnEventReceivedEntityCollection,
          message.channelId
        );
        clrcle(
          Cesium,
          window.viewer,
          a,
          clrcleEntityCollection,
          message.channelId
        );
      });
    },
    beforeDestroy() { },
    async mounted() {
      new Clipboard(".btn");
      let createViewer = await new userCreateViewer("cesiumContainer");
      OnEventReceivedEntityCollection = new Cesium.CustomDataSource(
        "OnEventReceivedEntityCollection"
      );
      window.viewer.dataSources.add(OnEventReceivedEntityCollection);
      clrcleEntityCollection = new Cesium.CustomDataSource(
        "clrcleEntityCollection"
      );
      window.viewer.dataSources.add(clrcleEntityCollection);
      ifromImplant();
      this.titleShow = getTitleShow();
      this.elSwitchShow = !this.titleShow;
      addFusion();

      this.init();
      this.leftDownAction();
      // window.viewer.camera.flyTo({
      //   destination: new Cesium.Cartesian3.fromDegrees(80.471409, 44.097807, 100),
      //   orientation: {
      //     heading: 0,
      //     pitch: -89,
      //     roll: 0
      //   }
      // })
      // this.zipjs();
    },

    methods: {
      async init() {
        console.log("改变");

        handler = new Cesium.ScreenSpaceEventHandler(window.viewer.canvas);

        // if (window.AppConfig.tilesUrl && window.AppConfig.tilesUrl != "") {
        //   const element = window.AppConfig.tilesUrl;
        //   let tiles = this.addTileset(element);
        //   window.viewer.zoomTo(tiles, new Cesium.HeadingPitchRange(0, -90, 0));
        // }
        // if (window.AppConfig.pipe && window.AppConfig.pipe != "") {
        //   let pipe = this.addTileset(window.AppConfig.pipe);
        // }

        // tiles.allTilesLoaded.addEventListener((listener, scope) => {
        //   console.log("加载完成", listener, scope);

        // });

        // // 加载3D模型
        // var scene = window.viewer.scene;
        // var modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
        //   Cesium.Cartesian3.fromDegrees(
        //     111.2679369307231,
        //     40.37957767255365,
        //     971.4700400595647
        //   )
        // );
        // // gltf数据加载位置
        // scene.primitives.add(
        //   Cesium.Model.fromGltf({
        //     url: window.AppConfig.gltf, // 如果为bgltf则为.bgltf
        //     modelMatrix: modelMatrix,
        //     scale: 6.0, // 放大倍数
        //   })
        // );
        //  var modelMatrix1 = Cesium.Transforms.eastNorthUpToFixedFrame(
        //   Cesium.Cartesian3.fromDegrees(
        //     102.81512106885,
        //     24.3193462524381,
        //     2604
        //   )
        // ); // gltf数据加载位置
        // let cartesian = Cesium.Cartesian3.fromDegrees(102.81712106885,
        //   24.3203762524381,
        //   0)
        // if (Cesium.defined(cartesian)) {
        //   let headingPitchRoll = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(64), Cesium.Math.toRadians(0), Cesium.Math.toRadians(0));
        //   let modelMatrix2 = Cesium.Transforms.headingPitchRollToFixedFrame(cartesian, headingPitchRoll, Cesium.Ellipsoid.WGS84, Cesium.Transforms.eastNorthUpToFixedFrame, new Cesium.Matrix4());
        //   var model = scene.primitives.add(
        //     Cesium.Model.fromGltf({
        //       url: "model/Person_Blue_Guest3.glb", // 如果为bgltf则为.bgltf
        //       modelMatrix: modelMatrix2,
        //       minimumPixelSize: 128, // never smaller than 128 pixels
        //       maximumScale: 20000, // never larger than 20000 * model size (overrides minimumPixelSize)
        //       scale: 1.0 // 放大倍数
        //     })
        //   );
        // }
        // var a = window.viewer.camera.positionCartographic;

        // document.getElementById('cesiumContainer').style.width = window.viewer.scene.canvas.clientHeight + 'px'
        // window.viewer.camera.flyTo({
        //   destination: Cesium.Cartesian3.fromDegrees(
        //     102.81712106885,
        //     24.3203762524381,
        //     10
        //   ),
        // 相机飞入点
        // orientation: {
        //   heading: Cesium.Math.toRadians(0),
        //   pitch: Cesium.Math.toRadians(-60),
        //   roll: Cesium.Math.toRadians(0)
        // }
        // });
      },
      async createVideo(option) {
        console.log("开始投影");
        this.option = option;
        // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN); // 解除viewer的LEFT_DOWN事件监听器
        // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP); // 解除viewer的LEFT_DOWN事件监听器
        if (newCesiumVideo3d !== null) {
          this.$message("已有视频投影，请先删除");
          return;
        }
        if (this.option.position.x !== 0 && this.option.position.y !== 0) {
          const hr = Cesium.Math.toRadians(this.option.fov);
          const vr = Cesium.Math.toRadians(this.option.fovy);
          // this.setFireAndForgot(
          //   {
          //     channelId: this.id.channelId,
          //     cameraId: this.id.id,
          //     // rowScale: 100,
          //     // colScale: 100,
          //     // splitMode: 0,
          //     // mapH: 1,
          //     // mapW: 1,
          //     // selRow: 1,
          //     // selCol: 1,
          //     // leftTopX: 0,
          //     // leftTopY: 0,
          //     // rightBottomX: 0,
          //     // rightBottomY: 0,

          //     rowScale: this.id.scale ? this.id.scale : 100,
          //     colScale: this.id.scale ? this.id.scale : 100,
          //     splitMode: this.id.splitMode ? this.id.splitMode : 0,
          //     mapH: this.id.splitRow ? this.id.splitRow : 1,
          //     mapW: this.id.splitCol ? this.id.splitCol : 1,
          //     selRow: this.id.selRow ? this.id.selRow : 1,
          //     selCol: this.id.selCol ? his.id.selCol : 1,
          //     leftTopX: this.id.splitPixel ? this.id.splitPixel.left_top_x : 0,
          //     leftTopY: this.id.splitPixel ? this.id.splitPixel.left_top_y : 0,
          //     rightBottomX: this.id.splitPixel
          //       ? this.id.splitPixel.right_bottom_x
          //       : 0,
          //     rightBottomY: this.id.splitPixel
          //       ? this.id.splitPixel.right_bottom_y
          //       : 0,
          //     streamMediaCustomId: this.id.streamMediaCustomId,
          //   },
          //   null,
          //   1
          // );
          setTimeout(async () => {
            let mapx = null;
            let mapy = null;
            let mask = null;
            // if (maps[this.id.id]) {
            //   mapx = maps[this.id.id].mapx;
            //   mapy = maps[this.id.id].mapy;
            //   mask = maps[this.id.id].mask;
            // } else {
            await this.getimg(
              "/img/mapfile/" +
              window.AppConfig.mapfileSampleInterval +
              "/" +
              this.id.id +
              ".mapx.bmp"
            ).then((value) => {
              mapx = value;
            });

            await this.getimg(
              "/img/mapfile/" +
              window.AppConfig.mapfileSampleInterval +
              "/" +
              this.id.id +
              ".mapy.bmp"
            ).then((value) => {
              mapy = value;
            });
            await this.getimg("/img/mapfile/" + this.id.id + ".mask.bmp").then(
              (value) => {
                mask = value;
              }
            );
            maps[this.id.id] = {
              mapx: mapx,
              mapy: mapy,
              mask: mask,
            };
            // }
            // const res = await axios.post("/api/itosApi/Media/StartRealPlay", {
            //   channelId: this.id.channelId,
            //   cameraId: this.id.id,
            //   rowScale: this.id.scale ? this.id.scale : 100,
            //   colScale: this.id.scale ? this.id.scale : 100,
            //   splitMode: this.id.splitMode ? this.id.splitMode : 0,
            //   mapH: this.id.splitRow ? this.id.splitRow : 1,
            //   mapW: this.id.splitCol ? this.id.splitCol : 1,
            //   selRow: this.id.selRow ? this.id.selRow : 1,
            //   selCol: this.id.selCol ? his.id.selCol : 1,
            //   leftTopX: this.id.splitPixel ? this.id.splitPixel.left_top_x : 0,
            //   leftTopY: this.id.splitPixel ? this.id.splitPixel.left_top_y : 0,
            //   rightBottomX: this.id.splitPixel
            //     ? this.id.splitPixel.right_bottom_x
            //     : 0,
            //   rightBottomY: this.id.splitPixel
            //     ? this.id.splitPixel.right_bottom_y
            //     : 0,
            //   streamMediaCustomId: this.id.streamMediaCustomId,
            //   StreamType: 0,
            // });
            const res = await axios.post(`/api/itosApi/api/VideoStream/RealPlay/Start/${this.id.channelId}`, {
              linkMode: window.jessConfig.linkMode,
              streamType: 0,
              onlyIFrame: 0
            })
            console.log(res, "-----------地址")
            if (res && res.status == 200) {
              // this.setFireAndForgot(
              //   {
              //     channelId: this.id.channelId,
              //     cameraId: this.id.id,
              //     rowScale: this.id.scale ? this.id.scale : 100,
              //     colScale: this.id.scale ? this.id.scale : 100,
              //     splitMode: this.id.splitMode ? this.id.splitMode : 0,
              //     mapH: this.id.splitRow ? this.id.splitRow : 1,
              //     mapW: this.id.splitCol ? this.id.splitCol : 1,
              //     selRow: this.id.selRow ? this.id.selRow : 1,
              //     selCol: this.id.selCol ? his.id.selCol : 1,
              //     leftTopX: this.id.splitPixel
              //       ? this.id.splitPixel.left_top_x
              //       : 0,
              //     leftTopY: this.id.splitPixel
              //       ? this.id.splitPixel.left_top_y
              //       : 0,
              //     rightBottomX: this.id.splitPixel
              //       ? this.id.splitPixel.right_bottom_x
              //       : 0,
              //     rightBottomY: this.id.splitPixel
              //       ? this.id.splitPixel.right_bottom_y
              //       : 0,
              //     streamMediaCustomId: this.id.streamMediaCustomId,
              //     streamUrl: this.id.streamUrl,
              //   },
              //   res.data.data,
              //   1
              // );
            } else {
              this.$message.error("获取流地址失败");
              return;
            }
            //this.GetMatchView();
            this.option.url = res.data.stream;
            // this.option.url = res.data.data.stream_addr;
            this.option.streamHandle = res.data.handle;
            // this.option.streamHandle = res.data.data.stream_handle;
            this.option.channelId = this.id.channelId;

            this.option.leftTopX = this.id.splitPixel
              ? this.id.splitPixel.left_top_x
              : 0;
            this.option.leftTopY = this.id.splitPixel
              ? this.id.splitPixel.left_top_y
              : 0;
            this.option.rightBottomX = this.id.splitPixel
              ? this.id.splitPixel.right_bottom_x
              : 0;
            this.option.rightBottomY = this.id.splitPixel
              ? this.id.splitPixel.right_bottom_y
              : 0;
            this.option.splitPixel = this.id.splitPixel;
            let splitSrcWH = {
              Height: this.id.splitSrcHeight,
              Width: this.id.splitSrcWidth,
            };
            this.option.splitSrcWH = splitSrcWH;
            var objString = JSON.stringify(this.option);
            var op = JSON.parse(objString);
            op.aspectRatio = Math.tan(hr / 2) / Math.tan(vr / 2);
            // cesium中当纵视场角大于横视场角，会将横视场角赋给纵视场角且重新计算横视场角
            if (Math.tan(hr / 2) / Math.tan(vr / 2) <= 1) {
              op.fov = this.option.fovy;
            }
            op.mapx = mapx;
            op.mapy = mapy;
            op.mask = mask;
            op.streamMediaCustomId = this.id.streamMediaCustomId;
            op.streamurl = res.data.streamUrl;
            let newcesium = null;
            let val = new CesiumVideo3d(Cesium, window.viewer, op);

            // .then((val) => {
            //   newcesium = val;
            //   newCesiumVideo3d = newcesium;
            //   newcesiumvideo3d2.push(newcesium);
            //   this.getdata();
            // });
            newcesium = val;
            newCesiumVideo3d = newcesium;
            newcesiumvideo3d2.push(newcesium);
            this.getdata();
          }, false);
        } else {
          this.$message("请在地图上选择点位");
        }
      },
      // 删除投影
      delVideo() {
        if (newCesiumVideo3d) {
          // delete maps[newCesiumVideo3d.id];
          newCesiumVideo3d.destroy();
          const videosdid = newcesiumvideo3d2.indexOf(newCesiumVideo3d);
          newcesiumvideo3d2.splice(videosdid, 1);
          newCesiumVideo3d = null;
          window.viewer.entities.removeAll();
          delete maps[newCesiumVideo3d.id];
        } else {
          this.$message("还未视频投影,请先投影");
        }
        // if (this.abc === 0) {
        //   this.newcesiumvideo3d2[0].destroy()
        //   this.abc++
        // } else {
        //   this.newcesiumvideo3d2[1].destroy()
        // }
      },
      // 改变横视场角，纵视场角
      updatefov(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d.fov = option.fov;
          this.updatefovY(option);
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      updatefovY(option) {
        if (newCesiumVideo3d) {
          console.log("改变横向视场角", option, newCesiumVideo3d.fov)
          const hr = Cesium.Math.toRadians(option.fov);
          const vr = Cesium.Math.toRadians(option.fovy);
          // cesium中当纵视场角大于横视场角，会将横视场角赋给纵视场角且重新计算横视场角
          if (Math.tan(hr / 2) / Math.tan(vr / 2) <= 1) {
            newCesiumVideo3d.fov = option.fovy;
          }
          newCesiumVideo3d.aspectRatio = Math.tan(hr / 2) / Math.tan(vr / 2);
          console.log("改变后的fov", newCesiumVideo3d.fov)
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      // 改变透明度
      updateAlpha(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d.alpha = option.alpha;
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      // 改变相机位置
      updateCameraPosition(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d.param.elevation = option.elevation;
          newCesiumVideo3d._changeCameraPosition(option.position);
          console.log("改变了吗");
          this.updateRotation(option); // 更新fov
        } else {
          // this.$message("还未视频投影,请先投影");
        }
      },
      // 改变俯仰角，横摆角
      updateRotation(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d._changeRotation(option.rotation);
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      // 改变远平面
      updatefar(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d._changeFar(option.far);
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      // 改变近平面
      updatenear(option) {
        if (newCesiumVideo3d) {
          newCesiumVideo3d._changeNear(option.near);
        } else {
          this.$message("还未视频投影,请先投影");
        }
      },
      getdata() {
        // cesium地图绑定dom宽高
        vueCamera.vW = window.viewer.scene.canvas.clientWidth;
        vueCamera.vH = window.viewer.scene.canvas.clientHeight;
        // 视野相机空间位置
        var position = window.viewer.camera.positionCartographic;
        var long = Cesium.Math.toDegrees(position.longitude);
        var lat = Cesium.Math.toDegrees(position.latitude);
        var Height = position.height;
        // var cartesian = window.viewer.scene.camera.position
        // var cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        // var longitudeString = Cesium.Math.toDegrees(cartographic.longitude)
        // var latitudeString = Cesium.Math.toDegrees(cartographic.latitude)
        // var heightString = window.viewer.scene.globe.getHeight(cartographic)
        vueCamera.point = { x: long, y: lat, z: Height };
        // 相机横摆角
        vueCamera.yawDegree = Cesium.Math.toDegrees(
          window.viewer.scene.camera.heading
        );
        // 相机俯仰角
        vueCamera.pitchDegree = Cesium.Math.toDegrees(
          window.viewer.scene.camera.pitch
        );
        // 相机旋转角
        vueCamera.rollDegree = Cesium.Math.toDegrees(
          window.viewer.scene.camera.roll
        );
        // 横向视场角
        var fov = Cesium.Math.toDegrees(window.viewer.scene.camera.frustum.fov);
        // var aspectRatio = window.viewer.scene.camera.frustum.aspectRatio
        // 纵向视场角
        var fovy = Cesium.Math.toDegrees(window.viewer.scene.camera.frustum.fovy);
        var a = window.viewer.scene.camera.frustum.projectionMatrix;
        vueCamera.vFovDegree = fov;
        vueCamera.hFovDegree = fovy;
        // if (fov <= 46 || fovy <= 46) {
        //   this.$notify({
        //     title: '警告',
        //     message: '视场角小于46度,无法取点',
        //     type: 'warning'
        //   })
        // }
      },
      async getpoint() {
        var size = 0;
        await axios.post("/api/Calib/GetSampling", {}).then((col) => {
          size = Number(col.data.data);
        });
        var clientWidth = window.viewer.scene.canvas.clientWidth;
        var clientHeight = window.viewer.scene.canvas.clientHeight;
        var clientlist = [];
        for (let a = 0; a <= clientWidth; a += size) {
          for (let b = 0; b <= clientHeight; b += size) {
            const car = new Cesium.Cartesian2(a, b);
            const cartesian1 = window.viewer.scene.pickPosition(car);
            if (Cesium.defined(cartesian1)) {
              const cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
              const lng = Cesium.Math.toDegrees(cartographic1.longitude);
              const lat = Cesium.Math.toDegrees(cartographic1.latitude);
              const height = cartographic1.height; // 模型高度
              const mapPosition = {
                x: lng,
                y: lat,
                z: height,
              };
              clientlist.push(mapPosition);
              // console.warn(
              //   'centerX:' + mapPosition.x + ', centerY:' + mapPosition.y + ', height:' + mapPosition.z
              // )
              // axios.post('Setting/SetCameraMap', { id: '10', points: clientlist }).then((col) => {
              //   this.$message('')
              // })
            }
          }
        }
      },
      // getcamera () {
      //   axios.post('api/Calib/GetCameras', {}).then((col) => {
      //     this.cameralist = col.data.data
      //   })
      // },
      async GetSampling() {
        await axios.post("/api/Calib/GetSampling", {}).then((col) => {
          return Number(col.data.data);
        });
      },
      getpoint2() {
        // const vX = this.option.position.x
        // const vY = this.option.position.y
        // const vZ = this.option.position.z
        // const cPitch = this.option.rotation.x// 相机俯仰角
        // const cHeadi = this.option.rotation.y// 相机横摆角
        // const cFovX = this.option.fov// 相机横向视场角
        // const cFovY = this.option.fovy// 相机纵向视场角
        const vPosition = window.viewer.scene.camera.position;
        const vPitch = Cesium.Math.toDegrees(window.viewer.scene.camera.pitch); // 相机俯仰角
        const vHeadi = Cesium.Math.toDegrees(window.viewer.scene.camera.heading); // 相机横摆角
        const vRoll = Cesium.Math.toDegrees(window.viewer.scene.camera.roll);
        const vW = window.viewer.scene.canvas.clientWidth; // 视角相机在屏幕上的宽度
        const vH = window.viewer.scene.canvas.clientHeight; // 视角相机在屏幕上的高度
        const vFovX = Cesium.Math.toDegrees(
          window.viewer.scene.camera.frustum.fov
        ); // 视野相机横向视场角
        const vFovY = Cesium.Math.toDegrees(
          window.viewer.scene.camera.frustum.fovy
        ); // 视野相机纵向视场角
        const vPositions = { x: 0, y: 0, z: this.option.elevation };
        // var height = cartographic1.height // 模型高度
        axios
          .post("/api/Calib/GetScreenPoints", {
            eye: {
              w: vW,
              h: vH,
              hFovDegree: vFovX,
              vFovDegree: vFovY,
              point: vPositions,
              yawDegree: vHeadi,
              pitchDegree: vPitch,
              rollDegree: vRoll,
            },
            cameraId: this.id.id,
          })
          .then((rew) => {
            this.rew = rew;
            // this.addCameraEntity()
            this.moveViewCamera();
          });
      },
      save(option) {
        axios
          .post("/api/Setting/SetManualCameraCfg", {
            id: option.id,
            yaw: option.rotation.y,
            pitch: option.rotation.x,
            roll: option.rotation.z,
            elevation: option.elevation,
            lng: option.position.x,
            lat: option.position.y,
            height: option.position.z,
            hfov: option.fov,
            vfov: option.fovy,
            nearPlane: option.near,
            farPlane: option.far,
            x: Cesium.Cartesian3.fromDegrees(
              option.position.x,
              option.position.y,
              option.position.z + option.elevation
            ).x,
            y: Cesium.Cartesian3.fromDegrees(
              option.position.x,
              option.position.y,
              option.position.z + option.elevation
            ).y,
            z: Cesium.Cartesian3.fromDegrees(
              option.position.x,
              option.position.y,
              option.position.z + option.elevation
            ).z,
          })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              console.log(res, "保存成功");
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
              });

            } else {
              this.$notify.error({
                title: "失败",
                message: "保存失败",
              });
            }
          })
          .catch((err) => { });
      },

      addCameraEntity() {
        this.rew.data.data.debugData.degreeSamplingPointsOnGroundInWorld.forEach(
          (element) => {
            const cartesian1 = new Cesium.Cartesian3(
              element.x,
              element.y,
              4110850
            );
            if (Cesium.defined(cartesian1)) {
              const cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
              const lng = Cesium.Math.toDegrees(cartographic1.longitude);
              const lat = Cesium.Math.toDegrees(cartographic1.latitude);
              const height = cartographic1.height; // 模型高度
              const mapPosition = {
                x: lng,
                y: lat,
                z: height,
              };
              window.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(
                  mapPosition.x,
                  mapPosition.y,
                  mapPosition.z
                ),
                point: {
                  color: Cesium.Color.BLUE,
                  pixelSize: 5,
                },
              });
            }
          }
        );
      },
      // 移动视野相机到相机位置
      moveViewCamera() {
        this.cameraMap = [];
        window.viewer.camera.flyTo({
          destination: newCesiumVideo3d.fixedCamera.position,
          orientation: {
            heading: newCesiumVideo3d.fixedCamera.heading,
            pitch: newCesiumVideo3d.fixedCamera.pitch,
            roll: newCesiumVideo3d.fixedCamera.roll,
          },
          complete: () => {
            this.foyto();
          },
        });
      },

      /**
       * 循环转动视角取点并保存
       */
      foyto() {
        const element = this.rew.data.data.screens[this.index];
        const a = Cesium.Color.fromRandom();
        window.viewer.scene.camera.flyTo({
          destination: window.viewer.scene.camera.position,
          orientation: {
            heading: Cesium.Math.toRadians(element.yaw),
            pitch: Cesium.Math.toRadians(element.pitch),
            roll: Cesium.Math.toRadians(element.roll),
          },
          duration: 1,
          complete: () => {
            element.dots.forEach((el) => {
              const car = new Cesium.Cartesian2(el.x, el.y);
              if (!isNaN(el.x) && !isNaN(el.y)) {
                const cartesian1 = window.viewer.scene.pickPosition(car);
                if (Cesium.defined(cartesian1)) {
                  const cartographic1 =
                    Cesium.Cartographic.fromCartesian(cartesian1);
                  // const cartesian = window.viewer.scene.globe.pick(window.viewer.camera.getPickRay(cartographic1), window.viewer.scene)
                  const lng = Cesium.Math.toDegrees(cartographic1.longitude);
                  const lat = Cesium.Math.toDegrees(cartographic1.latitude);
                  const height = cartographic1.height; // 模型高度
                  const mapPosition = {
                    x: cartesian1.x,
                    y: cartesian1.y,
                    z: cartesian1.z,
                    index: el.index,
                  };
                  this.cameraMap.push(mapPosition);
                  window.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(lng, lat, height),
                    point: {
                      color: a,
                      pixelSize: 5,
                    },
                  });
                }
              }
            });
            if (this.index < this.rew.data.data.screens.length - 1) {
              this.index++;
              setTimeout(() => {
                this.foyto();
              }, 1000);
            } else {
              axios.post("/api/Setting/SetCameraMap", {
                id: this.id.id,
                points: this.cameraMap,
              });
              this.index = 0;
            }
          },
        });
      },

      /**
       * 摄像头列表选中项改变，点击地图上摄像机图标时改变选中摄像头，拖拽摄像头图标时改变摄像头位置
       * @param {*} col
       */
      emitindex(col) {
        handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        this.id = col;
        let entityValue = window.viewer.entities.values.filter((val) => {
          return val.id === col.id;
        });
        if (entityValue && entityValue.length !== 0) {
          pickInfoEntity = window.viewer.entities.getById(col.id);
          this.updateOption(col);
          const newcesium = newcesiumvideo3d2.filter((el) => {
            return el.id === col.id;
          });
          if (newcesium && newcesium.length !== 0) {
            newCesiumVideo3d = newcesium[0];
          }
          return;
        }

        const addcamera = new addCamera(Cesium, window.viewer, col);
        // window.viewer.camera.setView();

        pickInfoEntity = window.viewer.entities.getById(col.id);
        addcameras.push(addcamera);
        newCesiumVideo3d = null;
        const thas = this;
        handler.setInputAction(function (position) {
          // 为viewer绑定MOUSE_DOWN事件监听器（执行函数，监听的事件）
          const pickInfo = window.viewer.scene.pick(position.position); // pickInfo为当前操作的实体，若点击的空白区域，不是实体，那么pickInfo为undefined
          if (!pickInfo || typeof pickInfo.id === "undefined") {
            return;
          } // 如果点击空白区域，则不往下执行
          thas.updateCamera(pickInfo.id.id);
          pickInfoEntity = pickInfo.id;
          window.viewer.scene.screenSpaceCameraController.enableRotate = false; // 将相机锁定，不然后续移动实体时相机也会动
          handler.setInputAction((arg) => {
            var cartesian1 = window.viewer.scene.pickPosition(arg.endPosition);
            if (Cesium.defined(cartesian1)) {
              var cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
              var lng = Cesium.Math.toDegrees(cartographic1.longitude);
              var lat = Cesium.Math.toDegrees(cartographic1.latitude);
              var height = cartographic1.height; // 模型高度
              var mapPosition = {
                x: lng,
                y: lat,
                z: height,
              };
            }

            thas.updateposition(lng, lat, height);
            pickInfo.id.position._value = Cesium.Cartesian3.fromDegrees(
              mapPosition.x,
              mapPosition.y,
              mapPosition.z + col.elevation
            ); // 更新实体位置为当前鼠标位置
            // pickInfo.id.polyline.positions=Cesium.Cartesian3.fromDegreesArrayHeights([
            //     mapPosition.x,
            //     mapPosition.y,
            //     mapPosition.z + col.elevation,
            //     mapPosition.x,
            //     mapPosition.y,
            //     0,
            //   ]);
            let cameras = addcameras.filter((camera) => {
              return camera.id === pickInfo.id.id;
            });
            // pickInfo.id.polyline.positions = Cesium.Cartesian3.fromDegreesArrayHeights([
            //   mapPosition.x,
            //   mapPosition.y,
            //   mapPosition.z + col.elevation,
            //   mapPosition.x,
            //   mapPosition.y,
            //   0,
            // ]);
            pickInfo.id.polyline.positions = new Cesium.CallbackProperty(() => {
              return Cesium.Cartesian3.fromDegreesArrayHeights([
                mapPosition.x,
                mapPosition.y,
                mapPosition.z + col.elevation,
                mapPosition.x,
                mapPosition.y,
                0,
              ]);
            }, false);
          }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
          handler.setInputAction(({ position }) => {
            // 为viewer绑定LEFT_UP事件监听器（执行函数，监听的事件）
            // that.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN)// 解除viewer的LEFT_DOWN事件监听器
            handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE); // 解除viewer的MOUSE_MOVE事件监听器
            thas.updateCameraPosition();
            window.viewer.scene.screenSpaceCameraController.enableRotate = true; // 取消相机锁定
          }, Cesium.ScreenSpaceEventType.LEFT_UP);
        }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
        this.updateOption(col);
      },
      /**
       * 更新选中摄像头参数
       * @param {*} col
       */
      updateOption(col) {
        this.option.position.x = col.lng;
        this.option.position.y = col.lat;
        this.option.position.z = col.height;
        this.option.far = col.farPlane;
        this.option.rotation.y = col.yaw;
        this.option.rotation.x = col.pitch;
        this.option.near = col.nearPlane;
        this.option.fov = col.hfov;
        this.option.fovy = col.vfov;
        this.option.elevation = col.elevation;
        this.option.id = col.id;
        this.option.rotation.z = col.roll;
        this.configShow = false;
        this.buttonShow = true;
        this.boundaryshow = false;
      },
      updateposition(x, y, z) {
        this.option.position.x = x;
        this.option.position.y = y;
        this.option.position.z = z;
      },
      updateCamera(id) {
        this.$refs.cameralist.cesiumCurrent(id);
      },

      // 视锥显示
      changeshow(show) {
        newCesiumVideo3d.debugFrustum = show;
      },

      /**
       * 预览视频融合
       * @param
       */
      async preview() {
        //this.GetMatchView();
        this.debounceReturn = this.debounce(this.viewMovement, 1500);
        this.addEvent();
        if (newcesiumvideo3d2.length !== 0) {
          newcesiumvideo3d2.forEach((el) => {
            el.destroy();
          });
          window.viewer.entities.removeAll();
        }
        this.boundaryshow = false;
        this.configShow = false;
        this.buttonShow = false;
        const result = this.getCameraPosition();
        const res = await this.GetCameraCfg(result);
        console.log(res, "获取数据");
        previewVideo.forEach((el) => {
          el.destroy();
        });
        previewVideo = [];
        // res.data.data.cameraViewList.forEach((el) => {
        //   this.previewData(el);
        // });
        this.delAndAdd(res);
      },

      /**
       *获取当前视角下存在的相机列表
       * @param {} camera
       */
      async GetCameraCfg(camera) {
        const res = await axios.post("/api/Calib/GetEyeView", camera);
        // this.streamType.name=res.data.data.streamTypeName
        // this.streamType.value=res.data.data.streamTypeValue
        return res;
      },

      /**
       * 查找当前投影中应关闭和新增的投影
       * @param {*} res
       */
      delAndAdd(res) {
        console.log(res, "返回值");
        const removevideo = [];
        if (
          !res.data.data.cameraViewList ||
          res.data.data.cameraViewList.length === 0
        ) {
          previewVideo.forEach((el) => {
            el.destroy();
          });
          previewVideo = [];
          return;
        }
        if (this.streamType && this.streamType != {}) {
          if (res.data.data.streamTypeValue != this.streamType.value) {
            previewVideo.forEach((el) => {
              el.destroy();
            });
            previewVideo = [];
            console.log("结束所有");
          }
          this.streamType.name = res.data.data.streamTypeName;
          this.streamType.value = res.data.data.streamTypeValue;
        } else {
          this.streamType.name = res.data.data.streamTypeName;
          this.streamType.value = res.data.data.streamTypeValue;
        }
        previewVideo.forEach((el) => {
          let istrue = false;
          res.data.data.cameraViewList.forEach((element) => {
            if (
              element.id === el.id &&
              element.selCol === el.selCol &&
              element.selRow === el.selRow
            ) {
              istrue = true;
            }
          });

          if (!istrue) {
            removevideo.push(el);
          }
        });
        const addvideo = [];
        res.data.data.cameraViewList.forEach((element) => {
          let istrue = false;
          previewVideo.forEach((el) => {
            if (
              element.id === el.id &&
              element.selCol === el.selCol &&
              element.selRow === el.selRow
            ) {
              istrue = true;
            }
          });
          if (!istrue) {
            addvideo.push(element);
          }
        });
        console.log(addvideo, "add----------rem", removevideo);
        addvideo.forEach((el) => {
          this.previewData(el);
        });
        removevideo.forEach((el) => {
          setTimeout(() => {
            let element = el;
            element.destroy();
            const index = previewVideo.indexOf(element);
            if (index !== -1) {
              previewVideo.splice(index, 1);
            }
          }, 3000);
        });
      },

      /**
       * 添加鼠标事件
       */
      async addEvent() {
        handler.setInputAction(async (event) => {
          this.debounceReturn();
        }, Cesium.ScreenSpaceEventType.WHEEL);
        handler.setInputAction(async (event) => {
          this.debounceReturn();
        }, Cesium.ScreenSpaceEventType.LEFT_UP);
        handler.setInputAction(async (event) => {
          this.debounceReturn();
        }, Cesium.ScreenSpaceEventType.MIDDLE_UP);
        handler.setInputAction(async (event) => {
          this.debounceReturn();
        }, Cesium.ScreenSpaceEventType.RIGHT_UP);
      },
      // 防抖函数
      debounce(func, wait) {
        let timeout;
        return function () {
          // 清空定时器
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(func, wait);
        };
      },

      /**
       * 视角移动重新计算投影
       */
      async viewMovement() {
        const result = this.getCameraPosition();
        const res = await this.GetCameraCfg(result);
        this.delAndAdd(res);
      },

      /**
       * 格式化视角相机数据取流
       */
      getCameraPosition() {
        return {
          w: window.viewer.scene.canvas.clientWidth,
          h: window.viewer.scene.canvas.clientHeight,
          hFovDegree: Cesium.Math.toDegrees(
            window.viewer.scene.camera.frustum.fov
          ),
          vFovDegree: Cesium.Math.toDegrees(
            window.viewer.scene.camera.frustum.fovy
          ),
          point: {
            x: window.viewer.scene.camera.position.x,
            y: window.viewer.scene.camera.position.y,
            z: window.viewer.scene.camera.position.z,
          },
          yawDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.heading),
          pitchDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.pitch),
          rollDegree: Cesium.Math.toDegrees(window.viewer.scene.camera.roll),
        };

        // return result
      },

      // 预览处理数据
      async previewData(re) {
        let mapx = null;
        let mapy = null;
        let mask = null;
        // if (maps[re.id]) {
        //   mapx = maps[re.id].mapx;
        //   mapy = maps[re.id].mapy;
        //   mask = maps[re.id].mask;
        // } else {
        await this.getimg(
          "/img/mapfile/" +
          window.AppConfig.mapfileSampleInterval +
          "/" +
          re.id +
          ".mapx.bmp"
        ).then((value) => {
          mapx = value;
        });

        await this.getimg(
          "/img/mapfile/" +
          window.AppConfig.mapfileSampleInterval +
          "/" +
          re.id +
          ".mapy.bmp"
        ).then((value) => {
          mapy = value;
        });
        await this.getimg("/img/mapfile/" + re.id + ".mask.bmp").then(
          (value) => {
            mask = value;
          }
        );
        maps[re.id] = {
          mapx: mapx,
          mapy: mapy,
          mask: mask,
        };
        // }
        // const res = await axios.post("/api/itosApi/Media/StartRealPlay", {
        //   channelId: re.channelId,
        //   cameraId: re.id,
        //   rowScale: re.scale,
        //   colScale: re.scale,
        //   splitMode: re.splitMode,
        //   mapH: re.splitRow,
        //   mapW: re.splitCol,
        //   selRow: re.selRow,
        //   selCol: re.selCol,
        //   leftTopX: re.pixel ? re.pixel.left_top_x : 0,
        //   leftTopY: re.pixel ? re.pixel.left_top_y : 0,
        //   rightBottomX: re.pixel ? re.pixel.right_bottom_x : 0,
        //   rightBottomY: re.pixel ? re.pixel.right_bottom_y : 0,
        //   streamMediaCustomId: re.streamMediaCustomId,
        //   StreamType: this.streamType.value,
        // });
        const res = await axios.post(`/api/itosApi/api/VideoStream/RealPlay/Start/${re.channelId}`, {
          linkMode: window.jessConfig.linkMode,
          streamType: 0,
          onlyIFrame: 0
        })
        // axios.post("/fire/v1.0/od/Api/AddAnalysisTask",{
        //   cameraId:
        //   mediaUri
        // })
        // if (res.data.Code !== 0) {
        //   this.setFireAndForgot(
        //     {
        //       channelId: re.channelId,
        //       cameraId: re.id,
        //       rowScale: re.scale,
        //       colScale: re.scale,
        //       splitMode: re.splitMode,
        //       mapH: re.splitRow,
        //       mapW: re.splitCol,
        //       selRow: re.selRow,
        //       selCol: re.selCol,
        //       leftTopX: re.pixel ? re.pixel.left_top_x : 0,
        //       leftTopY: re.pixel ? re.pixel.left_top_y : 0,
        //       rightBottomX: re.pixel ? re.pixel.right_bottom_x : 0,
        //       rightBottomY: re.pixel ? re.pixel.right_bottom_y : 0,
        //     },
        //     res.data.data,
        //     "StartPreview"
        //   );
        // }
        const datas = {};
        datas.url = res.data.data.stream; // 视频地址
        // datas.url = res.data.data.stream_addr; // 视频地址
        datas.streamHandle = res.data.data.handle; //流id
        // datas.streamHandle = res.data.data.stream_handle; //流id
        datas.alpha = 1.0; // 透明度
        datas.aspectRatio =
          Math.tan(Cesium.Math.toRadians(re.hfov) / 2) /
          Math.tan(Cesium.Math.toRadians(re.vfov) / 2); // 投影纵横比
        datas.debugFrustum = false; // 是否显示视锥线
        datas.far = re.farPlane; // 远平面
        if (
          Math.tan(Cesium.Math.toRadians(re.hfov) / 2) /
          Math.tan(Cesium.Math.toRadians(re.vfov) / 2) <=
          1
        ) {
          datas.fov = re.vfov;
        } else {
          datas.fov = re.hfov; // 横向视场角
        }

        datas.fovy = re.vfov; // 纵向视场角
        datas.elevation = re.elevation; //相机高度
        datas.id = re.id; // 相机id
        datas.near = re.nearPlane; // 近平面
        datas.position = { x: re.lng, y: re.lat, z: re.height }; // 相机位置
        datas.rotation = { x: re.pitch, y: re.yaw, z: re.roll }; // 俯仰横摆旋转角
        datas.scale = re.scale;
        datas.selRow = re.selRow;
        datas.selCol = re.selCol;
        datas.channelId = re.channelId;
        datas.type = 3; // 投影类型
        datas.mapx = mapx;
        datas.mapy = mapy;
        datas.mask = mask;
        datas.streamMediaCustomId = re.streamMediaCustomId;
        datas.splitPixel = re.pixel;
        let splitSrcWH = {
          Height: re.splitSrcHeight,
          Width: re.splitSrcWidth,
        };
        datas.splitSrcWH = splitSrcWH;

        this.addCesiumVideo(datas);
      },

      // 预览投影方法
      addCesiumVideo(data) {
        let newcesium = new CesiumVideo3d(Cesium, window.viewer, data);
        previewVideo.push(newcesium);
      },
      // //取投影范围
      // boundary() {
      //   if (newCesiumVideo3d) {
      //     // lines(Cesium, window.viewer, handler);
      //     const thas = this;
      //     handler.setInputAction(function (event) {
      //       var cartesian1 = window.viewer.scene.pickPosition(event.position);
      //       const entity = { x: cartesian1.x, y: cartesian1.y, z: cartesian1.z };
      //       thas.indexs.push(entity);
      //       if (Cesium.defined(cartesian1)) {
      //         var cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
      //         var lng = Cesium.Math.toDegrees(cartographic1.longitude);
      //         var lat = Cesium.Math.toDegrees(cartographic1.latitude);
      //         var height = cartographic1.height; // 模型高度
      //         var mapPosition = {
      //           x: lng,
      //           y: lat,
      //           z: height,
      //         };
      //       }
      //       console.warn(
      //         "centerX:" +
      //         mapPosition.x +
      //         ", centerY:" +
      //         mapPosition.y +
      //         ", height:" +
      //         mapPosition.z
      //       );
      //       // window.viewer.entities.removeAll()
      //       window.viewer.entities.add({
      //         name: "boundary",
      //         position: Cesium.Cartesian3.fromDegrees(
      //           mapPosition.x,
      //           mapPosition.y,
      //           mapPosition.z
      //         ),
      //         point: {
      //           color: Cesium.Color.RED,
      //           pixelSize: 10,
      //         },
      //       });
      //     }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      //     this.buttonShow = false;
      //     this.boundaryshow = true;
      //   } else {
      //     this.$message("该摄像头还未视频投影,请先投影");
      //   }
      // },
      // cancel() {
      //   const entitis = [];
      //   window.viewer.entities.values.forEach((el) => {
      //     if (el.name === "boundary") {
      //       entitis.push(el);
      //     }
      //   });
      //   entitis.forEach((el) => {
      //     window.viewer.entities.remove(el);
      //   });
      //   handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      //   this.indexs = [];
      //   this.boundaryshow = false;
      // },
      // promise() {
      //   axios.post("/api/Setting/SetCameraRange", {
      //     id: this.id.id,
      //     range: this.indexs,
      //   });
      //   this.cancel();
      //   this.$message({
      //     message: "保存成功",
      //     type: "success",
      //   });
      // },
      //流媒体依赖请求
      setFireAndForgot(req, resp, action) {
        if (window.AppConfig.isMediaNotifyiOn) {
          axios.post("/fire/v1.0/od/Api/MediaNotify", {
            req: req,
            resp: resp,
            action: action,
          });
        }
      },

      // 目标识别
      // async GetMatchView() {
      //   myEntityCollection = new Cesium.CustomDataSource("clickEntityCollection");
      //   window.viewer.dataSources.add(myEntityCollection);
      //   // myEntityCollection.entities.add()
      //   setInterval(async () => {
      //     let res = await axios.get("/fire/v1.0/od/Api/GetMatchPositions");
      //     // let res = {};

      //     // if (!res.data) {
      //     //   res={data:[{
      //     //     cardSN:"123456",
      //     //     name:"小鱼",
      //     //     geo:{x:111.25976665568275,y:40.38070125390686,z:973.675823926532},
      //     //   }]}
      //     // }
      //     let aru = this.screenEntity(res);
      //     aru.rem.forEach((re) => {
      //       this.remEntity(re);
      //     });
      //     aru.add.forEach((re) => {
      //       this.addEntity(re);
      //     });
      //     aru.upd.forEach((re) => {
      //       this.updEntity(re);
      //     });
      //   }, 1000);
      // },
      screenEntity(res) {
        let remEntitys = [];
        myEntityCollection.entities.values.forEach((el) => {
          let istrue = false;
          res.data.forEach((element) => {
            if (element.cardSN === el.id) {
              istrue = true;
            }
          });

          if (!istrue) {
            remEntitys.push(el);
          }
        });
        let addEntitys = [];
        let updEntitys = [];
        res.data.forEach((element) => {
          let istrue = false;
          myEntityCollection.entities.values.forEach((el) => {
            if (element.cardSN === el.id) {
              istrue = true;
            }
          });
          if (!istrue) {
            addEntitys.push(element);
          } else {
            updEntitys.push(element);
          }
        });

        return { rem: remEntitys, add: addEntitys, upd: updEntitys };
      },
      addEntity(re) {
        myEntityCollection.entities.add({
          id: re.cardSN,
          name: re.name,
          position: new Cesium.CallbackProperty(() => {
            return new Cesium.Cartesian3.fromDegrees(
              re.geo.x,
              re.geo.y,
              re.geo.z
            );
          }, false),

          //   ellipse: {
          //   show: true,
          //     semiMinorAxis: 50,
          //     semiMajorAxis: 50,

          //   // height: 200,
          //   // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          //   extrudedHeight: 1.0,
          //   extrudedHeightReference: Cesium.HeightReference.RELATIVE_TO_GROUND ,

          //   // rotation: Cesium.Math.toRadians(45), // 从北方逆时针旋转
          //   // stRotation: 0.0, // 纹理从北方逆时针旋转
          //   // granularity: Cesium.Math.RADIANS_PER_DEGREE, // 椭圆上各点之间的角距离
          //   material: Cesium.Color.BLUE.withAlpha(0.5),
          //   fill: true,
          //   outline: true,
          //   outlineColor: Cesium.Color.DARK_GREEN,
          //   outlineWidth: 100.0,
          //   numberOfVerticalLines: 16, // 沿轮廓的周长绘制的垂直线的数量
          //   shadows: Cesium.ShadowMode.DISABLED,
          //   // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
          //   //   1.0e3,
          //   //   2.0e3
          //   // ),

          //   // 在地面上时将对地形，3D tiles还是对两者进行分类  type:ClassificationType  default:ClassificationType.BOTH
          //   // TERRAIN 将仅对地形进行分类;CESIUM_3D_TILE 将仅对3D瓷砖进行分类;BOTH	将同时对Terrain和3D Tile进行分类。
          //   classificationType: Cesium.ClassificationType.BOTH,
          // },
          ellipse: {
            semiMinorAxis: window.AppConfig.peopleRange,
            semiMajorAxis: window.AppConfig.peopleRange,
            material: new Cesium.ImageMaterialProperty({
              image: require("../assets/未选中圆圈.png"),
              repeat: new Cesium.Cartesian2(1.0, 1.0),
              transparent: true,
              color: Cesium.Color.WHITE.withAlpha(1),
            }),
            // Cesium.Color.fromCssColorString("#ffc0cb"),

            // rotation: Cesium.Math.toRadians(-40.0),
            // fill:false,
            // outline: true,
            // outlineColor: Cesium.Color.RED,
            // outlineWidth: 30.0,
            // numberOfVerticalLines: 20.0,
          },
          // point: {
          //   color: Cesium.Color.TRANSPARENT,
          //   pixelSize: 30,
          //   // disableDepthTestDistance: 5000
          //   outlineColor:new Cesium.Color(1,1,1,0.5),
          //   outlineWidth:10,
          //   heightReference: Cesium.HeightReference.none,
          // },
          label: {
            text: "label名",
            font: "14pt Source Han Sans CN", //字体样式
            fillColor: Cesium.Color.BLACK, //字体颜色
            backgroundColor: Cesium.Color.AQUA, //背景颜色
            showBackground: true, //是否显示背景颜色
            style: Cesium.LabelStyle.FILL, //label样式
            outlineWidth: 2,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT, //水平位置
            // pixelOffset: new Cesium.Cartesian2(0, 0) //偏移
            eyeOffset: new Cesium.Cartesian3(),
          },
        });
        // addHollowCircle(Cesium, window.viewer, re);
        // window.viewer.entities.add({
        //     position: Cesium.Cartesian3.fromDegrees(104, 30, 2.61),
        //     point: {
        //         color: Cesium.Color.RED, //点位颜色
        //         pixelSize: 10 //像素点大小
        //     },
        //     label: {
        //         text: 'label名',
        //         font: '14pt Source Han Sans CN', //字体样式
        //         fillColor: Cesium.Color.BLACK, //字体颜色
        //         backgroundColor: Cesium.Color.AQUA, //背景颜色
        //         showBackground: true, //是否显示背景颜色
        //         style: Cesium.LabelStyle.FILL, //label样式
        //         outlineWidth: 2,
        //         verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
        //         horizontalOrigin: Cesium.HorizontalOrigin.LEFT, //水平位置
        //         pixelOffset: new Cesium.Cartesian2(10, 0) //偏移
        //     }
        // });
        // window.viewer.camera.flyTo({
        //   destination: Cesium.Cartesian3.fromDegrees(104, 30, 2.61), // 相机飞入点
        // });
      },
      remEntity(re) {
        myEntityCollection.entities.remove(re);
      },
      updEntity(re) {
        myEntityCollection.entities.getById(re.cardSN).position =
          new Cesium.CallbackProperty(() => {
            return new Cesium.Cartesian3.fromDegrees(
              re.geo.x,
              re.geo.y,
              re.geo.z
            );
          }, false);
        // new Cesium.Cartesian3.fromDegrees(re.geo.x, re.geo.y, re.geo.z);
      },
      //修改经纬度时改变相机位置
      xyChange(option) {
        console.log(option, "修改经纬度");
        pickInfoEntity.position._value = Cesium.Cartesian3.fromDegrees(
          option.position.x,
          option.position.y,
          option.position.z + option.elevation
        );
        pickInfoEntity.polyline.positions = new Cesium.CallbackProperty(() => {
          return Cesium.Cartesian3.fromDegreesArrayHeights([
            option.position.x,
            option.position.y,
            option.position.z + option.elevation,
            option.position.x,
            option.position.y,
            0,
          ]);
        }, false);
        this.updateCameraPosition(option);
      },
      async getimg(url) {
        let that = this;
        return await new Promise((resolve) => {
          let img = new Image();
          img.src = url;
          img.onload = function () {
            // that.type = that.optionType.Image
            let Texture = new Cesium.Texture({
              context: window.viewer.scene.context,
              source: img,
            });
            resolve(Texture);
          };
          img.onerror = function () { };
        }).then((testure) => {
          return testure;
        });
      },

      addpaths() {
        bxmap.FlyCesium.Init(window.viewer);
      },
      packUp() {
        this.buttonShow = !this.buttonShow;
      },
      gotovideo(option) {
        console.log(newCesiumVideo3d);
        window.viewer.camera.flyTo({
          destination: newCesiumVideo3d.fixedCamera.position,
          orientation: {
            heading: newCesiumVideo3d.fixedCamera.heading,
            pitch: newCesiumVideo3d.fixedCamera.pitch,
            roll: newCesiumVideo3d.fixedCamera.roll,
          },
        });
      },

      updateCameraConfig(item) {
        this.cameraItem = item;
        this.configShow = true;
        this.buttonShow = false;
        this.boundaryshow = false;
      },

      async updateApi() {
        let res = await axios.post("/api/Setting/ForceReloadCameras");
        if (res.status == 200) {
          this.$message({
            message: "刷新成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "刷新失败",
            type: "error",
          });
        }
      },

      clockEntity(position) {
        this.longlat = position.longlat;
        this.Cartesian3 = position.Cartesian3;
      },
      //加载模型
      addTileset(url) {
        let tileset = new Cesium.Cesium3DTileset({
          url: url,
          skipLevelOfDetail: true,
          baseScreenSpaceError: 1024,
          skipScreenSpaceErrorFactor: 16,
          skipLevels: 1,
          immediatelyLoadDesiredLevelOfDetail: false,
          loadSiblings: false,
          cullWithChildrenBounds: true,
          maximumScreenSpaceError: 16,
        });

        window.viewer.scene.primitives.add(tileset);
        tileset.readyPromise.then(function (tileset) {
          //固定视角范围
          // window.viewer.camera.viewBoundingSphere(
          //   tileset.boundingSphere,
          //   new Cesium.HeadingPitchRange(0, -20, 0)
          // );
          // 高度偏差，向上是正数，向下是负数
          var heightOffset = window.AppConfig.heightOffset;
          // 计算tileset的绑定范围
          var boundingSphere = tileset.boundingSphere;
          // 计算中心点位置
          var cartographic = Cesium.Cartographic.fromCartesian(
            boundingSphere.center
          );
          // 计算中心点位置的地表坐标
          var surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
          );
          viewer;
          // 偏移后的坐标
          var offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset
          );
          var translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
          );
          // tileset.modelMatrix转换
          tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        });
        return tileset;
      },
      //改变视野相机位置，姿态
      reset(camera) {
        window.viewer.camera.setView({
          destination: camera.position,
          orientation: {
            // 指向
            heading: camera.heading,
            // 视角
            pitch: camera.pitch,
            roll: camera.roll,
          },
        });
      },
      leftDownAction() {
        let viewer = window.viewer;
        let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        let _this = this;
        let id;
        handler.setInputAction(function (movement) {
          let pick = viewer.scene.pick(movement.position);
          if (Cesium.defined(pick) && pick?.id?.id) {
            // _this.leftDownFlag = true;
            console.log(pick.id.id, "选中的id");
            id = pick.id.id;
            _this.bubble(pick.id);
          } else {
            // console.log(_this.bubbles)
            if (_this.bubbles) {
              _this.bubbles.windowClose();
            }
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      },

      bubble(id) {
        if (this.bubbles) {
          this.bubbles.windowClose();
        }
        console.log(id);
        this.bubbles = new Bubble(
          Object.assign(id, {
            viewer: window.viewer,
          })
        );
      },
      // cameralisClose() {
      //   this.cameralistShow = false;
      // },
      btnClick(type) {

        switch (type) {
          case "cameraList": //投影列表
            this.cameralistShow = !this.cameralistShow;
            break;
          case "preview": //预览
            if (this.fusionNumbet == 0) {
              fusionObj = new fusion(true)
              this.fusionNumbet = 1
            } else if (this.fusionNumbet == 1) {
              fusionObj.destroy()
              this.fusionNumbet = 0
            }
            // this.preview();

            break;
          // case "listval": //新增相机
          //   this.listval();
          //   break;
          case "addpaths": //小车航行
            this.addpaths();
            break;
          case "updateApi": //刷新api
            this.updateApi();
            break;
          case "transformation": //坐标转换
            this.coordinateTransformationShow =
              !this.coordinateTransformationShow;
            break;
          case "query": //坐标查询
            this.inputPositionShow = !this.inputPositionShow;
            break;
          case "dot": //点位选择
            this.updateApi();
            break;
          case "region": //点位选择
            this.regionListShow = !this.regionListShow;
            break;
          case "regionConfig": //点位选择
            this.regionListConfigShow = !this.regionListConfigShow;
            break;
          case "UpdateModelHost": //修改区域主机
            console.log("修改区域主机");
            this.updateHostShoe = !this.updateHostShoe;

            break;
          case "UpdateModelProxy": //修改区域协议
            console.log("修改区域协议");
            this.updateProxyShoe = !this.updateProxyShoe;

            break;
          case "visualAngle": //修改区域协议
            console.log("视角坐标");
            this.visualAngleShoe = !this.visualAngleShoe;

            break;
          case "poleConfig"://杆子设置
            this.poleShow = !this.poleShow
            break;
          case "polePreview"://杆子投影
            // this.poleShow = !this.poleShow
            console.log("杆子投影")
            // new poleInit()
            break;

          default:
            break;
        }
      },
      change(show) {
        console.log(show, "开关量变化");
        fusionVideoCameraDestroy(show);
      },
      //调整模型坐标
      convert(row, item) {
        this.convertRow = row;
        this.convertItem = item;
        this.convertShow = true

        console.log(row, "调整模型坐标", item);
      }
    },
  };
</script>

<style lang="scss" scoped>
  #cesiumContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #divtable {
    position: absolute;
    top: 100px;
    right: 10px;
  }

  .button {
    position: absolute;
    height: 50%;
    width: 30%;
    top: 100px;
    right: 10px;
    z-index: 100;
    border: #000 5px solid;
    background-color: #000;
    opacity: 0.8;
    color: #fff;
    height: calc(100% - 100px);
    overflow: auto;

    ::v-deep .el-form-item__label {
      color: #fff;
    }
  }

  .button:hover {
    z-index: 999;
  }

  .buttons {
    position: absolute;
    top: 100px;
    left: 10px;
    z-index: 100;
    opacity: 0.8;
    overflow: auto;
    color: #fff;
  }

  .inputposition {
    position: absolute;
    top: 100px;
    left: 650px;
    z-index: 100;
    opacity: 0.8;
    overflow: auto;
    color: #fff;
  }

  .buttonClick {
    position: absolute;
    top: 100px;
    width: 500px;
    right: 500px;
    z-index: 100;
    padding: 10px;
    opacity: 0.8;
    color: #fff;
  }

  .col {
    padding: 5px;
  }

  .grid-content {
    padding: 0 5px 0 0;
  }

  .inputdiv {
    width: 100%;
  }

  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }

  ::v-deep .el-radio {
    color: #fff;
  }

  .spantextdiv {
    margin: 5px;
  }

  .buttonFrom {
    height: calc(100% - 30px);
    overflow: auto;
  }

  .centre {
    position: absolute;
    pointer-events: none;
    font-size: 30px;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  .coordinate {
    position: absolute;
    top: 20%;
    left: 10px;
    z-index: 100;
  }

  .elSwitch {
    position: absolute;
    bottom: 5%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    /* 50%为自身尺寸的一半 */
  }

  .regionListConfig {
    ::v-deep .el-dialog__body {
      padding: 30px 20px 10px 20px;
    }
  }
</style>