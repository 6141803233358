<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import * as signalR from "@microsoft/signalr";
export default {
  data: function () {
    return {
      // isRouterAlive: true,
      // connection: new signalR.HubConnectionBuilder()
      //   .withUrl("/hubs/SignalREventHub")
      //   .build(),
    };
  },
  created() {
    // this.connection.on("OnEventReceived", (message) => {
    //   console.log("报警消息", message);
    //   this.$bus.emit("OnEventReceived", message);
    // });
    // this.connection
    //   .start()
    //   .then(() => {
    //     console.log("连接成功");
    //   })
    //   .catch(() => {
    //     console.log("连接失败");
    //   });
  },
  mounted() {
    document.title = `${window.AppConfig.title}`;
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #000;
  overflow: hidden;
}
</style>
