<template>
  <div class="cameraconfig" v-draggable>
    <closeTitle :close="createVideo" :title="cameraSettingCfgInput.DeviceName"></closeTitle>
    <div class="from">
      <span>相机基本配置</span>
      <el-form ref="form" :model="cameraSettingCfgInput" label-width="80px">
        <el-form-item label="通道ID">
          <el-input v-model="cameraSettingCfgInput.Id" :disabled="isupdate" placeholder="例:YIAN11"></el-input>
        </el-form-item>
        <el-form-item label="通道名称">
          <el-input v-model="cameraSettingCfgInput.DeviceName" placeholder="例:依安枪机11"></el-input>
        </el-form-item>
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="通道类型">
              <el-select v-model="cameraSettingCfgInput.DeviceType" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通道编码">
              <el-input v-model="cameraSettingCfgInput.ChannelId" placeholder="填写通道取流编码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="所属区域">
          <el-select v-model="cameraSettingCfgInput.regionModelId" placeholder="请选择">
            <el-option v-for="item in regionList" :key="item.regionId" :label="item.regionName" :value="item.regionId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-divider></el-divider>
        <span>相机坐标（请在底图上点选相机位置坐标）</span>
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="X">
              <div class="inputdiv">
                <el-input v-model.number="cameraSettingCfgInput.Lng"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Y">
              <div class="inputdiv">
                <el-input v-model.number="cameraSettingCfgInput.Lat"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="Z">
              <div class="inputdiv">
                <el-input v-model.number="cameraSettingCfgInput.Height"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <span>切分行</span>
        <!-- <el-row class="row">
          <el-col :span="12">
            <el-form-item label="MapW">
              <el-input v-model.number="cameraSettingCfgInput.MapW"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="MapH">
              <el-input v-model.number="cameraSettingCfgInput.MapH"></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="目标流宽">
              <el-input v-model.number="cameraSettingCfgInput.W"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目标流高">
              <el-input v-model.number="cameraSettingCfgInput.H"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider></el-divider>
        <!-- <el-form-item label="StreamUrl">
          <el-input v-model="cameraSettingCfgInput.StreamUrl"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="SplitMode">

          <el-select v-model.number="cameraSettingCfgInput.SplitMode" placeholder="请选择">
            <el-option v-for="item in SplitModes" :key="item.value" :label="item.value" :value="item.label">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="切割LTX">
              <el-input v-model.number="cameraSettingCfgInput.SplitPixel.left_top_x"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="切割LTY">
              <el-input v-model.number="cameraSettingCfgInput.SplitPixel.left_top_y"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="12">
            <el-form-item label="切割RBX">
              <el-input v-model.number="cameraSettingCfgInput.SplitPixel.right_bottom_x"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="切割RBY">
              <el-input v-model.number="cameraSettingCfgInput.SplitPixel.right_bottom_y"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-form-item label="关联流媒体自定义编码">
          <el-input v-model="cameraSettingCfgInput.StreamMediaCustomId"></el-input>
        </el-form-item> -->

        <el-divider></el-divider>
      </el-form>
    </div>
    <div class="bu">
      <el-row class="row">
        <el-col :span="12">
          <el-button @click="createVideo">取消</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="promise">保存</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import * as Cesium from "cesium";
  import closeTitle from "../closeTitle/index.vue"
  var handler = null;
  export default {
    name: "cameraconfig",
    props: {
      cameraItem: Object,
    },
    components: {
      closeTitle
    },
    data() {
      return {
        isupdate: false,
        point: {},
        options: [
          {
            value: "普通",
          },
          {
            value: "鱼眼",
          },
          {
            value: "全景",
          },
        ],
        SplitModes: [
          {
            label: 1,
            value: "坐标",
          },
          {
            label: 0,
            value: "分块",
          },
          {
            label: 2,
            value: "转发",
          },
        ],
        fileList: [],
        cameraSettingCfgInput: {
          Id: "",
          DeviceName: "",
          InstallAddress: "",
          DeviceType: "普通",
          ChannelId: "0",
          MapW: 1,
          MapH: 1,
          point: {
            x: 0,
            y: 0,
            z: 0,
          },
          W: 0,
          H: 0,
          Lng: 0,
          Lat: 0,
          Height: 0,
          StreamUrl: "",
          SplitMode: 1,
          SplitPixel: {
            left_top_x: 0,
            left_top_y: 0,
            right_bottom_x: 0,
            right_bottom_y: 0,
          },
          StreamMediaCustomId: "Default",
          regionModelId: "",
        },
        regionList: [],
      };
    },

    created() { },
    mounted() {
      this.getRegion();
      handler = new Cesium.ScreenSpaceEventHandler(window.viewer.canvas);
      handler.setInputAction((event) => {
        var cartesian1 = window.viewer.scene.pickPosition(event.position);
        if (Cesium.defined(cartesian1)) {
          this.addentity(cartesian1)
          console.log(cartesian1, "点击");
          this.point = cartesian1;
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    beforeDestroy() {
      this.remEntity()
      handler.destroy();
    },

    watch: {
      point: {
        handler: function (val) {
          console.log(val, "改变点位");
          this.cameraSettingCfgInput.point.x = val.x;
          this.cameraSettingCfgInput.point.y = val.y;
          this.cameraSettingCfgInput.point.z = val.z;
          let cartographic1 = Cesium.Cartographic.fromCartesian(val);
          this.cameraSettingCfgInput.Lng = Cesium.Math.toDegrees(
            cartographic1.longitude
          );
          this.cameraSettingCfgInput.Lat = Cesium.Math.toDegrees(
            cartographic1.latitude
          );
          this.cameraSettingCfgInput.Height = cartographic1.height; // 模型高度
        },
        // immediate: true,
        deep: true,
      },
      cameraItem: {
        handler: function (val) {
          console.log("修改", val);
          if (val?.id) {
            let v = JSON.parse(JSON.stringify(val));
            console.log(v.point.x, "v");
            this.isupdate = true;
            this.cameraSettingCfgInput.Id = v.id;
            this.cameraSettingCfgInput.DeviceName = v.deviceName;
            this.cameraSettingCfgInput.ChannelId = v.channelId;
            this.cameraSettingCfgInput.InstallAddress = v.installAddress;
            this.cameraSettingCfgInput.DeviceType = v.deviceType;
            this.cameraSettingCfgInput.MapW = v.mapW;
            this.cameraSettingCfgInput.MapH = v.mapH;
            this.cameraSettingCfgInput.point = v.point;
            this.cameraSettingCfgInput.W = v.w;
            this.cameraSettingCfgInput.H = v.h;
            this.cameraSettingCfgInput.Lng = v.lng;
            this.cameraSettingCfgInput.Lng = v.lng;
            this.cameraSettingCfgInput.Lat = v.lat;
            this.cameraSettingCfgInput.Height = v.height;
            this.cameraSettingCfgInput.StreamUrl = v.streamUrl;
            this.cameraSettingCfgInput.SplitMode = v.splitMode;
            this.cameraSettingCfgInput.SplitPixel = v.splitPixel;
            this.cameraSettingCfgInput.StreamMediaCustomId =
              v.streamMediaCustomId;
            this.cameraSettingCfgInput.regionModelId = v.regionModelId;

            let c3 = new Cesium.Cartesian3(v.point.x, v.point.y, v.point.z);
            console.log(c3, "c3");
            this.addentity(c3)
            console.log(this.cameraSettingCfgInput, "cameraSettingCfgInput");
          } else {
            this.isupdate = false;
            this.cameraSettingCfgInput = {
              Id: "",
              DeviceName: "",
              InstallAddress: "",
              DeviceType: "普通",
              ChannelId: "0",
              MapW: 1,
              MapH: 1,
              point: {
                x: 0,
                y: 0,
                z: 0,
              },
              W: 0,
              H: 0,
              Lng: 0,
              Lat: 0,
              Height: 0,
              StreamUrl: "",
              SplitMode: 1,
              SplitPixel: {
                left_top_x: 0,
                left_top_y: 0,
                right_bottom_x: 0,
                right_bottom_y: 0,
              },
              StreamMediaCustomId: "Default",
              regionModelId: "",
            };
          }
        },
        immediate: true,
        deep: true,
      },
      "cameraSettingCfgInput.DeviceName": {
        handler: function (val) {
          console.log(val, "name");
          this.cameraSettingCfgInput.InstallAddress = val;
        },
      },
    },

    methods: {
      createVideo() {
        //  this.$bus.emit("cancel")
        this.$emit("cancel");
      },
      // 提交配置信息
      promise() {
        // console.log(this.cameraSettingCfgInput)
        axios
          .post("/api/Setting/SetCameraCfg", this.cameraSettingCfgInput)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 1) {
                // this.$bus.emit("uplist");
                this.$emit("uplist");
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.createVideo();
              } else {
                this.$message.error(res.data.msg);
              }
            }
          });
      },
      // 自定义上传
      innerupload(fileObj) {
        const formData = new FormData();
        formData.set("file", fileObj.file);
        axios
          .post("/api/Setting/Upload", formData, {
            headers: {
              "Content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.data !== null) {
                this.cameraSettingCfgInput.ncPath = res.data.data.path;
              } else {
                alert(res.data.msg);
              }
            }
          })
          .catch();
      },
      outerupload(fileObj) {
        const formData = new FormData();
        formData.set("file", fileObj.file);
        axios
          .post("/api/Setting/Upload", formData, {
            headers: {
              "Content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.data !== null) {
                this.cameraSettingCfgInput.wcPath = res.data.data.path;
              } else {
                alert(res.data.msg);
              }
            }
          })
          .catch();
      },
      //获取区域
      getRegion() {
        let res = axios
          .post("/api/Setting/GetRegionModelList", {
            regionModelIds: [],
            regionModelType: "Unknown",
            enabledOnly: false,
          })
          .then((res) => {
            this.regionList = res.data.data;
          });
      },
      close() {
        console.log("close")
      },
      addentity(cartesian1) {
        this.remEntity();
        console.log("不存在存在remove", cartesian1);
        window.viewer.entities.add({
          id: "boundary",
          name: "boundary",
          position: cartesian1,
          point: {
            color: Cesium.Color.RED,
            pixelSize: 10,
          },
        });
      },
      remEntity() {
        if (window.viewer.entities.getById("boundary")) {
          console.log("存在remove");
          window.viewer.entities.removeById("boundary");
        }
      }
    },
  };
</script>
<style lang="scss" scoped>
  .cameraconfig {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 100;
    height: 50%;
    width: 30%;
    /* border: #000 5px solid; */
    background-color: #000;
    /* padding: 10px; */
    opacity: 0.8;
    color: #fff;
    animation: opacity 0.25s forwards 5s;

    ::v-deep .el-form-item__label {
      color: #fff;
    }
  }

  ::v-deep .cameraconfig .cameralist .el-table__header-wrapper .el-table__header th {
    background-color: rgba(70, 130, 180, 0.2);
    color: #fff;
  }

  .inputdiv {
    width: 100%;
  }

  ::v-deep .el-table thead {
    color: #fff;
  }

  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }

  .from {
    height: calc(100% - 100px);
    padding: 10px;
    overflow: auto;
  }

  .bu {
    text-align: center;
    position: relative;
    margin-top: 5px;
    width: 100%;
    height: 50px;
  }
</style>