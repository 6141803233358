import * as Cesium from "cesium";
import { getModelMessage, getFeature } from "../modelMessige/index"
export function useModel(props) {
  function addModel(url, row) {
    let tileset = new Cesium.Cesium3DTileset({
      url,
      skipLevelOfDetail: true,
      baseScreenSpaceError: 1024,
      skipScreenSpaceErrorFactor: 16,
      skipLevels: 1,
      immediatelyLoadDesiredLevelOfDetail: false,
      loadSiblings: false,
      cullWithChildrenBounds: true,
      maximumScreenSpaceError: 16,
    });

    window.viewer.scene.primitives.add(tileset);
    console.log("加载模型")


    tileset.readyPromise.then((tileset) => {
      console.log("模型加载完成",row)
      translation(tileset, { tx: row.longitudeOffset, ty: row.latitudeOffset, tz: row.heightOffset })
      update3dtilesMaxtrix(tileset, { rx: row.pitch, ry: row.yaw, rz: row.roll })
  


    });

    tileset.tileLoad.addEventListener((tile) => {
      // console.log("模型的信息", tile)
      // getModelMessage(tile, [
      // ])
    })
    return tileset;
  }
  function translation(tileset, convert = {
    tx: 0, ty: 0, tz: 0
  }) {
    console.log("模型移动",tileset,convert)
    let { long, lat, height } = getCartographic(tileset)
    // 计算中心点位置的地表坐标
    let surface = Cesium.Cartesian3.fromDegrees(
      long,
      lat,
      0.0
    );
    // 偏移后的坐标
    let offset = Cesium.Cartesian3.fromDegrees(
      long + convert.tx,
      lat + convert.ty,
      convert.tz
    );
    console.log("偏移后坐标",offset)
    let translation = Cesium.Cartesian3.subtract(
      offset,
      surface,
      new Cesium.Cartesian3()
    );
    // tileset.modelMatrix转换
    tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);

  }


  //获取模型中心点位置
  function getCartographic(tileset) {
    // 计算tileset的绑定范围（包围球）
    let boundingSphere = tileset.boundingSphere;
    // 计算中心点位置
    let cartographic = Cesium.Cartographic.fromCartesian(
      boundingSphere.center
    );
    // 计算中心点位置的地表坐标
    let long = Cesium.Math.toDegrees(cartographic.longitude)
    let lat = Cesium.Math.toDegrees(cartographic.latitude)
    let height = cartographic.height;
    return { long, lat, height }
  }

  //更新3dtiles的旋转
  function update3dtilesMaxtrix(tileset, convert = { rx: 0, ry: 0, rz: 0 }) {
    //旋转
    var mx = Cesium.Matrix3.fromRotationX(Cesium.Math.toRadians(convert.rx));
    var my = Cesium.Matrix3.fromRotationY(Cesium.Math.toRadians(convert.ry));
    var mz = Cesium.Matrix3.fromRotationZ(Cesium.Math.toRadians(convert.rz));
    var rotationX = Cesium.Matrix4.fromRotationTranslation(mx);
    var rotationY = Cesium.Matrix4.fromRotationTranslation(my);
    var rotationZ = Cesium.Matrix4.fromRotationTranslation(mz);
    let tr = tileset.root.transform
    var position = new Cesium.Cartesian3(tr[12], tr[13], tr[14]);
    var m = Cesium.Transforms.eastNorthUpToFixedFrame(position);
    //旋转、平移矩阵相乘
    Cesium.Matrix4.multiply(m, rotationX, m);
    Cesium.Matrix4.multiply(m, rotationY, m);
    Cesium.Matrix4.multiply(m, rotationZ, m);
    //赋值给tileset
    tileset.root.transform = m;
  }


  function removeModel(model) {
    console.log("卸载模型");
    return window.viewer.scene.primitives.remove(model);
  }
  function removeModelAll() {
    window.viewer.scene.primitives.removeAll();
  }

  return {
    addModel,
    removeModel,
    removeModelAll,
    translation,
    update3dtilesMaxtrix,
  };
}
