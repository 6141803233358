<template lang="">
  <div class="home">
    <camera-split-area @dialogNone="cameraSplitAreaShow" :dialogVisible="dialogVisible"
      :cameraSplit="cameraSplit"></camera-split-area>

    <!-- 投影参数 -->
    <cameraCfg :camera="currentCamera" v-if="showCameraConfig" @xyChange="xyChange"
      @updateCameraPosition="updateCameraPosition" @updateRotation="updateRotation" @updatenear="updatenear"
      @updatefar="updatefar" @updatefov="updatefov" @updatefovY="updatefovY" @updateAlpha="updateAlpha"
      @changeshow="changeshow" @createVideo="createVideo" @delVideo="delVideo" @getpoint2="getpoint2"
      @gotovideo="gotovideo" @save="save" @boundary="boundary"></cameraCfg>

    <!-- 投影相机参数 -->
    <cameraconfig v-if="configShow" :cameraItem="cameraItem" @cancel="cancel" @uplist="uplist"></cameraconfig>

    <cameralistTwoC :cameralistShow="cameralistShow" :cameralisClose="cameralisClose" @emitindex="emitindex"
      @CameraSplitArea="CameraSplitArea" @upCameraConfig="upCameraConfig" @close="cameralisClose" v-if="cameralistShow"
      ref="cameralist">
    </cameralistTwoC>
    <projectionArea class="projectionArea" v-if="projectionAreaShow" :id="id" :close="boundary"></projectionArea>
  </div>
</template>
<script>
  import axios from "axios";
  import cameraCfg from "../cameraCfg/index.vue";
  import cameraconfig from "../cameraConfig/index.vue";
  import cameraSplitArea from "../cameraSplitArea/index.vue";
  import cameralistTwoC from "../cameraList/indexTwoC.vue";
  import projectionArea from "../projectionArea/index.vue";
  export default {
    name: "home",
    props: {
      cameralistShow: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Object,
        default: {}
      },
      cameralisClose: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      cameraCfg,
      cameraconfig,
      "camera-split-area": cameraSplitArea,
      cameralistTwoC,
      projectionArea
    },
    data() {
      return {
        cameralist: [],
        currentCamera: null,
        currentRow: null,
        show: true,
        showCameraConfig: false,
        configShow: false,
        cameraItem: null,
        dialogVisible: false,
        cameraSplit: {
          channelId: 0,
          imageOriginWidth: 0,
          imageOriginHeight: 0,
        },
        projectionAreaShow: false
      };
    },
    created() { },
    beforeDestroy() { },
    mounted() {
      // this.getcamera();
    },
    methods: {
      //点击相机列表事件
      emitindex(row) {
        this.currentCamera = row;
        this.showCameraConfig = true;
        this.$emit("emitindex", row);
      },
      //相机分割区域
      CameraSplitArea(cameraSplit, show) {
        this.cameraSplit = cameraSplit;
        this.cameraSplitAreaShow(show);
      },
      //分割区域弹窗展示
      cameraSplitAreaShow(show) {
        this.dialogVisible = show;
      },
      //增加或修改相机参数
      upCameraConfig(item) {
        if (item) {
          this.cameraItem = item;
        } else {
          this.cameraItem = null;
        }
        this.configShow = true;
      },
      //关闭投影相机配置弹窗
      cancel() {
        this.configShow = false;
      },
      //修改相机配置后更新列表
      uplist() {
        console.log("uplist更新相机列表");
        this.$refs.cameralist.getcamera();
      },
      xyChange(option) {
        console.log(option, "xyChange");

        this.$emit("xyChange", option);
      },
      updateCameraPosition(option) {
        this.$emit("updateCameraPosition", option);
      },
      updateRotation(option) {
        this.$emit("updateRotation", option);
      },
      updatenear(option) {
        this.$emit("updatenear", option);
      },
      updatefar(option) {
        this.$emit("updatefar", option);
      },
      updatefov(option) {
        this.$emit("updatefov", option);
      },
      updatefovY(option) {
        this.$emit("updatefovY", option);
      },
      updateAlpha(option) {
        this.$emit("updateAlpha", option);
      },
      changeshow(option) {
        this.$emit("changeshow", option);
      },
      createVideo(option) {
        this.$emit("createVideo", option);
      },
      delVideo(option) {
        this.$emit("delVideo", option);
      },
      getpoint2(option) {
        this.$emit("getpoint2", option);
      },
      gotovideo(option) {
        this.$emit("gotovideo", option);
      },
      save(option) {
        this.$emit("save", option);
      },
      boundary(option) {
        this.projectionAreaShow = !this.projectionAreaShow
        // this.$emit("boundary", option);
      },
    },
  };
</script>
<style lang=""></style>