import { implant } from "./implant.js";
const { getUrlData } = implant();

import { region } from "./region.js";
const { getRegion } = region();

import { fusion } from "./fusion.js";
import axios from "axios";
import * as cesium from "cesium";
import { clockModel, destroy } from "../utils/clock.js";

import { keyboardControlClass } from "../../views/cesiumUtils/entitys";

var fusionVideoCamera = null;
var model = null;
var keyboardControl = null;
var individualPositioning = [
  // {
  //   propertyes: [
  //     {
  //       time: "2024-01-01T00:00:00",
  //       position: {
  //         x: 117.0448773839347,
  //         y: 40.765753043566,
  //         z: 340.14336207283,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:05:00",
  //       position: {
  //         x: 117.0459773839347,
  //         y: 40.765753043566,
  //         z: 340.14336207283,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:05:00",
  //       position: {
  //         x: 117.0459773839347,
  //         y: 40.766253043566,
  //         z: 340.14336207283,
  //       },
  //     },
  //   ],

  //   id: 1,
  //   name: "张一",
  //   NO: "001",
  //   pr: "康定1-1",
  //   craft: "普工",
  // },
  // {
  //   propertyes: [
  //     {
  //       time: "2024-01-01T00:00:00",
  //       position: {
  //         x: 117.05110033913304,
  //         y: 40.765801082764604,
  //         z: 340.0563345803803,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:05:00",
  //       position: {
  //         x: 117.05210033913304,
  //         y: 40.765801082764604,
  //         z: 340.0563345803803,
  //       },
  //     },
  //   ],
  //   id: 2,
  //   name: "张二",
  //   NO: "002",
  //   pr: "康定1-1",
  //   craft: "普工",
  // },

  // {
  //   propertyes: [
  //     {
  //       time: "2024-01-01T00:00:00",
  //       position: {
  //         x: 117.04940059941646,
  //         y: 40.76574448489063,
  //         z: 340.07036393080426,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:05:00",
  //       position: {
  //         x: 117.05040059941646,
  //         y: 40.76574448489063,
  //         z: 340.07036393080426,
  //       },
  //     },
  //   ],
  //   id: 3,
  //   name: "张三",
  //   NO: "003",
  //   pr: "康定1-1",
  //   craft: "普工",
  // },

  // {
  //   propertyes: [
  //     {
  //       time: "2024-01-01T00:00:00",
  //       position: {
  //         x: 117.06564416780479,
  //         y: 40.76575688539349,
  //         z: 340.01242806194904,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:01:00",
  //       position: {
  //         x: 117.06664416780479,
  //         y: 40.76575688539349,
  //         z: 340.01242806194904,
  //       },
  //     },
  //     {
  //       time: "2024-01-01T00:01:30",
  //       position: {
  //         x: 117.06664416780479,
  //         y: 40.76615688539349,
  //         z: 340.01242806194904,
  //       },
  //     },
  //   ],
  //   id: 4,
  //   name: "张四",
  //   NO: "004",
  //   pr: "康定1-1",
  //   craft: "普工",
  // },

  {
    propertyes: [
      {
        time: "2024-01-01T00:00:00",
        position: {
          x: 117.06665521632481,
          y: 40.76581394315688,
          z: 340.04579642754965,
        },
      },
      {
        time: "2024-01-01T00:01:00",
        position: {
          x: 117.06765521632481,
          y: 40.76581394315688,
          z: 340.04579642754965,
        },
      },
      {
        time: "2024-01-01T00:01:30",
        position: {
          x: 117.06765521632481,
          y: 40.76601394315688,
          z: 340.04579642754965,
        },
      },
    ],
    id: 5,
    name: "张五",
    NO: "005",
    pr: "康定1-1",
    craft: "普工",
  },
];

export async function ifromImplant() {
  let regionId = getUrlData().regionId;
  console.log(regionId, "传入参数");
  if (regionId) {
    let regionData = await getRegion(regionId);
    console.log(regionData, "区域数据");
  }
}
export function getTitleShow() {
  let titleShow = getUrlData().titleShow;
  if (titleShow) {
    titleShow = titleShow === "true" ? true : false;
  } else {
    titleShow = true;
  }
  return titleShow;
}

export function addFusion() {
  let regionId = getUrlData().regionId;
  let cameraId = getUrlData().cameraId;
  if (regionId) {
    axios
      .post("api/Calib/GetCameras", {
        ids: [],
        regionModelIds: [regionId],
      })
      .then((res) => {
        console.log(res);
        res.data.data.forEach((item) => {
          if (item.id === cameraId) {
            let option = {
              // ...item
              id: item.id,
              position: {
                x: item.lng,
                y: item.lat,
                z: item.height,
              },
              far: item.farPlane,
              // 横摆角俯仰角
              rotation: {
                x: item.pitch,
                y: item.yaw,
                z: item.roll,
              },
              elevation: item.elevation,

              // 透明度
              alpha: 1,
              // 近平面
              near: item.nearPlane,
              // 视场角
              fov: item.hfov,
              // 是否显示视锥线
              debugFrustum: false,
              type: 3,
              aspectRatio: null,
              fovy: item.vfov,
            };

            fusionVideoCamera = new fusion(item, option);
            console.log(option, "融合参数");
            window.viewer.camera.flyTo({
              destination: cesium.Cartesian3.fromDegrees(
                option.position.x,
                option.position.y,
                option.position.z + option.elevation
              ),
              orientation: {
                heading: cesium.Math.toRadians(option.rotation.y),
                pitch: cesium.Math.toRadians(option.rotation.x),
                roll: cesium.Math.toRadians(option.rotation.z),
              },
              complete: function () {
                console.log("视角飞到成功");
                keyboardControl = new keyboardControlClass(
                  cesium,
                  window.viewer
                );
                keyboardControl.enterKeyBoardMouseRoamingMode();
              },
            });
          }
        });
      });
  }
}
export function fusionVideoCameraDestroy(show) {
  console.log("融合销毁", show);
  if (show) {
    addFusion();
    individualPositioning.forEach((item) => {
      let entity = window.viewer.entities.getById(item.id);
      if (entity) {
        // entity.show = false;
        destroy();
      }
    });
  } else {
    if (fusionVideoCamera) {
      console.log("销毁融合", fusionVideoCamera);
      fusionVideoCamera.destroy();
      fusionVideoCamera = null;
      keyboardControl.exitKeyBoardMouseRoamingMode();
      keyboardControl = null;
    }

    individualPositioning.forEach((item) => {
      // let entity = window.viewer.entities.getById(item.id);
      // if (entity) {
      //   entity.show = true;
      // } else {
      //   // addModel(item);
      //   // let i = 0;
      //   // let j = 1;
      //   // let it = { ...item };

      //   // // 每隔100毫秒修改一次参数
      //   // setInterval(() => {
      //   //   if (i == 0) {
      //   //     item.position = {
      //   //       x: it.position.x,
      //   //       y: it.position.y,
      //   //       z: it.position.z,
      //   //     };
      //   //   } else {
      //   //     item.position = {
      //   //       x: item.position.x + i * 0.0000001,
      //   //       y: item.position.y,
      //   //       z: item.position.z,
      //   //     };
      //   //   }
      //   //   console.log(i, "i", j, "j");

      //   //   // 使变量间隔2秒重复
      //   //   if (i >= 200) {
      //   //     j = -1;
      //   //   }
      //   //   if (i <= 0) {
      //   //     j = 1;
      //   //   }

      //   //   i += j;
      //   // }, 100);
      //   // createClock(item.propertyes[0].time, item.propertyes[1].time);
      //   // const position = computeCirclularFlight(item.propertyes);

      //   // addModelClock(
      //   //   item,
      //   //   item.propertyes[0].time,
      //   //   item.propertyes[1].time,
      //   //   position
      //   // );
      //   clockModel(item);
      // }
      clockModel(item);
    });
  }
}
function addModel(item) {
  console.log("添加模型", item);
  model = window.viewer.entities.add({
    id: item.id,
    name: item.name,
    NO: item.NO,
    pr: item.pr,
    craft: item.craft,

    position: new cesium.CallbackProperty(function (time, result) {
      return cesium.Cartesian3.fromDegrees(
        item.position.x,
        item.position.y,
        item.position.z
      );
    }, false),
    model: {
      show: true,
      uri: "http://62.234.216.56:9400/static/3D/glb/WhiteClothedForeigner.glb",
      scale: 0.0001,
      minimumPixelSize: 0.0,
      maximumScale: 0.0001,
      runAnimations: true,
    },

    show: true,
  });
}
