import * as Cesium from "cesium"
class modelMessage {
    constructor(parameters) {
        this.viewer = window.viewer
        this.handler = null
        this.silhouetteBlue = null
        this.silhouetteGreen = null
    };
    init() {
        let viewer = window.viewer
        this.handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);


        // HTML overlay for showing feature name on mouseover
        const nameOverlay = document.createElement("div");
        this.viewer.container.appendChild(nameOverlay);
        nameOverlay.className = "backdrop";
        nameOverlay.style.display = "none";
        nameOverlay.style.position = "absolute";
        nameOverlay.style.bottom = "0";
        nameOverlay.style.left = "0";
        nameOverlay.style["pointer-events"] = "none";
        nameOverlay.style.padding = "4px";
        nameOverlay.style.backgroundColor = "black";

        // Information about the currently selected feature
        const selected = {
            feature: undefined,
            originalColor: new Cesium.Color(),
        };

        // An entity object which will hold info about the currently selected feature for infobox display
        const selectedEntity = new Cesium.Entity();

        // Get default left click handler for when a feature is not picked on left click
        const clickHandler = this.handler.getInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
        );

        // Update the 'nameOverlay' for the given picked feature,
        // at the given (screen) position.
        function updateNameOverlay(pickedFeature, position) {
            if (!Cesium.defined(pickedFeature)) {
                nameOverlay.style.display = "none";
                return;
            }
            // A feature was picked, so show its overlay content
            nameOverlay.style.display = "block";
            nameOverlay.style.color = "#ffffff"
            nameOverlay.style.bottom = `${viewer.canvas.clientHeight - position.y}px`;
            nameOverlay.style.left = `${position.x}px`;
            const name = pickedFeature.getProperty("name");
            nameOverlay.textContent = name;
        }

        // Create the HTML that will be put into the info box that shows
        // information about the currently selected feature
        function createPickedFeatureDescription(pickedFeature) {
            const description =
                `${'<table class="cesium-infoBox-defaultTable"><tbody>' +
                "<tr><th>id</th><td>"
                }${pickedFeature.getProperty("id")}</td></tr>` +

                `<tr><th>name</th><td>${pickedFeature.getProperty(
                    "name",
                )}</td></tr>` +

                `</tbody></table>`;
            return description;
        }
        console.log("是否支持后处理程序:", Cesium.PostProcessStageLibrary.isSilhouetteSupported(this.viewer.scene))

        // If silhouettes are supported, silhouette features in blue on mouse over and silhouette green on mouse click.
        // If silhouettes are not supported, change the feature color to yellow on mouse over and green on mouse click.
        if (Cesium.PostProcessStageLibrary.isSilhouetteSupported(this.viewer.scene)) {
            // Silhouettes are supported
            this.silhouetteBlue =
                Cesium.PostProcessStageLibrary.createEdgeDetectionStage();
            this.silhouetteBlue.uniforms.color = Cesium.Color.BLUE;
            this.silhouetteBlue.uniforms.length = 0.01;
            this.silhouetteBlue.selected = [];
            console.log(this.silhouetteBlue)

            this.silhouetteGreen =
                Cesium.PostProcessStageLibrary.createEdgeDetectionStage();
            this.silhouetteGreen.uniforms.color = Cesium.Color.LIME;
            this.silhouetteGreen.uniforms.length = 0.01;
            this.silhouetteGreen.selected = [];
            console.log(this.silhouetteGreen)

            this.viewer.scene.postProcessStages.add(
                Cesium.PostProcessStageLibrary.createSilhouetteStage([
                    this.silhouetteBlue,
                    this.silhouetteGreen,
                ]),
            );

            // Silhouette a feature blue on hover.
            this.handler.setInputAction((movement) => {
                // If a feature was previously highlighted, undo the highlight
                this.silhouetteBlue.selected = [];

                // Pick a new feature
                const pickedFeature = this.viewer.scene.pick(movement.endPosition);

                updateNameOverlay(pickedFeature, movement.endPosition);

                if (!Cesium.defined(pickedFeature)) {
                    return;
                }

                // Highlight the feature if it's not already selected.
                if (pickedFeature !== selected.feature) {
                    this.silhouetteBlue.selected = [pickedFeature];
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // Silhouette a feature on selection and show metadata in the InfoBox.
            this.handler.setInputAction((movement) => {
                // If a feature was previously selected, undo the highlight
                this.silhouetteGreen.selected = [];

                // Pick a new feature
                const pickedFeature = this.viewer.scene.pick(movement.position);
                if (!Cesium.defined(pickedFeature)) {
                    clickHandler(movement);
                    return;
                }

                // Select the feature if it's not already selected
                if (this.silhouetteGreen.selected[0] === pickedFeature) {
                    return;
                }

                // Save the selected feature's original color
                const highlightedFeature = this.silhouetteBlue.selected[0];
                if (pickedFeature === highlightedFeature) {
                    this.silhouetteBlue.selected = [];
                }

                // Highlight newly selected feature
                this.silhouetteGreen.selected = [pickedFeature];

                // Set feature infobox description
                // this.viewer.selectedEntity = selectedEntity;
                // selectedEntity.description = createPickedFeatureDescription(pickedFeature);
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        } else {
            // Silhouettes are not supported. Instead, change the feature color.

            // Information about the currently highlighted feature
            const highlighted = {
                feature: undefined,
                originalColor: new Cesium.Color(),
            };

            // Color a feature yellow on hover.
            this.handler.setInputAction(function onMouseMove(movement) {
                // If a feature was previously highlighted, undo the highlight
                if (Cesium.defined(highlighted.feature)) {
                    highlighted.feature.color = highlighted.originalColor;
                    highlighted.feature = undefined;
                }
                // Pick a new feature
                const pickedFeature = this.viewer.scene.pick(movement.endPosition);
                updateNameOverlay(pickedFeature, movement.endPosition);

                if (!Cesium.defined(pickedFeature)) {
                    return;
                }

                // Highlight the feature if it's not already selected.
                if (pickedFeature !== selected.feature) {
                    highlighted.feature = pickedFeature;
                    Cesium.Color.clone(pickedFeature.color, highlighted.originalColor);
                    pickedFeature.color = Cesium.Color.YELLOW;
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // Color a feature on selection and show metadata in the InfoBox.
            this.handler.setInputAction(function onLeftClick(movement) {
                // If a feature was previously selected, undo the highlight
                if (Cesium.defined(selected.feature)) {
                    selected.feature.color = selected.originalColor;
                    selected.feature = undefined;
                }
                // Pick a new feature
                const pickedFeature = this.viewer.scene.pick(movement.position);
                if (!Cesium.defined(pickedFeature)) {
                    clickHandler(movement);
                    return;
                }
                // Select the feature if it's not already selected
                if (selected.feature === pickedFeature) {
                    return;
                }
                selected.feature = pickedFeature;
                // Save the selected feature's original color
                if (pickedFeature === highlighted.feature) {
                    Cesium.Color.clone(highlighted.originalColor, selected.originalColor);
                    highlighted.feature = undefined;
                } else {
                    Cesium.Color.clone(pickedFeature.color, selected.originalColor);
                }
                // Highlight newly selected feature
                pickedFeature.color = Cesium.Color.LIME;

                // Set feature infobox description
                this.viewer.selectedEntity = selectedEntity;
                selectedEntity.description = createPickedFeatureDescription(pickedFeature);
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
    }
    destroy() {
        this.silhouetteBlue.selected = [];
        this.silhouetteGreen.selected = [];
        this.silhouetteBlue = null
        this.silhouetteGreen = null
        this.viewer.scene.postProcessStages.removeAll()
        this.handler.destroy()
    }
}


export { modelMessage }