<template lang="">
  <div class="spantext">
    <span>
      <div>横摆角(heading): <span id="heading">{{ cameraParameter.heading.toFixed(2) }} </span> <el-button class="btn"
          type="primary" size="mini" data-clipboard-target="#heading">复制</el-button></div>
      <div>俯仰角(pitch): <span id="pitch">{{ cameraParameter.pitch.toFixed(2) }}</span> <el-button class="btn"
          type="primary" size="mini" data-clipboard-target="#pitch">复制</el-button></div>
      <div>旋转角(roll): <span id="roll">{{ cameraParameter.roll.toFixed(2) }}</span> <el-button class="btn" type="primary"
          size="mini" data-clipboard-target="#roll">复制</el-button></div>

      <div>
        经纬度：<span id="jwd">{{ cameraParameter.coordinate.lng }},{{
          cameraParameter.coordinate.lat
          }},{{ cameraParameter.coordinate.height }}</span> <el-button class="btn" type="primary" size="mini"
          data-clipboard-target="#jwd">复制</el-button>
      </div>
      <div>
        笛卡尔：<span id="dke">{{ cameraParameter.cartesian.x }},{{
          cameraParameter.cartesian.y
          }},{{ cameraParameter.cartesian.z }}</span> <el-button class="btn" type="primary" size="mini"
          data-clipboard-target="#dke">复制</el-button>
      </div>
    </span>
  </div>
</template>
<script>
  import { getCameraParameter } from "../../views/cesiumUtils/cesiumEntitys.js";
  import * as Cesium from "cesium"
  export default {
    props: {

    },
    data() {
      return {
        cameraParameter: {
          heading: 0,
          pitch: 0,
          roll: 0,
          cartesian: {},
          coordinate: {},
        }
      };
    },
    mounted() {
      this.camera();
      window.viewer.camera.changed.addEventListener(this.camera);
    },
    beforeDestroy() {
      window.viewer.camera.changed.removeEventListener(this.camera);
    },
    methods: {
      camera() {
        this.cameraParameter = getCameraParameter(Cesium, window.viewer);
      }

    },
  };
</script>
<style lang="scss" scoped>
  .spantext {
    position: absolute;
    top: 100px;
    left: 10px;
    z-index: 100;
    opacity: 0.8;
    overflow: auto;
    color: #fff;

    div {
      margin: 5px 0;
    }

  }
</style>