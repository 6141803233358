<template>
  <div class="coordinateTransformation">
    <span>坐标转换:</span>
    <div>
      <div class="contentItem">
        <el-input v-model="coordinate" placeholder="请输入内容"></el-input>
      </div>
      <div class="contentItem">
        <el-button class="btn" type="primary" @click="toLonAndLat"
          >经纬度</el-button
        >
        <el-button class="btn" type="primary" @click="toCartesian3"
          >笛卡尔</el-button
        >
      </div>
      <div class="contentItem">
        <div>
          <el-row>
            <el-col :span="17">
              <el-input v-model="x" placeholder="请输入内容" id="x"></el-input
            ></el-col>
            <el-col :span="7">
              <el-button type="primary" class="btn" data-clipboard-target="#x"
                >复制</el-button
              ></el-col
            >
          </el-row>
        </div>
        <div>
          <el-row>
            <el-col :span="17">
              <el-input v-model="y" placeholder="请输入内容" id="y"></el-input
            ></el-col>
            <el-col :span="7">
              <el-button type="primary" class="btn" data-clipboard-target="#y"
                >复制</el-button
              ></el-col
            >
          </el-row>
        </div>
        <div>
          <el-row>
            <el-col :span="17">
              <el-input v-model="z" placeholder="请输入内容" id="z"></el-input
            ></el-col>
            <el-col :span="7">
              <el-button type="primary" class="btn" data-clipboard-target="#z"
                >复制</el-button
              ></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Cesium from "cesium";
import Clipboard from "clipboard";
export default {
  name: "coordinateTransformation",
  data() {
    return {
      coordinate: "",
      input: "",
      x: "",
      y: "",
      z: "",
    };
  },
  created() {
    new Clipboard(".btn");
  },
  methods: {
    toLonAndLat() {
      let ponstion = this.coordinate.split(",");
      console.log(ponstion);
      let a = {};
      a.lng = ponstion[0];
      a.lat = ponstion[1];
      a.height = ponstion[2];
      let c3 = new Cesium.Cartesian3(a.lng, a.lat, a.height);
      let ll = this.GetWGS84FromDKR(c3);
      console.log(ll);
      this.x = ll.x;
      this.y = ll.y;
      this.z = ll.z;
    },
    toCartesian3() {
      let ponstion =  this.coordinate.split(",");
      console.log(ponstion);
      let a = {};
      a.lng = ponstion[0];
      a.lat = ponstion[1];
      a.height = ponstion[2];
      console.log(a)
      let c3 = Cesium.Cartesian3.fromDegrees(
        Number(a.lng),
        Number(a.lat),
        Number(a.height),
      );
      console.log(c3)
      this.x =c3.x;
      this.y = c3.y;
      this.z = c3.z;
    },

    GetWGS84FromDKR(coor) {
      let cartographic = Cesium.Cartographic.fromCartesian(coor);
      let x = Cesium.Math.toDegrees(cartographic.longitude);
      let y = Cesium.Math.toDegrees(cartographic.latitude);
      let z = cartographic.height;
      let wgs84 = {
        x: x,
        y: y,
        z: z,
      };
      return wgs84;
    },
  },
};
</script>
<style lang="scss" scoped>
.coordinateTransformation {
  color: #fff;
  .contentItem {
    margin-top: 10px;
  }
}
</style>
