<template lang="">
  <el-table ref="multipleTable" :data="regionList" tooltip-effect="dark" style="width: 100%"
    @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55"> </el-table-column>

    <el-table-column prop="regionName" label="区域名称"> </el-table-column>
  </el-table>
</template>
<script>
  import axios from "axios";
  export default {
    data() {
      return {
        regionList: [],
        multipleSelection: [],
      };
    },
    created() { },
    mounted() {
      this.GetRegionModelList();
    },
    methods: {
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //请求区域列表
      GetRegionModelList() {
        axios
          .post("/api/Setting/GetRegionModelList", {
            enabledOnly: false,
            regionModelType: "Unknown",
          })
          .then((row) => {
            let data = row.data.data;
            data.forEach((item) => {
              console.log(item, "item");
              item.isChecked = false;
              item.isIndeterminate = false;
              if (item.models && item.models.length > 0) {
                item.models.forEach((el) => {
                  el.isChecked = false;
                  el.type = "model";
                });
              }
              if (item.dems && item.dems.length > 0) {
                item.dems.forEach((el) => {
                  el.isChecked = false;
                  el.type = "dem";
                });
              }
              if (item.tmses && item.tmses.length > 0) {
                item.tmses.forEach((el) => {
                  el.isChecked = false;
                  el.type = "tms";
                });
              }
            });
            this.regionList = data;
          });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
