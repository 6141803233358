// import CallbackProperty from "cesium/Source/DataSources/CallbackProperty";

/**
 * 使用鼠标画折线
 * @param {*} Cesium
 * @param {*} viewer
 * @param {*} handler 鼠标动作
 */
export function lines(Cesium, viewer, handler) {
  var line = undefined; //全局线对象
  var linearr = []; //线的坐标存储
  //鼠标点击事件监听
  handler.setInputAction(function (event) {
    //获取世界坐标点
    var pick = viewer.camera.getPickRay(event.position);
    var cartesian = viewer.scene.globe.pick(pick, viewer.scene);
    //如果世界坐标点存在
    if (cartesian) {
      linearr.push(cartesian);
      //添加一个线对象
      if (!line) {
        line = viewer.entities.add({
          polyline: {
            positions: new Cesium.CallbackProperty(function () {
              return linearr;
            }, false),
            width: 3,
            material: Cesium.Color.RED,
            clampToGround: true,
          },
        });
      }
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  //鼠标移动事件监听
  handler.setInputAction(function (event) {
    var pick = viewer.camera.getPickRay(event.endPosition);
    var cartesian = viewer.scene.globe.pick(pick, viewer.scene);
    if (cartesian) {
      if (line) {
        //考虑在鼠标移动的一瞬间,linearr应该增加一个坐标点,当再次移动时,该坐标点应该更换
        linearr[linearr.length - 1] = cartesian;
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
}
/**
 *
 * @param {*} Cesium  Cesium
 * @param {*} viewer  Cesium.Viewer对象
 * @param {*} re  圆心所在经纬度（笛卡尔）
 */
export function addHollowCircle(Cesium, viewer, re) {
  var scene = viewer.scene;
  var circleOutlineInstance1 = new Cesium.GeometryInstance({
    id: "测试圆圈",
    geometry: new Cesium.CircleOutlineGeometry({
      center: new Cesium.Cartesian3.fromDegrees(re.geo.x, re.geo.y, re.geo.z),
      radius: 50,
      height: 990,
    }),
    attributes: {
      color: Cesium.ColorGeometryInstanceAttribute.fromColor(
        Cesium.Color.fromCssColorString("#ffc0cb")
      ),
    },
  });

  let primitive = new Cesium.Primitive({
    geometryInstances: [circleOutlineInstance1],
    appearance: new Cesium.PerInstanceColorAppearance({
      flat: true,
      translucent: false,
      renderState: {
        lineWidth: Math.min(10.0, scene.maximumAliasedLineWidth),
      },
    }),
  })
  scene.primitives.add(
    primitive
  );

  let a = primitive.getGeometryInstanceAttributes('测试圆圈')
  // scene.primitives._primitives[3]._instanceBoundingSpheres[0].radius=100

}
//生成摄像机的点和该点到地心的连线
/**
 *
 * @param {*} Cesium cesium
 * @param {*} viewer cesium.Viewer
 * @param {*} col 位置信息
 */
export const addCamera = (function () {
  var id = null
  var ab = function (Cesium, viewer, col) {
    this.cf = []
    this.id = col.id
    this.cd(Cesium, viewer, col)
  }
  ab.prototype.cd = function (Cesium, viewer, col) {
    // var linePositions = Cesium.Cartesian3.fromDegreesArrayHeights([
    //   col.lng,
    //   col.lat,
    //   col.height + col.elevation,
    //   col.lng,
    //   col.lat,
    //   0,
    // ]);
    // this.cf=linePositions
    viewer.entities.add({
      id: col.id,
      position: Cesium.Cartesian3.fromDegrees(
        col.lng,
        col.lat,
        col.height + col.elevation
      ),
      polyline: {
        // positions: new Cesium.CallbackProperty(()=>{
        //   return this.cf;
        // }, false),
        positions: Cesium.Cartesian3.fromDegreesArrayHeights([
          col.lng,
          col.lat,
          col.height + col.elevation,
          col.lng,
          col.lat,
          0,
        ]),
        // positions:linePositions,
        // width: 5,
        material: new Cesium.PolylineDashMaterialProperty({
          color: Cesium.Color.YELLOW,
          dashLength: 20 //短划线长度
        })


      },
      point: {
        color: Cesium.Color.RED,
        pixelSize: 10,
      },
      // length: linePositions.length - 1
    });
    // viewer.entities.add({
    //   id: col.id + "line",
    //   polyline: {
    //     positions: new Cesium.CallbackProperty(function () {
    //       return linePositions;
    //     }, false),
    //     width: 5,
    //     material: Cesium.Color.RED,
    //   },
    // });

  }

  return ab



})();
export function inputposition1(Cesium, viewer, position, EntityCollection, id) {
  let entity = EntityCollection.entities.getById(id)
  if (entity) {
    entity.position = new Cesium.CallbackProperty(() => {
      return Cesium.Cartesian3.fromDegrees(
        Number(position.lng),
        Number(position.lat),
        Number(position.height)
      )
    }, false)

  } else {
    let entity2 = viewer.entities.add({
      id: id,
      position: Cesium.Cartesian3.fromDegrees(
        Number(position.lng),
        Number(position.lat),
        Number(position.height)
      ),
      // polyline: {
      //   // positions: new Cesium.CallbackProperty(()=>{
      //   //   return this.cf;
      //   // }, false),
      //   positions:Cesium.Cartesian3.fromDegreesArrayHeights([
      //     col.lng,
      //     col.lat,
      //     col.height + col.elevation,
      //     col.lng,
      //     col.lat,
      //     0,
      //   ]),
      //   // positions:linePositions,
      //   // width: 5,
      //   material: new Cesium.PolylineDashMaterialProperty({
      //     color: Cesium.Color.YELLOW,
      //     dashLength: 20 //短划线长度
      //   })


      // },
      point: {
        color: Cesium.Color.YELLOW,
        pixelSize: 10,
      },
      // length: linePositions.length - 1
    });
    EntityCollection.entities.add(entity2)
  }



}
export function inputposition2(Cesium, viewer, position, EntityCollection, id) {
  let entity = EntityCollection.entities.getById(id)
  console.log(entity, "实体")
  if (entity) {
    entity.position = new Cesium.CallbackProperty(() => {
      return new Cesium.Cartesian3(Number(position.lng), Number(position.lat), Number(position.height))
    }, false)

  } else {
    let entity2 = viewer.entities.add({
      position: new Cesium.Cartesian3(Number(position.lng), Number(position.lat), Number(position.height)),
      // polyline: {
      //   // positions: new Cesium.CallbackProperty(()=>{
      //   //   return this.cf;
      //   // }, false),
      //   positions:Cesium.Cartesian3.fromDegreesArrayHeights([
      //     col.lng,
      //     col.lat,
      //     col.height + col.elevation,
      //     col.lng,
      //     col.lat,
      //     0,
      //   ]),
      //   // positions:linePositions,
      //   // width: 5,
      //   material: new Cesium.PolylineDashMaterialProperty({
      //     color: Cesium.Color.YELLOW,
      //     dashLength: 20 //短划线长度
      //   })
      // },
      point: {
        color: Cesium.Color.YELLOW,
        pixelSize: 10,
      },
      // length: linePositions.length - 1
    });
    EntityCollection.entities.add(entity2)
  }


}
export function clrcle(Cesium, viewer, position, EntityCollection, id) {
  let entity = EntityCollection.entities.getById(id)
  if (entity) {
    entity.position = new Cesium.CallbackProperty(() => {
      return new Cesium.Cartesian3.fromDegrees(
        Number(position.lng),
        Number(position.lat),
        Number(position.height)
      )
    }, false)

  } else {
    EntityCollection.entities.add({
      id: id,
      position: new Cesium.CallbackProperty(() => {
        return new Cesium.Cartesian3.fromDegrees(
          Number(position.lng),
          Number(position.lat),
          Number(position.height)
        );
      }, false),

      //   ellipse: {
      //   show: true,
      //     semiMinorAxis: 50,
      //     semiMajorAxis: 50,

      //   // height: 200,
      //   // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //   extrudedHeight: 1.0,
      //   extrudedHeightReference: Cesium.HeightReference.RELATIVE_TO_GROUND ,

      //   // rotation: Cesium.Math.toRadians(45), // 从北方逆时针旋转
      //   // stRotation: 0.0, // 纹理从北方逆时针旋转
      //   // granularity: Cesium.Math.RADIANS_PER_DEGREE, // 椭圆上各点之间的角距离
      //   material: Cesium.Color.BLUE.withAlpha(0.5),
      //   fill: true,
      //   outline: true,
      //   outlineColor: Cesium.Color.DARK_GREEN,
      //   outlineWidth: 100.0,
      //   numberOfVerticalLines: 16, // 沿轮廓的周长绘制的垂直线的数量
      //   shadows: Cesium.ShadowMode.DISABLED,
      //   // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
      //   //   1.0e3,
      //   //   2.0e3
      //   // ),

      //   // 在地面上时将对地形，3D tiles还是对两者进行分类  type:ClassificationType  default:ClassificationType.BOTH
      //   // TERRAIN 将仅对地形进行分类;CESIUM_3D_TILE 将仅对3D瓷砖进行分类;BOTH	将同时对Terrain和3D Tile进行分类。
      //   classificationType: Cesium.ClassificationType.BOTH,
      // },
      ellipse: {
        semiMinorAxis: window.AppConfig.peopleRange,
        semiMajorAxis: window.AppConfig.peopleRange,
        material: new Cesium.ImageMaterialProperty({
          image: require("../../assets/未选中圆圈.png"),
          repeat: new Cesium.Cartesian2(1.0, 1.0),
          transparent: true,
          color: Cesium.Color.WHITE.withAlpha(1),
        }),
        // Cesium.Color.fromCssColorString("#ffc0cb"),

        // rotation: Cesium.Math.toRadians(-40.0),
        // fill:false,
        // outline: true,
        // outlineColor: Cesium.Color.RED,
        // outlineWidth: 30.0,
        // numberOfVerticalLines: 20.0,
      },
      // point: {
      //   color: Cesium.Color.TRANSPARENT,
      //   pixelSize: 30,
      //   // disableDepthTestDistance: 5000
      //   outlineColor:new Cesium.Color(1,1,1,0.5),
      //   outlineWidth:10,
      //   heightReference: Cesium.HeightReference.none,
      // },
      label: {
        text: String(id),
        font: "14pt Source Han Sans CN", //字体样式
        fillColor: Cesium.Color.BLACK, //字体颜色
        backgroundColor: Cesium.Color.AQUA, //背景颜色
        showBackground: true, //是否显示背景颜色
        style: Cesium.LabelStyle.FILL, //label样式
        outlineWidth: 2,
        verticalOrigin: Cesium.VerticalOrigin.CENTER, //垂直位置
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT, //水平位置
        // pixelOffset: new Cesium.Cartesian2(0, 0) //偏移
        eyeOffset: new Cesium.Cartesian3(),
      },
    });

  }

}
/**
 * 将笛卡尔坐标转换为经纬度和高度并返回一个对象
 * @param {*} Cesium 引入cesium对象
 * @param {*} cartesian3 笛卡尔坐标
 * @returns
 */
export function toLatAndLon(Cesium, cartesian3) {
  let cartographic1 = Cesium.Cartographic.fromCartesian(cartesian3);
  let lon = Cesium.Math.toDegrees(cartographic1.longitude);
  let lat = Cesium.Math.toDegrees(cartographic1.latitude);
  let height = cartographic1.height; // 模型高度
  return {
    lon: lon,
    lat: lat,
    height: height
  }

}
/**
 *
 * @param {*} Cesium 引入cesium对象
 * @param {*} param1 经纬度和高度，当高度不传时默认为0
 * @returns
 */
export function toCartesian3(Cesium, {
  lon,
  lat,
  height = 0.0
}) {
  let cartesian = Cesium.Cartesian3.fromDegrees(lon, lat, height)
  return cartesian
}
export class keyboardControlClass {
  constructor(Cesium, viewer) {
    this.Cesium = Cesium
    this.viewer = viewer
    this.scene = viewer.scene;
    this.canvas = viewer.canvas; // 此处用viewer.canvas或viewer.scene.canvas都可以，是同一个canvas对象
    this.camera = viewer.camera;
    this.ellipsoid = viewer.scene.globe.ellipsoid;
    // 声明相机漫游标记
    this.flags = null;

    // 声明handler
    this.handler = null;

    this.removeCallback = null
    this.keyboardDown = null
    this.keyboardUp = null
    this.wheel = null
  };
  /**
 * 进入键盘鼠标漫游模式
 */
  enterKeyBoardMouseRoamingMode() {
    console.log('进入漫游模式');
    // 1.禁用默认相机操作模式
    this.scene.screenSpaceCameraController.enableRotate = false;
    this.scene.screenSpaceCameraController.enableTranslate = false;
    this.scene.screenSpaceCameraController.enableZoom = false;
    this.scene.screenSpaceCameraController.enableTilt = false;
    this.scene.screenSpaceCameraController.enableLook = false;
    // 2.初始化相机漫游的标记
    this.flags = {
      wheel: false, // 是否正在用滚轮调整视角
      wheelNumber: 0, // 滚轮滚动次数
      looking: false, // 是否正在用鼠标调整视角
      startPosition: null, // 鼠标指针开始移动位置
      endPosition: null, // 鼠标指针停止移动位置
      moveForward: false, // 是否向前移动
      moveBackward: false, // 是否向后移动
      moveLeft: false, // 是否向左移动
      moveRight: false, // 是否向右移动
      moveUp: false, // 是否向上移动
      moveDown: false, // 是否向下移动
      up: false, //视角向上
      down: false, //视角向下
      left: false, //视角向左
      right: false //视角向右
    }; // 相机漫游标记
    // 3.添加鼠标监听事件
    this.handler = new this.Cesium.ScreenSpaceEventHandler(this.canvas);
    // 左键按下
    this.handler.setInputAction((movement) => {
      this.flags.looking = true;
      this.flags.startPosition = this.Cesium.Cartesian3.clone(movement.position);
      this.flags.endPosition = this.Cesium.Cartesian3.clone(movement.position);
    }, this.Cesium.ScreenSpaceEventType.LEFT_DOWN);
    // 鼠标移动
    this.handler.setInputAction((movement) => {
      this.flags.endPosition = movement.endPosition;
    }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    // 左键弹起
    this.handler.setInputAction(() => {
      this.flags.looking = false;
    }, this.Cesium.ScreenSpaceEventType.LEFT_UP);

    // 4.添加键盘监听事件
    // 键盘按下事件
    document.addEventListener('keydown', this.keyboardDown = (event) => {
      this.keyDown(event)
    }, false);
    // 键盘弹起事件
    document.addEventListener('keyup', this.keyboardUp = (event) => {
      this.keyUp(event)
    }, false);
    //滚轮事件
    document.addEventListener('wheel', this.wheel = (event) => {
      console.log(event, "滚轮事件")
      this.flags.wheel = true;
      this.flags.wheelNumber = event.deltaY;


    }, false);

    // 5.添加渲染事件
    // this.viewer.clock.onTick.addEventListener(this.renderEvent);
    this.removeCallback = this.viewer.clock.onTick.addEventListener(() => {
      this.renderEvent()
    });
  }
  /**
 * 退出键盘鼠标漫游模式
 */
  exitKeyBoardMouseRoamingMode() {
    console.log('退出漫游');
    // 1.移除鼠标监听事件
    if (this.handler) {
      this.handler.destroy();
      this.handler = null;
    }

    // 2.移除键盘监听事件
    document.removeEventListener('keydown', this.keyboardDown, false);
    document.removeEventListener('keyup', this.keyboardUp, false);

    // 3.移除渲染事件
    // this.viewer.clock.onTick.removeEventListener(this.renderEvent);
    this.removeCallback()

    // 4.启用默认相机操作模式
    this.scene.screenSpaceCameraController.enableRotate = true;
    this.scene.screenSpaceCameraController.enableTranslate = true;
    this.scene.screenSpaceCameraController.enableZoom = true;
    this.scene.screenSpaceCameraController.enableTilt = true;
    this.scene.screenSpaceCameraController.enableLook = true;
  }
  /**
 * 键盘按下
 */
  keyDown(event) {
    console.log("键盘按下", event, this)
    let flagName = this.getFlagFromKeyCode(event);
    if (typeof flagName !== 'undefined') {
      this.flags[flagName] = true;
    }
  }

  /**
   * 键盘弹起
   */
  keyUp(event) {
    let flagName = this.getFlagFromKeyCode(event);
    if (typeof flagName !== 'undefined') {
      this.flags[flagName] = false;
    }
  }

  /**
   * 渲染函数
   */
  renderEvent() {
    // 镜头转向
    if (this.flags.looking) {
      let width = this.viewer.canvas.clientWidth;
      let height = this.viewer.canvas.clientHeight;
      let lookFactor = window.lookFactor; // 镜头转向系数
      let x = (this.flags.endPosition.x - this.flags.startPosition.x) / width;
      let y = -(this.flags.endPosition.y - this.flags.startPosition.y) / height;
      // 计算出x,y之后，有两种方式实现镜头，经过测试感觉方式 1更流畅
      // 方式 1
      // this.camera.lookRight(x * lookFactor);
      // this.camera.lookUp(y * lookFactor);
      this.camera.setView({
        orientation: {
          heading: this.Cesium.Math.toRadians(x * lookFactor) + this.camera.heading,
          pitch: this.Cesium.Math.toRadians(y * lookFactor) + this.camera.pitch,
          roll: this.camera.roll
        }
      });
      // 方式 2
      // this.camera.setView({
      //   orientation: {
      //     heading: this.camera.heading + x * lookFactor,
      //     pitch: this.camera.pitch + y * lookFactor,
      //     roll: 0.0,
      //   },
      // });
    }
    // 根据高度来决定镜头移动的速度
    let cameraHeight = this.ellipsoid.cartesianToCartographic(this.camera.position).height;
    let moveRate = cameraHeight / window.moveRate;
    if (this.flags.wheel) {

      if (this.flags.wheelNumber > 0) {
        // this.camera.zoomIn(true);
        this.camera.moveBackward(moveRate);

      } else {
        // this.camera.zoomOut(true);
        this.camera.moveForward(moveRate);
      }
      this.flags.wheel = false;
      this.flags.wheelNumber = 0;
    }

    if (this.flags.moveForward) {
      this.camera.moveForward(moveRate);
    }
    if (this.flags.moveBackward) {
      this.camera.moveBackward(moveRate);
    }
    if (this.flags.moveUp) {
      this.camera.moveUp(moveRate);
    }
    if (this.flags.moveDown) {
      this.camera.moveDown(moveRate);
    }
    if (this.flags.moveLeft) {
      this.camera.moveLeft(moveRate);
    }
    if (this.flags.moveRight) {
      this.camera.moveRight(moveRate);
    }
    if (this.flags.up) {
      this.camera.setView({
        orientation: {
          heading: this.camera.heading,
          pitch: this.Cesium.Math.toRadians(1) + this.camera.pitch,
          roll: this.camera.roll
        }
      })
      console.log("up")
    }
    if (this.flags.down) {
      this.camera.setView({
        orientation: {
          heading: this.camera.heading,
          pitch: this.camera.pitch - this.Cesium.Math.toRadians(1),
          roll: this.camera.roll
        }
      })
      console.log("down")
    }
    if (this.flags.left) {
      this.camera.setView({
        orientation: {
          heading: this.camera.heading - this.Cesium.Math.toRadians(1),
          pitch: this.camera.pitch,
          roll: this.camera.roll
        }
      })
      console.log("left")
    }
    if (this.flags.right) {
      this.camera.setView({
        orientation: {
          heading: this.camera.heading + this.Cesium.Math.toRadians(1),
          pitch: this.camera.pitch,
          roll: this.camera.roll
        }
      })
    }

  }

  /**
   * 从键盘码获取flag标记
   */
  getFlagFromKeyCode(event) {
    console.log(event)
    switch (event.keyCode) {
      case 'W'.charCodeAt(0):
        return 'moveForward';
      case 'S'.charCodeAt(0):
        return 'moveBackward';
      case 'Q'.charCodeAt(0):
        return 'moveUp';
      case 'E'.charCodeAt(0):
        return 'moveDown';
      case 'D'.charCodeAt(0):
        return 'moveRight';
      case 'A'.charCodeAt(0):
        return 'moveLeft';
      case 'K'.charCodeAt(0):
        if (event.shiftKey) {

        } else {
          return 'down';
        }

      case 'J'.charCodeAt(0):
        if (event.shiftKey) {

        } else {
          return 'left';
        }


      case 'L'.charCodeAt(0):
        if (event.shiftKey) {

        } else {
          return 'right';
        }


      case 'I'.charCodeAt(0):
        if (event.shiftKey) {

        } else {
          return 'up';
        }


      default:
        return undefined;
    }
  }

}

/**
 *
 */
// export function keyboardControl(Cesium, viewer) {
//   let scene = viewer.scene;
//   let canvas = viewer.canvas; // 此处用viewer.canvas或viewer.scene.canvas都可以，是同一个canvas对象
//   let camera = viewer.camera;
//   let ellipsoid = viewer.scene.globe.ellipsoid;
//   // 声明相机漫游标记
//   let flags = null;
//   // 声明handler
//   let handler = null;

//   /**
//    * 进入键盘鼠标漫游模式
//    */
//   function enterKeyBoardMouseRoamingMode() {
//     console.log('进入漫游模式');
//     // 1.禁用默认相机操作模式
//     scene.screenSpaceCameraController.enableRotate = false;
//     scene.screenSpaceCameraController.enableTranslate = false;
//     scene.screenSpaceCameraController.enableZoom = false;
//     scene.screenSpaceCameraController.enableTilt = false;
//     scene.screenSpaceCameraController.enableLook = false;

//     // 2.初始化相机漫游的标记
//     flags = {
//       looking: false, // 是否正在用鼠标调整视角
//       startPosition: null, // 鼠标指针开始移动位置
//       endPosition: null, // 鼠标指针停止移动位置
//       moveForward: false, // 是否向前移动
//       moveBackward: false, // 是否向后移动
//       moveLeft: false, // 是否向左移动
//       moveRight: false, // 是否向右移动
//       moveUp: false, // 是否向上移动
//       moveDown: false, // 是否向下移动
//       up: false, //视角向上
//       down: false, //视角向下
//       left: false, //视角向左
//       right: false //视角向右
//     }; // 相机漫游标记

//     // 3.添加鼠标监听事件
//     handler = new Cesium.ScreenSpaceEventHandler(canvas);
//     // 左键按下
//     handler.setInputAction((movement) => {
//       console.log("左键按下")
//       flags.looking = true;
//       flags.startPosition = Cesium.Cartesian3.clone(movement.position);
//       flags.endPosition = Cesium.Cartesian3.clone(movement.position);
//     }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
//     // 鼠标移动
//     handler.setInputAction((movement) => {
//       console.log("鼠标移动")
//       flags.endPosition = movement.endPosition;
//     }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
//     // 左键弹起
//     handler.setInputAction(() => {
//       console.log("左键弹起")
//       flags.looking = false;
//     }, Cesium.ScreenSpaceEventType.LEFT_UP);

//     // 4.添加键盘监听事件
//     // 键盘按下事件
//     document.addEventListener('keydown', keyDown, false);
//     // 键盘弹起事件
//     document.addEventListener('keyup', keyUp, false);

//     // 5.添加渲染事件
//     viewer.clock.onTick.addEventListener(renderEvent);
//   }

//   // DOM添加一个开启键盘鼠标漫游模式的按钮，使用绝对定位放在屏幕左上角，用于测试
//   let enterButton = document.createElement('button');
//   enterButton.innerText = '开启';
//   enterButton.style.position = 'absolute';
//   enterButton.style.left = '20px';
//   enterButton.style.top = '50px';
//   enterButton.onclick = enterKeyBoardMouseRoamingMode;
//   document.body.appendChild(enterButton);

//   /**
//    * 退出键盘鼠标漫游模式
//    */
//   function exitKeyBoardMouseRoamingMode() {
//     console.log('退出漫游');
//     // 1.移除鼠标监听事件
//     if (handler) {
//       handler.destroy();
//       handler = null;
//     }

//     // 2.移除键盘监听事件
//     document.removeEventListener('keydown', keyDown, false);
//     document.removeEventListener('keyup', keyUp, false);

//     // 3.移除渲染事件
//     viewer.clock.onTick.removeEventListener(renderEvent);

//     // 4.启用默认相机操作模式
//     scene.screenSpaceCameraController.enableRotate = true;
//     scene.screenSpaceCameraController.enableTranslate = true;
//     scene.screenSpaceCameraController.enableZoom = true;
//     scene.screenSpaceCameraController.enableTilt = true;
//     scene.screenSpaceCameraController.enableLook = true;
//   }

//   // DOM添加一个关闭键盘鼠标漫游模式的按钮，使用绝对定位放在屏幕左上角，用于测试
//   let exitButton = document.createElement('button');
//   exitButton.innerText = '关闭';
//   exitButton.style.position = 'absolute';
//   exitButton.style.left = '70px';
//   exitButton.style.top = '50px';
//   exitButton.onclick = exitKeyBoardMouseRoamingMode;
//   document.body.appendChild(exitButton);

//   /**
//    * 键盘按下
//    */
//   function keyDown(event) {
//     let flagName = getFlagFromKeyCode(event);
//     if (typeof flagName !== 'undefined') {
//       flags[flagName] = true;
//     }
//   }

//   /**
//    * 键盘弹起
//    */
//   function keyUp(event) {
//     let flagName = getFlagFromKeyCode(event);
//     if (typeof flagName !== 'undefined') {
//       flags[flagName] = false;
//     }
//   }

//   /**
//    * 渲染函数
//    */
//   function renderEvent() {
//     console.log(flags,"flags")
//     // 镜头转向
//     if (flags.looking) {
//       let width = viewer.canvas.clientWidth;
//       let height = viewer.canvas.clientHeight;
//       let lookFactor = 0.05; // 镜头转向系数，系数越大约灵敏，此处取0.05比较适中
//       let x = (flags.endPosition.x - flags.startPosition.x) / width;
//       let y = -(flags.endPosition.y - flags.startPosition.y) / height;
//       // 计算出x,y之后，有两种方式实现镜头，经过测试感觉方式 1更流畅
//       // 方式 1
//       camera.lookRight(x * lookFactor);
//       camera.lookUp(y * lookFactor);
//       // 方式 2
//       // camera.setView({
//       //   orientation: {
//       //     heading: camera.heading + x * lookFactor,
//       //     pitch: camera.pitch + y * lookFactor,
//       //     roll: 0.0,
//       //   },
//       // });
//     }
//     // 根据高度来决定镜头移动的速度
//     let cameraHeight = ellipsoid.cartesianToCartographic(camera.position).height;
//     let moveRate = cameraHeight / 100.0;
//     if (flags.moveForward) {
//       camera.moveForward(moveRate);
//     }
//     if (flags.moveBackward) {
//       camera.moveBackward(moveRate);
//     }
//     if (flags.moveUp) {
//       camera.moveUp(moveRate);
//     }
//     if (flags.moveDown) {
//       camera.moveDown(moveRate);
//     }
//     if (flags.moveLeft) {
//       camera.moveLeft(moveRate);
//     }
//     if (flags.moveRight) {
//       camera.moveRight(moveRate);
//     }
//     if (flags.up) {
//       camera.setView({
//         orientation:{
//           heading:camera.heading,
//           pitch:Cesium.Math.toRadians(1)+camera.pitch,
//           roll:camera.roll
//         }
//       })
//       console.log("up")
//     }
//     if (flags.down) {
//       camera.setView({
//         orientation:{
//           heading:camera.heading,
//           pitch:camera.pitch-Cesium.Math.toRadians(1),
//           roll:camera.roll
//         }
//       })
//       console.log("down")
//     }
//     if (flags.left) {
//       camera.setView({
//         orientation:{
//           heading:camera.heading-Cesium.Math.toRadians(1),
//           pitch:camera.pitch,
//           roll:camera.roll
//         }
//       })
//       console.log("left")
//     }
//     if (flags.right) {
//       camera.setView({
//         orientation:{
//           heading:camera.heading+Cesium.Math.toRadians(1),
//           pitch:camera.pitch,
//           roll:camera.roll
//         }
//       })
//       console.log("right")
//     }
//   }

//   /**
//    * 从键盘码获取flag标记
//    */
//   function getFlagFromKeyCode(event) {
//     console.log(event)
//     switch (event.keyCode) {
//       case 'W'.charCodeAt(0):
//         return 'moveForward';
//       case 'S'.charCodeAt(0):
//         return 'moveBackward';
//       case 'Q'.charCodeAt(0):
//         return 'moveUp';
//       case 'E'.charCodeAt(0):
//         return 'moveDown';
//       case 'D'.charCodeAt(0):
//         return 'moveRight';
//       case 'A'.charCodeAt(0):
//         return 'moveLeft';
//       case 40:
//         if (event.shiftKey) {

//         } else {
//           return 'down';
//         }

//       case 37:
//         if (event.shiftKey) {

//         } else {
//           return 'left';
//         }


//       case 39:
//         if (event.shiftKey) {

//         } else {
//           return 'right';
//         }


//       case 38:
//         if (event.shiftKey) {

//         } else {
//           return 'up';
//         }


//       default:
//         return undefined;
//     }
//   }
//   enterKeyBoardMouseRoamingMode()

// }





