<template lang="">
  <div v-draggable class="convert">

    <closeTitle :close="close"></closeTitle>
    <div>
      <div class="inputdiv">

        <el-row>
          <el-col :span="4">
            <span>经度平移：</span>
          </el-col>
          <el-col :span="14">
            <el-input-number v-model="tx" :precision="14" :step="txValue" :min="-180" :max="180"></el-input-number>
          </el-col>
          <el-col :span="6">
            <el-select v-model="txValue" placeholder="请选择">
              <el-option v-for="item in tOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="inputdiv">
        <el-row>
          <el-col :span="4">
            <span>纬度平移：</span>
          </el-col>
          <el-col :span="14"> <el-input-number v-model="ty" :precision="14" :step="tyValue" :min="-85"
              :max="85"></el-input-number>
          </el-col>
          <el-col :span="6"> <el-select v-model="tyValue" placeholder="请选择">
              <el-option v-for="item in tOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="inputdiv">
        <el-row>
          <el-col :span="4"><span>高度平移：</span>
          </el-col>
          <el-col :span="14"><el-input-number v-model="heightOffset" :precision="2" :step="tzValue" :min="-1000"
              :max="1000"></el-input-number>
          </el-col>
          <el-col :span="6"><el-select v-model="tzValue" placeholder="请选择">
              <el-option v-for="item in rOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>



      </div>
      <div class="inputdiv">
        <el-row>
          <el-col :span="4"> <span>X轴旋转：</span>
          </el-col>
          <el-col :span="14"><el-input-number v-model="rx" :precision="2" :step="rxValue" :min="-180"
              :max="180"></el-input-number>
          </el-col>
          <el-col :span="6"> <el-select v-model="rxValue" placeholder="请选择">
              <el-option v-for="item in rOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>



      </div>
      <div class="inputdiv">
        <el-row>
          <el-col :span="4"> <span>Y轴旋转：</span>
          </el-col>
          <el-col :span="14"> <el-input-number v-model="ry" :precision="2" :step="ryValue" :min="-180"
              :max="180"></el-input-number>
          </el-col>
          <el-col :span="6"> <el-select v-model="ryValue" placeholder="请选择">
              <el-option v-for="item in rOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>



      </div>
      <div class="inputdiv">
        <el-row>
          <el-col :span="4"> <span>Z轴旋转：</span>
          </el-col>
          <el-col :span="14"><el-input-number v-model="rz" :precision="2" :step="rzValue" :min="-180"
              :max="180"></el-input-number>
          </el-col>
          <el-col :span="6"> <el-select v-model="rzValue" placeholder="请选择">
              <el-option v-for="item in rOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>



      </div>

    </div>
    <div class="inputdiv">
      <el-button type="primary" @click="save">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>
<script>
  import closeTitle from "../../closeTitle/index.vue";
  import { useModel } from "../hooks/model.js";
  import * as Cesium from "cesium";
  import axios from "axios";
  const { update3dtilesMaxtrix, up, translation } = useModel();
  export default {
    components: {
      closeTitle,
    },
    props: {
      close: {
        type: Function,
        default: () => {
          console.log("close");
        },
      },
      convertRow: {
        type: Object,
        default: () => {
          return {}
        }

      },
      convertItem: {
        type: Object,
        default: () => {
          return {}
        },
      }
    },
    watch: {
      heightOffset: {
        handler(val, old) {
          translation(this.convertItem.model, {
            tx: this.tx, ty: this.ty, tz: val
          })
        },
        deep: true
      },
      tx: {
        handler(val, old) {
          translation(this.convertItem.model, {
            tx: val, ty: this.ty, tz: this.heightOffset,
          })
        },
        deep: true
      },
      ty: {

        handler(val, old) {
          translation(this.convertItem.model, {
            tx: this.tx, ty: val, tz: this.heightOffset
          })
        },
        deep: true
      },

      rx: {
        handler(val, old) {
          update3dtilesMaxtrix(this.convertItem.model, {
            rx: val, ry: this.ry, rz: this.rz
          })
        },
        deep: true
      },
      ry: {
        handler(val, old) {
          update3dtilesMaxtrix(this.convertItem.model, {
            rx: this.rx, ry: val, rz: this.rz
          })
        },
        deep: true
      },
      rz: {
        handler(val, old) {
          update3dtilesMaxtrix(this.convertItem.model, {
            rx: this.rx, ry: this.ry, rz: val
          })

        }
      },


    },
    data() {
      return {
        tx: JSON.parse(JSON.stringify(this.convertRow.longitudeOffset)),
        ty: JSON.parse(JSON.stringify(this.convertRow.latitudeOffset)),

        heightOffset: JSON.parse(JSON.stringify(this.convertRow.heightOffset)),

        rx: JSON.parse(JSON.stringify(this.convertRow.pitch)),
        ry: JSON.parse(JSON.stringify(this.convertRow.yaw)),
        rz: JSON.parse(JSON.stringify(this.convertRow.roll)),
        Cartographic: {
          long: 0,
          lat: 0,
          height: 0
        },
        txValue: 0.000001,
        tyValue: 0.000001,
        tzValue: 0.01,
        tOptions: [{
          value: 0.1,
          label: '0.1'
        }, {
          value: 0.01,
          label: '0.01'
        }, {
          value: 0.001,
          label: '0.001'
        }, {
          value: 0.0001,
          label: '0.0001'
        }, {
          value: 0.00001,
          label: '0.00001'
        }, {
          value: 0.000001,
          label: '0.000001'
        }, {
          value: 0.0000001,
          label: '0.0000001'
        }, {
          value: 0.00000001,
          label: '0.00000001'
        }, {
          value: 0.000000001,
          label: '0.000000001'
        }, {
          value: 0.0000000001,
          label: '0.0000000001'
        }, {
          value: 0.00000000001,
          label: '0.00000000001'
        }, {
          value: 0.000000000001,
          label: '0.000000000001'
        }, {
          value: 0.0000000000001,
          label: '0.0000000000001'
        }, {
          value: 0.00000000000001,
          label: '0.00000000000001'
        }],
        rxValue: 0.01,
        ryValue: 0.01,
        rzValue: 0.01,

        rOptions: [{
          value: 1,
          label: '1'
        }, {
          value: 0.1,
          label: '0.1'
        }, {
          value: 0.01,
          label: '0.01'
        }],


      }

    },
    mounted() {
      console.log(this.convertRow, this.convertItem, "拿到要修改的模型数据");
      this.getCartographic(this.convertItem.model)
    },
    methods: {

      getCartographic(tileset) {
        // 计算tileset的绑定范围（包围球）
        let boundingSphere = tileset.boundingSphere;
        // 计算中心点位置
        let cartographic = Cesium.Cartographic.fromCartesian(
          boundingSphere.center
        );
        // 计算中心点位置的地表坐标
        let long = Cesium.Math.toDegrees(cartographic.longitude)
        let lat = Cesium.Math.toDegrees(cartographic.latitude)
        let height = cartographic.height;
        console.log(cartographic, "保存的中心位置", long, lat, height);
        this.Cartographic = {
          long: long,
          lat: lat,
          height: height - this.convertRow.heightOffset
        }
      },
      save() {
        console.log(this.convertRow, "区域数据");
        // this.convertRow.models.forEach(model => {
        //   delete model.model
        // })
        // let row = this.convertRow
        // let row = JSON.parse(JSON.stringify(this.convertRow))
        let row = this.deepClone(this.convertRow)
        console.log(row, "深拷贝");
        row.heightOffset = this.heightOffset
        row.longitudeOffset = this.tx
        row.latitudeOffset = this.ty
        row.pitch = this.rx
        row.yaw = this.ry
        row.roll = this.rz
        axios.post('/api/Setting/SetRegionModelList', [row]).then(res => {
          if (res.data.code == 1) {
            this.$message.success("修改成功")
            this.close()
          } else {
            this.$message.error('修改失败');
          }

        })

      },
      //使用递归实现深拷贝
      deepClone(obj) {
        //变量先置空
        let newobj = null;
        //obj不能为空,并且是对象或者是数组,null也是object
        if (typeof (obj) == 'object' && obj !== null) {
          newobj = obj instanceof Array ? [] : {};
          //递归进行深度的拷贝
          for (var i in obj) {
            console.log(i, "i");
            if (i == "model" || i == "dem" || i == "tms") {
              continue
            }
            newobj[i] = this.deepClone(obj[i])


          }
          //如果不是对象直接赋值
        } else {
          newobj = obj;
        }
        return newobj;
      }


    },

  }
</script>
<style lang="scss" scoped>
  .convert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 500px;
    height: 400px;
    background-color: #fff;
  }

  .inputdiv {
    margin: 10px;
    text-align: center;
  }
</style>