<template lang="">
  <!--相机投影范围-->
  <div class="button" v-draggable>
    <closeTitle :title="id.id" :close="cancel"></closeTitle>
    <span>相机投影范围</span>
    <div class="content">
      <el-form ref="form" label-width="80px">
        <el-row class="row" v-for="(value, index) in indexs" :key="index">
          <el-col :span="8">
            <el-form-item label="x">
              <div class="inputdiv">
                <el-input v-model.number="value.x"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="y">
              <div class="inputdiv">
                <el-input v-model.number="value.y"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="z">
              <div class="inputdiv">
                <el-input v-model.number="value.z"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>

        </el-form-item>
      </el-form>
    </div>

    <div class="di"><el-row class="row">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-button @click="cancel">取消</el-button>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-button type="primary" @click="promise">保存</el-button>
          </div>
        </el-col>
      </el-row></div>
  </div>
</template>
<script>

  import * as Cesium from 'cesium';
  import axios from 'axios';
  import closeTitle from "../closeTitle/index.vue"
  var handler = null
  export default {
    props: {
      id: {
        type: Object,
        default: {}
      },
      close: {
        type: Function,
        default: () => {

        }
      }
    },
    components: {
      closeTitle
    },
    data() {
      return {
        indexs: []
      }
    },
    created() {

    },
    mounted() {
      handler = new Cesium.ScreenSpaceEventHandler(window.viewer.canvas);
      this.boundary()
    },
    destroyed() {
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK); //销毁事件
      handler.destroy()
    },
    methods: {
      //取投影范围
      boundary() {
        const thas = this;
        handler.setInputAction(function (event) {
          var cartesian1 = window.viewer.scene.pickPosition(event.position);
          const entity = { x: cartesian1.x, y: cartesian1.y, z: cartesian1.z };
          thas.indexs.push(entity);
          if (Cesium.defined(cartesian1)) {
            var cartographic1 = Cesium.Cartographic.fromCartesian(cartesian1);
            var lng = Cesium.Math.toDegrees(cartographic1.longitude);
            var lat = Cesium.Math.toDegrees(cartographic1.latitude);
            var height = cartographic1.height; // 模型高度
            var mapPosition = {
              x: lng,
              y: lat,
              z: height,
            };
          }
          console.warn(
            "centerX:" +
            mapPosition.x +
            ", centerY:" +
            mapPosition.y +
            ", height:" +
            mapPosition.z
          );
          // window.viewer.entities.removeAll()
          window.viewer.entities.add({
            name: "boundary",
            position: Cesium.Cartesian3.fromDegrees(
              mapPosition.x,
              mapPosition.y,
              mapPosition.z
            ),
            point: {
              color: Cesium.Color.RED,
              pixelSize: 10,
            },
          });
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        // this.buttonShow = false;
        // this.boundaryshow = true;

      },
      cancel() {
        const entitis = [];
        window.viewer.entities.values.forEach((el) => {
          if (el.name === "boundary") {
            entitis.push(el);
          }
        });
        entitis.forEach((el) => {
          window.viewer.entities.remove(el);
        });
        this.indexs = [];
        // this.boundaryshow = false;
        this.close()
      },
      promise() {
        axios.post("/api/Setting/SetCameraRange", {
          id: this.id.id,
          range: this.indexs,
        });
        this.cancel();
        this.$message({
          message: "保存成功",
          type: "success",
        });
      },
    },


  }
</script>
<style lang="scss" scoped>
  .button {
    position: absolute;
    height: 50%;
    width: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 10px;
    z-index: 100;
    border: #000 5px solid;
    background-color: #000;
    opacity: 0.8;
    color: #fff;
    overflow: auto;

    ::v-deep .el-form-item__label {
      color: #fff;
    }
  }

  .button:hover {
    z-index: 999;
  }

  .content {
    width: 100%;
    height: calc(100% - 110px);
    overflow: auto;
  }

  .di {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0px;
  }

  .grid-content {
    width: 100%;
    text-align: center;
  }
</style>