<template>
  <div class="regionList" v-draggable>

    <closeTitle :close="close"></closeTitle>

    <el-table :data="regionList" highlight-current-row stripe style="width: 100%" max-height="370" ref="singleTable"
      @row-dblclick="rowDbClick">
      <el-table-column width="40" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.isChecked" :indeterminate="scope.row.isIndeterminate"
            @change="handleSelectionChange(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column align="center" type="expand">
        <template slot-scope="props">
          <!-- 展开内容表 -->
          <el-table ref="modelTable" :data="props.row.models" style="width: 100%" class="child-table" row-key="guid"
            @row-dblclick="(row, column, event)=>{itemDBClick(row,props.row)}" :show-header="false">
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'model')"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/model.png" alt="" srcset="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="modelName" label="模型名称" />
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="convert(props.row, scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table :data="props.row.dems" style="width: 100%" class="child-table" row-key="guid"
            @row-dblclick="(row, column, event)=>{itemDBClick(row, props.row)}" :show-header="false" ref="demTable">
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'dem')"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/dem.png" alt="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="demName" label="dem名称" />
          </el-table>
          <el-table :data="props.row.tmses" style="width: 100%" class="child-table" row-key="guid"
            @row-dblclick="(row, column, event)=>{itemDBClick(row, props.row)}" :show-header="false" ref="tmsTable">
            <el-table-column label="选择" width="120" align="center">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isChecked"
                  @change="clickCheckItemFn(props.row, scope.row, 'tms')"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="类型" width="50" align="center">
              <template slot-scope="scope">
                <img src="/img/map/tms.png" alt="" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="tmsName" label="tms名称" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="#" type="index" width="50"> </el-table-column>
      <el-table-column prop="regionName" label="区域名称" width="500">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import axios from "axios";
  import { useModel } from "./hooks/model";
  const { addModel, removeModel, removeModelAll } = useModel();
  import { useTms } from "./hooks/tms";
  const { addTms, removeTms, removeTmsAll } = useTms();
  import { useDem } from "./hooks/dem";
  const { addDem, removeDem } = useDem();
  import closeTitle from "../closeTitle/index.vue";
  export default {
    components: {
      closeTitle,
    },
    props: {
      regionListShow: {
        type: Boolean,
        default: false,
      },
    },
    watch: {

      regionListShow: {
        handler(val) {
          if (val) {
            console.log("区域列表显示更改", val);
            this.getcamera();
          }
        },
        immediate: true,

      },
      // regionList: {
      //   handler(val) {
      //     if (val) {
      //       console.log("选中改变", val);
      //       this.isCheckedAll(val)
      //     }
      //   },
      //   immediate: true,
      //   deep: true,

      // }
    },
    data() {
      return {
        regionList: [],
        type: "Region",
      };
    },

    created() {
      this.$bus.on("uplist", () => {
        this.getcamera();
      });


    },
    beforeDestroy() {
      this.$bus.off("uplist");
    },
    mounted() {
      console.log("区域列表显示", this.regionListShow);
      this.getcamera();

    },
    methods: {
      getcamera() {
        axios
          .post("/api/Setting/GetRegionModelList", {
            regionModelType: this.type,
            enabledOnly: false,
          })
          .then(async (row) => {
            if (this.regionList.length > 0) {
              let data1 = row.data.data;
              let data = await this.updateGetRegionModelList(data1);
              this.regionList.forEach((item, index) => {
                let region = data.find(el => {
                  return el.regionName == item.regionName
                })
                region.isIndeterminate = item.isIndeterminate
                if (item.models && item.models.length > 0) {
                  item.models.forEach((el) => {
                    console.log(el, "el");
                    if (el.model) {
                      let r = data[index].models.find((item) => {
                        return item.modelName == el.modelName;
                      });
                      console.log(r, "r");
                      r.isChecked = el.isChecked;
                      r.model = el.model;
                    }
                  });
                }
                if (item.dems && item.dems.length > 0) {
                  item.dems.forEach((el) => {
                    if (el.dem) {
                      let r = data[index].dems.find((item) => {
                        return item.demName == el.demName;
                      });
                      console.log(r, "r");
                      r.isChecked = el.isChecked;
                      r.dem = el.dem;
                    }
                  });
                }
                if (item.tmses && item.tmses.length > 0) {
                  item.tmses.forEach((el) => {
                    if (el.tms) {
                      let r = data[index].tmses.find((item) => {
                        return item.tmsName == el.tmsName;
                      });
                      console.log(r, "r");
                      r.isChecked = el.isChecked;
                      r.tms = el.tms;
                    }
                  });
                }
              })
              this.regionList = data;

            } else {
              let data = row.data.data;
              data = this.updateGetRegionModelList(data);
              this.regionList = data;
              this.automaticActive()
            }


          });
      },
      updateGetRegionModelList(data) {
        //深度拷贝
        let data1 = JSON.parse(JSON.stringify(data));
        data1.forEach((item) => {
          item.isChecked = false;
          item.isIndeterminate = false;
          if (item.models && item.models.length > 0) {
            item.models.forEach((el) => {
              el.isChecked = false;
              el.type = "model";
            });
          }
          if (item.dems && item.dems.length > 0) {
            item.dems.forEach((el) => {
              el.isChecked = false;
              el.type = "dem";
            });
          }
          if (item.tmses && item.tmses.length > 0) {
            item.tmses.forEach((el) => {
              el.isChecked = false;
              el.type = "tms";
            });
          }
        });
        return data1
      },
      //刷新自动选中
      automaticActive() {
        this.$nextTick(() => {
          // setTimeout(() => {
          let activeRegion = window.sessionStorage.getItem("activeRegion")
          if (activeRegion) {
            activeRegion = JSON.parse(activeRegion)
            let item = this.regionList.find(el => el.regionId == activeRegion.regionId)
            this.$refs.singleTable.toggleRowExpansion(item, true)
            this.$nextTick(() => {
              // setTimeout(() => {
              activeRegion.models.forEach((el, index) => {
                let model = item.models.find(el2 => el2.modelName == el.modelName)
                model.isChecked = true
                this.clickCheckItemFn(item, model, 'model')
                if (index == activeRegion.models.length - 1) {
                  setTimeout(() => {
                    this.itemDBClick(model, item)
                  }, 1000 * 3)

                }
              })
              activeRegion.dems.forEach(el => {
                console.log(el, "dem");
                let dem = item.dems.find(el2 => el2.demName == el.demName)
                dem.isChecked = true
                this.clickCheckItemFn(item, dem, 'dem')
              })
              activeRegion.tmses.forEach((el, index) => {
                console.log(el, "tms");
                let tms = item.tmses.find(el2 => el2.tmsName == el.tmsName)
                tms.isChecked = true
                this.clickCheckItemFn(item, tms, 'tms')
                if (activeRegion.models.length == 0 && activeRegion.tmses.length != 0 && index == activeRegion.tmses.length - 1) {
                  setTimeout(() => {
                    this.itemDBClick(tms, item)
                  }, 1000 * 3)
                }
              })
              // }, 1000 * 5)

            })



          }
          // }, 1000 * 8)

        })

      },

      close() {
        console.log("关闭");
        this.$emit("close");
      },

      flyTo(modal) {
        window.viewer.flyTo(modal, { duration: 3.0, maximumHeight: 1000000 });
      },
      rowDbClick(row, column, event) {
        //父级双击跳转到模型区域
        console.log(row);
        if (row.models?.length > 0) {
          this.activeSessionStorage(row)
          this.flyTo(row.models[0].model);
        }
        if (row.demList?.length > 0) {
          window.viewer.terrainProvider = row.demList[0];
        }
      },
      itemDBClick(row, r) {
        //子级双击跳转到模型区域

        switch (row.type) {
          case "model":
            if (row.model && row.model !== null && row.isChecked) {
              this.flyTo(row.model);
              this.activeSessionStorage(r)
            } else {
              this.$notify.info({
                title: "视角移动未成功",
                message: "该模型还未加载",
              });
            }
            break;
          case "tms":
            if (row.tms && row.tms !== null && row.isChecked) {
              this.flyTo(row.tms);
            } else {
              this.$notify.info({
                title: "视角移动未成功",
                message: "该影像还未加载",
              });
            }
            break;
          case "dem":
            this.$notify.info({
              title: "视角移动未成功",
              message: "暂不支持视角移动到地形区域",
            });

          default:
            break;
        }

      },

      // 行选中事件
      handleSelectionChange(val) {
        console.log("行选中事件")
        //当父级选中状态被改变，所有子级状态改变
        val.models = val.models.map((i) => {
          i.isChecked = val.isChecked;
          return i;
        });
        val.dems = val.dems.map((i) => {
          i.isChecked = val.isChecked;
          return i;
        });
        val.tmses = val.tmses.map((i) => {
          i.isChecked = val.isChecked;
          return i;
        });
        //判断父级的选中状态，根据状态来加载或卸载模型，地形，影像
        if (val.isChecked) {
          this.regionLoading(val);
        } else {
          this.regionUnload(val);
        }

        val.isIndeterminate = false;
      },

      regionLoading(val) {
        for (let i = 0; i < val.models.length; i++) {
          const model = val.models[i];
          if (!model.model || model.model == null) {
            this.adModel(val, model);
          }
        }
        for (let i = 0; i < val.dems.length; i++) {
          const dem = val.dems[i];
          if (!dem.dem || dem.dem == null) {
            const url = `${val.modelProtocol}://${val.modelHost}/${dem.demUri}`;
            dem.dem = addDem(url);
          }
        }
        for (let i = 0; i < val.tmses.length; i++) {
          const tms = val.tmses[i];
          if (!tms.tms && tms.tms == null) {
            const url = `${val.modelProtocol}://${val.modelHost}/${tms.tmsUri}`;
            let t = addTms(url, tms.imgType);
            tms.tms = t;
          }
        }
      },
      regionUnload(val) {
        if (val?.models?.length > 0) {
          for (let i = 0; i < val.models.length; i++) {
            const model = val.models[i];
            if (model.model && model.model !== null) {
              const result = removeModel(model.model);
              console.log(result);
              model.model = null;
            }
          }
        }
        if (val?.dems?.length > 0) {
          for (let i = 0; i < val.dems.length; i++) {
            const dem = val.dems[i];
            if (dem.dem && dem.dem !== null) {
              const result = removeDem();
              console.log(result);
              dem.dem = null;
            }
          }
        }
        if (val?.tmses?.length > 0) {
          for (let i = 0; i < val.tmses.length; i++) {
            const tms = val.tmses[i];
            if (tms.tms && tms.tms !== null) {
              const result = removeTms(tms.tms);
              console.log(result, "行卸载tms");
              tms.tms = null;
            }
          }
        }

        //卸载该区域模型影像地形等
        console.log("卸载该区域模型影像地形等");
      },

      adModel(row, item) {
        //加载模型并判断模型是否加载成功
        const modelUrl = `${row.modelProtocol}://${row.modelHost}/${item.modelUri}`;
        const model = addModel(modelUrl, row);
        model.readyPromise.then(
          (tileset) => {
            // 模型加载成功后的处理逻辑
            console.log("模型加载成功！");
            item.model = model;
          },
          (error) => {
            // 加载失败的处理逻辑
            this.$notify.error({
              title: "加载" + item.modelName + "模型失败",
              message: "模型无法加载,err:" + error,
            });
          }
        );
      },

      // 每个小项选择事件-单选
      clickCheckItemFn(row, item, type) {
        console.log(row, item, type, "每小项勾选");
        if (item.isChecked) {
          //加载选中的模型，地形，影像
          //检查是否全部子级被选中改变父级选中状态
          switch (item.type) {
            case "model":
              this.adModel(row, item);
              break;
            case "dem":
              const demUri = `${row.modelProtocol}://${row.modelHost}/${item.demUri}`;
              const dem = addDem(demUri);
              item.dem = dem;
              break;
            case "tms":
              const tmsUri = `${row.modelProtocol}://${row.modelHost}/${item.tmsUri}`;
              const tms = addTms(tmsUri, item.imgType);
              console.log(tms, "单点加载tms");
              item.tms = tms;
              break;

            default:
              break;
          }
          this.allChecked(row);
        } else {
          //卸载取消勾选的模型,地形,影像
          // 检查是否所有数据取消勾选改变父级选中状态
          switch (item.type) {
            case "model":
              if (item.model && item.model != null) {
                const result = removeModel(item.model);
                item.model = null;
              }
              break;
            case "dem":
              if (item.dem && item.dem != null) {
                const result = removeDem();
                item.dem = null;
              }

              break;
            case "tms":
              if (item.tms && item.tms != null) {
                const result = removeTms(item.tms);
                console.log(result, "单点卸载tms");
                item.tms = null;
              }
              break;

            default:
              break;
          }

          this.noAllChecked(row);
        }
      },
      //子级勾选改变父级选中状态
      allChecked(row) {
        let modelIsAllChecked = row.models.every((v) => v.isChecked);
        let demIsAllChecked = row.dems.every((v) => v.isChecked);
        let tmseIsAllChecked = row.tmses.every((v) => v.isChecked);
        if (modelIsAllChecked && demIsAllChecked && tmseIsAllChecked) {
          row.isChecked = true;
          row.isIndeterminate = false;
        } else {
          row.isIndeterminate = true;
        }
      },
      //子级取消勾选，改变父级选中状态
      noAllChecked(row) {
        let modelIsAllChecked = row.models.every((v) => v.isChecked == false);
        let demIsAllChecked = row.dems.every((v) => v.isChecked == false);
        let tmseIsAllChecked = row.tmses.every((v) => v.isChecked == false);
        if (modelIsAllChecked && demIsAllChecked && tmseIsAllChecked) {
          row.isChecked = false;
          row.isIndeterminate = false;
        } else {
          row.isIndeterminate = true;
        }
      },
      // isCheckedAll(val) {
      //   val.forEach((row) => {
      //     let modelIsAllChecked = row.models.every((v) => v.isChecked);
      //     let demIsAllChecked = row.dems.every((v) => v.isChecked);
      //     let tmseIsAllChecked = row.tmses.every((v) => v.isChecked);
      //     console.log(modelIsAllChecked, demIsAllChecked, tmseIsAllChecked, "全选");
      //     if (modelIsAllChecked && demIsAllChecked && tmseIsAllChecked) {
      //       row.isChecked = true;
      //       row.isIndeterminate = false;
      //     } if (!modelIsAllChecked && !demIsAllChecked && !tmseIsAllChecked) {
      //       row.isChecked = false;
      //       row.isIndeterminate = false;
      //     } else {
      //       row.isIndeterminate = true;
      //     }
      //   })

      // },
      //判断是否有选中的
      // isChecked(row){
      //   let modelIsAllChecked = row.models.filter((v) => v.isChecked).length==0?false:true;
      //   let demIsAllChecked = row.dems.filter((v) => v.isChecked).length==0?false:true;
      //   let tmseIsAllChecked = row.tmses.filter((v) => v.isChecked).length==0?false:true;
      // },

      // 单项操作-删除
      clickDelFn(row, index, item, itemIndex) {
        console.log(row, index, item, itemIndex);
      },
      //保存到sessionStorage
      activeSessionStorage(row) {
        let item = {
          regionId: row.regionId,
          regionName: row.regionName,
          dems: [],
          models: [],
          tmses: []
        }

        row.models.forEach((el) => {
          if (el.isChecked) {
            item.models.push({
              modelName: el.modelName,
              isChecked: el.isChecked
            })
          }


        })
        row.dems.forEach((el) => {
          if (el.isChecked) {
            item.dems.push({
              demName: el.demName,
              isChecked: el.isChecked
            })
          }

        })
        row.tmses.forEach((el) => {
          if (el.isChecked) {
            item.tmses.push({
              tmsName: el.tmsName,
              isChecked: el.isChecked
            })
          }

        })
        this.resetSetItem("activeRegion", JSON.stringify(item));
      },
      convert(row, item) {
        console.log(row, item, "选中行");
        if (item.isChecked && item.model) {
          this.$emit('convert', row, item)
        } else {
          this.$notify.error({
            title: "无法编辑",
            message: "模型未加载或加载失败",
          });
        }

      }
    },
  };
</script>
<style lang="scss" scoped>
  .regionList {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: #fff;
    height: 400px;
    background-color: #fff;

    .title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background-color: #000;
      color: #fff;
    }

    .button {
      width: 50px;
    }

    .add {
      text-align: center;
      line-height: 30px;
      width: 30px;
      text-shadow: 2px 2px 2px #022122;
      cursor: pointer;
      -webkit-animation: fontColor 1s;
      animation: fontColor 1s;
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
    visibility: hidden;
  }

  .el {
    width: 30px;
    height: 30px;
  }

  .item {
    margin-left: 3px;
    margin-right: 3px;
  }

  .img {
    height: 30px;
    width: 30px;
  }
</style>