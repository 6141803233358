<template>
  <div>
    <div class="cameralist">
      <el-table :data="cameralist" highlight-current-row @current-change="handleCurrentChange" stripe
        style="width: 100%" max-height="400" ref="singleTable" v-show="show">
        <el-table-column label="#" type="index" width="50"> </el-table-column>
        <el-table-column prop="id" label="相机id" width="100">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <i class="el-icon-video-camera item" title="设置为中心相机"
              @click.stop="SetTopoCentricCameraCfg(scope.$index, scope.row)"></i>

            <i class="el-icon-menu item" title="刷新相机分割区域" @click.stop="CameraSplitArea(scope.$index, scope.row)"></i>

            <i class="el-icon-delete item" title="删除相机" @click.stop="delCamera(scope.$index, scope.row)"></i>

            <i class="el-icon-edit item" title="修改相机" @click.stop="updateCameraConfig(scope.$index, scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  export default {
    name: "cameralist",
    props: {},
    components: {},
    data() {
      return {
        cameralist: [],
        currentCamera: null,
        currentRow: null,
        show: true,
        showCameraConfig: false,
      };
    },
    created() {
      this.$bus.on(
        "uplist",
        () => {
          this.getcamera();
        }
        // this.getcamera()
      );
    },
    beforeDestroy() {
      this.$bus.off("uplist");
    },
    mounted() {
      this.getcamera();
    },
    methods: {
      getcamera() {
        axios.post("/api/Calib/GetCameras", {}).then((row) => {
          this.cameralist = row.data.data;
        });
      },
      handleEdit(index, row) {
        this.$refs.singleTable.setCurrentRow(null);
        this.$emit("listval", row);
      },
      handleCurrentChange(val) {
        this.currentRow = val;

        // this.currentCamera = val;

        // this.showCameraConfig = true;
        if (val) {
          this.$emit("emitindex", val);
        }
      },
      cesiumCurrent(id) {
        let camera = this.cameralist.filter((item) => {
          return item.id === id;
        });
        this.$refs.singleTable.setCurrentRow(camera[0]);
      },
      unfold() {
        this.show = !this.show;
      },
      cesiumCurrent(id) {
        let camera = this.cameralist.filter((item) => {
          return item.id === id;
        });
        this.$refs.singleTable.setCurrentRow(camera[0]);
      },
      async SetTopoCentricCameraCfg(index, row) {
        console.log(index, row);
        this.$confirm(`是否将${row.id}设置为中心相机?`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let rew = await axios.post("/api/Setting/SetTopoCentricCameraCfg", {
              Point: row.point,
              Elevation: row.elevation,
              Lng: row.lng,
              Lat: row.lat,
              Height: row.height,
            });
            if ((rew.status = 200)) {
              this.$message({
                type: "success",
                message: `成功将${row.id}设置为中心相机`,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      CameraSplitArea(index, row) {
        console.log(row);
        this.$emit("CameraSplitArea", row);
      },
      async delCamera(index, row) {
        this.$confirm(`是否将${row.id}删除？`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await axios.post("/api/Setting/DelCameraCfg", {
              keyPairsToDel: [
                {
                  id: row.id,
                  channelId: row.channelId,
                },
              ],
            });
            console.log(res);
            if (res.data.success) {
              this.$message({
                type: "success",
                message: `删除成功`,
              });
            } else {
              this.$message({
                type: "info",
                message: "删除失败",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      updateCameraConfig(index, row) {
        this.$emit("updateCameraConfig", row);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cameralist {
    position: absolute;
    bottom: 50px;
    left: 10px;
    z-index: 100;

    padding: 10px;
    opacity: 0.8;
    color: #fff;
    height: 430px;
    background-color: #fff;
  }

  .el-table__body tr:hover>td {
    background-color: #134087 !important;
  }

  .el {
    width: 30px;
    height: 30px;
  }

  .item {
    margin-left: 3px;
    margin-right: 3px;
  }
</style>