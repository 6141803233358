import * as cesium from "cesium"
import CesiumVideo3d from './cesiumVideo3dTow.js';
// import CesiumVideo3d from './CesiumVideo3d';
import { videoConfig as vConfig2 } from './videoConfig.js';
import {
    GetPoleList,
    realPlayStart,
    streamStatus,
    realPlayStop
} from "./previewApi.js"

export default class cameraInit {
    constructor(camera) {
        this.camera = JSON.parse(JSON.stringify(camera))
        // this.init()
        this.map = null
        this.Interval = null
        this.video = null
        this.handle = null
        this.cesiumVideo = null
    }
    async init() {
        console.log(this.camera, "相机层")
        await this.previewData()
    }

    //预处理投影数据
    async previewData() {
        let res = null
        try {
            this.map = await this.getMap(this.camera.cameraView.id);
            res = await realPlayStart(this.camera.cameraView.channelId, this.camera.streamTypeValue)
        } catch (error) {
            console.error("请求图片和接口时出错")
            return
        }
        this.handle = res.data.handle
        let video = null
        try {
            video = await this._createVideoEle(
                res.data.handle,
                res.data.stream,
                this.camera.cameraView.channelId
            );
            this.video = video
        } catch (error) {
            console.error(`创建视频标签失败,失败原因:${error}`);
            // throw error
            return
        }
        let data = this.preview()
        this.addCesiumvideo(data);



    }
    preview() {
        const datas = {};
        datas.alpha = this.camera.transparency
            ? this.camera.transparency
            : window.startAlpha; // 透明度
        datas.aspectRatio =
            Math.tan(cesium.Math.toRadians(this.camera.cameraView.hfov) / 2) /
            Math.tan(cesium.Math.toRadians(this.camera.cameraView.vfov) / 2); // 投影纵横比
        datas.debugFrustum = false; // 是否显示视锥线
        datas.far = this.camera.cameraView.farPlane; // 远平面
        datas.fov = datas.aspectRatio <= 1 ? this.camera.cameraView.vfov : this.camera.cameraView.hfov;
        datas.fovy = this.camera.cameraView.vfov; // 纵向视场角
        datas.elevation = this.camera.cameraView.elevation; //相机高度
        datas.id = this.camera.cameraView.id; // 相机id
        datas.near = this.camera.cameraView.nearPlane; // 近平面
        datas.position = { x: this.camera.cameraView.lng, y: this.camera.cameraView.lat, z: this.camera.cameraView.height }; // 相机位置
        datas.rotation = { x: this.camera.cameraView.pitch, y: this.camera.cameraView.yaw, z: this.camera.cameraView.roll }; // 俯仰横摆旋转角
        datas.scale = this.camera.cameraView.scale;
        datas.selRow = this.camera.cameraView.selRow;
        datas.selCol = this.camera.cameraView.selCol;
        datas.channelId = this.camera.cameraView.channelId;
        datas.type = 3; // 投影类型
        datas.mapx = this.map.mapx;
        datas.mapy = this.map.mapy;
        datas.mask = this.map.mask;
        datas.that = this;
        datas.videoEle = this.video.videoel;
        datas.splitPixel = this.camera.cameraView.pixel;
        datas.streamMediaCustomId = this.camera.cameraView.streamMediaCustomId;
        datas.splitSrcWH = {
            Height: this.camera.cameraView.splitSrcHeight,
            Width: this.camera.cameraView.splitSrcWidth
        };
        return datas
    }

    //预览
    async addCesiumvideo(data) {
        this.cesiumVideo = new CesiumVideo3d(cesium, window.viewer, data)
    }

    //获取正畸
    async getMap(id) {
        let map = {};
        if (this.map) {
            map = this.map;
        } else {
            await this.getTexture(
                '/img/mapfile/' + window.AppConfig.mapfileSampleInterval + '/' + id + '.mapx.bmp'
            ).then((value) => {
                map.mapx = value;
            });
            await this.getTexture(
                '/img/mapfile/' + window.AppConfig.mapfileSampleInterval + '/' + id + '.mapy.bmp'
            ).then((value) => {
                map.mapy = value;
            });
            await this.getTexture('/img/mapfile/' + id + '.mask.bmp').then((value) => {
                map.mask = value;
            });
            this.map = map;
        }
        return map;
    }
    //获取正畸文件转化为纹理
    async getTexture(url) {
        return await new Promise((resolve) => {
            let img = new Image();
            img.src = url;
            img.onload = function () {
                // that.type = that.optionType.Image
                let Texture = new cesium.Texture({
                    context: window.viewer.scene.context,
                    source: img
                });
                resolve(Texture);
            };
            img.onerror = function () { };
        }).then((testure) => {
            return testure;
        });
    }

    /** 创建视频Element
    * @param {String} url 视频地址
    **/
    async _createVideoEle(handle, url, channelId) {
        this.videoId = 'visualDomId';
        let section = document.createElement('section');

        const video = await this._flvPlayerOn(url, section, channelId);
        const Interval = setInterval(async () => {

            await streamStatus(handle, channelId, (reason) => {
                this.update()
            });
        }, 1000 * 10);
        this.Interval = Interval;
        return video;
    }
    _flvPlayerOn(url, section, channelId) {
        console.debug(`创建jess播放器`, channelId);
        let config = {
            ...vConfig2,
            ...window.jessConfig,
            ...window.jessConfigModelOverride
        };
        let videoPlayer = new JessibucaPro({
            container: section,
            decoder: '/jess/decoder-pro-simd.js',
            ...config
        });

        videoPlayer.play(url).then(() => {
            //处理播放器错误事件
            videoPlayer.once('error', (error) => {
                console.error(`播放器错误,`);
                if (error === JessibucaPro.ERROR.fetchError) {
                    console.debug(`http 请求失败,错误=${error}`);
                    this.update()
                } else if (error === JessibucaPro.ERROR.websocketError) {
                    console.debug(`websocket 请求失败,错误=${error}`);
                    this.update()
                } else {
                    console.debug(`未处理的error场景,错误=${error}`);
                    this.update()
                }
            });

            //针对画面播放时冻结进行重播
            // videoPlayer.on('stats', (stats) => {
            //     let shouldReplay =
            //         window.connConfig.mseVideoBufferDelayTime &&
            //         stats.mseVideoBufferDelayTime >=
            //         window.connConfig.mseVideoBufferDelayTime;
            //     console.debug(`画面冻结,${shouldReplay},${stats.mseVideoBufferDelayTime}`);
            //     if (shouldReplay) {
            //         this.update()
            //     }
            // });
            //视频播放回调
            videoPlayer.on('start', (start) => {
                //   videos[channelId].alpha = window.endAlpha;
                //   previewVideo.forEach((element) => {
                //     if (element.channelId == channelId) {
                //       element.alpha = window.endAlpha;
                //     }
                //   });
                // this.cesiumVideo.alpha = window.endAlpha

            });

            //当播放器停止播放时尝试重播
            videoPlayer.once('playFailedAndPaused', (reason) => {
                console.debug(`检测到播放器 playFailedAndPaused 状态`);
                if (window.connConfig['replayWhenPlayFailedAndPaused']) {
                    this.update()
                } else {
                    console.debug(`检测到播放器 playFailedAndPaused 状态,但未启用重播机制`);
                }
            });
        });
        let videoElement = videoPlayer.$container.querySelector('video');
        return {
            videoel: videoElement,
            videoPlayer: videoPlayer
        };
    }
    update() {
        // this.cesiumVideo.destroy()
        // this.video.videoPlayer.destroy()
        // clearInterval(this.Interval)
        // realPlayStop(this.handle, this.camera.cameraView.channelId)
        // this.video = null
        // this.Interval = null
        // this.handle = null
        // this.cesiumVideo = null
        // this.init()



    }
    destroy() {
        console.log(this.camera.cameraView.id, "销毁")
        if (this.cesiumVideo && this.cesiumVideo != null) {
            this.cesiumVideo.destroy()

        }
        if (this.video && this.video != null) {
            this.video.videoPlayer.destroy()
        }
        if (this.Interval && this.Interval != null) {
            clearInterval(this.Interval)
        }
        if (this.handle && this.handle != null) {
            realPlayStop(this.handle, this.camera.cameraView.channelId)
        }
        this.map = null
        // this.camera = null
        this.video = null
        this.Interval = null
        this.handle = null
        this.cesiumVideo = null
    }








}