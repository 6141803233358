<template>
  <el-dialog title="刷新相机分割区域信息" :visible.sync="dialogVisible" width="30%" :show-close="false" :before-close="dialogNone"
    class="cameraDialog">
    <el-form :model="cameraSplitAreamodell">
      <el-form-item label="通道编码" :label-width="formLabelWidth">
        <el-input disabled v-model.number="cameraSplitAreamodell.channelId" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="分割源宽度" :label-width="formLabelWidth">
        <el-input v-model="cameraSplitAreamodell.imageOriginWidth" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="分割源高度" :label-width="formLabelWidth">
        <el-input v-model="cameraSplitAreamodell.imageOriginHeight" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogNone">取 消</el-button>
      <el-button type="button" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import axios from "axios";
  export default {
    props: {
      dialogVisible: Boolean,
      ID: Number,
      cameraSplit: {
        type: Object,
        default: () => {
          return {
            channelId: 0,
            imageOriginWidth: 0,
            imageOriginHeight: 0,
          };
        },
      },
    },
    data() {
      return {
        cameraSplitAreamodell: {
          channelId: 0,
          imageOriginWidth: 0,
          imageOriginHeight: 0,
        },
        formLabelWidth: "120px",
      };
    },
    created() { },
    mounted() { },
    watch: {
      // ID: {
      //   handler: function (val) {
      //     this.cameraSplitAreamodell.cameraId = val;
      //   },
      //   immediate: true,
      // },
      cameraSplit: {
        handler: function (val) {
          this.cameraSplitAreamodell.channelId = val.channelId;
          this.cameraSplitAreamodell.imageOriginWidth = val.imageOriginWidth;
          this.cameraSplitAreamodell.imageOriginHeight = val.imageOriginHeight;
          console.log(this.cameraSplitAreamodell, "选中信息");
        },
        immediate: true,
      },
    },
    methods: {
      dialogNone() {
        this.$emit("dialogNone", false);
      },
      async save() {
        console.log("确定");
        let res = await axios.post(
          "/api/Setting/RefreshCameraSplitArea",
          this.cameraSplitAreamodell
        );
        if (res.status == 200) {
          this.$message({
            type: "success",
            message: `刷新相机分割区域信息成功`,
          });
          this.dialogNone();
        } else {
          this.$message({
            type: "err",
            message: `刷新相机分割区域信息失败`,
          });
        }
      },
      close() {
        this.dialogNone();
        console.log("关闭弹窗");
      },
    },
  };
</script>
<style scoped>
  .cameraDialog {
    color: rgba(0, 0, 0, 1);
  }

  ::v-deep.el-form-item__label {
    color: rgba(0, 0, 0, 1);
  }
</style>