<template lang="">
  <div class="regionForm">
    <el-form ref="form" :model="sizeForm" label-width="150px" size="mini">
      <el-row>
        <el-col :span="12"><el-form-item label="区域模型ID:">
            <el-input v-model="sizeForm.regionId" placeholder="例：zhangbeishouzhan"></el-input> </el-form-item></el-col>
        <el-col :span="12">
          <el-form-item label="区域模型名称:">
            <el-input v-model="sizeForm.regionName" placeholder="例：张北首站"></el-input> </el-form-item></el-col>
      </el-row>

      <el-row>
        <el-col :span="12"><el-form-item label="区域模型主机:">
            <el-input v-model="sizeForm.modelHost" placeholder="例：192.168.0.129:8080"></el-input>
          </el-form-item></el-col>
        <el-col :span="12">
          <el-form-item label="区域模型协议:">
            <el-input v-model="sizeForm.modelProtocol" placeholder="请输入模型协议"></el-input> </el-form-item></el-col>
      </el-row>

      <el-row>
        <el-col :span="12"><el-form-item label="区域模型类型:">
            <el-select v-model="sizeForm.regionModelType" placeholder="请选择">
              <el-option v-for="item in RegionModelTypes" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select> </el-form-item></el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light"></div>
        </el-col>
      </el-row>

      <span>模型</span>
      <el-divider></el-divider>
      <el-form-item v-for="(mode, index) in sizeForm.models" :label="'模型' + (index + 1)" :key="mode.key">
        <el-row>
          <el-col :span="4">
            <!-- <el-input
              v-model="mode.modelName"
              placeholder="模型名称"
            ></el-input> -->
            <el-autocomplete class="inline-input" v-model="mode.modelName" :fetch-suggestions="querySearch"
              placeholder="例：航飞"></el-autocomplete>
          </el-col>
          <el-col :span="12"><el-input v-model="mode.modelUri"
              placeholder="模型地址,例：static/3D/zhangbeishouzhan/tileset.json"></el-input></el-col>
          <el-col :span="7">
            <el-button @click.prevent="removeDomain(mode)">删除</el-button>
            <el-button v-if="sizeForm.models.length - 1 == index" @click.prevent="addDomain(mode)">+</el-button>
          </el-col>
        </el-row>
        <div class="spandiv">
          <span class="span">
            <span>是否启用:</span> <el-switch v-model="mode.enabled"></el-switch></span>
          <span class="span"><span>是否固定图层:</span>
            <el-switch v-model="mode.onFixedLayer"></el-switch></span>
        </div>
      </el-form-item>
      <el-form-item v-if="sizeForm.models.length == 0" label="模型">
        <el-button @click.prevent="addDomain()">+</el-button>
      </el-form-item>

      <span>地形</span>
      <el-divider></el-divider>

      <el-form-item v-for="(dem, index) in sizeForm.dems" :label="'地形' + (index + 1)" :key="dem.key">
        <el-row>
          <el-col :span="16">
            <el-input v-model="sizeForm.dems[index].demUri"
              placeholder="地形地址,例：static/3D/zhangbeishouzhan-dem"></el-input></el-col>

          <el-col :span="8"><el-button @click.prevent="removeDem(dem)">删除</el-button>
          </el-col>
        </el-row>
        <div class="spandiv">
          <span class="span">
            <span> 是否启用: </span>
            <el-switch v-model="dem.enabled"></el-switch>
          </span>
        </div>
      </el-form-item>
      <el-form-item v-if="sizeForm.dems.length == 0" label="地形">
        <el-button @click.prevent="addDem()">+</el-button>
      </el-form-item>
      <!-- <el-form-item label="影像">
        <el-input v-model="sizeForm.regionId"></el-input>
      </el-form-item> -->
      <span>底图</span>
      <el-divider></el-divider>
      <el-form-item v-for="(tms, index) in sizeForm.tmses" :label="'底图' + (index + 1)" :key="tms.key">
        <el-row>
          <el-col :span="4"><el-input v-model="tms.tmsName" placeholder="例：底图"></el-input></el-col>
          <el-col :span="12"><el-input v-model="tms.tmsUri"
              placeholder="底图图层地址,例：static/3D/zhangbeishouzhan-tms"></el-input></el-col>
          <el-col :span="4">
            <!-- <el-input v-model="tms.imgType"></el-input> -->
            <el-select v-model="tms.imgType" placeholder="底层图格式">
              <el-option v-for="item in imgTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button @click.prevent="removeTms(tms)">删除</el-button>
            <el-button v-if="sizeForm.tmses.length - 1 == index" @click.prevent="addTms()">+</el-button></el-col>
        </el-row>
        <div class="spandiv">
          <span class="span">
            <span>是否启用:</span>
            <el-switch v-model="tms.enabled"></el-switch>
          </span>
        </div>
      </el-form-item>
      <el-form-item v-if="sizeForm.tmses.length == 0" label="影像">
        <el-button @click.prevent="addTms()">+</el-button>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="离地高度">
        <el-input-number v-model="sizeForm.heightOffset" :precision="2" :step="0.1" label="描述文字"></el-input-number>
      </el-form-item>
      <el-form-item label="启用">
        <el-switch v-model="sizeForm.enabled"></el-switch>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import { imageType, modelType } from "./types.js";
  import axios from "axios";

  export default {
    props: {
      region: {
        type: Object,
        default: () => {
          return {
            regionId: "",
            regionName: "",
            modelHost: "",
            modelProtocol: "http",
            models: [
              // {
              //   modelName: "",
              //   modelUri: "",
              //   enabled: true,
              //   onFixedLayer: true,
              // },
            ],
            dems: [
              // {
              //   demName: "地形",
              //   demUri: "",
              //   enabled: true,
              // },
            ],
            tmses: [
              // {
              //   tmsName: "",
              //   tmsUri: "",
              //   imgType: "",
              //   enabled: true,
              // },
            ],
            heightOffset: 0,
            enabled: true,
            regionModelType: "",
            defaultEyeView: {
              longitude: 0,
              latitude: 0,
              height: 0,
              roll: 0,
              pitch: 0,
              yaw: 0,
            },
          };
        },
      },
    },

    mounted() {
      this.GetRegionModelTypes();
    },
    components: {},
    computed: {},
    watch: {},
    data() {
      return {
        sizeForm: this.region,
        imgTypes: imageType,
        modelTypes: modelType,
        RegionModelTypes: [],
        type: "",
      };
    },
    created() { },
    methods: {
      GetRegionModelTypes() {
        axios.get("/api/DataDictionaryTable/GetRegionModelTypes").then((row) => {
          console.log(row.data.data);
          for (let i = 0; i < row.data.data.length; i++) {
            if (row.data.data[i].name == "未设置") {
              row.data.data.splice(i, 1);
            }
          }
          this.RegionModelTypes = row.data.data;
          if (this.sizeForm.regionModelType == "") {
            this.sizeForm.regionModelType = row.data.data[0].value;
          }
        });
      },
      removeDomain(item) {
        var index = this.sizeForm.models.indexOf(item);
        if (index !== -1) {
          this.sizeForm.models.splice(index, 1);
        }
      },
      addDomain() {
        this.sizeForm.models.push({
          modelName: "",
          modelUri: "",
          enabled: true,
          onFixedLayer: true,
        });
      },
      removeDem(item) {
        var index = this.sizeForm.dems.indexOf(item);
        if (index !== -1) {
          this.sizeForm.dems.splice(index, 1);
        }
        switch (key) {
          case value:
            break;

          default:
            break;
        }
      },
      addDem() {
        this.sizeForm.dems.push({
          demName: "地形",
          demUri: "",
          enabled: true,
        });
      },
      removeTms(item) {
        var index = this.sizeForm.tmses.indexOf(item);
        if (index !== -1) {
          this.sizeForm.tmses.splice(index, 1);
        }
      },
      addTms() {
        this.sizeForm.tmses.push({
          tmsName: "",
          tmsUri: "",
          imgType: "jpg",
          enabled: true,
        });
      },
      querySearch(queryString, cb) {
        var restaurants = this.modelTypes;
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (
            restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
          );
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .regionForm {
    height: 500px;
    overflow: auto;
  }

  ::v-deep .el-button {
    margin-left: 10px;
  }

  .spandiv {
    margin-top: 15px;
  }

  .span {
    margin: 13px 30px 30px 0px;

    span {
      margin-right: 10px;
    }
  }
</style>
