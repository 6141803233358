import ECEF from '../CoordinateTranslate';
import videoShed3dShader from '../shader.js';
import axios from 'axios';
import * as Cesium from 'cesium';
class CesiumVideo3d {
    constructor(cesium, viewer, param) {
        console.log('cesiumVideo3dTow初始化', new Date());
        this.ECEF = new ECEF();
        this.param = param;
        this.splitSrcWH = this.param.splitSrcWH;
        this.option = this._initCameraParam();
        this.viewer = viewer;
        this.id = this.option.id;
        this.mapx = this.param.mapx;
        this.mapy = this.param.mapy;
        this.mask = this.param.mask;

        this.streamMediaCustomId = this.param.streamMediaCustomId;
        this.lastDecodedFrame = 0;
        this.statisticsIsOne = 0;
        this.staticFrameCounter = 0;

        this.optionType = {
            Color: 1,
            Image: 2,
            Video: 3
        };

        this.flvPlayer = null;
        this.Interval = null;
        this.tiaozheninterval = null;
        this.fixedCamera = null;

        this.channelId = this.option.channelId;
        this.scale = this.option.scale;
        this.near = this.option.near ? this.option.near : 0.1;

        this._cameraPosition = this.option.cameraPosition;
        this._position = this.option.position;
        this.type = this.option.type;
        this._alpha = this.param.alpha || 1;
        this.url = this.option.url;
        this.selCol = this.param.selCol;
        this.selRow = this.param.selRow;
        this.handle = this.option.streamHandle;
        this.color = this.param.color;

        this._debugFrustum = Cesium.defaultValue(this.option.debugFrustum, true);
        this._aspectRatio = this.option.aspectRatio || this._getWinWidHei();
        this._camerafov = this.option.fov || Cesium.Math.toDegrees(this.viewer.scene.camera.frustum.fov);
        this.texture = this.option.texture ||
            new Cesium.Texture({
                context: this.viewer.scene.context,
                source: {
                    width: 1,
                    height: 1,
                    arrayBufferView: new Uint8Array([255, 255, 255, 255])
                },
                flipY: false
            });

        this.videoEle = this.param.videoEle;
        this.splitPixel = this.param.splitPixel;
        this._videoPlay = Cesium.defaultValue(this.option.videoPlay, true);
        this.defaultShow = Cesium.defaultValue(this.option.show, true);
        this.videoTexture = null;
        this.fov = this.option.fov;
        this.activeVideoListener = null;

        if (!this._cameraPosition || !this._position) return;

        this._initialize();
    }

    _initialize() {
        console.log('_initialize方法调用时间:', new Date());
        switch (this.type) {
            default:
            case this.optionType.Video:
                this.activeVideo();
                break;
            case this.optionType.Image:
                this.activePicture(this.url);
                this.deActiveVideo();
                break;
            case this.optionType.Color:
                this.activeColor(this.color);
                this.deActiveVideo();
        }
        this._createShadowMap();
        this._getOrientation();
        this._addCameraFrustum();
        this._addPostProcess();
        this.viewer.scene.primitives.add(this);
    }

    _initCameraParam() {
        console.log('_initCameraParam方法调用时间:', new Date());
        var viewPoint = this.ECEF.enu_to_ecef(
            {
                longitude: this.param.position.x * 1,
                latitude: this.param.position.y * 1,
                altitude: this.param.position.z * 1 + this.param.elevation * 1
            },
            {
                distance: this.param.far,
                azimuth: this.param.rotation.y * 1,
                elevation: this.param.rotation.x * 1
            }
        );

        var position = Cesium.Cartesian3.fromDegrees(
            viewPoint.longitude,
            viewPoint.latitude,
            viewPoint.altitude
        );

        var cameraPosition = Cesium.Cartesian3.fromDegrees(
            this.param.position.x * 1,
            this.param.position.y * 1,
            this.param.position.z * 1 + this.param.elevation * 1
        );

        return {
            type: this.param.type,
            url: this.param.url,
            cameraPosition: cameraPosition,
            position: position,
            alpha: this.param.alpha,
            near: this.param.near,
            fov: this.param.fov,
            debugFrustum: this.param.debugFrustum,
            aspectRatio: this.param.aspectRatio,
            id: this.param.id,
            streamHandle: this.param.streamHandle,
            scale: this.param.scale,
            selRow: this.param.selRow,
            selCol: this.param.selCol,
            channelId: this.param.channelId
        };
    }

    activeVideo() {
        console.log('activeVideo方法调用时间:', new Date());
        var video = this.videoEle;
        if (video) {
            this.type = this.optionType.Video;
            var viewer = this.viewer;
            this.activeVideoListener || (this.activeVideoListener = () => {
                this.videoTexture && this.videoTexture.destroy();
                this.videoTexture = new Cesium.Texture({
                    context: viewer.scene.context,
                    source: video,
                    width: 1,
                    height: 1,
                    pixelFormat: Cesium.PixelFormat.RGBA,
                    pixelDatatype: Cesium.PixelDatatype.UNSIGNED_BYTE
                });
                console.log('activeVideoListener', new Date(), this.videoTexture);
            });
            viewer.clock.onTick.addEventListener(this.activeVideoListener);
        }
    }

    deActiveVideo() {
        console.log('deActiveVideo方法调用时间:', new Date(), this.activeVideoListener);
        if (this.activeVideoListener) {
            this.viewer.clock.onTick.removeEventListener(this.activeVideoListener);
            delete this.activeVideoListener;
        }
    }

    activePicture(url) {
        console.log('activePicture方法调用时间:', new Date());
        this.videoTexture = this.texture;
        var img = new Image();
        img.onload = function () {
            this.type = this.optionType.Image;
            this.videoTexture = new Cesium.Texture({
                context: this.viewer.scene.context,
                source: img
            });
        };
        img.onerror = function () { };
        img.src = url;
    }

    imageActivePicture(url) {
        console.log('imageActivePicture方法调用时间:', new Date());
        return new Promise((resolve) => {
            let img = new Image();
            img.src = url;
            img.onload = function () {
                let Texture = new Cesium.Texture({
                    context: this.viewer.scene.context,
                    source: img
                });
                resolve(Texture);
            };
            img.onerror = function () { };
        });
    }

    locate() {
        console.log('locate方法调用时间:', new Date());
        var cameraPosition = Cesium.clone(this.cameraPosition);
        var position = Cesium.clone(this.position);
        this.viewer.camera.position = cameraPosition;
        this.viewer.camera.direction = Cesium.Cartesian3.subtract(
            position,
            cameraPosition,
            new Cesium.Cartesian3(0, 0, 0)
        );
        this.viewer.camera.up = Cesium.Cartesian3.normalize(
            cameraPosition,
            new Cesium.Cartesian3(0, 0, 0)
        );
    }

    update() {
        console.log('update方法调用时间:', new Date());
        if (this.viewShadowMap) {
            this.viewer.scene.frameState.shadowMaps.push(this.viewShadowMap); // *重点* 多投影
        }
    }

    destroy() {
        console.log('destroy方法调用时间:', new Date());
        this.viewer.scene.postProcessStages.remove(this.postProcess);
        this.viewer.scene.primitives.remove(this.cameraFrustum);
        this.deActiveVideo();
        delete this.videoPlayer;
        delete this.postProcess;
        delete this.viewShadowMap;
        delete this.color;
        delete this.viewDis;
        delete this._cameraPosition;
        delete this._position;
        delete this.alpha;
        delete this._camerafov;
        delete this._cameraPosition;
        delete this.videoTexture;
        delete this.cameraFrustum;
        delete this._videoEle;
        delete this._debugFrustum;
        delete this._position;
        delete this._aspectRatio;
        delete this.url;
        delete this.orientation;
        delete this.texture;
        delete this.videoId;
        delete this.type;
        this.viewer.scene.primitives.remove(this);
        delete this.viewer;
        console.log('destroy结束', new Date());
    }

    // 创建shadowmap
    _createShadowMap() {
        console.log('_createShadowMap方法调用时间:', new Date());
        var e = this._cameraPosition;
        var t = this._position;
        var i = this.viewer.scene;
        var a = new Cesium.Camera(i);
        a.position = e;
        a.direction = Cesium.Cartesian3.subtract(
            t,
            e,
            new Cesium.Cartesian3(0, 0, 0)
        ); // 计算两个笛卡尔的组分差异。
        a.up = Cesium.Cartesian3.normalize(e, new Cesium.Cartesian3(0, 0, 0)); // 归一化
        var n = Cesium.Cartesian3.distance(t, e);
        this.viewDis = n;
        console.log('viewDis', this.viewDis, this.fov, this._aspectRatio, this.near, n);
        a.frustum = new Cesium.PerspectiveFrustum({
            fov: Cesium.Math.toRadians(this.fov),
            aspectRatio: this._aspectRatio,
            near: this.near,
            far: n
        });
        console.log('a.frustum', a.frustum);
        a.twistLeft(Cesium.Math.toRadians(this.param.rotation.z));

        this.fixedCamera = a;
        console.log('fixedCamera', this.fixedCamera);

        this.viewShadowMap = new Cesium.ShadowMap({
            lightCamera: a,
            enable: false,
            isPointLight: false,
            isSpotLight: true,
            cascadesEnabled: false,
            context: i.context,
            pointLightRadius: n,
            darkness: 1
        });
        console.log('viewShadowMap', this.viewShadowMap);
    }

    // 获取shadowmap位置
    _getOrientation() {
        console.log('_getOrientation方法调用时间:', new Date());
        var e = this._cameraPosition;
        var t = this._position;
        var i = Cesium.Cartesian3.normalize(
            Cesium.Cartesian3.subtract(t, e, new Cesium.Cartesian3()),
            new Cesium.Cartesian3()
        );
        var a = Cesium.Cartesian3.normalize(e, new Cesium.Cartesian3());
        var n = new Cesium.Camera(this.viewer.scene);
        n.position = e;
        n.direction = i;
        n.up = a;
        i = n.directionWC;
        a = n.upWC;
        var r = n.rightWC;
        var o = new Cesium.Cartesian3();
        var l = new Cesium.Matrix3();
        var u = new Cesium.Quaternion();
        r = Cesium.Cartesian3.negate(r, o);
        var d = l;
        Cesium.Matrix3.setColumn(d, 0, r, d);
        Cesium.Matrix3.setColumn(d, 1, a, d);
        Cesium.Matrix3.setColumn(d, 2, i, d);
        var c = Cesium.Quaternion.fromRotationMatrix(d, u);
        this.orientation = c;
        return c;
    }

    // 创建视锥
    _addCameraFrustum() {
        console.log('_addCameraFrustum方法调用时间:', new Date());
        var e = this;
        this.cameraFrustum = new Cesium.Primitive({
            geometryInstances: new Cesium.GeometryInstance({
                geometry: new Cesium.FrustumOutlineGeometry({
                    origin: e._cameraPosition,
                    orientation: e.orientation,
                    frustum: this.viewShadowMap._lightCamera.frustum,
                    _drawNearPlane: true
                }),
                attributes: {
                    // 视锥颜色
                    color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                        new Cesium.Color(0, 0.5, 0.5)
                    )
                }
            }),
            appearance: new Cesium.PerInstanceColorAppearance({
                translucent: false,
                flat: true
            }),
            asynchronous: false,
            show: this._debugFrustum && this.defaultShow
        });
        this.viewer.scene.primitives.add(this.cameraFrustum);
    }

    /**
     * 后处理程序（将投影视频Texture，正畸Texture等传入着色器进行处理）
     */
    _addPostProcess() {
        console.log('_addPostProcess方法调用时间:', new Date());
        var e = this;
        var t = videoShed3dShader;
        var i = e.viewShadowMap._isPointLight
            ? e.viewShadowMap._pointBias
            : e.viewShadowMap._primitiveBias;

        this.postProcess = new Cesium.PostProcessStage({
            fragmentShader: t,
            uniforms: {
                mixNum: function () {
                    return e._alpha;
                },
                shaderDepthOffset: function () {
                    return window.shaderDepthOffset;
                },
                stcshadow: function () {
                    return e.viewShadowMap._shadowMapTexture;
                },
                videoTexture: function () {
                    return e.videoTexture;
                },
                _shadowMap_matrix: function () {
                    return e.viewShadowMap._shadowMapMatrix;
                },
                shadowMap_lightPositionEC: function () {
                    return e.viewShadowMap._lightPositionEC;
                },
                shadowMap_texelSizeDepthBiasAndNormalShadingSmooth: function () {
                    var t = new Cesium.Cartesian2();
                    t.x = 1 / e.viewShadowMap._textureSize.x;
                    t.y = 1 / e.viewShadowMap._textureSize.y;
                    return Cesium.Cartesian4.fromElements(
                        t.x,
                        t.y,
                        i.depthBias,
                        i.normalShadingSmooth
                    );
                },
                shadowMap_normalOffsetScaleDistanceMaxDistanceAndDarkness: function () {
                    return Cesium.Cartesian4.fromElements(
                        i.normalOffsetScale,
                        e.viewShadowMap._distance,
                        e.viewShadowMap.maximumDistance,
                        e.viewShadowMap._darkness
                    );
                },
                vidcolor: function () {
                    return e.texture1;
                },
                mapx: function () {
                    return e.mapx;
                },
                mapy: function () {
                    return e.mapy;
                },
                mask: function () {
                    return e.mask;
                },
                offsetSrc: function () {
                    var offset = new Cesium.Cartesian2();
                    offset.x = e.splitPixel.left_top_x;
                    offset.y = e.splitPixel.left_top_y;
                    return offset;
                },
                sizeSrc: function () {
                    var sizeSrc = new Cesium.Cartesian2();
                    sizeSrc.x = e.splitSrcWH.Width;
                    sizeSrc.y = e.splitSrcWH.Height;
                    return sizeSrc;
                },
                sizeSplit: function () {
                    var sizeSplit = new Cesium.Cartesian2();
                    sizeSplit.x = e.splitPixel.right_bottom_x - e.splitPixel.left_top_x;
                    sizeSplit.y = e.splitPixel.right_bottom_y - e.splitPixel.left_top_y;
                    return sizeSplit;
                }
            }
        });
        this.viewer.scene.postProcessStages.add(this.postProcess);
    }

    setFireAndForgot(req, resp, action) {
        console.log('setFireAndForgot方法调用时间:', new Date());
        axios
            .post('/fire/v1.0/od/Api/MediaNotify', {
                req: req,
                resp: resp,
                action: action
            })
            .then((res) => {
                if (!res.data.isSuccess) {
                    this.$message.error(res.data.message);
                }
            });
    }

    isPowerOf2(value) {
        console.log('isPowerOf2方法调用时间:', new Date());
        return (value & (value - 1)) === 0;
    }

    _getWinWidHei() {
        console.log('_getWinWidHei方法调用时间:', new Date());
        var viewer = this.viewer.scene;
        return viewer.canvas.clientWidth / viewer.canvas.clientHeight;
    }
}

// 导出类
export default CesiumVideo3d;
