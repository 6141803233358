import Vue from "vue";
import Router from "vue-router";
import CesiumScene from "../views/CesiumScene.vue";
import { whiteList } from "./whithList.js";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "scene",
      component: CesiumScene,
    },
  ],
});
router.beforeEach((to, from, next) => {
  // console.log("to", to, "referrer", document.referrer);
  // const referrer = document.referrer;

  // let a = whiteList.findIndex((item, index) => {
  //   console.log("对比是否符合白名单", item, index);
  //   return item.path == referrer;
  // });
  // console.log("a", a);
  // if (a == -1) {
  //   // 不符合白名单，跳转登录页
  //   // next;
  // } else {
  //   next();
  // }
  next();
});
export default router;
