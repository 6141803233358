<template lang="">
  <div class="">
    <div class="indentation" @click="titleShow=!titleShow">
      <img src="/img/收起.png" alt="" class="img" :class="titleShow?'imgup':'imgdown'">
    </div>
    <transition name="el-zoom-in-top">
      <div class="title" v-show="titleShow">
        <div class="tab">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in config" :key="item.name" :label="item.label" :name="item.name">

              <el-button v-for="btn in item.button" :key="btn.type" @click="btnClick(btn)">{{btn.name}}</el-button>

            </el-tab-pane>

          </el-tabs>
        </div>
      </div>
    </transition>




  </div>

</template>
<script>
  import { modelMessage } from "../../views/modelMessage/index.js"
  export default {
    props: {},
    data() {
      return {
        activeName: "update",
        config: [
          {
            label: "编辑",
            name: "update",
            button: [
              {
                name: '区域列表',
                type: 'region',
                active: false
              },
              {
                name: '区域相机',
                type: 'cameraList',
                active: false
              }
            ]

          },
          {
            label: "投影管理",
            name: "first",
            button: [
              {
                name: '俯视预览',
                type: 'preview',
                active: false
              },
              {
                name: '杆子投影',
                type: 'polePreview',
                active: false
              },
              // {
              //   name: '新增相机',
              //   type: 'listval'
              // },
              // {
              //   name: '小车航行',
              //   type: 'addpaths'
              // },
              {
                name: '刷新api',
                type: 'updateApi',
                active: false
              }
            ]
          },
          {
            label: "坐标管理",
            name: "second",
            button: [
              {
                name: '坐标转换',
                type: 'transformation',
                active: false
              },
              {
                name: '坐标查询',
                type: 'query',
                active: false
              },
              {
                name: '视野坐标',
                type: 'visualAngle',
                active: false
              },
              // {
              //   name: '点位选择',
              //   type: 'dot'
              // }
            ]

          },
          {
            label: "视野管理",
            name: "third",
            button: [
              {
                name: '视野飞到',
                type: 'flyTo',
                active: false
              },
            ]
          },
          {
            label: "区域管理",
            name: "fourth",
            button: [
              {
                name: '区域配置',
                type: 'regionConfig',
                active: false
              },
              {
                name: '区域主机',
                type: 'UpdateModelHost',
                active: false
              },
              {
                name: '区域协议',
                type: 'UpdateModelProxy',
                active: false
              }
            ]
          },
          {
            label: "杆子管理",
            name: "pole",
            button: [
              {
                name: '杆子配置',
                type: 'poleConfig',
                active: false
              },


            ]
          },
          {
            label: "模型",
            name: "model",
            button: [
              {
                name: '模型信息',
                type: 'modelMessage',
                active: false
              },


            ]
          }

        ],
        titleShow: true,
        modelMessage: null
      };
    },
    mounted() {
      const { active } = this.$route.query;
      if (active) {
        this.activeName = active;
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      btnClick(btn) {
        switch (btn.type) {
          case "modelMessage":
            btn.active = !btn.active
            if (btn.active) {
              btn.modelMessage = new modelMessage()
              btn.modelMessage.init()
              return
            } else {
              btn.modelMessage.destroy()
              btn.modelMessage = null
            }
            break;

          default:
            this.$emit("btnClick", btn.type);
            break;
        }


      },
    },
  };
</script>
<style lang="scss" scoped>
  .indentation {
    position: absolute;
    display: revert;
    width: 50px;
    height: 100px;
    z-index: 500;

    .img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .imgup {
      transform: rotate(90deg);
    }

    .imgdown {
      transform: rotate(-90deg);
    }

  }

  .title {
    position: absolute;
    /* display: flex; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 200;
    background: rgb(255, 255, 255);
    padding: 0 20px;
    box-sizing: border-box;


    .btn {
      margin-left: 20px;
    }

    .tab {
      margin-left: 50px;
      width: calc(100% - 50px);
    }
  }

  .el-tabs__item {
    font-size: 20px;
    font-weight: 700;
  }

  .el-tabs__item.is-active {
    color: #409eff;
  }

  .el-tabs__active-bar {
    background-color: #409eff;
  }
</style>