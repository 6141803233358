<template>
  <div class="cameraconfig">
    <span>边界区域</span>
    <div v-for="index in indexs" :key="index">
      <span>X:</span><span>{{index.x}}</span>
      <span>Y:</span><span>{{index.x}}</span>
      <span>Z:</span><span>{{index.x}}</span>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
export default {
  name: 'cameraconfig',
  props: {
  },
  data () {
    return {
      indexs: []
    }
  },
  created () {

  },
  beforeDestroy () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.cameraconfig {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  // border-color: #000;
  height: calc(100% - 50px);
  width: 30%;
  border: #000 5px solid;
  background-color: #000;
  padding: 10px;
  opacity: 0.8;
  color: #fff;
  animation: opacity 0.25s forwards 5s;
  ::v-deep .el-form-item__label {
  color: #fff;
}
}
::v-deep .cameraconfig
  .cameralist
  .el-table__header-wrapper
  .el-table__header
  th {
  background-color: rgba(70, 130, 180, 0.2);
  color: #fff;
}
.inputdiv {
  width: 100%;
}
::v-deep .el-table thead {
  color: #fff;
}


::v-deep .el-form-item {
    margin-bottom: 10px;
}
</style>
