<template lang="">


  <div class="button">
    <div class="el">
      <i class="el-icon-s-unfold" @click="packUp" title="关闭"></i>
    </div>
    <div class="buttonFrom" v-show="fromShow">
      <el-form ref="form" :model="option" label-width="80px">
        <el-form-item label="相机id">
          <el-input v-model.number="option.id" :disabled="true"></el-input>

        </el-form-item>
        <el-form-item label="相机经度">
          <el-input v-model.number="option.position.x"></el-input>
          <el-input-number v-model="option.position.x" @change="xyChange" :min="1" :max="1000" label="描述文字"
            :step="0.0000005"></el-input-number>
        </el-form-item>
        <el-form-item label="相机纬度">
          <el-input v-model.number="option.position.y"></el-input>
          <el-input-number v-model="option.position.y" @change="xyChange" :min="1" :max="1000" label="描述文字"
            :step="0.0000005"></el-input-number>
        </el-form-item>
        <el-form-item label="相机高度">
          <el-input v-model.number="option.position.z"></el-input>
        </el-form-item>
        <el-form-item label="高程差值">
          <!-- <el-input v-model.number="option.elevation"></el-input> -->
          <el-input-number v-model="option.elevation" :step="0.1" @change="updateCameraPosition"></el-input-number>
        </el-form-item>

        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="俯仰角">
              <div class="block">
                <el-slider v-model="option.rotation.x" :min="-90" :max="90" :step="0.1" show-input input-size="mini"
                  @change="updateRotation">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="横摆角">
              <div class="block">
                <el-slider v-model="option.rotation.y" :min="-180" :max="180" :step="0.1" show-input input-size="mini"
                  @change="updateRotation">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="旋转角">
              <div class="block">
                <el-slider v-model="option.rotation.z" :min="-180" :max="180" :step="0.1" show-input input-size="mini"
                  @change="updateRotation">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="近平面">
              <div class="block">
                <el-slider v-model="option.near" :min="0" :max="100" :step="0.1" show-input input-size="mini"
                  @change="updatenear">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="远平面">
              <div class="block">
                <el-slider v-model="option.far" :min="1" :max="50000" :step="10" show-input input-size="mini"
                  @change="updatefar">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="横视场角">
              <div class="block">
                <el-slider v-model="option.fov" :min="0" :max="180" :step="0.1" show-input input-size="mini"
                  @change="updatefov">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="col">
          <el-col :span="24">
            <el-form-item label="纵视场角">
              <div class="block">
                <el-slider v-model="option.fovy" :min="0" :max="180" :step="0.1" show-input input-size="mini"
                  @change="updatefovY">
                </el-slider>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="透明度">
          <el-input-number v-model="option.alpha" :precision="1" :step="0.1" :min="0" :max="1" @change="updateAlpha">
          </el-input-number>
        </el-form-item>
        <el-form-item label="视锥线">
          <el-radio-group v-model="option.debugFrustum" @change="changeshow">
            <el-radio :label="true">显示</el-radio>
            <el-radio :label="false">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="融合类型">
          <el-radio-group v-model.number="option.type">
            <el-radio :label="2">图片</el-radio>
            <el-radio :label="3">视频</el-radio>
          </el-radio-group>
              </el-form-item> -->
        <el-form-item>
          <el-row class="col">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button type="primary" @click="createVideo">投影</el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <el-button @click="delVideo">删除</el-button>
              </div>
            </el-col>
          </el-row>

          <el-row class="col">
            <!-- <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button @click="updateCameraPosition">相机位置</el-button>
              </div>
                  </el-col> -->
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <el-button @click="getpoint2">取点保存</el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <el-button @click="gotovideo">到视频点</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row class="col">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button @click="save">保存</el-button>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button @click="boundary">取投影区域</el-button>
              </div>
            </el-col>
            <!-- <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-button @click="getdian">取中心点</el-button>
              </div>
                  </el-col> -->
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <!-- <projectionArea class="projectionArea" v-if="projectionAreaShow"></projectionArea> -->
  </div>

</template>
<script>
  import * as cesium from "cesium"
  import projectionArea from "../projectionArea/index.vue";
  export default {
    name: "cameraCfg",
    components: {
      projectionArea
    },
    props: {
      camera: {
        type: Object,
        default: () => ({}),
      },
    },
    watch: {
      camera: {
        handler(newVal) {
          this.option = {
            // ...newVal
            id: newVal.id,
            position: {
              x: newVal.lng,
              y: newVal.lat,
              z: newVal.height,
            },
            far: newVal.farPlane,
            // 横摆角俯仰角
            rotation: {
              x: newVal.pitch,
              y: newVal.yaw,
              z: newVal.roll,
            },
            elevation: newVal.elevation,

            // 透明度
            alpha: 0.7,
            // 近平面
            near: newVal.nearPlane,
            // 视场角
            fov: newVal.hfov,
            // 是否显示视锥线
            debugFrustum: false,
            type: 3,
            aspectRatio: null,
            fovy: newVal.vfov,
          };
        },
        immediate: true,
        deep: true,
      },
    },

    data() {
      return {
        option: {},
        fromShow: true,
        projectionAreaShow: false,
      };
    },

    created() { },
    methods: {
      packUp() {
        this.fromShow = !this.fromShow;
      },

      submit(type, id) {
        this.$emit("submit", type, id);
      },
      getpoint2() {
        if (!this.camera.newCesiumVideo3d) {
          this.$notify({
            title: "警告",
            message: "未视频投影无法取点保存",
            type: "warning",
          });
        }
      },

      xyChange() {
        this.$emit("xyChange", this.option);
      },
      updateCameraPosition() {
        this.$emit("updateCameraPosition", this.option);
      },
      updateRotation() {
        this.$emit("updateRotation", this.option);
      },
      updatenear() {
        this.$emit("updatenear", this.option);
      },
      updatefar() {
        this.$emit("updatefar", this.option);
      },
      updatefov() {
        this.$emit("updatefov", this.option);
      },
      updatefovY() {
        this.$emit("updatefovY", this.option);
      },
      updateAlpha() {
        this.$emit("updateAlpha", this.option);
      },
      changeshow() {
        this.$emit("changeshow", this.option);
      },
      createVideo() {
        this.$emit("createVideo", this.option);
      },
      delVideo() {
        this.$emit("delVideo", this.option);
      },
      getpoint2() {
        this.$emit("getpoint2", this.option);
      },
      gotovideo() {
        this.$emit("gotovideo", this.option);

        // window.viewer.camera.flyTo({
        //   destination: new cesium.Cartesian3.fromDegrees(this.option.position.x, this.option.position.y, this.option.position.z + this.option.elevation),
        //   orientation: {
        //     heading: this.option.rotation.x,
        //     pitch: this.option.rotation.y,
        //     roll: this.option.rotation.z,
        //   },
        // });

      },
      save() {
        console.log(this.option, "保存数据")
        this.$emit("save", this.option);
      },
      boundary() {
        this.$emit("boundary", this.option);
        // this.projectionAreaShow = !this.projectionAreaShow
      },
    },
  };
</script>
<style lang="scss" scoped>
  .button {
    position: absolute;

    top: 100px;
    right: 10px;
    z-index: 100;
    border: #000 5px solid;
    background-color: #000;
    opacity: 0.8;
    color: #fff;
    height: calc(100% - 100px);

    ::v-deep .el-form-item__label {
      color: #fff;
    }
  }
</style>