<template>
  <!-- 相机列表 -->
  <div class="cameralist" v-draggable>
    <closeTitle :close="close"></closeTitle>
    <div>
      <div type="button" class="add" @click="addCamera">➕</div>
    </div>
    <el-table :data="cameralist" highlight-current-row @current-change="handleCurrentChange" stripe style="width: 100%"
      max-height="400" ref="singleTable" v-show="show">
      <el-table-column label="#" type="index" width="50"> </el-table-column>
      <el-table-column prop="id" label="相机id" width="100"> </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <i class="el-icon-video-camera item" title="设置为中心相机"
            @click.stop="SetTopoCentricCameraCfg(scope.$index, scope.row)"></i>

          <i class="el-icon-menu item" title="刷新相机分割区域" @click.stop="CameraSplitArea(scope.$index, scope.row)"></i>

          <i class="el-icon-delete item" title="删除相机" @click.stop="delCamera(scope.$index, scope.row)"></i>

          <i class="el-icon-edit item" title="修改相机" @click.stop="updateCameraConfig(scope.$index, scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import axios from "axios";
  import cameraCfg from "../cameraCfg/index.vue";
  import cameraconfig from "../cameraConfig/index.vue";
  import cameraSplitArea from "../cameraSplitArea/index.vue";
  import closeTitle from "../closeTitle/index.vue";

  export default {
    name: "cameralistTwoC",
    props: {
      cameralistShow: {
        type: Boolean,
        default: false,
      },
      cameralisClose: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      cameraCfg,
      cameraconfig,
      "camera-split-area": cameraSplitArea,
      closeTitle,
    },
    watch: {
      cameralistShow: {
        handler(newVal) {
          if (newVal) {
            console.log("相机列表show监听", this.cameralistShow);
          }
        },
        immediate: true,
      },
    },
    data() {
      return {
        cameralist: [],
        currentCamera: null,
        currentRow: null,
        show: true,
        showCameraConfig: false,
        configShow: false,
        cameraItem: null,
        dialogVisible: false,
        ID: null,
        cameraSplit: {
          cameraId: 0,
          imageOriginWidth: 0,
          imageOriginHeight: 0,
        },
      };
    },
    created() {

      console.log("相机列表", this.cameralistShow);
    },
    beforeDestroy() {
      console.log("关闭相机列表", this.cameralistShow);
    },
    mounted() {
      console.log("相机列表", this.cameralistShow);
      this.getcamera();
      this.watchStorage()
    },
    methods: {
      //获取相机列表数据

      getcamera() {
        console.log("获取相机列表数据");
        let activeRegion = window.sessionStorage.getItem("activeRegion")
        if (activeRegion) {
          activeRegion = JSON.parse(activeRegion)
          activeRegion = [activeRegion.regionId]
        } else {
          activeRegion = [""]
        }
        axios.post("/api/Calib/GetCameras", {
          ids: [

          ],
          regionModelIds: activeRegion
        }).then((row) => {
          this.cameralist = row.data.data;
        });
      },
      //相机数组点击
      handleCurrentChange(val) {
        this.currentRow = val;
        if (val) {
          this.$emit("emitindex", val);
        }
        //此处数组点击
      },

      cesiumCurrent(id) {
        let camera = this.cameralist.filter((item) => {
          return item.id === id;
        });
        this.$refs.singleTable.setCurrentRow(camera[0]);
      },

      // 设置中心相机
      async SetTopoCentricCameraCfg(index, row) {
        console.log(index, row);
        this.$confirm(`是否将${row.id}设置为中心相机?`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let rew = await axios.post("/api/Setting/SetTopoCentricCameraCfg", {
              RegionModelId: row.regionModelId,
              Point: row.point,
              Elevation: row.elevation,
              Lng: row.lng,
              Lat: row.lat,
              Height: row.height,
            });
            if ((rew.status = 200)) {
              this.$message({
                type: "success",
                message: `成功将${row.id}设置为中心相机`,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },

      //相机分割区域
      CameraSplitArea(index, row) {
        this.cameraId = row.channelId;
        let cameraSplit = {
          channelId: row.channelId,
          imageOriginWidth: row.splitSrcWidth,
          imageOriginHeight: row.splitSrcHeight,
        };
        this.$emit("CameraSplitArea", cameraSplit, true);
      },

      //删除相机
      async delCamera(index, row) {
        this.$confirm(`是否将${row.id}删除？`, "", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await axios.post("/api/Setting/DelCameraCfg", {
              keyPairsToDel: [
                {
                  id: row.id,
                  channelId: row.channelId,
                },
              ],
            });
            if (res.data.success) {
              this.$message({
                type: "success",
                message: `删除成功`,
              });
              this.getcamera();
            } else {
              this.$message({
                type: "info",
                message: "删除失败",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      //添加相机
      addCamera() {
        this.$emit("upCameraConfig", null);
      },

      //修改相机配置
      updateCameraConfig(index, row) {
        this.$emit("upCameraConfig", row);
      },

      //修改相机配置后更新列表
      uplist() {
        this.getcamera();
      },
      close() {
        console.log("关闭");
        // this.cameralistShow = false;
        this.$emit("close");
      },
      //监听sessionStorage变化
      watchStorage() {
        // window.addEventListener("storage", (event) => {
        //   console.log("监听sessionStorage变化", event);
        //   // this.getcamera();
        // })
        window.addEventListener("activeRegion", (event) => {
          console.log("监听到sessionStorage变化", event);
          this.getcamera();
        });
      }

    },
  };
</script>
<style lang="scss" scoped>
  .cameralist {
    position: absolute;
    bottom: 70px;
    left: 10px;
    z-index: 100;
    color: #fff;
    height: 430px;
    background-color: #fff;

    .button {
      width: 50px;
    }

    .add {
      text-align: center;
      line-height: 30px;
      width: 30px;
      text-shadow: 2px 2px 2px #022122;
      cursor: pointer;
      -webkit-animation: fontColor 1s;
      animation: fontColor 1s;
    }
  }


  .el {
    width: 30px;
    height: 30px;
  }

  .item {
    margin-left: 3px;
    margin-right: 3px;
  }

  .title {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #000;
    color: #fff;
  }
</style>