import * as cesium from "cesium";
// 添加模型,并设置飞行轨迹,飞行时间,飞行位置,飞行方向,飞行速度,飞行模型,并画出飞行轨迹
function addModelClock(item, start, stop, position) {
  console.log("添加模型", item);
  window.viewer.entities.add({
    id: item.id,
    name: item.name,
    NO: item.NO,
    pr: item.pr,
    craft: item.craft,
    availability: new cesium.TimeIntervalCollection([
      new cesium.TimeInterval({
        start: cesium.JulianDate.fromDate(new Date(start)),
        stop: cesium.JulianDate.fromDate(new Date(stop)),
      }),
    ]),

    position: position,
    orientation: new cesium.VelocityOrientationProperty(position),
    model: {
      show: true,
      uri: "http://62.234.216.56:9400/static/3D/glb/WhiteClothedForeigner.glb",
      scale: 0.0004,
      minimumPixelSize: 0.0,
      maximumScale: 0.0004,
      runAnimations: true,
    },
    path: {
      resolution: 1,
      material: new cesium.PolylineGlowMaterialProperty({
        glowPower: 0.1,
        color: cesium.Color.YELLOW,
      }),
      width: 10,
    },

    show: true,
  });
}
//计算飞行轨迹对应事件所在位置
function computeCirclularFlight(propertyes) {
  const property = new cesium.SampledPositionProperty();
  for (let i = 0; i < propertyes.length; i += 1) {
    property.addSample(
      cesium.JulianDate.fromDate(new Date(propertyes[i].time)),
      cesium.Cartesian3.fromDegrees(
        propertyes[i].position.x,
        propertyes[i].position.y,
        propertyes[i].position.z
      )
    );
  }
  return property;
}
//创建时间轴,设置时间轴范围
function createClock(startTime, stopTime) {
  const start = cesium.JulianDate.fromDate(new Date(startTime));
  const stop = cesium.JulianDate.fromDate(new Date(stopTime));
  window.viewer.clock.startTime = start.clone();
  window.viewer.clock.stopTime = stop.clone();
  window.viewer.clock.currentTime = stop.clone();
  window.viewer.clock.clockRange = cesium.ClockRange.LOOP_STOP; //Loop at the end
  window.viewer.clock.multiplier = -1;
  window.viewer.clock.shouldAnimate = true;
}
export function clockModel(item) {
  createClock(
    item.propertyes[0].time,
    item.propertyes[item.propertyes.length - 1].time
  );
  const position = computeCirclularFlight(item.propertyes);
  addModelClock(
    item,
    item.propertyes[0].time,
    item.propertyes[item.propertyes.length - 1].time,
    position
  );
}
export function destroy() {
  window.viewer.entities.removeAll();
}
