import axios from "axios";
async function login() {
    let res = await axios.post('/api/itosApi/api/Authentication', {
        user: window.login.username,
        password: window.login.password
    });
    window.sessionStorage.setItem('isWindowOpen', 'self');
    window.sessionStorage.setItem('token', res.data.token);
    // const payload = JSON.parse(
    //     decodeURIComponent(
    //         escape(
    //             window.atob(
    //                 res.data.token
    //                     .split('.')[1]
    //                     .replace('-', '+')
    //                     .replace('_', '')
    //             )
    //         )
    //     )
    // );
}
export { login }