export const imageType = [
  {
    value: "jpg",
    label: "jpg",
  },
  {
    value: "png",
    label: "png",
  },
];
export const modelType = [
  {
    value: "航飞",
    label: "航飞",
  },
  {
    value: "手工",
    label: "手工",
  },
  {
    value: "管道",
    label: "管道",
  },
];
